import { Box } from "@mui/material"
import { Outlet } from "react-router-dom";
import { SousMenuConfig } from "../../../application/components/SousMenu/SousMenuConfig"
import { useAuthStore } from "../../../application/store/auth.store";

export const AppConfiguration = () => {
    const  user  = useAuthStore((state) => state.user);

    return (
        <>
            <Box className="div-content">
                {['ADMIN', 'AGENT'].includes(user.role)  && <SousMenuConfig />}
                <Outlet />
            </Box>
        </>
    )
}