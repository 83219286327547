import NODE_API from "./axios.helper";

export const handleGetAnalytics = async(slug, filter: any = null) => {
    try {
        let res: any = null;
        if(filter){
            res = await NODE_API.get('/analytics/summary/'+slug+`?start_date=${filter?.startDate}&end_date=${filter?.endDate}`);
        }else{
            res = await NODE_API.get('/analytics/summary/'+slug);
        }
        if(res?.success){
            return res?.data;
        }
        return null;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetAnalyticsMonthly = async(slug) => {
    try {
        const res = await NODE_API.get('/analytics/summaryMonthly/'+slug);
        if(res?.success){
            return res?.data;
        }
        return null;
    } catch (error) {
        console.log(error);
    }
}
