import NODE_API from "./axios.helper";

export const handleAddHistories = async(data) => {
    try {
        const res = await NODE_API.post('/histories', {
            phoneNumber: data.phoneNumber,
            appointmentId: data.appointmentId,
            metadata: {
                status: data.status,
                mairie: data.mairie,
                typeDossier: data.typeDossier,
                nombrePersonne: data?.nombrePersonne,
                reason: data?.reason,
                heureRdv: data?.heureRdv,
                remarque: data.remarque,
                agent: data?.agent,
                usagerId: data?.usager?._id
            }
        });
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const GetSmsCountByMairie = async (mairieId: string) => {
    try {
        return await NODE_API.get('/histories/sms-count/' + mairieId);
    } catch (error) {
        console.log(error)
    }
}

export const GetSmsEmailHistories = async (mairieId: string, dataFilter: any = null) => {
    try {
        let query = 'page=0&limit=10';
        if(dataFilter){
            query = dataFilter
        }
        return await NODE_API.get(`/histories/sms-email/${mairieId}?${query}`);
    } catch (error) {
        console.log(error)
    }
}

