import { Box } from "@mui/material";
import { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { LIST_SOUS_MENU_SMS } from "./data";
import './SousMenu.style.scss';

export const SousMenuSMS = () => {
    const menus = useMemo(() => {
        return LIST_SOUS_MENU_SMS;
    }, []);

    const location = useLocation();

    return (
        <>
            <Box>
                <div className="div-sous-menu">
                    {menus.map((menu, key) => 
                        <Link
                            key={key}
                            className={location.pathname.includes(`/admin/smsEmail/${menu.key}`) ? 'item-sous-menu active' : 'item-sous-menu'}
                            to={`/admin/smsEmail/${menu.key}`}
                        >
                            {menu.name}
                        </Link>
                    )}
                </div>
            </Box>
            <Outlet/>
        </>
    )
}