import { Box, Button, Stack } from "@mui/material";
import IconSendSvgSuccess from '../../../../../../application/assets/svg/check-blast.svg';

export const ConfirmSendSmsSuccess = () => {
    return (
        <Box className="p-3">
            <div className="flex justify-center mb-2">
                <img src={IconSendSvgSuccess} width={80} alt="Logo"/>
            </div>
            <h3>SMS envoyé(s) avec succès !</h3>
        </Box>
    )
}
