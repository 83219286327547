export const LIST_SOUS_MENU = [
    {
        name: 'LA MAIRIE',
        key: 'mairie'
    },
    {
        name: 'LES AGENDAS',
        key: 'agendas'
    },
    {
        name: 'LES AGENTS',
        key: 'agents'
    },
    {
        name: 'LES MOTIFS DE RDV',
        key: 'motifs'
    }
]

export const LIST_SOUS_MENU_SUPER_ADMIN = [
    {
        name: 'LA MAIRIE',
        key: 'mairie'
    }
]

export const LIST_SOUS_MENU_USAGER = [
    // {
    //     name: 'Créer un usager',
    //     key: 'create_usager'
    // },
    {
        name: 'Liste des usagers',
        key: 'liste_usager'
    },
    {
        name: 'Exporter',
        key: 'import_export'
    }
]

export const LIST_SOUS_MENU_SMS = [
    {
        name: 'SMS',
        key: 'sms'
    },
    {
        name: 'Historique d\'EMAIL/SMS',
        key: 'email'
    }
]