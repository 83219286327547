import {Box, Grid, Stack, Typography} from "@mui/material";
import {useMediaQuery} from "usehooks-ts";
import { BREAKPOINT_MOBILE } from "../../../constant";
import '../../../application/styles/home.style.scss';
import './collectivity.style.scss';
import Laptop from '../../../application/assets/images/laptop.png';
import InfoLocale from '../../../application/assets/images/info.png';
import Amf from '../../../application/assets/images/amf.png';
import User from "../../../application/assets/images/user.png";
import AntsImage from "../../../application/assets/images/ants.jpeg";

const Collectivity = () => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);

    return (
        <Box sx={{width: '100%'}} className="header-1-container">
            <div className={"container-collectivity w-full"}>
                <Grid container className="container-grid justify-between">
                    <Grid item xs={mobile ? 12 : 6}>
                        <Stack>
                            <Stack className="title">
                                <p>La solution <span>DE GESTION</span></p>
                                <p>Des <span>RENDEZ-VOUS</span></p>
                                <p>Des <span>collectivités</span></p>
                            </Stack>
                            <p className="mini-title-collectivity">CNI / PASSEPORTS / agendas personnalisés</p>
                        </Stack>
                        <Stack marginTop={4}>
                            <p>Développée à l'initiative de l'Association des maires et des présidents d'EPCI d'Eure-et-Loir
                            en partenariat avec Info-locale, l'application rdvenmairie.fr est une solution de prise de rendez-vous
                            en ligne spécialement conçue pour les collectivités locales.</p>
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5}>
                        <Stack className={mobile ? 'mt-4': 'mt-0'}>
                            <img id={"laptop"} src={Laptop} alt={'laptop'} width={'400px'} className="m-auto" />
                            <p className="text-center uppercase mt-4 fw-600">Un partenariat :</p>
                            <Stack flexDirection={'row'} gap={2} justifyContent={'center'}>
                                <img id={"Amf"} src={Amf} alt={'Amf'} width={mobile ? '80px': '150px'} />
                                <img id={"Infolocale"} src={InfoLocale} alt={'Infolocale'} width={mobile ? '80px': '150px'} />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} marginTop={4}>
                        <h5 className="text-center fs-18 fw-600">Le 24 mai 2023, nous avons signé la convention d'interopérabilité et de partenariat entre la plateforme nationale « RDV MAIRIE » et notre solution.</h5>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={4}>
                        <Stack>
                            <img id={"laptop"} src={AntsImage} alt={'ants'} className="m-auto" />  
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={2}>
                        <Stack>
                            <p className='fw-600'>Notre solution rdvenmairie.fr répond dores et déjà aux 5 critères d'éligibilité à la majoration de la dotation "titres sécurisés" :</p>
                            <Stack>
                                <ul className="list-info-ants">
                                    <li>Les rendez-vous en ligne de la collectivité sont visibles via la plateforme nationale de l'ANTS de manière pérenne.</li>
                                    <li>Notre solution intègre le renseignement du numéro de pré-demande dans son fonctionnement habituel de prise de rendez-vous en ligne par l'usager.</li>
                                    <li>Notre solution fait appel à la fonctionnalité "anti doublons" de l'ANTS pour limiter la prise de rendez-vous en doublons.</li>
                                    <li>L'implantation de notre solution et son paramètrage par la collectivité n'entraînent pas de préférence locale par traitement différencié selon le lieu de résidence de l'usager.</li>
                                    <li>Notre solution répond aux standards de performance définis par l'ANTS.</li>
                                </ul>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={4}>
                        <Stack>
                            <p className="title-red">Notre offre <span> "TOUT EN UN"</span></p>
                            <Stack>
                                <ul className="list-info">
                                    <li>Mise à disposition de notre solution pour <span className='fw-600'>1 dispositif de recueil et 10 agendas personnalisés</span> (Ex: agenda des services, agenda des élus, réservation de véhicule ou de matériel...)</li>
                                    <li><span className='fw-600'>Abonnement</span> pour une période de <span className='fw-600'>12 mois</span> sans tacite reconduction.</li>
                                    <li><span className='fw-600'>2000 sms</span> inclus. (*)</li>
                                    <li> <span className='fw-600'>Support téléphonique et par email</span> du lundi au vendredi de 8h30 à 12h30 et de 13h30 à 18h.</li>
                                    <li> <span className='fw-600'>Installation, formation et paramètrage</span> de la solution inclus.</li>
                                </ul>
                            </Stack>
                            <p className='tarif'>Au tarif de 500€ TTC</p>
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={4}>
                        <Stack>
                            <p className="title-red">Notre <span>"ENGAGEMENT"</span></p>
                            <Stack>
                                <ul className="list-info">
                                    <li> 
                                        <span className='fw-600'>Nous vous garantissons le raccordement de votre logiciel de prise de rendez-vous en ligne avant le 1er juillet 2023</span> pour toute commande avant le 26 juin 2023 et ainsi vous permettre de bénéficier de:
                                        <ul className="list">
                                            <li> <span className='fw-600'>La dotation supplémentaire de 500€/an</span> et par dispositif de recueil. </li>
                                            <li> <span className='fw-600'>La dotation exceptionnelle de 1000€ au titre de l'année 2023</span> </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Stack>
                        </Stack>
                        <Stack>
                            <p>(*) 2000 sms ne vous suffisent pas ! Nous vous proposons des packs sms supplémentaires utilisables sans limite de delais et reportables d'une année sur l'autre :</p>
                            <ul className="list-price">
                                <li> Pack 1000 sms : 80€ HT </li>
                                <li> Pack 3000 sms : 210€ HT </li>
                                <li> Pack 5000 sms : 300€ HT </li>
                            </ul>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} marginTop={4}>
                        <p className="text-center"><span className="info-bloc-title">Principales fonctionnalités</span></p>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={4}>
                        <Stack>
                            <p className="title-red">Pour les <span>collectivités</span></p>
                            <Stack>
                                <ul className="list-info">
                                    <li>Une gestion différenciée des durées de rendez-vous selon que l’usager dispose ou non d’un numéro de pré-demande lors de sa prise de rendez-vous.</li>
                                    <li>Réduction du nombre de rendez-vous non honorés pour cause de doublons grâce à un partage de l’information entre les collectivités et la fonctionnalité "anti doublons" de l'ANTS.</li>
                                    <li>Présentation des doublons potentiels selon deux niveaux d'alerte.</li>
                                    <li>Agents : Prise de rendez-vous simple et rapide pour les rendez-vous sollicités au téléphone ou à l'accueil de la mairie.</li>
                                    <li>Solution intégrée de notification des usagers par sms en cas d'incapacité de les accueillir ( Panne de DR, agent indisponible...)</li>
                                    <li>Traçabilité des emails et sms envoyés à chaque usager et statut de chaque message (Envoyé, bloqué, ouvert...)</li>
                                    <li>Nombreuses statistiques générées en fonction des critères de dates que vous choisissez (Nombre de rdv. Répartition en fonction du mode de réservation, des motifs de rdv, du statut des rdv, du lieu d'habitation des usagers...)</li>
                                    <li>Personnalisation de la solution grâce à de nombreux paramétrages.</li>
                                </ul>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={mobile ? 12 : 5.5} marginTop={4}>
                        <Stack>
                            <p className="title-red">Pour les <span>usagers</span></p>
                            <Stack>
                                <ul className="list-info">
                                    <li>Une prise de rendez-vous simplifiée et disponible 24h/24 7j/7 sur PC, tablettes et smartphones.</li>
                                    <li>La proposition de rendez-vous au plus près dans leur bassin de vie et/ou au plus tôt.</li>
                                    <li>La possibilité de s'inscrire sur une liste d'attente et recevoir une alerte si un rendez-vous se libère plus tôt.</li>
                                    <li>L'accès à un espace sécurisé de gestion de ses rendez-vous (annulation, modification ajout de numéros de pré-demande).</li>
                                    <li>Une assistance à la préparation du rendez-vous à travers une note d'information présentée en fin de procédure de prise de rendez-vous et des notifications envoyées par email ou sms.</li>
                                    <li>Envoi d'un sms ou d'un email de rappel 24 ou 48h avant le rendez-vous.</li>
                                </ul>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container>
                    <Stack className="footer-landing">
                        <p className="text-center info-footer font-bold">Parce que rien ne vaut une démonstration...</p>
                        <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={3} marginTop={2}>
                            <img src={User} width={70} alt="Logo"/>
                            <Stack>
                                <Typography><span className={"font-bold"}>Contactez nous</span></Typography>
                                <Typography><span className={"font-bold"}>02 37 33 03 25</span></Typography>
                                <Typography><span className={"font-bold"}>contact@rdvenmairie.fr</span></Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </div>
        </Box>
    )
}

export default Collectivity;
