import {Box, Grid, Paper} from "@mui/material";
import {CalendarFull} from "./CalendarFull";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CustomDialog} from "./CustomDialog";
import {FullCalendarView} from "./FullCalendarView";
import {MairieCardInfo} from "./MairieCardInfo";
import { BREAKPOINT_TABLETTE } from "../../constant";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import { CustomLoading } from "./LazyLoading/LazyLoading";
import { getAgendasByMairieMotif } from "../../helpers/mairie.helper";
import { treatAgendaWithBusy } from "../../infrastructure/utils/agenda-mairie";
import AxiosContext from "../context/axios.context";
import { getMotifsBySlug } from "../store/mairie.store";

interface MairieCardType {
    selected?: any, 
    item: any, 
    detailView?: any, 
    onDateSelect?: any,
    busyDate?:any
}
export const MairieCard = (props: MairieCardType) => {
    const { selected, item, detailView, onDateSelect, busyDate } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(30);
    const { value: { instance: axios } } = useContext(AxiosContext);
    const [session, setSessionValue] = useSessionStorage('session', {} as any)
    const [agendasMairie, setAgendasMairie] = useState<any>([]);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);
    const selectedMotif = useMemo(() => selected.key.replaceAll('_',' '),[selected]);
    const handleCloseModal = () => {
        setOpen(false);
    };

    const getAgendasByMotif = useCallback(async() => {
        if(selected && item){
            setLoading(true);
            const res: any = await getAgendasByMairieMotif(item?._id, item?.slug, selected.key);
            if(res?.success){
                setAgendasMairie(res.data);
            }
        }
    }, [item, selected])

    const getMotif = useCallback(async() => {
        if(selectedMotif){
            setLoading(true);
            const res: any = await getMotifsBySlug(item.slug);
            if(res){
                if(res?.length > 0){
                    const motif = res.find((data) => data.name === selectedMotif);
                    if(motif){
                        if(session?.ants){
                            setDuration(motif.antsDuration)
                        }else{
                            setDuration(motif.duration)
                        }
                    }
                }
            }
        }
    }, [item.slug, selectedMotif, session?.ants]);

    const calendarOfAgendas = useMemo(() => {
        if(agendasMairie?.length > 0){
            const agenda = treatAgendaWithBusy(item, agendasMairie);
            setLoading(false);
            return agenda;
        }else{
            setLoading(false);
            return []
        }
    }, [agendasMairie, item]);

    useEffect(() => {
        getMotif();        
        getAgendasByMotif();
    }, [getAgendasByMotif, getMotif]);


    return <Paper>
        <Grid container padding={5}>
            <Grid item xs={(detailView || tablette) ? 12 : 5}>
                <MairieCardInfo defaultDuration={duration} item={item} detailView={detailView} />
            </Grid>
            {loading ? <Grid item xs={tablette ? 12 : 6} marginTop={tablette ? 5 : 0} className="flex justify-center">
                <CustomLoading size={100} /> 
            </Grid>:
            !detailView && 
            <Grid item xs={tablette ? 12 : 7} marginTop={tablette ? 5 : 0}>
                <Box>
                     {(item.available_slots ?? []).length <= 0 ?
                         <CalendarFull/> :
                         <>
                             {/* <HourDateCalendar
                                 busy={(busyDate && busyDate[item.slug]) || {}}
                                 hourlyAgendas={calendarOfAgendas}
                                 step={duration}
                                 onDateSelect={onDateSelect}
                                 availableSlots={item.available_slots}
                             />
                             <Stack alignItems={"center"} marginTop={4}>
                                 <Button
                                     variant={"outlined"}
                                     color={"secondary"}
                                     onClick={() => setOpen(true)}
                                 >Afficher l'agenda complet</Button>
                             </Stack> */}
                             <FullCalendarView
                                 selected={selected}
                                 onDateSelect={onDateSelect}
                                 item={item}
                                 busy={(busyDate && busyDate[item.slug]) || {}}
                                 hourlyAgendas={calendarOfAgendas}
                             />
                         </>
                     }
                </Box>
            </Grid>
            }
        </Grid>

        <CustomDialog
            setOpen={setOpen}
            open={open}
            onClose={handleCloseModal}
            component={
                <FullCalendarView
                    selected={selected}
                    onDateSelect={onDateSelect}
                    item={item}
                    busy={(busyDate && busyDate[item.slug]) || {}}
                    hourlyAgendas={calendarOfAgendas}
                />}
        />

    </Paper>
}
