import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import { SelectCustom, useStyles } from './select-multiple.style';

interface MutipleSelectProps {
    loading?: boolean,
    title?: string,
    placeholder?: string,
    listData: any[],
    selectedValues: any,
    handleChangeDataSelected: any
}
export const SelectMultiple = ({ loading = false, title, placeholder, listData, selectedValues, handleChangeDataSelected }: MutipleSelectProps) => {
  const styles: any = useStyles();

  return (
    <div className="input-wrapper">
      {!loading && <FormControl className={styles.multiSelectContainer}>
        {title && <InputLabel className={styles.multiSelectLabel}>{title}</InputLabel>}
        <SelectCustom
          multiple
          onChange={(event) => {
            const { target: { value } }:any = event;
            handleChangeDataSelected(value);
          }}
          value={selectedValues}
          input={<OutlinedInput />}
          renderValue={(selected: any) => {
            if(selected?.length > 0 ) 
              return selected.map((item:any, key:number) => {
                const char = selected[key+1] ? ', ' : '';
                return (
                  (item.name) + char
                );
              })
            else 
              return (placeholder)
          }}
            
                      
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
                width: 250
              }
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            variant: 'menu'
          }}
        >
          {listData && listData.map((item: any, key:number) => (
            <MenuItem key={item._id} value={item}>
              <Checkbox checked={selectedValues.filter(selected => selected._id === item._id).length > 0} className={styles.multiSelectCk}/>
              <ListItemText primary={item.name} className={styles.multiSelectLiItem} />
            </MenuItem>
          ))}
        </SelectCustom>
      </FormControl>}
    </div>
  );
};