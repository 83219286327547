import React, { useState, useRef } from 'react'
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import dayjs from 'dayjs'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

// Our app
const InputUpload = ({ files, setFiles }) =>  {
  const pondRef = useRef(null);
  return (
    <FilePond
        ref={pondRef}
        files={files}
        allowMultiple={true}
        server={{
          process: (fieldName, file, metadata, load) => {
            setTimeout(() => {
              load(dayjs());
            }, 1500);
          },
          load: (source, load) => {
            fetch(source)
            .then((res) => res.blob())
            .then(load);
          },
        }}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file))
        }}
        labelIdle='Faites glisser et déposez vos fichiers ou <span class="filepond--label-action">Parcourir</span>'
    ></FilePond>
  )
}

export default InputUpload;