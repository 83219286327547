import {
    Button, Checkbox,
    FormControl, IconButton, Link,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import * as yup from "yup";
import {Search} from "@mui/icons-material";
import {useEffect, useMemo, useState} from "react";
import {SelectTypeDossier} from "./SelectTypeDossier";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import {BREAKPOINT_MOBILE} from "../../constant";
import { SelectCodePostal } from "./SelectCodePostal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalAnts } from "./ModalAnts/ModalAnts";
import { useAuthStore } from "../store/auth.store";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
    city: yup.object().required(),
    zip: yup.string().required()
});

export const SelectDemande = (props) => {
    const { onSearch, antsModalCallback } = props;
    const location = useLocation();
    const  motifs  = useAuthStore((state) => state.motifs);
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [session, setSession] = useSessionStorage<any>('session', {});
    const { register, setValue, handleSubmit, formState: { errors }, getValues} = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const [selectedTypeDossierKey, setSelectedTypeDossierKey] = useState('');
    const [selectedDossier, setSelectedDossier] = useState({} as any);
    const [nombrePersonne, setNombrePersonne] = useState<any>(1);
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);

    const maxPersonne = useMemo(() => {
        if (location.pathname.includes('iframe') && session?.ville?.slug === "meursault")
            return 3;
        else return 6
    }, [location.pathname, session?.ville]);

    const handleTypeDossierChange = (value) => {
        setSelectedTypeDossierKey(value);
        setSession(prev => ({...prev, typeDossier: value}))
    }


    const onSubmitHandler = (data) => {
        if (nombrePersonne > 0 && nombrePersonne <= 10) {
            if(['DEPOT_CNI','DEPOT_PASSEPORT','DEPOT_CNI_&_PASSEPORT'].includes(selectedTypeDossierKey)){
                onSearch({
                    typeDossier: selectedTypeDossierKey,
                    motif: motifs.find(motif => motif.key === selectedTypeDossierKey),
                    ville: data?.city,
                    zip: data?.zip,
                    nombrePersonne
                });
                handleOpen();
            }else{
                onSearch({
                    typeDossier: selectedTypeDossierKey,
                    motif: motifs.find(motif => motif.key === selectedTypeDossierKey),
                    ville: data?.city,
                    zip: data?.zip,
                    ants: false,
                    nombrePersonne
                });
                antsModalCallback();
            }
        }
    }
    
    const handleModalAntsCallback = ({ants}) => {
        setSession(prev => ({ ...prev, ants }))
        setOpen(false);
        antsModalCallback()
    }

    const handleNombrePersonne = (event) => {
        if (event.target.value === '') {
            setNombrePersonne(null);
        }else if (Number(event.target.value) < 1) {
            setNombrePersonne(1);
        }
        else if (Number(event.target.value) >= maxPersonne) {
            setNombrePersonne(maxPersonne);
        } else {
            setNombrePersonne(event.target.value)
        }
    }

    useEffect(() => {
        const dossier = motifs.find(dossier => dossier.key === selectedTypeDossierKey);
        setSelectedDossier(dossier);
    }, [selectedTypeDossierKey, motifs])

    return (
    <Paper elevation={4} className={"p-12"} sx={ {
        maxWidth: 600,
        width: mobile ? 350 : 'auto'
    } }>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <p className={"font-bold text-xl"}>Votre demande</p>
            <p className={"font-light"}>Sélectionnez l'objet de votre demande de rendez-vous</p>

            <Stack marginY={3} spacing={2}>
                <FormControl sx={{ my: 1, width: '100%' }}>
                    <SelectTypeDossier onSelect={ handleTypeDossierChange } selected={selectedTypeDossierKey} motifs={motifs}/>
                </FormControl>
                <SelectCodePostal disabled={false} register={register} setValue={setValue} />  
                {(errors?.city || errors?.zip) && <p className="mb-0 text-red-500">Les valeurs ne devraient pas être vide</p>}
            </Stack>

            {selectedDossier?.key && (selectedDossier?.key.includes('CNI') || selectedDossier?.key.includes('PASSEPORT')) && <>
                <Stack>
                    <Typography variant={"subtitle1"} color={"secondary"}>
                        <span className={"font-bold"}>
                            Nombre de personne(s) concernée(s) par votre demande
                        </span>
                    </Typography>
                    <List
                        sx={{ width: '100%' }}
                    >
                        <ListItem>
                            <ListItemText primary={selectedDossier.label} />
                            <OutlinedInput
                                inputProps={{ step: 1, min: 1, max: maxPersonne }}
                                value={nombrePersonne}
                                onChange={handleNombrePersonne}
                                type="number"
                                sx={{width: 65}}
                            />
                        </ListItem>
                    </List>
                </Stack>
            </>}
            <Stack flexDirection={'row'} alignItems={'center'}>
                <IconButton sx={{ padding: 0, marginLeft: 0 }}><Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}/></IconButton>
                <Typography>Je reconnais avoir lu et compris les <Link href='/cgu' target='_blank'>CGU</Link> et je les accepte.</Typography>
            </Stack>
            <Button fullWidth variant={"contained"} sx={ { borderRadius: 28 } } disabled={!(selectedDossier && checked)} type="submit"><Search/></Button>
        </form>
        <ModalAnts open={open} handleClose={handleClose} nombrePersonne={nombrePersonne} callback={handleModalAntsCallback} />
    </Paper>
    )
}
