import React, { useState } from 'react';
import {Stack, Typography} from "@mui/material";
import {CircleRounded} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import {grey} from "@mui/material/colors";
import { handleUpdateAppointment } from '../../../../helpers/appointment.helper';
import { handleAddHistories } from '../../../../helpers/histories.helper';
import { getAntsRdv, useMairieStore } from '../../../../application/store/mairie.store';
import '../dashborad.style.scss';
import { Popup } from "../../../../application/components/Popup/Popup";
import {Accordion, AccordionDetails, AccordionSummary, Link} from "@mui/material";
import { ExpandCircleDown } from "@mui/icons-material";
import { handleDeleteAppointment } from '../../../../helpers/ants.helper';

const AntsDateCard = ({data, type}) => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const  rdvConsumed  = useMairieStore((state) => state.rdvConsumed);
    const  rdvExpired  = useMairieStore((state) => state.rdvExpired);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const handleDeletePerson = async( data ) => {
        const {appointment} = data;
        setLoading(true);
        let res: any = null;
        let input: any = {_id: appointment._id, status: 'CANCELED'};
        const concernedPerson = appointment?.concernedPerson?.filter(person => person?.ants !== data?.application_id);
        if(concernedPerson?.length > 0){
            const dureeMotif = Number(appointment?.dureeRdv)/Number(appointment?.concernedPerson?.length);
            input = {
                ...input, 
                concernedPerson: concernedPerson, 
                status: 'PENDING',
                dureeRdv: Number(appointment?.dureeRdv)-(dureeMotif || 0),
                nombrePersonne: concernedPerson?.length
            }
        }
        res = await handleUpdateAppointment(input);
        if(res?.success){
            if(input?.status === 'CANCELED'){
                for(const person of appointment?.concernedPerson){
                    if(person?.ants !== ''){
                        await handleDeleteAppointment({
                            ants: person?.ants,
                            meeting_point: mairies[0]?.name,
                            meeting_point_id: mairies?.[0]?._id,
                            appointment_date: appointment?.heureRdv?.replace('T', ' ')
                        })
                    }
                }
            }
            const result = await handleAddHistories({
                phoneNumber: appointment.phoneNumber,
                appointmentId: appointment._id,
                status: input?.status,
                mairie: appointment.mairie,
                heureRdv: appointment.heureRdv,
                typeDossier: appointment.typeDossier,
                nombrePersonne: concernedPerson?.length > 0 ? concernedPerson?.length : 1,
                usager: appointment.usager
            })
            
            if(result.success){
                if(type === 'consumed'){
                    useMairieStore.getState().setConsumed(rdvConsumed?.filter(row => row?.application_id !== data?.application_id));
                    getAntsRdv(mairies, type);
                }
                if(type === 'expired'){
                    useMairieStore.getState().setExpired(rdvExpired?.filter(row => row?.application_id !== data?.application_id));
                    getAntsRdv(mairies, type);
                }
                setSuccess(true);
            }
        }
        setLoading(false);
    }

    const handleCloseAlert = () => {
        setSuccess(false);
    }

    return <>
        <Stack flexDirection={'row'} sx={{ minWidth: '250px', width: '100%', backgroundColor: red['200'], borderRadius: '8px'}} padding={1}>
            <Stack padding={'10px 8px'}><CircleRounded sx={{ fontSize: 10, color: red['900'] }}/></Stack>
            <Stack padding={'5px 0 0 0'} flexBasis={'100%'}>
                <Stack>
                    <Typography fontWeight={'bold'}>RDV {dayjs(data?.appointment?.heureRdv)?.format('DD MMMM YYYY - HH:mm')}</Typography>
                </Stack>
                <Stack flexDirection={'row'} gap={1}>
                    <Typography fontWeight={'light'}>{data?.appointment?.firstName}</Typography>
                    <Typography fontWeight={'bolder'}>{data?.appointment?.lastName}</Typography>
                    <span>|</span>
                    <Typography>{data?.appointment?.typeDossier?.replace(/_/g, ' ')} ({data?.appointment?.nombrePersonne})</Typography>
                </Stack>
                <Stack>
                    <Typography variant={'caption'}>
                        {data?.appointment?.phoneNumber}
                    </Typography>
                </Stack>
            </Stack>
            <Stack alignItems={'center'} justifyContent={'center'}>
                <LoadingButton
                    loading={loading} 
                    variant="contained" 
                    color='primary' 
                    className="btn-reset-ants"
                    startIcon={<CancelIcon />}
                    onClick={() => handleDeletePerson(data)}
                >
                    Annuler le RDV
                </LoadingButton>
            </Stack>
        </Stack>
        <Popup message={'Rendez-vous annulé avec succès'} type="success" open={success} handleClose={handleCloseAlert} />
    </>
}

const AntsDuplicatedCard = ({data}) => <>
    <Accordion 
        sx={{ width: '100%' }}
    >
        <AccordionSummary
            expandIcon={<ExpandCircleDown />}
            sx={{ backgroundColor: grey[100], width: '100%' }}
        >
            <Stack>
                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    <Typography><b>N° {data?.application_id}</b> {data?.appointment.firstName} {data?.appointment.lastName}</Typography>
                    <Typography>{data?.appointment?.phoneNumber}</Typography>
                    <Typography>{data?.appointment?.email}</Typography>
                    <Typography>{data?.appointment?.typeDossier?.replace(/_/g,' ')}</Typography>
                </Stack>
            </Stack>
        </AccordionSummary>
        <AccordionDetails>
            {/* { uniqBy(data?.duplicated, (v: any) => [v, v.mairie].join())?.map(entry => ( */}
            {data?.appointments.map(entry => (
                <Stack flexDirection={'row'} sx={{ minWidth: '250px', width: '100%', backgroundColor: red['200'], borderRadius: '8px', marginTop: '10px'}} padding={1}>
                    <Stack padding={'10px 8px'}><CircleRounded sx={{ fontSize: 10, color: red['900'] }}/></Stack>
                    <Stack padding={'5px 0 0 0'} flexBasis={'100%'}>
                        <Stack>
                            <Typography fontWeight={'bold'}>RDV {dayjs(entry.appointment_date)?.format('DD MMMM YYYY - HH:mm')} | {entry?.meeting_point}</Typography>
                        </Stack>
                    </Stack>
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <Link target={'_blank'} href={entry?.management_url} style={{ color: '#FFFFFF' }}>
                            <LoadingButton
                                variant="contained" 
                                color='primary' 
                                className="btn-reset-ants"
                                startIcon={<CancelIcon />}
                            >
                                Annuler le RDV
                            </LoadingButton>
                        </Link>
                    </Stack>
                </Stack>
            ))}
        </AccordionDetails>
    </Accordion>
</>

export const AntsComponentDate = ({data, type}) => {
    return <>
        <Stack sx={{ backgroundColor: 'white', padding: '1em', borderRadius:'8px'}}>
            <Typography variant={'h5'}>
                {type === 'consumed' && 'Numéro(s) de pré-demande "consommé(s)"'}
                {type === 'expired' && 'Numéro(s) de pré-demande expiré(s)'}
                {type === 'duplicated' && ' Rdv en doublon (par comparaison  des numéros de pré demande)'}
            </Typography>
            <Typography variant={'caption'} className={'text-gray-400'}>
                {type === 'consumed' && 'Il s\'agit d\'un rendez-vous dont le dossier a déjà été envoyé et instruit en préfecture. Sauf erreur de saisie de l\'usager, cette prise de rendez-vous n\'aboutiera pas.'}
                {type === 'expired' && ''}
            </Typography>
            <Stack gap={1} flexDirection={'row'} flexWrap={'wrap'} marginTop={3} sx={{maxHeight: '425px', overflow: 'auto'}}>
                { data && data?.length > 0 ? data.map(entry => (
                    <>
                        {type !== 'duplicated' && <AntsDateCard data={entry} type={type}/>}
                        {type === 'duplicated' && <AntsDuplicatedCard data={entry}/>}
                    </>
                )): 
                <p>
                    Aucun RDV concerné actuellement.
                </p>}
            </Stack>
        </Stack>
    </>
}
