export const menuMotifAdmin = [
    {
        name: 'Ajouter',
        key: 'add'
    },
    {
        name: 'Modifier',
        key: 'modify'
    }
];

export const listCheckMotif = [
    { text: 'Confirmation du rendez-vous', name: 'confirm' },
    { text: 'Annulation du rendez-vous', name: 'cancel' },
    { text: 'Modification du rendez-vous', name: 'update' },
    { text: 'Rappel rendez-vous', name: 'reminder' },
    { text: 'Libération de rendez-vous', name: 'release' },
];

export const listInfoContent = [
    { text: 'Prénom de l\'usager', id: '$prenom' },
    { text: 'Durée du rendez-vous', id: '$duree-rdv' },
    { text: 'Nom de l\'usager', id: '$nom' },
    { text: 'Nom de la mairie', id: '$nom-mairie' },
    { text: 'Adresse de la mairie', id: '$address-mairie' },
    { text: 'Date et heure du rendez-vous', id: '$date-heure-rdv' },
    { text: 'Type document', id: '$type-documents' },
    { text: 'Lien map', id: '$link-map' },
    { text: 'Nombre personnes', id: '$nbre' },
    { text: 'Date du rendez-vous modifié', id: '$date-modify-rdv' },
];

export const initialMotif = {
    confirm: [
        { type: 'SMS', checked: false, subject: '', content: null },
        { type: 'EMAIL', checked: false, subject: '', content: null },
    ],
    cancel: [
        { type: 'SMS', checked: false, subject: '', content: null },
        { type: 'EMAIL', checked: false, subject: '', content: null },
    ],
    update: [
        { type: 'SMS', checked: false, subject: '', content: null },
        { type: 'EMAIL', checked: false, subject: '', content: null },
    ],
    reminder: [
        { type: 'SMS', checked: false, subject: '', content: null },
        { type: 'EMAIL', checked: false, subject: '', content: null },
    ],
    release: [
        { type: 'SMS', checked: false, subject: '', content: null },
        { type: 'EMAIL', checked: false, subject: '', content: null },
    ]
}
