import { Checkbox } from "@mui/material";
import { useMemo } from "react";

interface PropsCheckBox {
    item: any;
    state: any;
    setState: any;
    hidden?: boolean;
}

const DivCheckbox = ({
    item,
    state,
    setState,
    hidden = false,
}: PropsCheckBox) => {
    const isDisable: any = useMemo(() => {
        let etat = Object.keys(state).length;
        Object.keys(state).map((key) => !state[key] && etat--);
        return etat;
    }, [state]);

    return (
        <Checkbox
            name={item}
            checked={state[item]}
            defaultChecked={true}
            disabled={isDisable === 1 && state[item]}
            onChange={() =>
                setState((currentState) => ({
                    ...currentState,
                    [item]: !currentState[item],
                }))
            }
            hidden={hidden}
        />
    );
};

export default DivCheckbox;
