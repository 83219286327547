import NODE_API from "./axios.helper";

export const handlePutCalendar = async(data) => {
    try {
        const res = NODE_API.post('/calendar',{
            mairie: data._id,
            hours: data.hours,
            holidays: data.holidays
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetBusyDate = async(setBusyDate) => {
    try{
        const res = await NODE_API.get('/appointment/busy-date')
        if(res?.success){
            setBusyDate(res?.data)
        }
    }catch (error) {
        console.log(error);
    }
}