import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

export const BlocNavIcon = styled(Grid)`
    padding: 0px 20px;
`;

export const BlocMenuBO = styled(Grid)`
    justify-content: start;
    height: 100%;
    display: flex;
    align-items: center;
    .link{
        height: 100%;
        padding: 0px 20px;
        display: flex;
        color: black;
        align-items: center;
        text-transform: uppercase;
        &:hover{
            background: #E9E9E9;
        }
        &.active{
            font-weight: 500;
            background: #E9E9E9;
        }
    }
`;

export const BoxFilter = styled(Box)`
    background: #FFFFFF;
    border-radius: 0px 20px 20px 0px;
    padding: 20px;
    margin: 0px 10px 0px 0px;
    fieldset{
        margin: 0px;
    }
    .title-filter{
        font-size: 18px;
        font-weight: 500;  
    }
    .description-filter{
        font-size: 10px;
        color: #808080;
    }
`;

export const BoxLegende = styled(Box)`
    background: #DFBCBC;
    padding: 15px;
    margin: 0px 10px 0px 0px;
    .bloc-list{
        padding: 10px;
        background: #FFFFFF;
        border-radius: 10px;
    }
`;

export const CardLegende = styled(Grid)<any>`
    background: ${(props:any) => props.color};
    border: 1px solid ${(props:any) => props.border} !important;
    padding: 5px 5px;
    min-height: 50px;
    height: auto;
    position: relative;
    img{
        position: absolute;
        top: 5px;
        right: 5px;
    }
    p{
        font-size: 12px;
        color: #000000;
    }
`;