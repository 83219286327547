import { FormControl, InputAdornment, OutlinedInput, Paper, Stack, Grid } from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import '../../Usager.style.scss';
import { SelectCodePostal } from "../../../../../application/components/SelectCodePostal";
import { InputPhone } from "../../../../../application/components/InputPhone/InputPhone";

export const EditUsager = ({ register, errors, getValues, control, setValue, usager }) => {

    const params = {
        register, 
        errors,
        control,
        getValues
    }

    return (
        <>
            <Stack gap={2} className="padding-left-right">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Nom de la usager*'}
                                    defaultValue={usager?.lastName ?? ''}
                                    {...register("lastName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.lastName ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('lastName') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Prénom de l\'usager*'}
                                    defaultValue={usager?.firstName ?? ''}
                                    {...register("firstName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.firstName ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('firstName') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                <Stack>
                    <SelectCodePostal disabled={false} register={register} setValue={setValue} data={usager} />
                </Stack>  
                <Stack>
                    <Paper className="custom-paper">
                        <InputPhone {...params} defaultValue={usager?.phoneNumber?.replace('+33','0') ?? ''} />
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse email*'}
                                type={"email"}
                                defaultValue={usager?.email ?? ''}
                                {...register("email")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.email ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
            </Stack> 
        </>
    )
}
