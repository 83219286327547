export const menuAgendaAdmin = [
    {
        name: 'Paramètrage',
        key: 'parameter'
    },
    {
        name: 'Ajouter',
        key: 'add'
    },
    {
        name: 'Modifier',
        key: 'modify'
    },
    {
        name: 'Exporter les Rendez-vous',
        key: 'export'
    }
];