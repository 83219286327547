import {Box, Stack, Typography} from "@mui/material";
import { ReactComponent as UnhappySvg} from "../assets/svg/unhappy.svg";

export const CalendarFull = () => {
    return <>
        <Stack
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
            className={"bg-neutral-100 rounded-sm text-center"}
            padding={5}
        >
            <UnhappySvg/>
            <Typography><span className={"font-bold"}>Pas de rendez-vous disponible actuellement</span></Typography>
        </Stack>
    </>
}
