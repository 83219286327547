import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {LocationOn} from "@mui/icons-material";
import {ReactComponent as TownHallSvg} from "../assets/svg/town-hall.svg";
import { useEffect, useState } from "react";
import { API, BREAKPOINT_MOBILE } from "../../constant";
import { useNavigate } from "react-router-dom";
import { CalendarWeek } from "./CalendarWeek/CalendarWeek";
import { useMediaQuery } from "usehooks-ts";
import { CustomDialog } from "./CustomDialog";
import DetailsInfoMairie from "./DetailsInfoMairie";

export const PreviewMairie = ({item, ...props}) => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState<any>(null);
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const [open, setOpen] = useState<boolean>(false);
    
    const handleCloseModal = () => setOpen(false);

    useEffect(() => {
        if(item?.city_logo || item?.picture){
            setProfile(item?.city_logo ?? API+item?.picture);
        }
    }, [item])

    return <>
        {item && <Stack>
            <Typography variant={"h4"}><span className={"font-bold"}>Lieu de rendez-vous</span></Typography>
            <Stack flexDirection={'row'} width={'100%'} gap={3}>
                <p className={"text-3xl"}>{item.name}</p>
                <Box>
                    <Button variant={"text"} color={'secondary'} onClick={() => navigate('/search')}>Modifier</Button>
                </Box>
            </Stack>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box className="my-5">
                    {profile ? <img className="profile-mairie-preview" src={profile} width={"100%"} height={"100%"} alt={item.name} /> : <TownHallSvg/>}
                </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack flexDirection={"column"}>
                        <p className={"font-bold text-sm"}>Adresse</p>
                        <p>{item?.public_entry_address ?? item?.address}</p>
                        <p>{item.zip} {item.city_name ?? item?.city}</p>
                    </Stack>
                    <Stack flexDirection={"row"} marginTop={3}>
                        <LocationOn/>
                        <p>{Math.round(item?.distance ?? 0)} km</p>
                    </Stack>
                    <Button color={"secondary"} onClick={() => setOpen(true)} variant={"text"}>Voir details</Button>
                </Grid>
                {!mobile && <Grid item xs={12}>
                    <Stack flexDirection={"column"} marginBottom={5} className="disableDiv">
                        <p className={"font-bold text-sm mb-4"}>Heures d'ouverture</p>
                        {/* <p>{`${hourOpen[0].startHour} à ${hourOpen[0].endHour}`} {hourOpen[1].startHour !== '-' && ' - '+hourOpen[1]?.startHour+' à '+hourOpen[1]?.endHour}</p> */}
                        {item?.calendar?.hours && <CalendarWeek calendarWeek={item.calendar?.hours} handleChangeHour={() => null} isDisabled={true} />}
                    </Stack>
                </Grid>}
            </Grid>
        </Stack>}
        <CustomDialog
            setOpen={setOpen}
            open={open}
            onClose={handleCloseModal}
            component={
                <DetailsInfoMairie mairie={item}  />}
        />
    </>
}
