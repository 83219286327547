import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HeaderTable } from './header/Header';
import uuid from 'react-uuid'

type Order = 'asc' | 'desc';  

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
    padding: '1rem',
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: any;
    originalRows: any[];
    handleChangeRows: any;
}
  

function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell) => (
            <HeaderTable {...props} headCell={headCell} />
          ))}
        </TableRow>
      </TableHead>
    );
}

const CustomDisplayLabelPagination = ({ from, to, count }) => {
    return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`;
}

const TableSorting = ({ id = uuid, rows, headCells, children, paramsSorting, setRows, originalRows }) => {
    const {order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage} = paramsSorting;
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TableContainer component={Paper} id={id}>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                        handleChangeRows={setRows}
                        originalRows={originalRows}
                    />
                    <TableBody>
                        {children}
                        {rows.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={6}>Aucun résultat</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage={"Par:"}
                labelDisplayedRows={CustomDisplayLabelPagination}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>)
}

export default TableSorting;