import { useState } from "react";
import { visuallyHidden } from '@mui/utils';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box, styled, TextField } from "@mui/material";
import isArray from "lodash/isArray";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from 'date-fns/locale/fr';
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
    padding: '1rem',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D3D3D',
        color: theme.palette.common.white,
        flexDirection: 'column'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTabelShortLabel = styled(TableSortLabel)(({theme, color}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto'
}))

const TextFieldCustom = styled(TextField)(({ theme, color }) => ({
    color: '#FFFFFF',
    '& .MuiInputBase-root': {
        color: '#FFFFFF'
    },
    '& .MuiButtonBase-root':{
        color: '#FFFFFF'
    },  
    '& .MuiOutlinedInput-notchedOutline':{
        borderColor: '#FFFFFF',
        color: '#FFFFFF'
    }
}))

export const HeaderTable = ({headCell, orderBy, order, onRequestSort,  handleChangeRows, originalRows}) => {
    const [searched, setSearched] = useState<any>(null);
    const createSortHandler =
      (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const requestSearch = (searchedVal: any, keys: any, type) => {
      setSearched(searchedVal);
    
      const filteredRows = originalRows.filter((row) => {
        switch (type) {
            case 'numeric':
                if(searchedVal !== ''){
                    if(isArray(keys)){
                        return keys.some(function(key) {
                            return searchedVal.includes(row[key])
                        })
                    }else
                        return row[keys] === Number(searchedVal);
                }else{
                    return row;
                }
            case 'text':
                if(isArray(keys)){
                    return keys.some(function(key) {
                        return row[key]?.toLowerCase().includes(searchedVal.toLowerCase());
                    })
                }else{
                    return row[keys]?.toLowerCase().includes(searchedVal.toLowerCase());
                }
            case 'date':
                if(searchedVal){
                    return dayjs(row[keys]).isSame(dayjs(searchedVal), 'day');
                }else{
                    return row;
                }
            default:
                return row;
        }
      });
      handleChangeRows(filteredRows);
    };

    return (
        <StyledTableCell
            key={headCell.id}
            align='left'
            className='uppercase'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
        >
            <StyledTabelShortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
            >
            {headCell.label}
            {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
            </StyledTabelShortLabel>
            {headCell.filter && 
            <>
                {!headCell?.isDate ? <TextFieldCustom
                    sx={{ width: '100%'}}
                    value={searched}
                    size='small'
                    onChange={(e) => requestSearch(e.target.value, headCell.id, headCell.numeric ? 'numeric' : 'text')}
                /> : <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                    <DesktopDatePicker
                        value={searched}
                        onChange={(newValue) => {
                            requestSearch(newValue, headCell.id, 'date')}
                        }
                        renderInput={(params) => <TextFieldCustom {...params} size="small" sx={{ width: 200 }} />}
                    />
                </LocalizationProvider>}
            </>}
        </StyledTableCell>
    )
}