import { Switch } from '@mui/material';
import styled from 'styled-components';

export const SwitchButton = styled(Switch)`
    width: 60px !important;
    height: 28px !important;
    color: white;
    padding: 0px !important;
    .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;
      transition-duration: 300ms;
      &.Mui-checked {
        transform: translateX(32px);
        color: #fff;
        & + .MuiSwitch-track{
          background-color: #2699FB;
          opacity: 1;
          border: none;
        }
        &.Mui-disabled .MuiSwitch-track{
          opacity: 0.5;
        }
      }
      .Mui-focusVisible .MuiSwitch-thumb{
        color: #33cf4d;
        border: 6px solid #fff;
      }
      .Mui-disabled .MuiSwitch-thumb{
        color: grey;
      }
      .Mui-disabled .MuiSwitch-track{
        opacity: 0.7;
      }
    }
    .MuiSwitch-thumb{
      box-sizing: 'border-box';
      width: 24px;
      height: 24px;
    }
    .MuiSwitch-track{
      border-radius: 30px;
  
      &::before{
        content: 'oui';
        position: absolute;
        left: 3px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
      }
    }
`