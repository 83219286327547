import '../../../application/styles/home.style.scss';
import { Box } from "@mui/material";
import {SelectDemande} from "../../../application/components/SelectDemande";
import {useEffect, useMemo, useState} from "react";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import { BREAKPOINT_MOBILE } from "../../../constant";
import {TitleHome, TitleHomeTop} from '../../../application/styled/text';
import uuid from 'react-uuid';
import ModalModifyRdv from '../../../application/components/ModalModifyRdv';
import {useNavigate, useSearchParams} from 'react-router-dom';

const HomePage = () => {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    const [session, setSessionValue] = useSessionStorage('session', {})
    const mobile = useMediaQuery(BREAKPOINT_MOBILE)
    const navigate = useNavigate();

    useEffect(() => {
        setSessionValue({  id: uuid() });
    }, [setSessionValue]);

    const handleFormSubmit = (payload) => {
        setSessionValue({
            ...session, 
            ...payload, 
            villeUser: payload.ville
        })
    }

    const appointmentId = useMemo(() => {
        if(searchParams.get('appointmentId')){
            setOpen(true);
            return searchParams.get('appointmentId');
        }
        return null;
    }, [searchParams]);


    return (
        <Box sx={{width: '100%'}}>
            <div className={"header-1-container w-full"}>
                <Box>
                    <TitleHomeTop type={{mobile}}>Réservez votre</TitleHomeTop>
                    <TitleHome type={{mobile}}>Rendez-vous en Mairie</TitleHome>
                </Box>
                <Box zIndex={3} marginTop={mobile ? 0: 10}>
                    <SelectDemande onSearch={handleFormSubmit} antsModalCallback={() => navigate('/search')} />
                </Box>
            </div>
            {appointmentId && <ModalModifyRdv open={open} handleClose={handleClose} appointmentId={appointmentId} heureRdvDispo={searchParams.get('heureRdvDispo')} room={searchParams.get('room')} mairieName={searchParams.get('mairieName')} mairieId={searchParams.get('mairieId')} />}
        </Box>
    )
}

export default HomePage;
