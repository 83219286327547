import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TuneIcon from '@mui/icons-material/Tune';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { CustomDialog } from '../../../../../../../application/components/CustomDialog';
import { DetailsMairie } from './DetailsMairieSuperAdmin';
import { CardSms } from '../../styled/CardSms';
import dayjs from 'dayjs';
import { Order } from '../../../../../../../application/types/type';
import { getComparator, stableSort } from '../../../../../../../infrastructure/utils';
import TableSorting from '../../../../../../../application/components/table-sorting';
import { useMairieStore } from '../../../../../../../application/store/mairie.store';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D3D3D',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


  
export const ListeMairieSuperAdmin = () => {
    const [open, setOpen] = useState(false);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [rows, setRows] = useState<any>(mairies);
    const [mairie, setMairie] = useState<any>(null);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage};

    useEffect(() => {
        if(mairies){
            setRows(mairies);
        }
    }, [mairies])
    
    const handleCloseModal = () => {
        setOpen(false)
    }

    const headCells: readonly any[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            filter: false,
            label: '',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            filter: true,
            label: 'Mairie',
        },
        {
            id: 'address',
            numeric: false,
            disablePadding: false,
            filter: true,
            label: 'Adresse',
        },
        {
            id: 'annexes',
            numeric: true,
            disablePadding: false,
            filter: false,
            label: 'Annexe',
        },
        {
            id: 'agenda',
            numeric: true,
            disablePadding: false,
            filter: true,
            label: 'Agenda',
        },
        {
            id: 'subscription',
            numeric: false,
            disablePadding: false,
            filter: true,
            label: 'Abonnement',
        },
        {
            id: 'sms',
            numeric: true,
            disablePadding: false,
            filter: true,
            label: 'SMS RESTANTS',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            filter: false,
            label: 'Action',
        },
    ];

    const Row = (props: { row: any, key: number }) => {
        const { row, key } = props;
        const [openAnnexe, setOpenAnnexe] = useState(false);
        return (
            <Fragment key={key}>
                <StyledTableRow >
                    <StyledTableCell>
                        {row.annexes.length > 0 && 
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenAnnexe(!openAnnexe)}
                        >
                            {openAnnexe ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>}
                    </StyledTableCell> 
                    <StyledTableCell component="th" scope="row">
                        {row.name} <br></br> <span className="text-light-grey">{row.phoneNumber}</span> 
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.address} <br></br>{row.zip}</StyledTableCell>
                    <StyledTableCell align="center">
                        {row.annexes.length < 1 ? '-' : row.annexes.length > 1 ? `${row.annexes.length} annexes` : `${row.annexes.length}annexe`}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.agenda} {row.agenda > 1 ? 'Agendas' : 'Agenda'}</StyledTableCell>
                    <StyledTableCell align="left">Du {dayjs(row.subscription.startDate).format('DD MMM YYYY')} au {dayjs(row.subscription.endDate).format('DD MMM YYYY')}</StyledTableCell>
                    <StyledTableCell align="left">
                        <CardSms color={'#FC0005'}>
                            {row.sms} SMS
                        </CardSms>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <TuneIcon 
                            className="cursor-pointer" 
                            onClick={() => {setMairie(row); setOpen(true)}}
                        />
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={openAnnexe} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Annexe
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Nom</StyledTableCell>
                                        <StyledTableCell>Adresse</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {row.annexes.map((annexe, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {annexe.name} <br></br> <span className="text-light-grey">{annexe.phoneNumber}</span> 
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{annexe.address} <br></br>{annexe.zip}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <TuneIcon 
                                                    className="cursor-pointer" 
                                                    onClick={() => {setMairie(annexe); setOpen(true)}}
                                                />
                                                <DeleteForeverIcon 
                                                    className="btn-delete"
                                                    onClick={() => null}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </Fragment>
        )
    }  

    return (
        <Box className="box-mairie box-list-super-admin">
            <Grid container className="grid-mairie">
                <TableContainer component={Paper}>
                    <TableSorting rows={rows} headCells={headCells} paramsSorting={paramsSorting} setRows={setRows} originalRows={mairies}>
                        <>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index) => {
                                    return (
                                        <>
                                            <Row key={index} row={row} />
                                        </>
                                    );
                                })}
                        </>
                    </TableSorting>
                        {/* </TableBody>
                    </Table> */}
                </TableContainer>
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={<DetailsMairie mairie={mairie} handleCloseModal={handleCloseModal} />}
            />
        </Box>
    )
}