import NODE_API from "./axios.helper";

export const handlePutSocialMedia = async(data) => {
    try {
        const res = await NODE_API.post('/social-media',{
            mairie: data._id,
            facebook: data.facebook,
            twitter: data.twitter,
            instagram: data.instagram,
            url: data.url,
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}