import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, OutlinedInput, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {ReactComponent as LogoInversedSvg} from "../../../application/assets/svg/logo-rdv.svg";
import { loginHandler } from "../../../application/store/auth.store";
import './Login.style.scss';

const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
});


export const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const { register, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const onSubmitHandler = async (data) => {
        setError('')
        const res: any = await loginHandler(data);
        
        if(res?.error){
            setError('Identifiant ou mot de passe incorrect.');
        }else{
            if(res?.role === 'SUPERADMIN')
                navigate('/admin/configuration/mairie');
            else
                navigate('/admin/dashboard');
        }
    }

    return (
        <>
            <Box className="box-login">
                <div className="div-login">
                    <LogoInversedSvg className="logo" />
                    <form onSubmit={handleSubmit(onSubmitHandler)} className="px-10 pb-10" >
                        <Stack>
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        size="small"
                                        placeholder={'Identifiant*'}
                                        {...register("username")}
                                    />
                                </FormControl>
                            </Paper>            
                        </Stack>
                        <Stack className="mt-5">
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        size="small"
                                        placeholder={'Mot de passe*'}
                                        {...register("password")}
                                        type="password"
                                    />
                                </FormControl>
                            </Paper>            
                        </Stack>
                        {error && <p className="text-red-500">{error}</p>}
                        <FormGroup className="mt-2">
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Se souvenir de moi" />
                        </FormGroup>
                        <Button variant={"contained"} color={'primary'} type={"submit"}>
                            <span className={"font-bold"}>Se connecter</span>
                        </Button>
                    </form>
                </div>
            </Box>
        </>
    )
}
