import { Grid, Paper, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {useMediaQuery} from "usehooks-ts";
import {BREAKPOINT_TABLETTE} from "../../constant";
require('dayjs/locale/fr');

export const PreviewConfirmationRdv = ({design, data, isIFrame = false}) => {
    const heureRdv = dayjs(data?.heureRdv).locale('fr');
    const defaultDuration = data?.duration * (data?.nombrePersonne || 1);
    const duration = dayjs().hour(0).minute(defaultDuration);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);
    return <Paper sx={{ width: (tablette || isIFrame) ? '100%': '60%' }}>
        <Stack gap={tablette ? 0 : 2}>
            <Stack className={"bg-neutral-200 py-3"}>
                { design === 1 && <>
                    <p className={"text-center uppercase text-4xl font-thin"}>Votre demande de rdv</p>
                </>}
                { design === 2 && <>
                    <p className={"uppercase text-xl font-thin mx-10"}>
                       <span className="underline">La Réservation concerne :</span>
                        <div className="mt-3">
                            {data.concernedPerson?.map((person, key) =>
                                <p key={key} className="m-0">{person?.firstName} <span className={"font-bold"}>{person?.lastName} | </span>
                                {/* {person?.birthday && dayjs(person?.birthday).locale('fr').format('DD MMMM YYYY')} */}
                                </p>
                            )}
                        </div>
                    </p>
                </>}
            </Stack>
            { design === 1 && <>
                <Stack alignItems={"center"} marginTop={2}>
                    <p className={"text-3xl mb-0"}>{data?.firstName} <b>{data?.lastName}</b></p>
                    <p className={"font-light"}>{data?.phoneNumber}</p>
                </Stack>
            </>}
            <Stack paddingX={design === 2 ? 5 : 0}>
                <Stack gap={4} paddingY={4} paddingX={tablette ? 2 : 4}>
                    <Grid container>
                        <Grid item xs={tablette ? 12 : 6}>
                            <Typography color={"primary"}><span className={"font-bold"}>Type de dossier</span></Typography>
                            <p>{data?.typeDossier?.label ?? data?.motif?.label} (x{data?.nombrePersonne})</p>
                        </Grid>
                        <Grid item xs={tablette ? 12 : 6}>
                            <Typography color={"primary"}><span className={"font-bold"}>Date et heure de rendez-vous</span></Typography>
                            <p className={"capitalize"}>{heureRdv.format('dddd DD MMMM YYYY - HH:mm')}</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={tablette ? 12 : 6}>
                            <Typography color={"primary"}><span className={"font-bold"}>Lieu de rendez-vous</span></Typography>
                            <p className={"text-xl mb-0"}>{data?.ville?.name}</p>
                            <p className="mb-0">{data?.ville?.address}</p>
                            <p className="mb-0">{data?.ville?.zip}, {data?.ville?.city}</p>
                        </Grid>
                        <Grid item xs={tablette ? 12 : 6}>
                            <Typography color={"primary"}><span className={"font-bold"}>Durée de rendez-vous</span></Typography>
                            <p>{duration.format('HH')}:{duration.format('mm')}</p>
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    </Paper>
}
