import NODE_API from './axios.helper';
import queryString from "query-string";

export const handleAddMairie = async (data:any) => {
    try {
        const input = {
            name: data.name,
            username: data.username,
            password: data.password,
            startDate: data.startDate,
            endDate: data.endDate,
            slug: data.slug,
            address: data.address,
            address2: data.address2,
            zip: data.zip,
            city: data.city,
            email: data.email,
            latitude: data.latitude,
            longitude: data.longitude,
            phoneNumber: data.phoneNumber,
            parent: null,
            agenda: Number(data.agenda),
            room: Number(data.room),
            sms: Number(data.sms)
        }
        
        const res = await NODE_API.post('/mairies/create', {
            ...input
        });

        return res;
    } catch (error) {
        console.log(error)
    }
}

export const handleUpdateMairie = async (data:any) => {
    try {
        const res = await NODE_API.put('/mairies/'+ data._id, {
            ...data
        });

        return res;
    } catch (error) {
        console.log(error)
    }
}

export const handleAddMairieAnnexe = async (data:any) => {
    try {
        const input = {
            name: data.name,
            slug: data.slug,
            address: data.address,
            address2: data.address2,
            zip: data.zip,
            city: data.city,
            parent: data.parent,
            email: data.email,
            phoneNumber: data.phoneNumber,
            latitude: data.latitude,
            longitude: data.longitude,
        }

        const res = await NODE_API.post('/mairies/create', {
            ...input
        });
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const handleGetMairies = async() => {
    try {
        const res = await NODE_API.get('/mairies');
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetListMairies = async() => {
    try {
        const res = await NODE_API.get('/mairies/lists?fields=city,zip,_id');
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getListAgentByMairie = async(mairieId) => {
    try {
        const res = await NODE_API.get('/users/agents/'+mairieId);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getListAgendasByMairie = async(slug, agent = null) => {
    try {
        let res: any = null;
        if(agent){
            res = await NODE_API.get(`/agenda/mairie/${slug}?agent=${agent}`);
        }else{
            res = await NODE_API.get(`/agenda/mairie/${slug}`);
        }
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getAgendasByMairieMotif = async(mairie, slug, motif) => {
    try {
        const input = {
            mairie: mairie,
            slugMairie: slug,
            motif: motif
        }
        const res = await NODE_API.post(`/agenda/mairie-motif`, input);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getListUsagers = async(slug) => {
    try {
        const res = await NODE_API.get(`/usagers/mairies/${slug}`);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleFilteredMairies = async(data) => {
    const ville = data.ville;
    const radius = data?.radius ?? 60;
    const filterBy = data?.filterBy ?? 'date';
    try {
        if(ville.coordinates){
            const query = queryString.stringify({
                longitude: ville.coordinates[0],
                latitude: ville.coordinates[1],
                radius_km: radius, // previous 1000,
                start_date: data.startDate,
                end_date: data.endDate,
                reason: data.typeDossier.replaceAll('_&_','_'),
                isANTS: data.ants,
                isANTS_website: false,
                isOnline: true,
                documents_number: data.nombrePersonne,
                idMairies: data?.idMairies || [],
                filterBy
            })
            const res = await NODE_API.get(`/appointment/slotsFromPosition?${query}`);
            return res;
        }else{
            return []
        }
       
    } catch (error) {
        console.log(error);
    }
}

export const handleGetMairieSlotTime = async(data) => {
    try {
        if(data.slug){
            const res = await NODE_API.get(`/appointment/slotsFromMairie?slug=${data.slug}&reason=${[data.typeDossier.replaceAll('_&_','_')]}&start_date=${data.startDate}&end_date=${data.endDate}&isANTS=${data.isANTS}&isANTS_website=${false}&isOnline=${data?.isOnline}&documents_number=${data.nombrePersonne}&specific_hour=${data?.specificHour}`);
            return res;
        }else{
            return []
        }
       
    } catch (error) {
        console.log(error);
    }
}

export const handleGetMairie = async(mairieId) => {
    try {
        const res = await NODE_API.get('/mairies/'+mairieId);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetMairieBySlug = async(slug) => {
    try {
        const res = await NODE_API.get('/mairies/slug/'+slug);
        if(res?.success){
            return res?.data
        }
        return null;
    } catch (error) {
        console.log(error);
    }
}



export const handleGetAvailableTimeSlots = async (mairieId) => {
    try {
        const res = await NODE_API.get('/appointment/availableTimeSlots?meeting_point_ids='+ mairieId);
        return res;
    } catch (error) {
        console.log(error);
    }
}
