import { FormControl, InputAdornment, OutlinedInput, Paper, Stack, Grid, FormGroup, FormControlLabel, Box, Autocomplete, TextField } from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import '../Agent.style.scss';
import { FormPropsAgent } from "../../../../../../application/types/agent.type";
import { InputPhone } from "../../../../../../application/components/InputPhone/InputPhone";
import { SwitchButton } from "../../../../../../application/components/SwitchButton";
import { useMemo } from "react";

export const FormAgent = ({ register, errors, getValues, control, setValue, agent, mairieParent, setMairieParent, listMairies }: FormPropsAgent) => {
    const params = {
        register, 
        errors,
        control,
        getValues
    }

    const agentAccess = useMemo(() => {
        return [
            {name: 'Espace configuration', key: 'space_configuration', checked: agent?.access.space_configuration},
            {name: 'Liste des usagers', key: 'liste_usager', checked: agent?.access.liste_usager},
            {name: 'Statistiques', key: 'statistiques', checked: agent?.access.statistiques},
            {name: 'Exports', key: 'export', checked: agent?.access.export},
            {name: 'Email/Sms', key: 'space_sms', checked: agent?.access.space_sms},
            {name: 'Créer un rendez-vous', key: 'create_appointment', checked: agent?.access.create_appointment},
        ]
    }, [agent])


    return (
        <>
            <Stack gap={2} className="padding-left-right">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper className="custom-paper">
                            <Autocomplete
                                disableClearable
                                options={listMairies}
                                getOptionLabel={(option) => {
                                    if (option['name'])
                                        return option['name'] 
                                    if (typeof option === 'string')
                                        return option
                                    return ''
                                }}
                                value={mairieParent}
                                onChange={(event: any, newValue: any) => {
                                    setMairieParent(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                paddingRight: "10px!important",
                                            },
                                        }}
                                        placeholder={"Mairie Principale*"}
                                    />
                                )}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Nom de l\'agent*'}
                                    defaultValue={agent?.lastName ?? ''}
                                    {...register("lastName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.lastName ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('lastName') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Prénom de l\'agent*'}
                                    defaultValue={agent?.firstName ?? ''}
                                    {...register("firstName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.firstName ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('firstName') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                {/* <Stack>
                    <Paper className="custom-paper">
                        <InputPhone {...params} defaultValue={agent?.phoneNumber.replace('+33','0') ?? ''} />
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse email'}
                                type={"email"}
                                defaultValue={agent?.email ?? ''}
                                {...register("email")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.email ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack> */}
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Identifiant*'}
                                defaultValue={agent?.username ?? ''}
                                {...register("username")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.username ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('username') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Mot de passe*'}
                                type="password"
                                defaultValue={agent?.passkey ?? ''}
                                {...register("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.password ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('password') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Confirmer Mot de passe*'}
                                type="password"
                                defaultValue={agent?.passkey ?? ''}
                                {...register("confirmPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.confirmPassword ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('confirmPassword') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <p className="bold-title">Droits d'accès</p>
                <Box className="flex flex-wrap gap-5">
                    {agentAccess.map((access) => 
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <SwitchButton 
                                        {...register(`access[${access.key}]`)}
                                        sx={{ m: 1 }} 
                                        defaultChecked={access.checked}
                                        name={`access[${access.key}]`}
                                    />
                                }
                                label={access.name}
                                className="switch-label"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    )}
                </Box>
            </Stack> 
        </>
    )
}
