import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Paper,
    Stack,
    TextareaAutosize,
} from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useCallback, useEffect, useState } from "react";
import "./CreateAppointment.style.scss";
import { CustomCalendar } from "../CustomCalendar";
import dayjs, { Dayjs } from "dayjs";
import { InputPhone } from "../InputPhone/InputPhone";
import { SelectCodePostal } from "../SelectCodePostal";
import { convertPhone } from "../../../infrastructure/utils";
import {
    handleGetMairie,
    handleGetMairieSlotTime,
} from "../../../helpers/mairie.helper";
import { SelectTypeDossier } from "../SelectTypeDossier";
import range from "lodash/range";
import isEmpty from "lodash/isEmpty";
import { CustomLoading } from "../LazyLoading/LazyLoading";
import { FormPersonConcerned } from "../FormPersonConcerned/FormPersonConcerned";
import {
    handleCheckBusy,
    handleUpSertBusy,
} from "../../../helpers/busy.helper";
import { Popup } from "../Popup/Popup";
import { useSessionStorage } from "usehooks-ts";
import { CalendarFull } from "../CalendarFull";
import { getMotifsBySlug, useMairieStore } from "../../store/mairie.store";
require("dayjs/locale/fr");

const formSchema = {
    isOtherType: yup.boolean(),
    showAnts: yup.boolean(),
    firstName: yup.string().when("isOtherType", {
        is: true,
        then: yup
            .string()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
            .min(2),
    }),
    lastName: yup.string().when("isOtherType", {
        is: true,
        then: yup
            .string()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
            .min(2),
    }),
    ants: yup.string().when("showAnts", {
        is: true,
        then: yup.string().min(10).max(10).required(),
    }),
};

const schema = yup.object().shape({
    isOtherType: yup.boolean(),
    firstName: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
        .min(2)
        .required(),
    lastName: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
        .min(2)
        .required(),
    nombrePersonne: yup
        .number()
        .min(1, "Le nombre de personne ne doit pas être inférieur à 1")
        .max(6, "Le nombre de personne ne doit pas etre supérieur à 6"),
    phoneNumber: yup
        .string()
        .transform((_value, originalValue) => {
            return originalValue?.replace(/[^0-9]/g, "");
        })
        .min(10)
        .max(10)
        .matches(/^[0][0-9][0-9]+$/)
        .notRequired(),
    email: yup.string().email(),
    concernedPerson: yup.array().when("isOtherType", {
        is: true,
        then: yup
            .array()
            .of(yup.object().shape(formSchema))
            .required("Must have fields")
            .min(1, "Minimum of 1 field"),
    }),
    comment: yup.string(),
});

interface CreateAppointmentProps {
    onConfirm: any;
    loading: boolean;
    setLoading: any;
    slug: string;
    slugToAppointment: string;
    appointment?: any;
}

export const CreateAppointmentForm = ({
    onConfirm,
    setLoading,
    loading,
    slug,
    slugToAppointment,
    appointment = null,
}: CreateAppointmentProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
        control,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const { fields } = useFieldArray({
        control,
        name: "concernedPerson",
    });

    const numberPersonne = watch("nombrePersonne") ?? 1;
    const TYPE_DOSSIERS = useMairieStore((state) => state.TYPE_DOSSIERS);
    const mairies = useMairieStore((state) => state.mairies);
    const [agenda, setAgenda] = useState<any>(null);
    const [loadRequest, seLoadRequest] = useState<boolean>(false);
    const [session] = useSessionStorage("session", {} as any);
    const [specificHour, setSpecificHour] = useState<boolean>(false);
    const [ants, setAnts] = useState<boolean>(false);
    const [alert, setAlert] = useState(false);
    const [typeDossier, setTypeDossier] = useState<any>(
        appointment?.typeDossier
    );
    const [item, setItem] = useState(null as any);
    const [duration, setDuration] = useState<any>(30);
    const [mairieSlotTime, setMairieSlotTime] = useState<any>({});
    const [calendarLists, setCalendarLists] = useState([] as any[]);
    const [today, setToday] = useState(dayjs().locale("fr"));
    const [heureRdv, setHeureRdv] = useState<Dayjs>();

    const getMotif = useCallback(async () => {
        seLoadRequest(true);
        let mairieMotifSlug = slug;
        //Get motif of parent if annexe
        const mairie: any = mairies.find((d) => d.slug === slug);
        if (mairie?.parent) {
            const mairieParent = await handleGetMairie(mairie?.parent);
            if (mairieParent?.success) {
                mairieMotifSlug = mairieParent?.data?.slug;
            }
        }
        const res: any = await getMotifsBySlug(mairieMotifSlug);

        let mairieSlotTime: any = {};
        if (res && typeDossier) {
            const selectedMotif = typeDossier?.replaceAll("_", " ");
            if (res?.length > 0) {
                const motif = res.find((data) => data.name === selectedMotif);
                if (motif) {
                    setDuration(ants ? motif.antsDuration : motif.duration);
                    mairieSlotTime = await handleGetMairieSlotTime({
                        startDate: dayjs().format("YYYY-MM-DD"),
                        endDate: dayjs().add(12, "month").format("YYYY-MM-DD"),
                        slug: slugToAppointment,
                        typeDossier: typeDossier,
                        isOnline: false,
                        nombrePersonne: numberPersonne,
                        isANTS: ants,
                        specificHour: specificHour,
                    });
                }
            }
        }

        if (mairieSlotTime) {
            setMairieSlotTime(mairieSlotTime);
        }
        seLoadRequest(false);
    }, [
        slug,
        mairies,
        typeDossier,
        ants,
        slugToAppointment,
        numberPersonne,
        specificHour,
    ]);

    useEffect(() => {
        let list: any = [];
        const concernedPersons = getValues("concernedPerson");
        if (concernedPersons?.length > 0) {
            list = concernedPersons.map((data) => ({
                ...data,
                showAnts: ants,
            }));
        }

        if (numberPersonne > 0 && list?.length < numberPersonne) {
            range(list?.length, numberPersonne).map((data) => {
                list.push({
                    firstName: "",
                    lastName: "",
                    birthday: null,
                    ants: "",
                    showAnts: ants,
                });
            });
            setValue("concernedPerson", list);
        } else if (list?.length > numberPersonne) {
            const diff = list?.length - numberPersonne;
            const newList = list.slice(0, diff * -1);
            setValue("concernedPerson", newList);
        } else {
            setValue("concernedPerson", list);
        }
    }, [numberPersonne, ants, setValue, getValues]);

    useEffect(() => {
        getMotif();
    }, [getMotif]);

    useEffect(() => {
        if (appointment) {
            setTypeDossier(appointment?.typeDossier);
            setValue("concernedPerson", appointment?.concernedPerson);
            setHeureRdv(appointment?.heureRdv);
            setAgenda(appointment?.room?._id);
            if (appointment?.concernedPerson[0]?.ants !== "") {
                setAnts(true);
            }
        }
    }, [appointment]);

    const handleOnDateSelect = async (calendar, data, mairie) => {
        const [hours, minutes] = data.hour.split(":");
        const date = dayjs(calendar).hour(hours).minute(minutes);
        setHeureRdv(date);
        setAgenda(data.idAgenda);
        const isDispo = await handleCheckBusy({
            mairie: mairie._id,
            agenda: data.idAgenda,
            dateRdv: dayjs(calendar).format("DD/MM/YYYY"),
            heureRdv: date,
            sessionId: session?.id,
            duration: Number(duration) * numberPersonne,
        });

        if (isDispo) {
            await handleUpSertBusy({
                mairie: mairie._id,
                agenda: data.idAgenda,
                dateRdv: dayjs(calendar).format("DD/MM/YYYY"),
                heureRdv: date,
                sessionId: session?.id,
                duration: Number(duration) * numberPersonne,
            });
        } else {
            setAlert(true);
        }
    };

    useEffect(() => {
        const mairie: any = mairies.find((d) => d.slug === slug);
        setItem(mairie);
    }, [mairies, slug]);

    useEffect(() => {
        let count = 1;
        const todayDate = dayjs(today).clone().locale("fr");
        const calendar = [todayDate];
        while (count < 6) {
            calendar.push(todayDate.add(count, "day"));
            count++;
        }
        setCalendarLists(calendar);
    }, [today]);

    const handleTypeDossierChange = (value) => {
        setTypeDossier(value);
        setMairieSlotTime({});
    };

    const handleChangeAnts = () => {
        setAnts((current) => !current);
        setMairieSlotTime({});
    };

    const handleChangeSpecificHour = () => {
        setSpecificHour((current) => !current);
        setMairieSlotTime({});
    };

    const handleCloseAlert = () => {
        setAlert(false);
    };

    const onSubmitHandler = (data) => {
        let type: any = "";
        if (typeDossier) {
            type = TYPE_DOSSIERS.find((type) => type.key === typeDossier)?.key;
        }
        if (agenda) {
            onConfirm({
                ...data,
                bureau: agenda,
                duration: duration,
                heureRdv: dayjs(heureRdv).utc().local().format().split("+")[0],
                typeDossier: type,
                mairie: slugToAppointment,
                isSpecificHour: specificHour,
                phoneNumber: convertPhone(data.phoneNumber),
                usager: appointment?.usager,
            });
        }
        //setOpenDuplicate(true);
        // reset();
    };

    useEffect(() => {
        if (
            !typeDossier?.includes("CNI") &&
            !typeDossier?.includes("PASSEPORT")
        ) {
            setValue(`concernedPerson.${0}.firstName`, getValues("firstName"));
            setValue(`concernedPerson.${0}.lastName`, getValues("lastName"));
        }
    }, [typeDossier, setValue, getValues("firstName"), getValues("lastName")]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <input
                    hidden
                    {...register(`isOtherType`, {
                        value:
                            !typeDossier?.includes("CNI") &&
                            !typeDossier?.includes("PASSEPORT"),
                    })}
                />
                <Stack gap={2}>
                    <Stack>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={specificHour}
                                    onChange={handleChangeSpecificHour}
                                    name="ANTS"
                                />
                            }
                            label="Ne pas contraindre les horaires"
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Paper className="custom-paper">
                            <p className="mb-0">Nom</p>
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-white"}
                                    placeholder={"Votre Nom"}
                                    defaultValue={appointment?.lastName}
                                    {...register("lastName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {!!errors.lastName ? (
                                                <CancelRounded
                                                    color={"error"}
                                                />
                                            ) : (
                                                !!getValues("lastName") && (
                                                    <CheckCircle
                                                        color={"secondary"}
                                                    />
                                                )
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                        <Paper className="custom-paper">
                            <p className="mb-0">Prénom</p>
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-white"}
                                    placeholder={"Votre Prénom"}
                                    defaultValue={appointment?.firstName}
                                    {...register("firstName")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {!!errors.firstName ? (
                                                <CancelRounded
                                                    color={"error"}
                                                />
                                            ) : (
                                                !!getValues("firstName") && (
                                                    <CheckCircle
                                                        color={"secondary"}
                                                    />
                                                )
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Paper className="custom-paper">
                            <p className="mb-0">
                                Numéro de téléphone mobile ou fixe{" "}
                            </p>
                            <Stack
                                flexDirection={"row"}
                                alignItems={"center"}
                                className="w-full"
                            >
                                <InputPhone
                                    control={control}
                                    getValues={getValues}
                                    errors={errors}
                                    defaultValue={appointment?.phoneNumber?.replace(
                                        "33",
                                        "0"
                                    )}
                                />
                            </Stack>
                        </Paper>
                        <Paper className="custom-paper">
                            <p className="mb-0">Email</p>
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-white"}
                                    placeholder={"Votre adresse email"}
                                    type={"email"}
                                    defaultValue={appointment?.email}
                                    {...register("email")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {!!errors.email ? (
                                                <CancelRounded
                                                    color={"error"}
                                                />
                                            ) : (
                                                !!getValues("email") && (
                                                    <CheckCircle
                                                        color={"secondary"}
                                                    />
                                                )
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack>
                        <SelectCodePostal
                            disabled={false}
                            register={register}
                            setValue={setValue}
                            data={mairies[0]}
                        />
                        {(errors?.city || errors?.zip) && (
                            <p className="mb-0 text-red-500">
                                Les valeurs ne devraient pas être vide
                            </p>
                        )}
                    </Stack>
                    <Stack>
                        <Stack
                            flexDirection={"row"}
                            gap={2}
                            alignItems={"center"}
                        >
                            <Paper className="custom-paper">
                                <p className="mb-0">Type de Motif</p>
                                <SelectTypeDossier
                                    onSelect={handleTypeDossierChange}
                                    selected={typeDossier}
                                    motifs={TYPE_DOSSIERS}
                                />
                            </Paper>
                            <Paper
                                sx={{ width: "30%" }}
                                className="custom-paper"
                            >
                                <p className="mb-0">Nombre de personnes</p>
                                <FormControl sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="nombrePersonne"
                                        type="number"
                                        defaultValue={
                                            appointment?.nombrePersonne ?? 1
                                        }
                                        {...register("nombrePersonne", {
                                            valueAsNumber: true,
                                            validate: (value) => value > 0,
                                        })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {!!errors.nombrePersonne ? (
                                                    <CancelRounded
                                                        color={"error"}
                                                    />
                                                ) : (
                                                    !!getValues(
                                                        "nombrePersonne"
                                                    ) && (
                                                        <CheckCircle
                                                            color={"secondary"}
                                                        />
                                                    )
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Paper>
                        </Stack>
                        {(typeDossier?.includes("CNI") ||
                            typeDossier?.includes("PASSEPORT")) && (
                            <>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={ants}
                                                onChange={handleChangeAnts}
                                                name="ANTS"
                                            />
                                        }
                                        label={`l'usager possède ${
                                            numberPersonne > 1
                                                ? "des numéros"
                                                : "un numéro"
                                        } ANTS`}
                                    />
                                </Stack>
                                {fields.map((field, index) => {
                                    return (
                                        <FormPersonConcerned
                                            key={index}
                                            field={field}
                                            index={index}
                                            register={register}
                                            getValues={getValues}
                                            setValue={setValue}
                                            ants={ants}
                                            errors={errors}
                                            typeDossier={typeDossier}
                                        />
                                    );
                                })}
                            </>
                        )}
                        <Stack marginTop={2}>
                            <p className="mb-0">Ajouter un commentaire?</p>
                            <TextareaAutosize
                                {...register("comment")}
                                aria-label="minimum height"
                                minRows={3}
                            />
                        </Stack>
                    </Stack>
                    {typeDossier && (
                        <>
                            {!item && (
                                <p className="text-center">
                                    Veuillez sélectionner une ville pour
                                    afficher le calendrier.
                                </p>
                            )}
                            {item &&
                            !isEmpty(mairieSlotTime) &&
                            !loadRequest ? (
                                <Stack>
                                    <p className="text-center">
                                        Les premiers créneaux disponibles.
                                        Cliquez sur la date et l’heure de votre
                                        choix.
                                    </p>
                                    <br></br>
                                    {!isEmpty(
                                        mairieSlotTime.available_slots
                                    ) ? (
                                        <CustomCalendar
                                            calendarLists={calendarLists}
                                            onDateSelect={(calendar, hour) =>
                                                handleOnDateSelect(
                                                    calendar,
                                                    hour,
                                                    item
                                                )
                                            }
                                            availableHours={
                                                mairieSlotTime.available_slots
                                            }
                                            startDate={today}
                                            setStartDate={setToday}
                                        />
                                    ) : (
                                        <CalendarFull />
                                    )}
                                </Stack>
                            ) : (
                                <CustomLoading size={100} />
                            )}
                        </>
                    )}
                    <Stack>
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            variant={"contained"}
                            color={"secondary"}
                            type={"submit"}
                        >
                            Ajouter
                        </LoadingButton>
                    </Stack>
                </Stack>
                <Popup
                    message="Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur."
                    type="error"
                    open={alert}
                    handleClose={handleCloseAlert}
                />
            </form>
        </>
    );
};
