import {
    Box,
    Button,
    Checkbox,
    Chip,
    Grid,
    IconButton,
    Paper,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Check } from "@mui/icons-material";
import { SelectTypeDossier } from "./SelectTypeDossier";
import { CustomCalendar } from "./CustomCalendar";
import { IdentificationForm } from "./IdentificationForm";
import { OtpVerification } from "./OtpVerification";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import AxiosContext from "../context/axios.context";
import { handleCheckBusy, handleUpSertBusy } from "../../helpers/busy.helper";
import { Popup } from "./Popup/Popup";
import dayjs from "dayjs";
import {
    handleGetStatus,
    handlePostAppointment,
} from "../../helpers/ants.helper";
import { isEmpty, isObject } from "lodash";
import { testStatusAntsNumber } from "../../infrastructure/utils/ants";
import { CustomDialog } from "./CustomDialog";
import { DetailsDuplicate } from "./DetailsDuplicate";
import { useAuthStore } from "../store/auth.store";
import { ModalAnts } from "./ModalAnts/ModalAnts";
import { getFilterdMairies, useMairieStore } from "../store/mairie.store";
require("dayjs/locale/fr");

const stepIconComponent = (props) => {
    const { active, completed, error, icon } = props;
    let backgroundColor = "bg-neutral-400";

    if (completed) {
        backgroundColor = "bg-green-700";
    }
    if (error) {
        backgroundColor = "bg-red-400";
    }
    return (
        <>
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                className={`text-white ${backgroundColor} rounded-full`}
                width={35}
                height={35}
                marginLeft={"-.25em"}
                marginRight={2}
            >
                {active && icon}
                {completed && <Check />}
                {!error && !active && !completed && icon}
            </Stack>
        </>
    );
};

export const RdvStepper = ({ onSubmitSuccess }) => {
    const [searchParams] = useSearchParams();
    const motifs = useAuthStore((state) => state.motifs);
    const [alert, setAlert] = useState<any>({
        open: false,
        message:
            "Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur.",
    });
    const [session, setSession] = useSessionStorage("session", {} as any);
    const [otpRequestToken, setOtpRequestToken] = useState<string>();
    const [duplicates, setDuplicates] = useState<any>({});
    const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
    const [openAnts, setOpenAnts] = useState<boolean>(false);
    const filteredMairies = useMairieStore((state) => state.filteredMairies);

    const mairie = useMemo(() => {
        if (session?.ville?._id) {
            return session?.ville;
        }
        return filteredMairies.find((d) => d.slug === session?.ville?.slug);
    }, [filteredMairies, session?.ville]);

    const [duration, setDuration] = useState(session?.duration ?? 30);
    const [selectedDate, setSelectedDate] = useState(dayjs().locale("fr"));
    const [nombrePersonne, setNombrePersonne] = useState(1);
    const [notifyMeWhenAppointmentFree, setNotifyMeWhenAppointmentFree] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const [today, setToday] = useState(dayjs().locale("fr"));
    const [calendarLists, setCalendarLists] = useState([] as any[]);
    const [activeStep, setActiveStep] = useState(0);
    const [ui, setUi] = useState({
        isStep1Editing: false,
        isStep2Editing: false,
        isFormValid: false,
        sendingOTP: false,
    });
    const [typeDossier, setTypeDossier] = useState({} as any);
    const {
        value: { instance: axios },
    } = useContext(AxiosContext);
    const verificationType = useMemo(() => {
        if (
            mairie?.agendaParameter &&
            mairie?.agendaParameter.verificationAccount[0].type
        ) {
            return mairie?.agendaParameter.verificationAccount[0].type;
        } else {
            return "EMAIL";
        }
    }, [mairie]);

    const ants = useMemo(() => {
        if (
            ["DEPOT_CNI", "DEPOT_PASSEPORT", "DEPOT_CNI_&_PASSEPORT"].includes(
                session?.typeDossier
            )
        )
            return true;
        return false;
    }, [session?.typeDossier]);

    const handleCloseAnts = () => setOpenAnts(false);

    useEffect(() => {
        if (!isEmpty(duplicates)) {
            setOpenDuplicate(true);
        }
    }, [duplicates]);

    const handleModalAntsCallback = ({ ants }) => {
        setOpenAnts(false);
    };

    useEffect(() => {
        if (searchParams.get("duration")) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setDuration(Number(searchParams.get("duration")));
            setOpenAnts(true);
            setSession((prev) => ({
                ...prev,
                heureRdv: dayjs.tz(searchParams.get("date"), timezone),
                duration: searchParams.get("duration"),
                bureau: searchParams.get("agenda"),
                type: { isOnline: true, isMairie: true },
                nombrePersonne: searchParams.get("person"),
            }));
        }
    }, [searchParams]);

    const handleOtpValid = async () => {
        let city =
            session.villeUser.codesPostaux + ", " + session.villeUser.nom;
        if (isObject(session?.city)) {
            city = session?.city?.codesPostaux + ", " + session?.city?.nom;
        }
        const isDispo = await handleCheckBusy({
            mairie: session.ville?._id,
            agenda: session.bureau,
            dateRdv: dayjs(session.heureRdv).format("DD/MM/YYYY"),
            heureRdv: session.heureRdv,
            sessionId: session?.id,
            duration: (session.nombrePersonne || 1) * duration,
        });

        if (isDispo) {
            const appointment = {
                firstName: session.firstName,
                lastName: session.lastName,
                phoneNumber: session.phoneNumber,
                dureeRdv: (session.nombrePersonne || 1) * duration,
                heureRdv: dayjs(session.heureRdv)
                    .utc()
                    .local()
                    .format()
                    .split("+")[0],
                typeDossier: session?.typeDossier,
                concernedPerson: session.concernedPerson,
                email: session.email,
                status: "PENDING",
                mairie: session.ville.slug,
                meeting_point_id: session.ville?._id,
                city: city,
                nombrePersonne: session.nombrePersonne,
                room: session.bureau,
                type: session.type,
                comment: session?.comment,
                notifyMeWhenAppointmentFree,
            };
            setLoading(true);
            await axios
                .post("/appointment", {
                    ...appointment,
                    heureRdv:
                        appointment.heureRdv &&
                        appointment.heureRdv?.slice(0, 16) + ":00",
                })
                .then(async ({ data }) => {
                    await handlePostAppointment({
                        ...appointment,
                        meeting_point: session.ville.name,
                        _id: data?.data?._id,
                        meeting_point_id: session.ville._id,
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setTimeout(() => {
                        onSubmitSuccess();
                    }, 500);
                });
        } else {
            setAlert((prevState) => ({ ...prevState, open: true }));
        }
    };

    const handleCloseModalDuplicate = () => {
        setOpenDuplicate(false);
    };

    const handleCloseAlert = () => {
        setAlert((prevState) => ({ ...prevState, open: false }));
    };

    useEffect(() => {
        if (session?.ville && session?.ville?.coordinates) {
            const input = {
                ...session,
                filterBy: "distance",
            };
            getFilterdMairies(input);
        }
    }, [session]);

    useEffect(() => {
        if (session?.heureRdv) {
            const typeDossier = session.motif;
            setTypeDossier(typeDossier);
            setNombrePersonne(session.nombrePersonne);
            setSelectedDate(dayjs(session.heureRdv).locale("fr"));
        }
    }, [session]);

    const handleStepChange = (stepId: number) => {
        switch (stepId) {
            case -1:
                setActiveStep(0);
                break;
            case 0:
                setActiveStep(1);
                break;
            case 1:
                setActiveStep(2);
                break;
            case 2:
                setActiveStep(3);
                break;
        }
    };
    const handleEditMode = (stepId: number) => {
        switch (stepId) {
            case 0:
                handleStepChange(-1);
                setUi((prev) => ({
                    ...prev,
                    isStep1Editing: true,
                    isStep2Editing: false,
                }));
                break;
            case 1:
                handleStepChange(0);
                setUi((prev) => ({
                    ...prev,
                    isStep1Editing: false,
                    isStep2Editing: true,
                }));
                break;
        }
    };

    const handleTypeDossierChange = () => {
        setUi((prev) => ({ ...prev, isStep1Editing: false }));
        handleStepChange(1);
    };

    useEffect(() => {
        handleStepChange(1);
        // const entries = generateRangeHours({hourly})
        // setAvailableHours(entries);
    }, []);

    useEffect(() => {
        let count = 1;
        const todayDate = dayjs().locale("fr");
        const calendar = [todayDate];
        while (count < 4) {
            calendar.push(todayDate.add(count, "day"));
            count++;
        }
        setCalendarLists(calendar);
    }, []);

    const handleOnDateSelect = async (calendar, data, mairie) => {
        const [hours, minutes] = data.hour.split(":");
        const date = dayjs(calendar).hour(hours).minute(minutes);
        const isDispo = await handleCheckBusy({
            mairie: mairie._id,
            agenda: data.idAgenda,
            dateRdv: dayjs(calendar).format("DD/MM/YYYY"),
            heureRdv: date,
            sessionId: session?.id,
            duration: Number(duration) * session.nombrePersonne,
        });

        if (isDispo) {
            const res = await handleUpSertBusy({
                mairie: mairie._id,
                agenda: data.idAgenda,
                dateRdv: dayjs(calendar).format("DD/MM/YYYY"),
                heureRdv: date,
                sessionId: session?.id,
                duration: Number(duration) * session.nombrePersonne,
            });
            if (res?.success) {
                setSession((prev) => ({
                    ...prev,
                    heureRdv: date,
                    duration: duration,
                    bureau: data.idAgenda,
                    type: data.type,
                }));
                setSelectedDate(date);
                setUi((prev) => ({ ...prev, isStep2Editing: false }));
                handleStepChange(1);
            }
        } else {
            setAlert((prevState) => ({ ...prevState, open: true }));
        }
    };

    const handleSubmitForm = async (data, mairieId: string) => {
        let success = true;
        setUi((prev) => ({ ...prev, sendingOTP: true }));
        setSession((prev) => ({
            ...prev,
            ...data,
            duration: duration,
        }));
        const appointmentsId = data?.concernedPerson.reduce(
            (prev, curr) => [...prev, curr.ants],
            []
        );
        const resAntsIdsStatus = await handleGetStatus(
            appointmentsId,
            mairieId
        );
        if (!isEmpty(resAntsIdsStatus)) {
            Object.keys(resAntsIdsStatus).map((key) => {
                const resAnts = testStatusAntsNumber(
                    resAntsIdsStatus[key]?.status
                );
                if (resAnts?.success) {
                    if (resAnts?.error) {
                        if (resAntsIdsStatus[key]?.appointments?.length > 0) {
                            setDuplicates(resAntsIdsStatus);
                            success = false;
                        }
                    }
                } else {
                    success = false;
                    setAlert({
                        message: resAnts?.error?.replace(
                            "$num",
                            `<br> <p>- ${key}</p>`
                        ),
                        open: true,
                    });
                    return "break";
                }
            });
        }
        if (success) {
            axios
                .post("/otp/generate", {
                    phoneNumber: data.phoneNumber,
                    email: data.email,
                    verificationType,
                    mairie: mairie._id,
                })
                .then(({ data }) => {
                    if (data?.success) {
                        setOtpRequestToken(data?.data?.otp_request_token);
                    }
                    setUi((prev) => ({ ...prev, isFormValid: true }));
                    handleStepChange(2);
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setUi((prev) => ({ ...prev, sendingOTP: false }));
                });
        }
        setUi((prev) => ({ ...prev, sendingOTP: false }));
    };

    return (
        <>
            <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step expanded={true}>
                        <StepLabel
                            color={"secondary"}
                            StepIconComponent={stepIconComponent}
                        >
                            <Typography variant={"h6"}>
                                <span className={"font-bold"}>
                                    Type de demande
                                </span>
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Stack marginLeft={3} flexDirection={"row"}>
                                {ui.isStep1Editing ? (
                                    <>
                                        <Stack gap={2}>
                                            <Stack
                                                flexDirection={"column"}
                                                gap={2}
                                            >
                                                <SelectTypeDossier
                                                    onSelect={(item) =>
                                                        setTypeDossier(item)
                                                    }
                                                    selected={typeDossier}
                                                    motifs={motifs}
                                                />
                                                <p
                                                    className={
                                                        "font-bold text-sm"
                                                    }
                                                >
                                                    Nombre de personne(s)
                                                    concernée(s) par votre
                                                    demande
                                                </p>
                                                <TextField
                                                    sx={{ width: 80 }}
                                                    type="number"
                                                    value={nombrePersonne}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 100,
                                                            min: 1,
                                                        },
                                                    }}
                                                    onChange={(e) =>
                                                        setNombrePersonne(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </Stack>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Button
                                                        color={"secondary"}
                                                        variant={"contained"}
                                                        onClick={() =>
                                                            handleTypeDossierChange()
                                                        }
                                                    >
                                                        Enregistrer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </>
                                ) : (
                                    <Box>
                                        <Chip
                                            label={`${typeDossier.label} (${nombrePersonne})`}
                                        />
                                    </Box>
                                )}
                                {/*{ !ui.isStep1Editing && <>*/}
                                {/*    <Box marginLeft={'auto'}>*/}
                                {/*        <Button variant={"text"} color={"secondary"} onClick={() => handleEditMode(0)}>Modifier</Button>*/}
                                {/*    </Box>*/}
                                {/*</>}*/}
                            </Stack>
                        </StepContent>
                    </Step>
                    <Step expanded={true}>
                        <StepLabel StepIconComponent={stepIconComponent}>
                            <Typography variant={"h6"}>
                                <span className={"font-bold"}>
                                    Date de rendez-vous
                                </span>
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Stack
                                marginLeft={3}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Stack>
                                    <Box>
                                        <p>
                                            Vous avez choisi le{" "}
                                            <span
                                                className={
                                                    "text-green-700 font-bold"
                                                }
                                            >
                                                <span className={"capitalize"}>
                                                    {selectedDate.format(
                                                        "dddd DD MMMM YYYY"
                                                    )}
                                                </span>{" "}
                                                à {selectedDate.format("HH:mm")}{" "}
                                            </span>
                                        </p>
                                    </Box>
                                    {ui.isStep2Editing && (
                                        <>
                                            <Box marginTop={3}>
                                                <Paper sx={{ padding: 2 }}>
                                                    <CustomCalendar
                                                        calendarLists={
                                                            calendarLists
                                                        }
                                                        availableHours={
                                                            mairie?.available_slots
                                                        }
                                                        onDateSelect={(
                                                            calendar,
                                                            hour
                                                        ) =>
                                                            handleOnDateSelect(
                                                                calendar,
                                                                hour,
                                                                mairie
                                                            )
                                                        }
                                                        startDate={today}
                                                        setStartDate={setToday}
                                                    />
                                                </Paper>
                                            </Box>
                                        </>
                                    )}
                                    <Stack
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                    >
                                        <IconButton
                                            sx={{ padding: 0, marginLeft: 0 }}
                                        >
                                            <Checkbox
                                                defaultChecked={
                                                    notifyMeWhenAppointmentFree
                                                }
                                                onChange={(e) =>
                                                    setNotifyMeWhenAppointmentFree(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </IconButton>
                                        <Typography variant={"subtitle2"}>
                                            Je souhaite être informé(e) si un
                                            rendez-vous se libère plus tôt.
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {!ui.isStep2Editing && (
                                    <>
                                        <Box marginLeft={"auto"}>
                                            <Button
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={() =>
                                                    handleEditMode(1)
                                                }
                                            >
                                                Modifier
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Stack>
                        </StepContent>
                    </Step>
                    <Step expanded={true}>
                        <StepLabel StepIconComponent={stepIconComponent}>
                            <Typography variant={"h6"}>
                                <span className={"font-bold"}>
                                    {session?.typeDossier?.includes("CNI") ||
                                    session?.typeDossier?.includes("PASSEPORT")
                                        ? "Identité des personnes concernées"
                                        : "Vos informations de contact"}
                                </span>
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Stack marginLeft={3} flexDirection={"row"}>
                                <Box className={"w-full"}>
                                    <IdentificationForm
                                        loading={ui.sendingOTP}
                                        nombrePersonne={
                                            Number(session.nombrePersonne) ||
                                            Number(searchParams.get("person"))
                                        }
                                        ants={ants}
                                        onConfirm={(event) =>
                                            handleSubmitForm(event, mairie?._id)
                                        }
                                    />
                                </Box>
                            </Stack>
                        </StepContent>
                    </Step>
                    <Step expanded={ui.isFormValid}>
                        <StepLabel StepIconComponent={stepIconComponent}>
                            <Typography variant={"h6"}>
                                <span className={"font-bold"}>
                                    Confirmation
                                </span>
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Stack marginLeft={3} flexDirection={"row"}>
                                <OtpVerification
                                    loading={loading}
                                    email={session.email}
                                    phoneNumber={session.phoneNumber}
                                    onValid={handleOtpValid}
                                    otpToken={otpRequestToken}
                                    verificationType={verificationType}
                                    mairie={mairie._id}
                                />
                            </Stack>
                        </StepContent>
                    </Step>
                </Stepper>
                <CustomDialog
                    setOpen={setOpenDuplicate}
                    open={openDuplicate}
                    onClose={handleCloseModalDuplicate}
                    component={<DetailsDuplicate duplicates={duplicates} />}
                />
                <Popup
                    message={alert?.message}
                    type="error"
                    open={alert?.open}
                    handleClose={handleCloseAlert}
                />
                <ModalAnts
                    open={openAnts}
                    handleClose={handleCloseAnts}
                    nombrePersonne={nombrePersonne}
                    callback={handleModalAntsCallback}
                />
            </Box>
        </>
    );
};
