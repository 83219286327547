import dayjs from "dayjs";

export const compareTime = (str1, str2) => {
    if(str1 === str2){
        return true;
    }
    let time1 = str1.split(':');
    let time2 = str2.split(':');
    if(Number(time1[0]) > Number(time2[0])){
        return true;
    } else if(Number(time1[0]) == Number(time2[0]) && Number(time1[1]) > Number(time2[1])) {
        return true;
    } else {
        return false;
    }
}

export const testIfinBusyDays = (start, end, busyDays = {}) => {
    const dateEn = dayjs(start).format('DD/MM/YYYY')
    let isBusy = false;  

    if(busyDays[dateEn]){
        busyDays[dateEn].map(hours => {
            const startHour = (dayjs(start) as any).tz().hour(hours.startHour.split(':')[0]).minute(hours.startHour.split(':')[1]);
            const endHour = (dayjs(start) as any).tz().hour(hours.endHour.split(':')[0]).minute(hours.endHour.split(':')[1]);
          
            if((dayjs(start).isSame(startHour) || dayjs(start).isAfter(startHour)) && (dayjs(end).isBefore(endHour) || dayjs(end).isSame(endHour))){
                isBusy = true;
            }
        })
    }
    return isBusy;
}