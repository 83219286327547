import { useMemo, useState } from "react";
import '../SmsEmail.style.scss';
import { TabMenu } from "../../../../../../application/components/TabMenu/TabMenu";
import { SendException } from "../view/SendException";
import {VolumeSms} from "../view/VolumeSms";
import { Box } from "@mui/material";

export const Sms = () => {
    const [activeSousMenu, setActiveSousMenu] = useState<string>('send_exception');

    const menus = useMemo(() => {
        return [
            {
                name: 'ENVOI EXCEPTIONEL',
                key: 'send_exception'
            },
            {
                name: 'Volume SMS',
                key: 'volume_sms'
            },
        ]
    }, []);

    return (
        <>
            <TabMenu activeSousMenu={activeSousMenu} setActiveSousMenu={setActiveSousMenu} menus={menus}/>
            <Box className="box-mairie">
                {activeSousMenu === 'send_exception' && <SendException setActiveSousMenu={setActiveSousMenu} />}
                {activeSousMenu === 'volume_sms' && <VolumeSms />}  
            </Box>
        </>
    )
}
