import './SmsEmail.style.scss';
import { SousMenuSMS } from "../../../../../application/components/SousMenu/SousMenuSMS";
import { Box } from '@mui/material';
import { useAuthStore } from '../../../../../application/store/auth.store';

export const SmsEmail = () => {
    const  user  = useAuthStore((state) => state.user);

    return (
        <Box className="div-content">
            {['ADMIN', 'AGENT'].includes(user.role) && <SousMenuSMS />}
        </Box>
    )
}
