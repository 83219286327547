import React from 'react';
import {Stack, Typography} from "@mui/material";
import {CircleRounded, OpenInNew} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import dayjs from "dayjs";

const OnlineStyle =  ({data}) => <>
    <div style={{
        border: '1px solid white',
        padding: '0 5px',
        color: 'white',
        borderRadius: '4px'
    }}>
        {data?.type?.isOnline ? 'En ligne' : 'En mairie'}
    </div>
</>

const CanceledDateCard = ({data}) => {
    return <>
        <Stack flexDirection={'row'} justifyContent={'space-between'}  sx={{ minWidth: '250px', width: '100%', backgroundColor: red['200'], borderRadius: '8px'}} padding={1}>
            <Stack flexDirection={'row'}>
                <Stack padding={'10px 8px'}><CircleRounded sx={{ fontSize: 10, color: red['900'] }}/></Stack>
                <Stack padding={'5px 0 0 0'}>
                    <Stack>
                        <Typography fontWeight={'bold'}>RDV {dayjs(data?.heureRdv)?.format('DD MMMM YYYY - HH:mm')}</Typography>
                        <Typography>Annulé le {dayjs(data?.updatedAt)?.format('DD MMMM YYYY à HH:mm')}</Typography>
                    </Stack>
                    <Stack flexDirection={'row'} gap={1}>
                        <Typography fontWeight={'light'}>{data?.usager?.firstName}</Typography>
                        <Typography fontWeight={'bolder'}>{data?.usager?.lastName}</Typography>
                        <span>|</span>
                        <Typography>{data?.typeDossier?.replace(/_/g, ' ')} ({data?.nombrePersonne})</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack>
                <Stack
                    marginBottom={1.75}
                    alignItems={'end'}
                ><OpenInNew sx={{ fontSize: '14px'}}/></Stack>
                <Stack>
                    <OnlineStyle data={data}/>
                </Stack>
            </Stack>
        </Stack>
    </>
}

export const CanceledDate = ({data}) => {
    return <>
        <Stack sx={{ backgroundColor: 'white', padding: '1em', borderRadius:'8px'}}>
            <Typography variant={'h5'}>Rendez-vous annulé(s)</Typography>
            <Typography variant={'caption'} className={'text-gray-400'}>Rendez-vous libérés</Typography>
            <Stack gap={1} flexDirection={'row'} flexWrap={'wrap'} marginTop={3} sx={{maxHeight: '425px', overflow: 'auto'}}>
                { data && data?.length > 0 ? data.map(entry => (
                    <CanceledDateCard data={entry}/>
                )): <p>Aucun rendez-vous annulé actuellement</p>}
            </Stack>
        </Stack>
    </>
}
