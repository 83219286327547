import {
    Box,
    Button,
    Stack,
    Link
} from "@mui/material";
import dayjs from "dayjs";
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { getNbDuplicates } from "../../../infrastructure/utils/ants";
import { useMemo } from "react";
require('dayjs/locale/fr');

export const DetailsDuplicate = ({ duplicates }) => {
    const nbDuplicates = getNbDuplicates(duplicates);
    const title = useMemo(() => {
        if(nbDuplicates > 1){
            return 'Des rendez-vous dont déjà enregistrés pour ces numéros de pré-demande'
        }
        return 'Un rendez-vous est déjà enregistré pour ce numéro de pré-demande'
    }, [nbDuplicates])

    const description = useMemo(() => {
        if(nbDuplicates > 1){
            return `Pour confirmer la demande de rendez-vous en cours, vous devez d'abord annuler les rendez-vous ci-dessous :`
        }
        return `Pour confirmer la demande de rendez-vous en cours, vous devez d'abord annuler le rendez-vous ci-dessous :`
    }, [nbDuplicates])

    return (
        <Box>
            <Stack flexDirection={"row"} className="items-center justify-center">
                <WarningIcon sx={{ fill: 'red', fontSize: '3rem' }}/>
            </Stack>
            <Stack flexDirection={"row"} className="items-center justify-center">
                <p className="title-bold px-5 mb-0">{title}</p>
            </Stack>
            <Stack className="px-5 py-2">
                <p>{description}</p>
                {Object.keys(duplicates)?.map(key => 
                    <>
                        {duplicates[key]?.appointments?.length > 0 && <Stack gap={2} marginTop={1} justifyContent={'space-between'}>
                            {duplicates[key]?.appointments?.map(appointment => 
                                <Stack flexDirection={'row'} gap={2} alignItems={'center'}>
                                    <Stack>
                                        <p>Numéro de pré-demande : <span className="bold">{key}</span></p>
                                        <p>{appointment?.meeting_point} {dayjs(appointment?.appointment_date).format('DD MMM YYYY à HH:mm')}</p>
                                    </Stack>    
                                    <Link target={'_blank'} href={appointment?.management_url} style={{ color: '#FFFFFF' }}>
                                        <Button
                                            variant="contained" 
                                            color='primary' 
                                            className="btn-rdv btn-reset btn-icon"
                                            startIcon={<CancelIcon />}
                                        >
                                            Annuler le RDV
                                        </Button>
                                    </Link>
                                </Stack>
                            )}
                        </Stack>}
                    </>
                )}
            </Stack>
            
        </Box>
    )
}
