import {Box, Button, Stack, Typography} from "@mui/material";
import {ReactComponent as TownHallSvg} from "../assets/svg/town-hall.svg";
import {LocationOn} from "@mui/icons-material";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import {useEffect, useMemo, useState} from "react";
import { BREAKPOINT_DESKTOP_MD } from "../../constant";
import dayjs from "dayjs";
import { CustomDialog } from "./CustomDialog";
import DetailsInfoMairie from "./DetailsInfoMairie";

export const MairieCardInfo = (props) => {
    const {defaultDuration, item, detailView} = props;
    const [open, setOpen] = useState<boolean>(false);
    const [session] = useSessionStorage('session', {} as any);
    const [duration, setDuration] = useState(dayjs());
    const desktopMd = useMediaQuery(BREAKPOINT_DESKTOP_MD);

    const handleCloseModal = () => setOpen(false);
    
    useEffect(() => {
        const tempDuration = defaultDuration * (session?.nombrePersonne || 1);
        setDuration(dayjs().hour(0).minute(tempDuration));
    }, [defaultDuration, session?.nombrePersonne])

    const convertDate = (value) => { // params 08:11
        const array = value.split(':');
        return `${array[0]}:${array[1]}`
    }

    const hourOpen = useMemo(() => {
        let date = new Date();
        let day = date.toLocaleString('fr-Fr', {weekday: 'long'});
        if(item?.calendar){
            const calendar = item?.calendar?.hours;
            if(calendar && calendar[day])
                if(calendar[day][0]?.startHour !== '-'){
                    return calendar[day];
                }
        }
        return [
            {
                "startHour": "08:00",
                "endHour": "12:00"
            },
            {
                "startHour": "14:00",
                "endHour": "17:00"
            }
        ];
    }, [item]);

    return (
        <>
            {item && <Stack flexDirection={`${desktopMd ? 'column' : 'row'}`} gap={4} alignItems={`${ desktopMd ? 'center' : 'start'}`}>
                <Box>
                    {item?.city_logo ? <img className="profile-mairie-preview" src={item?.city_logo} width={"100%"} height={"100%"} alt={item.name} /> : <TownHallSvg/>}
                </Box>
                <Stack gap={1}>
                    <Box>
                        <Typography variant={"h5"} color={"primary"}><span className={"font-bold"}>{item.name}</span></Typography>
                        <p className={"text-gray-400"}>{item.public_entry_address}</p>
                        <p className={"text-gray-400"}>{item.zip} {item.city_name}</p>
                    </Box>
                    <Box>
                        {/* <p className={"font-bold text-sm"}>Heures d'ouverture :</p>
                        <p>{convertDate(hourOpen[0]?.startHour)} à {convertDate(hourOpen[0]?.endHour)} 
                            {hourOpen[1].startHour !== '-' && ' - '+convertDate(hourOpen[1]?.startHour)+' à '+convertDate(hourOpen[1]?.endHour)}
                        </p> */}
                        <Stack flexDirection={"row"}>
                            <LocationOn/>
                            <p>{Math.round(item.distance)} km</p>
                        </Stack>
                        { detailView && <>
                            <p className={"text-sm mt-2"}>
                                <span className={"font-bold"}>Durée de rendez-vous estimé</span> : {duration.format('HH')}:{duration.format('mm')}
                            </p>
                        </>}
                    </Box>
                    { !detailView && <Box>
                        <Button variant={"text"} onClick={() => setOpen(true)} color={"secondary"}>Voir details</Button>
                    </Box>}
                </Stack>
            </Stack>}
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={
                    <DetailsInfoMairie mairie={item}  />}
            />
        </>
    )
}
