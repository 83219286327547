import {Alert, Stack, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import AxiosContext from "../context/axios.context";
import {LoadingButton} from "@mui/lab";

export const UserVerification = ({onValid, email, phoneNumber, otpToken, loading, verificationType}) => {

    const [ui, setUi] = useState({ resend: false, sent: false, codeIncorrect: false, loading: false, hasError: false });
    const [code, setCode] = useState<string>();
    const [otpRequestToken, setOtpRequestToken] = useState<string>(otpToken);
    const { value: { instance: axios } } = useContext(AxiosContext);

    const handleSubmit = async () => {
        setUi(prev => ({...prev, loading: true, success: false, message: '', sent: false }))
        await axios.post('/otp/check', {
            phoneNumber,
            code,
            otpRequestToken,
            email,
            verificationType // this will disable sms verification
        })
            .then(({data}) => {
                if (data?.success) {
                    onValid()   
                } else  {
                    setUi(prev => ({...prev, codeIncorrect: true, message: data?.message ?? 'Merci de prendre un rendez-vous pour aller dans le gestionnaire de compte.' }))
                }
            }).catch(error => {
                console.log(error)
            })
        setUi(prev => ({...prev, loading: false }))
    }

    const handleResendOTP = () => {
        setUi(prev => ({...prev, resend: true, sent: false, hasError: false, success: true, codeIncorrect: false, message: '' }));
        axios.post('/otp/generate', {
            phoneNumber,
            email,
        }).then(({data}) => {
            if (data?.success) {
                setOtpRequestToken(data?.data?.otp_request_token)
            }
        }).finally(() => {
            setUi(prev => ({...prev, resend: false, sent: true }));
        })
    }


    return <>
        <Stack gap={1}>
            {verificationType === 'SMS' && <p>Saisissez ici le code communiqué par SMS sur votre téléphone portable.</p>}
            {verificationType === 'EMAIL' && <p>Saisissez ici le code communiqué par email.</p>}
            <TextField
                type={"number"}
                className={"bg-white m-0"}
                placeholder={"Code"}
                size="small"
                onChange={e => setCode(e.target.value)}
            />
            
            { ui.sent && <Alert severity={"info"}>Un nouveau code a été envoyé.</Alert>}
            { ui.codeIncorrect && <Alert severity="error">Code saisi incorrect. Veuillez réessayer s'il vous plaît!</Alert>}
            { ui.hasError && <Alert severity="error">Désolé, une erreur est survenue !</Alert> }
            <LoadingButton loading={ui.resend} variant={"text"} color={"secondary"} onClick={() => handleResendOTP()}>
                {verificationType === 'SMS' && 'Renvoyez le SMS'}
                {verificationType === 'EMAIL' && 'Renvoyez l\'email'}
            </LoadingButton>
            <LoadingButton
                fullWidth 
                variant={"contained"}
                loading={ui.loading}
                disabled={!code || code.length !== 6}
                onClick={() => handleSubmit()}
            >Suivant</LoadingButton>
        </Stack>
    </>
}
