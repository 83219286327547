export const testStatusAntsNumber = (status, nbAnts = 1) => {
    let textError = `<b>Numéro de pré-demande non valide</b> </p><p>Le numéro de pré-demande suivant a déjà été utilisé dans un autre dossier : $num </p> Merci de vérifier que vous n'avez pas fait d'erreur de saisie. <p>Si ce n'est pas le cas, vous devez réaliser une nouvelle pré-demande sur le <a href="https://passeport.ants.gouv.fr/demarches-en-ligne" target="_blank">site de l'ANTS</a> pour réserver votre rendez-vous en ligne.</p><p>Si vous souhaitez conserver la priorité sur la date choisie, ne fermez pas cette page et saisissez le nouveau numéro de pré-demande obtenu dans un <b>délai maximum de 3 heures</b>.</p>`;
    switch (status) {
        case "consumed":
            if (nbAnts > 1) {
                textError = `<b>Numéros de pré-demande non valides</b> </p><p>Les numéros de pré-demande suivants ont déjà été utilisés dans un autre dossier : $num </p> Merci de vérifier que vous n'avez pas fait d'erreur de saisie. <p>Si ce n'est pas le cas, vous devez réaliser de nouvelles pré-demandes sur le <a href="https://passeport.ants.gouv.fr/demarches-en-ligne" target="_blank">site de l'ANTS</a> pour réserver votre rendez-vous en ligne.</p><p>Si vous souhaitez conserver la priorité sur la date choisie, ne fermez pas cette page et saisissez les nouveaux numéros de pré-demande obtenus dans un <b>délai maximum de 3 heures</b>.</p>`;
            }
            return {
                success: false,
                error: textError,
            };
        case "unknown":
            textError = `<b>Numéro de pré-demande non valide</b> </p><p>Le numéro de pré-demande $num n'est pas valable. </p> Merci de vérifier que vous n'avez pas fait d'erreur de saisie. <p>Si ce n'est pas le cas, vous devez réaliser une nouvelle pré-demande sur le <a href="https://passeport.ants.gouv.fr/demarches-en-ligne" target="_blank">site de l'ANTS</a> pour réserver votre rendez-vous en ligne.</p><p>Si vous souhaitez conserver la priorité sur la date choisie, ne fermez pas cette page et saisissez le nouveau numéro de pré-demande obtenu dans un <b>délai maximum de 3 heures</b>.</p>`;
            return {
                success: false,
                error: textError,
            };
        case "declared":
            textError = `<b>Numéro de pré-demande non valide</b> </p><p>Le numéro de pré-demande $num n'existe pas. </p> Merci de vérifier que vous n'avez pas fait d'erreur de saisie. <p>Si ce n'est pas le cas, vous devez réaliser une nouvelle pré-demande sur le <a href="https://passeport.ants.gouv.fr/demarches-en-ligne" target="_blank">site de l'ANTS</a> pour réserver votre rendez-vous en ligne.</p><p>Si vous souhaitez conserver la priorité sur la date choisie, ne fermez pas cette page et saisissez le nouveau numéro de pré-demande obtenu dans un <b>délai maximum de 3 heures</b>.</p>`;
            return {
                success: false,
                error: textError,
            };
        case "validated":
            return {
                success: true,
                error: "Rendez-vous dupliqué.",
            };
        default:
            return {
                success: true,
                error: null,
            };
    }
};

export const getNbDuplicates = (duplicates) => {
    let nb = 0;
    Object.keys(duplicates)?.map((key) => {
        if (
            duplicates[key]?.status === "validated" &&
            duplicates[key]?.appointments?.length > 0
        ) {
            nb++;
        }
    });
    return nb;
};
