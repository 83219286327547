
import { FormControlLabel, FormGroup, Grid, TableCell, TableRow, styled, FormControl, RadioGroup, Radio, TextField, Stack } from "@mui/material";
import dayjs from "dayjs";
import  capitalize  from "lodash/capitalize";
import { SwitchButton } from "../../../../../../application/components/SwitchButton";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useMairieStore } from "../../../../../../application/store/mairie.store";
require('dayjs/locale/fr');

const BorderedTD = styled(TableCell)`
    &.MuiTableCell-root {
    border-left: 1px solid rgba(224, 224, 224, 1);
    font-weight: bold;
    }
`;

const SecondInputWeek = ({label, callback, day, index, hours}) => {

    const handleChangeData = (value, key) => {
        callback(prevState => {
            const newState = prevState[day].map((obj, id) => {
              if(id === index){
                return {...obj, [key]: value}
              }else{
                return obj
              }
            });
            return {...prevState, [day]: newState};
        });
    }

    return (
        <Stack>
            <Stack flexDirection={'row'} gap={1} sx={{ flexWrap: 'wrap' }} alignItems={'center'}>
                {label}
                <TextField
                    variant="outlined" 
                    type={'number'} 
                    defaultValue={hours?.numberOfWeek}
                    InputProps={{
                        inputProps: {
                            max: 10,
                            min: 2
                        }
                    }}
                    onChange={(e) => handleChangeData(e.target.value, 'numberOfWeek')}
                    size={'small'}
                />
                semaines
            </Stack>
            <Stack flexDirection={'row'} marginTop={1} gap={1} sx={{ flexWrap: 'wrap' }} alignItems={'center'}>
                A partir du 
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                    <DesktopDatePicker
                        value={hours?.startDate}
                        minDate={dayjs()}
                        onChange={(newValue) => {
                            handleChangeData(newValue, 'startDate')
                        }}
                        renderInput={(params) => <TextField {...params} size="small" sx={{ width: 150 }} />}
                    />
                </LocalizationProvider>
            </Stack>
        </Stack>
    )
}

export const TableHourType = ({ index, day, hours, excludesHour, includeHour, callback}) => {
    const  mairies  = useMairieStore((state) => state.mairies);

    const handleChangeTypeHour = (callback, day, index, typeRDV) => {
        callback(prevState => {
            const newState = prevState[day].map((obj, id) => {
              if(id === index){
                let type = {isMairie: false, isOnline: false}
                if(obj?.type){
                    type = obj.type 
                }
                type = {...type, [typeRDV]: !type[typeRDV]}
                return {...obj, type: type}
              }else{
                return obj
              }
            });
            return {...prevState, [day]: newState};
        });
    }

    const handleChangeTypeRepetition = (callback, day, index, repetitionType) => {
        callback(prevState => {
            const newState = prevState[day].map((obj, id) => {
              if(id === index){
                return {...obj, repetitionType: repetitionType}
              }else{
                return obj
              }
            });
            return {...prevState, [day]: newState};
        });
    }

    return (
        <TableRow  key={index}>
            <BorderedTD sx={{ width: '5%' }}>{capitalize(day)}</BorderedTD>
            <BorderedTD sx={{ width: '15%' }} align="right">{hours.startHour}h à {hours.endHour}h</BorderedTD>
            <BorderedTD sx={{ width: '15%' }} align="right">
            {includeHour?.startDate && includeHour?.endDate ? <p>Du {dayjs(includeHour.startDate).locale('fr').format('DD MMM YYYY')} au {dayjs(includeHour.endDate).locale('fr').format('DD MMM YYYY')}</p> : <p>permanent</p>}
            </BorderedTD>
            <BorderedTD align="right" sx={{ width: '15%' }}>
                {excludesHour && excludesHour[0].startDate && excludesHour[0].startDate !== '' ? <>
                    Sauf 
                    {excludesHour.map((excludeHour) => 
                        <>
                            <p>du {dayjs(excludeHour.startDate).locale('fr').format('DD MMM YYYY')} à {dayjs(excludeHour.endDate).locale('fr').format('DD MMM YYYY')}</p><br></br>
                        </>
                    )}
                </>: <p>Aucune exception</p>}
            </BorderedTD>
            <BorderedTD align="right" sx={{ width: '15%' }}>
                <Grid container gap={3}>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <SwitchButton 
                                    sx={{ m: 1 }} 
                                    defaultChecked={hours['type']?.isOnline}
                                    onChange={() => handleChangeTypeHour(callback, day,  index, 'isOnline' )}
                                />}
                                label="En ligne"
                                className="switch-label-motif"
                                labelPlacement="start"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <SwitchButton 
                                    sx={{ m: 1 }} 
                                    defaultChecked={hours['type']?.isMairie}
                                    onChange={() => handleChangeTypeHour(callback, day,  index, 'isMairie' )}
                                />}
                                label="Mairie"
                                className="switch-label-motif"
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </BorderedTD>
            {mairies[0]?.agendaParameter?.configurationWeek && <BorderedTD align="right" sx={{ width: '40%' }}>
                <FormControl className="form-radio">
                    <RadioGroup
                        row
                        aria-labelledby="repetitionType"
                        defaultValue={hours?.repetitionType ?? 'all_week'}
                        className="group-radio"
                    >
                        <FormControlLabel value="all_week" control={<Radio onChange={(e) => handleChangeTypeRepetition(callback, day,  index, e.target.value )} />} label="Toutes les semaines" />
                        <FormControlLabel value="some_week" control={<Radio onChange={(e) => handleChangeTypeRepetition(callback, day,  index, e.target.value )} />} label={<SecondInputWeek label={'Toutes les '} callback={callback} hours={hours} day={day} index={index} />}  />
                    </RadioGroup>
                </FormControl>
            </BorderedTD>}
        </TableRow>
    )
}