import NODE_API from "./axios.helper";

export const handlePostMotif= async(data) => {
    try {
        const res = NODE_API.post('/motifs',{
            name: data.name,
            duration: data.duration,
            antsDuration: data.antsDuration,
            color: data.color,
            textEnd: data.textEnd,
            confirm : data.confirm,
            cancel: data.cancel,
            update: data.update,
            reminder: data.reminder,
            release: data.release,
            mairie: data.mairie
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handlePutMotif = async(data) => {
    try {
        const res = NODE_API.put('/motifs/'+data._id,{
            name: data.name,
            duration: data.duration,
            antsDuration: data.antsDuration,
            hidden: data?.hidden,
            color: data.color,
            textEnd: data.textEnd,
            confirm : data.confirm,
            cancel: data.cancel,
            update: data.update,
            reminder: data.reminder,
            release: data.release
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const removeMotif = async(data) => {
    try {
        const res = NODE_API.delete(`/motifs/${data?._id}`)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getMotifsBySlug = async(slug) => {
    const res = await NODE_API.get(`/motifs/slug/${slug}`);
    if(res?.data){
        return res?.data
    }
}