import {
    GroupingState,
    IntegratedGrouping,
    ViewState,
} from "@devexpress/dx-react-scheduler";
import {
    Scheduler,
    Appointments,
    WeekView,
    Resources,
    AppointmentTooltip,
    GroupingPanel,
    AppointmentForm,
    DateNavigator,
    TodayButton,
    Toolbar,
    DayView,
    ViewSwitcher,
    MonthView,
} from "@devexpress/dx-react-scheduler-material-ui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import Paper from "@mui/material/Paper";
import classNames from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import "./Scheduler.style.scss";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    styled,
    alpha,
    Tooltip,
} from "@mui/material";
import axios from "axios";
import { API } from "../../../constant";
import { Box } from "@mui/system";
import { CreateAppointmentForm } from "../ModalCreateAppointment/CreateAppointment";
import { CustomDialog } from "../CustomDialog";
import capitalize from "lodash/capitalize";
import { StyledAppointmentsAppointment } from "./styled/Scheduler.styled";
import { BasicLayout } from "./layout/BasicLayout";
import CalendarWarning from "../../../application/assets/svg/warning.png";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import dayjs from "dayjs";
import { testAccess } from "../../../infrastructure/utils/acces";
import { DetailsDuplicate } from "../DetailsDuplicate";
import { Popup } from "../Popup/Popup";
import { testStatusAntsNumber } from "../../../infrastructure/utils/ants";
import { isEmpty } from "lodash";
import {
    handleGetStatus,
    handlePostAppointment,
} from "../../../helpers/ants.helper";
import { useAuthStore } from "../../store/auth.store";
import { useMairieStore } from "../../store/mairie.store";
import { getAllAppointment, useAgendaStore } from "../../store/agenda.store";
import { compareTime, testIfinBusyDays } from "../../utils/utils";

const PREFIX = "BO";

const classes = {
    appointment: `${PREFIX}-appointment`,
    passTypeAppointment: `${PREFIX}-passTypeAppointment`,
    canceledAppointment: `${PREFIX}-canceledAppointment`,
    notCanceledAppointment: `${PREFIX}-notCanceledAppointment`,
    cniPassTypeAppointment: `${PREFIX}-cniPassTypeAppointment`,
    cniTypeAppointment: `${PREFIX}-cniTypeAppointment`,
    weekEndCell: `${PREFIX}-weekEndCell`,
    openingDay: `${PREFIX}-openingDay`,
    weekEndDayScaleCell: `${PREFIX}-weekEndDayScaleCell`,
    text: `${PREFIX}-text`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    addButton: `${PREFIX}-addButton`,
    overlay: `${PREFIX}-overlay`,
};

const getRange = (date, view) => {
    if (view === "Day") {
        return { startDate: date, endDate: date };
    }
    if (view === "Week") {
        let firstDay = date.getDate() - date.getDay();
        let lastDay = firstDay + 6;
        return {
            startDate: new Date(date).setDate(firstDay),
            endDate: new Date(date).setDate(lastDay),
        };
    }

    if (view === "Month") {
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return {
            startDate: firstDay,
            endDate: lastDay,
        };
    }
};

export const SchedulerComponent = ({
    checkBoxBureau,
    checkBoxType,
    mairie,
}) => {
    const [grouping, setGrouping] = useState([
        {
            resourceName: "location",
        },
    ]);
    const user = useAuthStore((state) => state.user);
    const motifs = useMairieStore((state) => state.motifs);
    const agendas = useMairieStore((state) => state.agendas);
    const appointments = useAgendaStore((state) => state.appointments);
    const slug = useAgendaStore((state) => state.slug);
    const loading = useAgendaStore((state) => state.loading);
    const defaultResourceBureau = useAgendaStore(
        (state) => state.defaultResourceBureau
    );

    const [resourcesBureau, setResourcesBureau] = useState(
        defaultResourceBureau
    );
    const [currentDate, setCurrentDate] = useState<any>(new Date());
    const [currentView, setCurrentView] = useState<string>(
        capitalize(mairie?.agendaParameter?.defaultView) ?? "Day"
    );
    const [range, setRange] = useState<any>(getRange(currentDate, currentView));
    const [showModal, setShowModal] = useState(false);
    const [ui, setUi] = useState({
        createAppointment: false,
    });
    const [alert, setAlert] = useState<any>({
        open: false,
        message:
            "Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur.",
    });
    const [duplicates, setDuplicates] = useState<any>({});
    const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
    const [status, setStatus] = useState({
        pending: true,
        canceled: false,
    });
    const [view, setView] = useState(false);
    const [dataAgenda, setDataAgenda] = useState<any>([]);
    const handleCloseModal = () => setShowModal(false);
    const StyledFab = styled(Fab)(({ theme }) => ({
        [`&.${classes.addButton}`]: {
            position: "fixed",
            background: "#176FBD",
            bottom: theme.spacing(6),
            right: theme.spacing(4),
        },
    }));

    const StyledNavBottom = styled(Stack)(({ theme }) => ({
        position: "fixed",
        background: "#ededed",
        borderRadius: "10px 0px 0px 0px",
        padding: "0px 20px",
        bottom: theme.spacing(0),
        right: theme.spacing(0),
        zIndex: 1,
    }));

    useEffect(() => {
        if (range?.endDate) {
            getAllAppointment(range.startDate, range.endDate);
        }
    }, [range?.startDate, range?.endDate]);

    const handleChangeStatus = (type) => {
        setStatus((state) => ({ ...state, [type]: !state[type] }));
    };

    const StyledAppointmentsAppointmentContent = styled(
        Appointments.AppointmentContent
    )(({ theme: { palette } }) => ({
        [`& .${classes.text}`]: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "2px 0px",
            whiteSpace: "break-spaces",
        },
        [`& .${classes.content}`]: {
            opacity: 0.7,
            color: "#000000",
        },
        [`& .${classes.container}`]: {
            width: "100%",
            lineHeight: 1.2,
            height: "100%",
        },
    }));

    const StyleAppointmentOverlayContent = styled(AppointmentForm.Overlay)(
        ({ theme: { palette } }) => ({
            [`&.Overlay-halfSize`]: {
                height: "50%",
                width: "100%",
                bottom: "0",
            },
            [`&.Overlay-custom`]: {
                position: "fixed",
            },
        })
    );

    const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
        ({ theme: { palette } }) => ({
            [`&.${classes.weekEndCell}`]: {
                backgroundColor: alpha(palette.action.disabledBackground, 0.04),
                "&:hover": {
                    backgroundColor: alpha(
                        palette.action.disabledBackground,
                        0.04
                    ),
                },
                "&:focus": {
                    backgroundColor: alpha(
                        palette.action.disabledBackground,
                        0.04
                    ),
                },
            },
            [`&.${classes.openingDay}`]: {
                backgroundColor: "rgba(90, 200, 225, 0.2)",
                "&:hover": {
                    backgroundColor: "rgba(90, 200, 225, 0.2)",
                },
                "&:focus": {
                    backgroundColor: "rgba(90, 200, 225, 0.2)",
                },
            },
        })
    );

    useEffect(() => {
        setResourcesBureau(defaultResourceBureau);
    }, [defaultResourceBureau]);

    const resourcesTypeData = React.useMemo(() => {
        const newMotifs = motifs.map((motif) => {
            return {
                id: motif.name.replaceAll(" ", "_"),
                text: motif.name,
                color: motif.color,
            };
        });
        return newMotifs;
    }, [motifs]);

    const resources = useMemo(() => {
        return [
            {
                fieldName: "location",
                title: "Location",
                instances: resourcesBureau,
            },
            {
                fieldName: "type",
                title: "Type",
                instances: resourcesTypeData,
            },
        ];
    }, [resourcesBureau, resourcesTypeData]);

    useEffect(() => {
        setResourcesBureau(
            defaultResourceBureau.filter((r: any) => checkBoxBureau[r.id])
        );
    }, [checkBoxBureau, defaultResourceBureau]);

    useEffect(() => {
        let newAppointments = appointments?.filter((r: any) => {
            let typeDossier = r.type;
            const type = typeDossier;
            return checkBoxType[type];
        });

        newAppointments = newAppointments?.filter((appointment: any) => {
            if (!status.pending)
                return !["PENDING"].includes(appointment.status);
            if (!status.canceled)
                return !["CANCELED"].includes(appointment.status);
            return appointment;
        });

        setDataAgenda(
            newAppointments.map((appointment) => {
                return {
                    ...appointment,
                    startDate: appointment?.startHour,
                    endDate: appointment?.endHour,
                };
            })
        );
    }, [appointments, checkBoxType, status]);

    const Appointment = ({
        data,
        ...restProps
    }: Appointments.AppointmentProps) => {
        const motif = motifs.find(
            (item) => item.name === data.type.replaceAll("_", " ")
        );
        return (
            <StyledAppointmentsAppointment
                {...restProps}
                className={
                    data.status === "CANCELED"
                        ? classes.canceledAppointment
                        : classes.notCanceledAppointment
                }
                color={motif?.color}
                data={data}
            />
        );
    };

    // #FOLD_BLOCK
    const AppointmentContent = ({
        data,
        ...restProps
    }: // #FOLD_BLOCK
    Appointments.AppointmentContentProps) => {
        return (
            <StyledAppointmentsAppointmentContent {...restProps} data={data}>
                <div className={classes.container}>
                    <div className={classes.text}>{data.title}</div>
                    <div className={classNames(classes.text, classes.content)}>
                        {dayjs(data.heureRdv).format("HH:mm")} à{" "}
                        {dayjs(data.endDate).format("HH:mm")}
                    </div>
                    <div className="status-icon">
                        {data.status === "DONE" && (
                            <CheckCircleIcon className="text-green" />
                        )}
                        {data?.comment && (
                            <Tooltip
                                title={data?.comment?.replace(
                                    "[Téléverser]",
                                    ""
                                )}
                                placement="right-start"
                            >
                                <InfoIcon />
                            </Tooltip>
                        )}
                    </div>
                    {data?.isDuplicate && data.status !== "DONE" && (
                        <div className="duplicate">
                            <img
                                src={CalendarWarning}
                                width="15px"
                                alt={"logo-duplicate"}
                            />
                        </div>
                    )}
                </div>
            </StyledAppointmentsAppointmentContent>
        );
    };

    const Content = ({ appointmentData }: any) => {
        const color = resourcesTypeData.find(
            (type) => type.id === appointmentData?.typeDossier
        )?.color;
        return (
            <Box className="mb-5">
                <Grid container className="box-content-appointment">
                    <Grid item xs={2}>
                        <FiberManualRecordIcon
                            sx={{ color: color, fontSize: "3rem" }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <p className="title">{appointmentData.title}</p>
                    </Grid>
                </Grid>
                <Grid container className="box-content-appointment">
                    <Grid
                        item
                        xs={2}
                        className="flex justify-center items-center"
                    >
                        <QueryBuilderIcon />
                    </Grid>
                    <Grid item xs={10}>
                        {dayjs(appointmentData.heureRdv).format("HH:mm")} à{" "}
                        {dayjs(appointmentData.endDate).format("HH:mm")}{" "}
                        <br></br>
                        Pris le:{" "}
                        {dayjs(appointmentData?.createdAt).format(
                            "DD/MM/YYYY à HH:mm"
                        )}
                    </Grid>
                </Grid>
                <Grid container className="box-content-appointment">
                    <Grid
                        item
                        xs={2}
                        className="flex justify-center items-center"
                    >
                        <EmailIcon />
                    </Grid>
                    <Grid item xs={10}>
                        <p className="mb-0">{appointmentData.usager?.email}</p>
                    </Grid>
                </Grid>
                {appointmentData?.city && (
                    <Grid container className="box-content-appointment">
                        <Grid
                            item
                            xs={2}
                            className="flex justify-center items-center"
                        >
                            <LocationOnIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <p className="mb-0">{appointmentData.city}</p>
                        </Grid>
                    </Grid>
                )}
                <Grid container className="box-content-appointment">
                    <Grid
                        item
                        xs={2}
                        className="flex justify-center items-center"
                    >
                        <LocalPhoneIcon />
                    </Grid>
                    <Grid item xs={10}>
                        <p className="mb-0">
                            {appointmentData.usager?.phoneNumber}
                        </p>
                    </Grid>
                </Grid>
                <Grid container className="box-content-appointment">
                    <Grid
                        item
                        xs={2}
                        className="flex justify-center items-center"
                    >
                        <SupervisedUserCircleIcon />
                    </Grid>
                    <Grid item xs={10}>
                        <p className="mb-0 font-bold">
                            Personne
                            {appointmentData?.concernedPerson?.length > 1 &&
                                "s"}{" "}
                            concernée
                        </p>
                    </Grid>
                    {appointmentData.concernedPerson.map((person) => (
                        <>
                            <Grid item xs={2} />
                            <Grid item xs={10}>
                                <p className="mb-0">
                                    {" "}
                                    -{" "}
                                    <span className="font-bold">
                                        {person?.lastName}
                                    </span>{" "}
                                    <span>{capitalize(person?.firstName)}</span>
                                </p>
                                {person?.ants && (
                                    <p className="ml-2">
                                        Ants : {person?.ants}
                                    </p>
                                )}
                            </Grid>
                        </>
                    ))}
                </Grid>
                {appointmentData.comment && (
                    <Grid container className="box-content-appointment">
                        <Grid
                            item
                            xs={2}
                            className="flex justify-center items-center"
                        >
                            <InfoIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <p className="font-bold">Objet du rendez-vous:</p>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={10}>
                            <p>{appointmentData.comment}</p>
                        </Grid>
                    </Grid>
                )}
                <Grid container className="box-content-appointment">
                    <Grid
                        item
                        xs={2}
                        className="flex justify-center items-center"
                    >
                        {appointmentData?.typeRdv?.isOnline ? (
                            <DesktopWindowsIcon />
                        ) : (
                            <OtherHousesIcon />
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        <p className="mb-0">
                            {" "}
                            {appointmentData?.typeRdv?.isOnline
                                ? "Rendez-vous en ligne"
                                : "Rendez-vous en mairie"}
                        </p>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const CommandButtonLayout = () => {
        return <></>;
    };

    const OverlayLayout = ({
        children,
        ...restProps
    }: AppointmentForm.OverlayProps) => {
        return (
            <StyleAppointmentOverlayContent
                className="custom-overlay"
                {...restProps}
            >
                {children}
            </StyleAppointmentOverlayContent>
        );
    };

    useEffect(() => {
        if (!isEmpty(duplicates)) {
            setOpenDuplicate(true);
        }
    }, [duplicates]);

    const handleOpenModalCreate = () => {
        setShowModal(true);
    };

    const handleCloseModalDuplicate = () => {
        setOpenDuplicate(false);
    };

    const handleCloseAlert = () => {
        setAlert((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmitForm = async (data, mairieId: string) => {
        setUi((prev) => ({ ...prev, createAppointment: true }));
        let success = true;
        const concernedPerson = data.concernedPerson.map((person) => {
            return {
                ...person,
                // birthday: dayjs(person.birthday, 'DD/MM/YYYY')
            };
        });
        const appointment = {
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            heureRdv: dayjs(data.heureRdv).utc().local().format().split("+")[0],
            dureeRdv: (data.nombrePersonne || 1) * data.duration,
            typeDossier: data.typeDossier,
            email: data.email !== "" ? data.email : null,
            status: "PENDING",
            mairie: data.mairie,
            meeting_point_id: mairieId,
            room: data.bureau,
            nombrePersonne: data.nombrePersonne,
            concernedPerson: concernedPerson,
            city: data.city.codesPostaux + ", " + data.city.nom,
            agent: user._id,
            isSpecificHour: data?.isSpecificHour,
            comment: data?.comment,
            usager: data.usager?._id,
        };
        const appointmentsId = data?.concernedPerson.reduce(
            (prev, curr) => [...prev, curr.ants],
            []
        );
        const resAntsIdsStatus = await handleGetStatus(
            appointmentsId,
            mairieId
        );
        if (!isEmpty(resAntsIdsStatus)) {
            Object.keys(resAntsIdsStatus).map((key) => {
                const resAnts = testStatusAntsNumber(
                    resAntsIdsStatus[key]?.status
                );
                if (resAnts?.success) {
                    if (resAnts?.error) {
                        if (resAntsIdsStatus[key]?.appointments?.length > 0) {
                            setDuplicates(resAntsIdsStatus);
                            success = false;
                        }
                    }
                } else {
                    setAlert({
                        message: resAnts?.error?.replace(
                            "$num",
                            `<br> <p>- ${key}</p>`
                        ),
                        open: true,
                    });
                    success = false;
                    return "break";
                }
            });
        }

        if (success) {
            await axios
                .post(API + "/appointment", {
                    ...appointment,
                    heureRdv:
                        appointment.heureRdv &&
                        appointment.heureRdv?.slice(0, 16) + ":00",
                })
                .then(async ({ data }) => {
                    if (data.success) {
                        await handlePostAppointment({
                            ...appointment,
                            meeting_point: mairie.name,
                            _id: data?.data?._id,
                            meeting_point_id: mairie._id,
                        });
                        getAllAppointment();
                        handleCloseModal();
                    }
                });
        }
        setUi((prev) => ({ ...prev, createAppointment: false }));
    };

    const currentViewChange = (currentView) => {
        let range = getRange(currentDate, currentView);
        setCurrentView(currentView);
        setRange(range);
    };

    const currentDateChange = (currentDate) => {
        let range = getRange(currentDate, currentView);
        setCurrentDate(currentDate);
        setRange(range);
    };

    const isRestTime = (startDate, endDate) => {
        const dateEn = dayjs(startDate).format("MM/DD/YYYY");
        return mairie?.calendar?.holidays.includes(dateEn);
    };

    const isOpeningDay = (startDate, endDate) => {
        const startHour = dayjs(startDate).format("HH:mm");
        const endHour = dayjs(endDate).format("HH:mm");
        const dayToday = dayjs(startDate)
            .format("dddd")
            .replace(".", "")
            .toLowerCase();
        const calendarOfMairie = mairie?.calendar?.hours;
        let isIncludeHour = false;
        if (calendarOfMairie[dayToday]) {
            calendarOfMairie[dayToday]?.map((hour) => {
                if (
                    compareTime(startHour, hour.startHour) &&
                    compareTime(hour.endHour, endHour)
                ) {
                    isIncludeHour = true;
                }
            });
        }
        return isIncludeHour;
    };

    const TimeTableCell = ({ ...restProps }) => {
        const { startDate, endDate, groupingInfo } = restProps;
        const agenda = agendas?.find(
            (data) => data.name === groupingInfo[0]?.text
        );

        if (
            isRestTime(startDate, endDate) ||
            testIfinBusyDays(startDate, endDate, agenda?.busyDays || {})
        ) {
            return (
                <StyledWeekViewTimeTableCell
                    {...restProps}
                    className={classes.weekEndCell}
                />
            );
        }
        return (
            <StyledWeekViewTimeTableCell
                {...restProps}
                className={
                    view && isOpeningDay(startDate, endDate)
                        ? classes.openingDay
                        : ""
                }
            />
        );
    };

    const debutHour = useMemo(() => {
        let minHour: number = 6;
        const startHour =
            mairie?.agendaParameter?.agendaHour?.startHour.split(":");
        if (startHour) {
            if (startHour?.length > 0 && Number(startHour[0]) > minHour) {
                minHour = Number(startHour[0]);
            }
        } else {
            if (mairie?.calendar?.hours) {
                const hourDays = mairie?.calendar?.hours;
                Object.keys(hourDays).map((day: any) => {
                    const firstHour = hourDays[day][0].startHour.split(":");
                    if (
                        firstHour?.length > 0 &&
                        Number(firstHour[0]) < minHour
                    ) {
                        minHour = Number(firstHour[0]);
                    }
                });
            }
        }
        if (minHour !== 0) {
            return minHour;
        }
    }, [mairie]);

    const lastHour = useMemo(() => {
        let maxHour: number = 19;
        const endHour = mairie?.agendaParameter?.agendaHour?.endHour.split(":");
        if (endHour) {
            if (endHour?.length > 0 && Number(endHour[0]) < maxHour) {
                maxHour = Number(endHour[0]);
            }
        } else {
            if (mairie?.calendar?.hours) {
                const hourDays = mairie?.calendar?.hours;
                Object.keys(hourDays).map((day: any) => {
                    const firstHour = hourDays[day][1].endHour.split(":");
                    if (
                        firstHour?.length > 0 &&
                        Number(firstHour[0]) > maxHour
                    ) {
                        maxHour = Number(firstHour[0]);
                    }
                });
            }
        }

        if (maxHour !== 0) {
            return maxHour + 1;
        }
    }, [mairie]);

    return (
        <BlockUi tag="div" blocking={loading}>
            <Paper className="relative">
                {resourcesBureau?.length > 0 ? (
                    <Stack marginBottom={5} className="bloc-scheduler">
                        {/* 
              // @ts-ignore */}
                        <Scheduler
                            data={dataAgenda}
                            locale={"fr-Fr"}
                            appointmentTooltipComponent={null}
                        >
                            <ViewState
                                defaultCurrentDate={currentDate}
                                defaultCurrentViewName={currentView}
                                currentDate={currentDate}
                                currentViewName={currentView}
                                onCurrentDateChange={currentDateChange}
                                onCurrentViewNameChange={currentViewChange}
                            />
                            <GroupingState
                                grouping={grouping}
                                groupByDate={(viewName) =>
                                    viewName === "Week" ||
                                    viewName === "Day" ||
                                    viewName === "Month"
                                }
                            />
                            {/* <DayView
                displayName={'Three days'}
                startDayHour={9}
                endDayHour={17}
                intervalCount={3}
              /> */}
                            <DayView
                                displayName="Jour"
                                startDayHour={debutHour}
                                endDayHour={lastHour}
                                cellDuration={
                                    mairie?.agendaParameter?.step ?? 60
                                }
                                timeTableCellComponent={TimeTableCell}
                            />
                            <WeekView
                                displayName="Semaine"
                                startDayHour={debutHour}
                                endDayHour={lastHour}
                                excludedDays={[0]}
                                cellDuration={
                                    mairie?.agendaParameter?.step ?? 60
                                }
                                intervalCount={1}
                                timeTableCellComponent={TimeTableCell}
                            />
                            {/* <EditingState
                onCommitChanges={commitChange}
              /> */}
                            <MonthView displayName="Mois" />
                            <Appointments
                                appointmentComponent={Appointment}
                                appointmentContentComponent={AppointmentContent}
                            />
                            {/* <DragDropProvider /> */}

                            <Resources
                                data={resources}
                                mainResourceName="location"
                            />

                            <AppointmentTooltip
                                showCloseButton
                                showOpenButton
                                contentComponent={Content}
                            />
                            <AppointmentForm
                                overlayComponent={OverlayLayout}
                                basicLayoutComponent={BasicLayout}
                                commandButtonComponent={CommandButtonLayout}
                            />
                            <IntegratedGrouping />
                            <Toolbar />
                            <ViewSwitcher />
                            <DateNavigator />
                            <TodayButton messages={{ today: "Aujourd'hui" }} />
                            <GroupingPanel />
                        </Scheduler>
                    </Stack>
                ) : (
                    <>
                        <p className="text-warning flex items-center justify-center gap-2">
                            <InfoIcon sx={{ fill: "orange" }} /> Merci de créer
                            votre premier agenda.
                        </p>
                    </>
                )}
                {testAccess(user, "create_appointment") && (
                    <StyledFab
                        color="secondary"
                        className={classes.addButton}
                        onClick={handleOpenModalCreate}
                    >
                        <AddIcon />
                    </StyledFab>
                )}
                <StyledNavBottom>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <p>STATUT</p>
                        <FormControl
                            sx={{
                                mx: 3,
                                display: "flex",
                                alignItems: "center",
                            }}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormGroup sx={{ flexDirection: "row" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleChangeStatus("pending")
                                            }
                                            checked={status.pending}
                                            name="pending"
                                        />
                                    }
                                    label="En cours"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                handleChangeStatus("canceled")
                                            }
                                            checked={status.canceled}
                                            name="canceled"
                                        />
                                    }
                                    label="Annulé"
                                />
                                {/* <FormControlLabel
                        control={
                        <Checkbox onChange={() => setView(current => !current)} checked={view} name="open" />
                        }
                        label="Ouverture"
                    /> */}
                            </FormGroup>
                        </FormControl>
                    </Stack>
                </StyledNavBottom>
                <CustomDialog
                    open={showModal}
                    setOpen={setShowModal}
                    onClose={handleCloseModal}
                    component={
                        <Box sx={{ padding: "20px", width: "800px" }}>
                            <CreateAppointmentForm
                                loading={ui.createAppointment}
                                setLoading={setUi}
                                slug={mairie.slug}
                                slugToAppointment={slug}
                                onConfirm={(data) =>
                                    handleSubmitForm(data, mairie?._id)
                                }
                            />
                        </Box>
                    }
                />
                <CustomDialog
                    setOpen={setOpenDuplicate}
                    open={openDuplicate}
                    onClose={handleCloseModalDuplicate}
                    component={<DetailsDuplicate duplicates={duplicates} />}
                />
                <Popup
                    message={alert?.message}
                    type="error"
                    open={alert?.open}
                    handleClose={handleCloseAlert}
                />
            </Paper>
        </BlockUi>
    );
};
