import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    MenuItem,
    Select,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SchedulerComponent } from "../../../application/components/Scheduler/Scheduler";
import {
    BoxFilter,
    BoxLegende,
    CardLegende,
} from "../../../application/styled/bloc";
import DivCheckbox from "./components/div-check-box";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import "./Agenda.style.scss";
import { CustomLoading } from "../../../application/components/LazyLoading/LazyLoading";
import CalendarWarning from "../../../application/assets/svg/warning.png";
import { useMairieStore } from "../../../application/store/mairie.store";
import {
    changeSlug,
    getAllAppointment,
    useAgendaStore,
} from "../../../application/store/agenda.store";

export const Agenda = () => {
    const agendas = useAgendaStore((state) => state.agendas);
    const slug = useAgendaStore((state) => state.slug);
    const mairies = useMairieStore((state) => state.mairies);
    const motifs = useMairieStore((state) => state.motifs);
    const [closeSideBar, setCloseSideBar] = useState(true);
    const agendaFilter: any = useMemo(() => {
        const array = agendas.filter((item) => item.mairie.slug === slug);
        return array.map((agenda) => {
            return { name: agenda.name, key: agenda._id };
        });
    }, [agendas, slug]);

    const categFilter = useMemo(() => {
        const newMotifs = motifs.map((motif) => {
            return { name: motif.name, key: motif.name.replaceAll(" ", "_") };
        });
        return newMotifs;
    }, [motifs]);

    const defaultAgendasChecked = useMemo(() => {
        let checked = {};
        if (agendas.length > 0) {
            agendas.map((agenda) => {
                const isChecked =
                    agenda?.mairie?.slug == slug && agenda.defaultShow;
                checked = { ...checked, [agenda?._id]: isChecked };
            });
        }
        return checked;
    }, [agendas, slug]);

    const [checkBoxBureau, setCheckBoxBureau] = useState<any>(
        defaultAgendasChecked
    );

    const defaultCheckedMotifs = useMemo(() => {
        const motifsChecked = {};
        motifs.map((motif) => {
            return (motifsChecked[motif.name.replaceAll(" ", "_")] = true);
        });
        return motifsChecked;
    }, [motifs]);

    const [checkBoxCateg, setCheckBoxCateg] = useState<any>(null);

    useEffect(() => {
        setCheckBoxCateg(defaultCheckedMotifs);
    }, [defaultCheckedMotifs]);

    useEffect(() => {
        setCheckBoxBureau(defaultAgendasChecked);
    }, [defaultAgendasChecked]);

    const legendes = useMemo(() => {
        const newMotifs = motifs.map((motif) => {
            return {
                name: motif.name,
                color: motif.color,
                border: motif.color,
                icon: "",
            };
        });
        newMotifs.push(
            { name: "Annulé", color: "white", border: "red", icon: "" },
            {
                name: "Doublons RDV",
                color: "white",
                border: "white",
                icon: CalendarWarning,
            }
        );
        return newMotifs;
    }, [motifs]);

    const handleCloseSideBar = () => {
        setCloseSideBar((prev) => !prev);
    };

    return (
        <>
            {!slug || !checkBoxCateg ? (
                <Box className="box-loading">
                    <CustomLoading size={100} />
                </Box>
            ) : (
                <Box paddingTop={12}>
                    <Grid container>
                        <Grid
                            item
                            xs={closeSideBar ? 0.3 : 3}
                            className="relative"
                        >
                            <PlayCircleIcon
                                className={
                                    closeSideBar
                                        ? "rotate-normale"
                                        : "rotate-inverse"
                                }
                                onClick={() => handleCloseSideBar()}
                            />
                            {!closeSideBar && (
                                <>
                                    <BoxFilter>
                                        <FormControl
                                            sx={{
                                                minWidth: 80,
                                                marginBottom: 2,
                                            }}
                                        >
                                            <Select
                                                labelId="mairies"
                                                id="mairies"
                                                size="small"
                                                value={slug}
                                                onChange={(e) => {
                                                    changeSlug(e.target.value);
                                                    getAllAppointment();
                                                }}
                                                autoWidth
                                            >
                                                <MenuItem
                                                    value={mairies[0]?.slug}
                                                >
                                                    {mairies[0]?.name}
                                                </MenuItem>
                                                {mairies[0]?.annexes &&
                                                    mairies[0]?.annexes.length >
                                                        0 &&
                                                    mairies[0]?.annexes.map(
                                                        (annexe, index) => (
                                                            <MenuItem
                                                                value={
                                                                    annexe?.slug
                                                                }
                                                                key={index}
                                                            >
                                                                {annexe?.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            fullWidth
                                            component="fieldset"
                                            margin="normal"
                                        >
                                            <FormLabel
                                                component="legend"
                                                focused={false}
                                            >
                                                <p className="title-filter mb-0">
                                                    Agenda
                                                </p>
                                                <p className="description-filter">
                                                    Les agendas disponibles
                                                </p>
                                            </FormLabel>
                                            <FormGroup
                                                aria-label="Temas"
                                                row={true}
                                            >
                                                {agendaFilter.map((data, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        value={data.key}
                                                        control={
                                                            <DivCheckbox
                                                                item={data.key}
                                                                state={
                                                                    checkBoxBureau
                                                                }
                                                                setState={
                                                                    setCheckBoxBureau
                                                                }
                                                            />
                                                        }
                                                        label={data.name}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </BoxFilter>
                                    <BoxFilter marginTop={2}>
                                        <FormControl
                                            fullWidth
                                            component="fieldset"
                                            margin="normal"
                                        >
                                            <FormLabel
                                                component="legend"
                                                focused={false}
                                            >
                                                <p className="title-filter mb-0">
                                                    Type de Motif
                                                </p>
                                                <p className="description-filter">
                                                    Afficher les dossiers par
                                                    catégorie
                                                </p>
                                            </FormLabel>
                                            <FormGroup
                                                aria-label="Temas"
                                                row={true}
                                            >
                                                {categFilter.map((data, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        value={data.key}
                                                        control={
                                                            <DivCheckbox
                                                                item={data.key}
                                                                state={
                                                                    checkBoxCateg
                                                                }
                                                                setState={
                                                                    setCheckBoxCateg
                                                                }
                                                            />
                                                        }
                                                        label={data.name}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </BoxFilter>
                                    <BoxLegende marginTop={2}>
                                        <div className="bloc-list">
                                            <p>Légende</p>
                                            <Grid
                                                container
                                                marginTop={2}
                                                gap={1}
                                            >
                                                {legendes.map(
                                                    (legende, index) => (
                                                        <CardLegende
                                                            item
                                                            xs={5.7}
                                                            key={index}
                                                            color={
                                                                legende.color
                                                            }
                                                            border={
                                                                legende.border
                                                            }
                                                        >
                                                            <p>
                                                                {legende.name}
                                                            </p>
                                                            {legende.icon !==
                                                                "" && (
                                                                <img
                                                                    src={
                                                                        legende.icon
                                                                    }
                                                                    width="15px"
                                                                    alt={
                                                                        "logo-duplicate"
                                                                    }
                                                                />
                                                            )}
                                                        </CardLegende>
                                                    )
                                                )}
                                            </Grid>
                                        </div>
                                    </BoxLegende>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={closeSideBar ? 11.7 : 9}>
                            <SchedulerComponent
                                checkBoxBureau={checkBoxBureau}
                                checkBoxType={checkBoxCateg}
                                mairie={mairies[0]}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};
