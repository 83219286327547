import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {listInfoContent} from "../../motifs/data/data";
import {useEffect, useRef, useState} from "react";
import {AttachFile, Send} from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {DesktopDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {CustomDialog} from "../../../../../../application/components/CustomDialog";
import {ConfirmSendSms} from "./ConfirmSendSms";
import {InputPhone} from "../../../../../../application/components/InputPhone/InputPhone";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {LoadingButton} from "@mui/lab";
import { read } from "xlsx";
import { v4 as uuid } from 'uuid';
import {handleGetContactAppointment} from "../../../../../../helpers/appointment.helper";
import { useMairieStore } from "../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][6-7][0-9]+$/),
    motif: yup.string(),
    agenda: yup.string()
});

export const SendException = ({ setActiveSousMenu }:any) => {
    const [message, setMessage] = useState('');
    const [phones, setPhones] = useState<any[]>([]);
    const [modalSms, setModalSms] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [loadingContact, setLoadingContact] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [destinataireType, setDestinataireType] = useState('single');
    const [filterDate, setFilterDate] = useState(dayjs());
    const  mairies  = useMairieStore((state) => state.mairies);
    const  agendas  = useMairieStore((state) => state.agendas);
    const  motifs  = useMairieStore((state) => state.motifs);
    const [fileContact, setFileContact] = useState<any>(null);
    const [fileState, setFileState] = useState<any>(null);
    const inputFileRef = useRef<any>();

    const { register, formState: { errors }, getValues, control, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const params = {
        register,
        errors,
        control,
        getValues,
        setValue
    }

    const handleOpenModalSms = () => {
        setModalSms(true)
    }

    const handleCloseModal = () => {
        setModalSms(false);
        setPhones([]);
        setRecipients([])
    }

    useEffect(() => {
        if (destinataireType === 'multiple' && mairies) {
            const motif = getValues('motif');
            const agenda = getValues('agenda');
            const filter_date = dayjs(filterDate).format('YYYY-MM-DD');
            const slug = mairies[0]?.slug;
            const queryOptions: any = { filter_date };
            if (motif) queryOptions.motif = motif;
            if (agenda) queryOptions.agenda = agenda;

            setLoadingContact(true);

            handleGetContactAppointment(slug, queryOptions)
                .then(({data}) => {
                    if (data) {
                        setRecipients(data)
                    }
                })
                .finally(() => {
                    setLoadingContact(false)
                })
        }
    }, [mairies, filterDate, watch('agenda'), watch('motif'), destinataireType])

    const addPhoneNumber = async() => {
        const phone = getValues('phoneNumber');
        if(phone){
            const isExist = phones.find(data => data === phone);
            if(isExist){
                setError(true);
            }else{
                setError(false);
                setPhones((current) => [...current, phone]);
            }
            setValue('phoneNumber', '')
        }
    }


    const handleRemovePhone = (phone) => {
        setPhones(phones.filter(item => item !== phone));
    }

    const readExcel = async (file: any) => {
        return new Promise(async resolve => {
            const fileReader = await new FileReader()
            fileReader.readAsArrayBuffer(file)

            fileReader.onload = (e: any) => {
                const bufferArray = e?.target.result
                const wb = read(bufferArray, { type: "buffer" })
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]

                const data: any = [];
                for (const entry of Object.keys(ws)) {
                    data.push(ws[entry]?.w)
                }
                resolve(data.filter(v => v && v.match(/^[0][6-7][0-9]+$/)))
            }
        })
    }

    useEffect(() => {
        if (fileContact) {
            readExcel(fileContact[0])
                .then((data: any) => {
                    setPhones(data);
                    setRecipients(data.map(e => ({ phone_number: e })));
                })
        }
    }, [fileContact])

        return (
            <>
                <Grid container className={'box-container'} spacing={3}>
                    <Grid item xs={4}>
                        <Paper sx={{ minHeight: '60vh'}}>
                            <Stack padding={2}>
                                <Stack marginBottom={2}>
                                    <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Expéditeur :</Typography>
                                    <TextField
                                        disabled={true}
                                        value={'RDVENMAIRIE'}
                                        size={'small'}
                                    />
                                </Stack>
                                <Stack marginBottom={2}>
                                    <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Destinataire :</Typography>
                                    <RadioGroup
                                        row
                                        defaultValue={destinataireType}
                                        onChange={(ev) => setDestinataireType(ev.target.value)}
                                    >
                                        <FormControlLabel value="single" control={<Radio />} label="Par destinataire" />
                                        <FormControlLabel value="multiple" control={<Radio />} label="Par filtre" />
                                    </RadioGroup>
                                    {destinataireType === 'single' &&
                                        <Box>
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <InputPhone {...params} />
                                                <AddCircleIcon className="cursor-pointer" sx={{ color: '#44c644' }} onClick={() => addPhoneNumber()} />
                                            </Stack>
                                            {error && <p className="text-red">Le numero de téléphone existe déjà dans la liste.</p>}

                                            <Stack marginTop={2}>
                                                <input
                                                    ref={inputFileRef}
                                                    type="file"
                                                    accept=".csv, xlsx, xls"
                                                    hidden={true}
                                                    key={fileState || uuid()}
                                                    onChange={e => {
                                                        setFileContact(e.target.files)
                                                        setFileState(uuid())
                                                    }}
                                                />
                                                <Button
                                                    variant={'outlined'}
                                                    onClick={() => inputFileRef?.current?.click()}
                                                >
                                                    <AttachFile/>
                                                    Importer un fichier .csv
                                                </Button>
                                            </Stack>

                                            <p className="mt-2">Déstinataires :</p>
                                            <Stack flexDirection={'row'} gap={1} alignItems={'center'} flexWrap={'wrap'}>
                                                {phones?.length > 0 && phones.map(phone =>
                                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'} className="item-holiday">
                                                        <p>{phone}</p>
                                                        <DeleteIcon className="cursor-pointer" sx={{ color: 'red', fontSize: '16px'}} onClick={() => handleRemovePhone(phone)} />
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </Box>}
                                </Stack>
                                { destinataireType === 'multiple' && <>
                                    <Stack marginBottom={2}>
                                        <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Envois par date de rendez-vous (Facultatif) :</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                            <DesktopDatePicker
                                                value={filterDate}
                                                inputFormat={'dd/MM/yyyy'}
                                                minDate={dayjs()}
                                                onChange={(newValue) => {
                                                    setFilterDate(dayjs(newValue))
                                                }}
                                                renderInput={(params) => <TextField {...params}  size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    <Stack marginBottom={2}>
                                        <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Motif :</Typography>
                                        <FormControl sx={{ mt: 1 }}>
                                            <Select size="small" {...register('motif')}>
                                                { motifs && motifs.map(motif => (
                                                    <MenuItem value={motif.name}>{motif.name}</MenuItem>
                                                ))}
                                                <MenuItem value={undefined}>-</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <Stack marginBottom={2}>
                                        <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Agenda :</Typography>
                                        <FormControl sx={{ mt: 1 }}>
                                            <Select size="small" {...register('agenda')}>
                                                { agendas && agendas.map(agenda => (
                                                    <MenuItem value={agenda._id}>{agenda.name}</MenuItem>
                                                ))}
                                                <MenuItem value={undefined}>-</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <LoadingButton loading={loadingContact}><p><b>{recipients.length}</b> contact(s) trouvé(s) !</p></LoadingButton>
                                </>}
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper sx={{ minHeight: '60vh'}}>
                            <Stack padding={2}>
                                <Stack>
                                    <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Message* :</Typography>
                                    <TextField
                                        label=""
                                        multiline
                                        variant={'outlined'}
                                        rows={4}
                                        helperText={`${message.length % 160}/160 | ${Math.ceil(message.length / 160) } sms`}
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                    />
                                </Stack>
                                <Divider sx={{ marginY: '2em' }}/>
                                { destinataireType === 'multiple' &&
                                    <Grid container className="gap-2">
                                        {listInfoContent.map((valueContent) =>
                                            <Grid item xs={5.5}>
                                                <div className="flex items-center gap-5">
                                                    <p className="mb-0 w-40 font-semibold">
                                                        <Typography variant={'subtitle2'} fontWeight={'bold'}>{valueContent.id}</Typography>
                                                    </p>
                                                    <div className="info-content">
                                                        <Typography variant={'subtitle2'}>{valueContent.text}</Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                }
                                <Stack alignItems={'end'} className="mt-5">
                                    {/*<div>*/}
                                    {/*    <Stack>*/}
                                    {/*        <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Destinataires* :</Typography>*/}
                                    {/*    </Stack>*/}
                                    {/*    <TextField*/}
                                    {/*        label=""*/}
                                    {/*        multiline*/}
                                    {/*        variant={'outlined'}*/}
                                    {/*        rows={4}*/}
                                    {/*        placeholder="Merci de séparer les numeros de téléphone par une virgule."*/}
                                    {/*        onChange={(event) => {*/}
                                    {/*            setDestinataires(event.target.value);*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="mt-2">
                                        <Button variant={'contained'} color={'secondary'} onClick={handleOpenModalSms} disabled={!(message && (phones?.length > 0) || (recipients?.length > 0))}>
                                            <Send sx={{marginRight: 1, transform: 'rotateZ(-45deg)', fontSize: '20px'}}/>
                                            Envoyer
                                        </Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                <CustomDialog
                    setOpen={setModalSms}
                    open={modalSms}
                    onClose={handleCloseModal}
                    component={
                        <ConfirmSendSms
                            mairieId={mairies[0]?._id}
                            handleClose={handleCloseModal}
                            messages={message}
                            recipients={ destinataireType === 'single' ?
                                phones.map(phone => (
                                    { phone_number: `+33${phone?.replace(/\s/g, '').substring(1)}` }
                                )) :
                                recipients
                            }
                        />
                    }
                />
            </>
        )
    }
