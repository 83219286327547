import { Paper } from "@mui/material"
import { useState } from "react";
import { StepOneDeletion } from "../Gestion/step/StepOneDeletion"
import { StepTwoDeletion } from "../Gestion/step/StepTwoDeletion"

const DeleteRdv = ({ appointment, setAppointment, type = 'normal', refetchData = () => {} }) => {
    const [activeStep, setActiveStep] = useState(1);
    return(
        <Paper elevation={4} className={"m-auto"} sx={ {
            maxWidth: 600,
            overflow: 'auto'
        } }>
            {activeStep === 1 && <StepOneDeletion appointment={appointment} type={type}  setAppointment={setAppointment} setActiveStep={setActiveStep} refetchData={refetchData} />}
            {activeStep === 2 && <StepTwoDeletion appointment={appointment} type={type} setAppointment={setAppointment} setActiveStep={setActiveStep} refetchData={refetchData} />}
        </Paper>
    )
}

export default DeleteRdv