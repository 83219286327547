import {createTheme} from "@mui/material/styles";
import {PaletteColor} from "@mui/material";

declare module "@mui/material/styles" {
    interface Palette {
        inversePrimary: PaletteColor;
    }
    interface PaletteOptions {
        inversePrimary: PaletteColor;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        inversePrimary: true;
    }
}

export const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#C74C51'
        },
        secondary: {
            main: '#23587F'
        },
        inversePrimary: createTheme().palette.augmentColor({
            color: {
                main: '#fff',
                contrastText: '#C74C51'
            }
        })
    },
    typography: {
        button: {
            textTransform: 'initial'
        }
    }
});
