import {Autocomplete, Grid, InputAdornment, TextField, useMediaQuery} from "@mui/material";
import {LocationOn} from "@mui/icons-material";
import { useEffect, useMemo, useState} from "react";
import { PropsInputZip } from "../types/zipForm.type";
import { BREAKPOINT_MOBILE } from "../../constant";
import { useDebounce } from "usehooks-ts";
import isNumber from "lodash/isNumber";
import { citySearchByZip, postalCodeSearchByCity } from "../../helpers/postalVille.helper";

export const SelectCodePostal = ({disabled, register, setValue, data,  ...props}: PropsInputZip) => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const [isSearching, setIsSearching] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [optionCity, setOptionCity] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const defaultCity = useMemo(() => {
        return optionCity.find(((ville: any) => ville?.nom === data?.city  && ville?.codesPostaux === data?.zip));
    }, [data, optionCity])
    const [ville, setVille] = useState<any>(defaultCity ?? null);

    const handleChangeVilles = (value) => {
        setVille(value);
        setOpenOption(false)
    }

    useEffect(() => {
        getVilles(data?.city?.replaceAll('-', ' '))
    }, [data?.city])

    useEffect(() => {
        setVille(defaultCity);
    }, [defaultCity])

    const getVilles = (async(value) => {
        let res: any = null;
        if(isNumber(value))
            res = await citySearchByZip(String(value));
        else
            res = await postalCodeSearchByCity(String(value));
        
        if(res?.data) {
            setOptionCity(res?.data);
        }
    })

    useEffect(() => {
        if(debouncedSearchTerm){
            setIsSearching(true);
            getVilles(debouncedSearchTerm.toLowerCase());
        }else{
            setOptionCity([]);
        }
        setIsSearching(false);
    },[debouncedSearchTerm])

    useEffect(() => {
        if(ville){
            setValue('city', ville);
            setValue('zip', ville.codesPostaux);
        }
    }, [ville, setValue]);
    return (
        <Grid container gap={mobile ? 2 :0}>
            <Grid item xs={12}>
                <Autocomplete
                    loading={isSearching}
                    loadingText="Chargement..."
                    noOptionsText="Aucun résultat"
                    open={openOption}
                    onClose={() => setOpenOption(false)}
                    className="bg-grey"
                    disabled={disabled}
                    options={optionCity}
                    getOptionLabel={(option) => {
                        if (option['nom'])
                             return option['codesPostaux']+', '+option['nom'] 
                        if (typeof option === 'string')
                            return option
                        return ''
                     }}
                     onChange={(event: any, newValue: string | null) => {
                        handleChangeVilles(newValue);
                    }}
                    value={ville ? ville : null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "10px!important",
                                },
                            }}
                            autoComplete="new-password"
                            disabled={disabled}
                            onChange={(event) => {
                                setOpenOption(true);
                                setIsSearching(true);
                                setSearchTerm(event.target.value)}
                            }
                            placeholder={"Votre code postal*"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LocationOn/>
                                    </InputAdornment>
                                )}
                            }
                        />
                    )}
                />
            </Grid>
        </Grid>
        )
    
}
