import { Button, FormControl, InputAdornment, OutlinedInput, Paper, Stack } from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import '../../../Mairie.style.scss';
export const StepThree = ({ register, errors, getValues, handleSubmit, control, mairies, onSubmitHandler }) => {
    
    return (
        <div className=" mt-5">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Stack gap={2}>
                    <div className="bloc-info-mairie">
                        <p className="name-mairie mb-0">{mairies[0]?.name}</p>
                        <p className="text-light-grey">{mairies[0]?.address}</p>
                    </div>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Lien Facebook*'}
                                    defaultValue={mairies[0]?.socialMedia?.facebook}
                                    {...register("facebook")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.facebook ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('facebook') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Lien Instagram*'}
                                    defaultValue={mairies[0]?.socialMedia?.instagram}
                                    {...register("instagram")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.instagram ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('instagram') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>            
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Lien Twitter*'}
                                    defaultValue={mairies[0]?.socialMedia?.twitter}
                                    {...register("twitter")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.twitter ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('twitter') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>            
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'URL*'}
                                    defaultValue={mairies[0]?.socialMedia?.url}
                                    {...register("url")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.url ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('url') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>            
                    </Stack>
                </Stack>
                <Stack className="div-btn-next">
                    <Button className="btn-next" variant={"contained"} color={"primary"} type="submit">Enregistrer</Button>
                </Stack>
            </form>
        </div>
    )
}