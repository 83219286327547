import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Chip, Grid, TablePagination } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { GetSmsEmailHistories } from '../../../../../../helpers/histories.helper';
import { HeaderTableHisotries } from './TableHeader';
import { useMairieStore } from '../../../../../../application/store/mairie.store';
require('dayjs/locale/fr');

const getColorStatus = (status: string) => {
    switch (status) {
        case 'Envoyé': return { backgroundColor: '#3d618f', color: 'white' }
        case 'En attente': return { backgroundColor: '#f6a530', color: 'black' }
        case 'Livré': return { backgroundColor: '#1d8c35', color: 'white' }
        case 'Ouvert': return { backgroundColor: '#1d8c35', color: 'white' }
        case 'Cliqué': return { backgroundColor: '#3d618f', color: 'white' }
        case 'Rebond': return { backgroundColor: '#fd7d9c', color: 'white' }
        case 'Spam': return { backgroundColor: '#000000', color: 'white' }
        case 'Désabonnement': return { backgroundColor: '#ffffff', color: 'white' }
        case 'Rebonds temporaire': return { backgroundColor: 'rgba(241,103,135,0.99)', color: 'white' }
        case 'Rebons permanent': return { backgroundColor: '#c55656', color: 'white' }
        case 'Bloqué': return { backgroundColor: '#c55656', color: 'white' }
        case 'Non livré': return { backgroundColor: '#c55656', color: 'white' }
        case 'Renvoyé': return { backgroundColor: '#09853f', color: 'white' }
    }
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const CustomDisplayLabelPagination = ({ from, to, count }) => {
    return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`;
}

const headCells: readonly any[] = [
    {
        id: 'destination',
        numeric: false,
        disablePadding: true,
        filter: true,
        label: 'Destinataire',
    },
    {
        id: 'content',
        numeric: false,
        disablePadding: true,
        filter: true,
        label: 'Contenu',
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Type',
    },
    {
        id: 'sendDate',
        numeric: false,
        isDate: true,
        disablePadding: false,
        filter: true,
        label: 'Envoyé le',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Statut',
    }
];

  
export const Email = () => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const [rows, setRows] = useState<any>({
        data: [],
        pageSize: 5
    });

    const [filter, setFilter] = useState<any>({
        destination: '',
        content: '',
        sendDate: null,
        type: '',
        status: ''
    })

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const getSmsEmailHistories = useCallback(async() => {
        const res = await GetSmsEmailHistories(mairies[0]?._id);
        if(res?.data){
            setRows(res);
        }
    }, [mairies])

    useEffect(() => {
      getSmsEmailHistories();
    }, [getSmsEmailHistories]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage+1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <Box className="box-mairie">
            <Grid container className="grid-mairie">
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <HeaderTableHisotries headCell={headCell} handleChangeRows={setRows} setPage={setPage} page={page} setFilter={setFilter} filter={filter} limit={rowsPerPage} />
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.data?.slice(0, rowsPerPage)
                                .map((row: any, index) =>         
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        {row?.destination} 
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row?.subject ?? row?.content} 
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{capitalize(row.type)}</StyledTableCell>
                                    <StyledTableCell align="center">{capitalize(dayjs(row.sendDate).locale('fr').format('dddd DD MMMM YYYY'))}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Chip label={capitalize(row.status)} sx={getColorStatus(row.status)}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows?.pageSize}
                    rowsPerPage={rowsPerPage}
                    page={rows?.currentPage ? rows?.currentPage-1 : page-1}
                    onPageChange={handleChangePage}
                    labelRowsPerPage={"Par:"}
                    labelDisplayedRows={CustomDisplayLabelPagination}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Box>
    
    )
}
