import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Paper, Stack, styled } from "@mui/material";
import { Fragment, useState } from "react";
import { Hour } from "../Hour/Hour";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    height: "45px",
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

interface PropsCalendarWeek {
    calendarWeek: any,
    handleChangeHour: (data, type, index, key?: string, callback?: any) => void,
    checkBox?: boolean,
    isDisabled?: boolean
}

interface PropsBlocDaysHour {
    index: number,
    calendarWeek: any,
    day: any,
    handleChangeHour: (data, type, index) => void,
    checkBox?: boolean,
    isDisabled: boolean
}

const BlocDaysHour = ({ index, calendarWeek, day, handleChangeHour, checkBox, isDisabled } : PropsBlocDaysHour) => {
    const [state, setState] = useState<boolean>(calendarWeek[day][0]['startHour'] !== '-' || calendarWeek[day][1]['startHour'] !== '-');
    return (
        <Grid item xs={2} sm={4} md={1.5} key={index} className='item-hour'>
            {checkBox && <Item  className="title-day bg-transparent" sx={{ background: 'transparent !important', boxShadow: 'none !important' }}>
                <Stack>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="bottom"
                                control={<Checkbox 
                                    defaultChecked={calendarWeek[day][0]['startHour'] !== '-' || calendarWeek[day][1]['startHour'] !== '-'}
                                    onChange={() => setState((current) => !current)}
                                />}
                                label=''
                                labelPlacement="bottom"
                            />
                        </FormGroup>
                    </FormControl>
                </Stack>
            </Item>}
            <Item className="capitalize title-day">{day}</Item>
            <Fragment>
                <Hour className={((checkBox && !state) || isDisabled) && 'disableDiv'} changeHour={handleChangeHour} defaultValue={calendarWeek[day][0].startHour} type="startHour" index={day}/>
                <Hour className={((checkBox && !state) || isDisabled) && 'disableDiv'} changeHour={handleChangeHour} defaultValue={calendarWeek[day][0].endHour} type="endHour" index={day}/>
                <Hour className={((checkBox && !state) || isDisabled) && 'disableDiv'} changeHour={handleChangeHour} defaultValue={calendarWeek[day][1].startHour} type="startHour2" index={day}/>
                <Hour className={((checkBox && !state) || isDisabled) && 'disableDiv'} changeHour={handleChangeHour} defaultValue={calendarWeek[day][1].endHour} type="endHour2" index={day}/>
            </Fragment>
        </Grid>
    )
}

export const CalendarWeek = ({ calendarWeek, handleChangeHour, checkBox, isDisabled = false } : PropsCalendarWeek) => {
    return (
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={2} className="item-hour">
                {checkBox && <p className="menu-left"></p>}
                <p className="menu-left"></p>
                <p className="menu-left">Début</p>
                <p className="menu-left">Fin</p>
                <p className="menu-left">Début</p>
                <p className="menu-left">Fin</p>
            </Grid>
            {Object.keys(calendarWeek).map((day, index) => (
                <BlocDaysHour key={index} index={index} calendarWeek={calendarWeek} day={day} handleChangeHour={handleChangeHour} checkBox={checkBox} isDisabled={isDisabled} />
            ))}
        </Grid>
    )
}