import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import {CancelRounded, CheckCircle} from "@mui/icons-material";
import {useMediaQuery } from "usehooks-ts";
import { BREAKPOINT_MOBILE } from "../../../constant";
import { DeleteIcon } from "../../common/icon";
import './FormPersonConcerned.style.scss';

export const FormPersonConcerned = ({ field, index, register, getValues, setValue, ants, errors, typeDossier, hidden = false }) => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    
    const handleDeletePerson = () => {
        const persons = getValues('concernedPerson');
        persons.splice(index, 1);
        setValue('concernedPerson', persons);
        setValue('nombrePersonne', persons.length)
    }

    return (
        <Grid container spacing={1} key={field.id} className="card-concerned relative">
            <input hidden {...register(`concernedPerson.${index}.showAnts`, { value: ants })}  />
            <input hidden {...register(`isOtherType`, { value: (!typeDossier?.includes('CNI') && !typeDossier?.includes('PASSEPORT')) })}  />

            <Grid item xs={mobile ? 12 :6}>
                <Stack>
                    <p className="mb-0">Nom (Nom de naissance)</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'Nom (Nom de naissance)'}
                            {...register(`concernedPerson.${index}.lastName`)}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors[`concernedPerson.${index}.lastName`] ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues(`concernedPerson.${index}.lastName`) && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
            </Grid>
            <Grid item xs={mobile ? 12 : 6}>
                <Stack>
                    <p className="mb-0">Prénom</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'Prénom'}
                            {...register(`concernedPerson.${index}.firstName`)}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors[`concernedPerson.${index}.firstName`] ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues(`concernedPerson.${index}.firstName`) && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
            </Grid>
            {ants && <Grid item xs={mobile ? 12 : 6}>
                <Stack>
                    <p className="mb-0">Numéro ANTS</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'10 caractères'}
                            {...register(`concernedPerson.${index}.ants`, { required: true })}
                            inputProps={{
                                maxLength: 10
                            }}
                            onInput={(e: any) => e.target.value = ("" + e.target.value).toUpperCase()}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors[`concernedPerson.${index}.ants`] ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues(`concernedPerson.${index}.ants`) && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
            </Grid>}
            {!hidden && <DeleteIcon className="cursor-pointer btn-delete" sx={{ color: 'red', fontSize: '20px'}} onClick={() => handleDeletePerson()} />}
        </Grid>
    )
}