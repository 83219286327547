import {
    Box,
    Stack,
} from "@mui/material";
import dayjs from "dayjs";
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import { handleUpdateAppointment } from "../../../helpers/appointment.helper";
import { useState } from "react";
import { handleAddHistories } from "../../../helpers/histories.helper";
import { capitalize } from "lodash";
import { getAllAppointment } from "../../store/agenda.store";
import { handleDeleteAppointment } from "../../../helpers/ants.helper";
import { handleGetMairieBySlug } from "../../../helpers/mairie.helper";
require('dayjs/locale/fr');

export const DetailsConsumed = ({ duplicates, range }) => {
    const [rows, setRows] = useState<any>(duplicates);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [indexData, setIndexData] = useState<number>();

    const handleDeletePerson = async( data, key ) => {
        const {appointment} = data;
        setLoading(true);
        setIndexData(key)
        let res: any = null;
        let input: any = {_id: appointment._id, status: 'CANCELED'};
        const concernedPerson = appointment?.concernedPerson?.filter(person => person?.ants !== data?.application_id);
        if(concernedPerson?.length > 0){
            const dureeMotif = Number(appointment?.dureeRdv)/Number(appointment?.concernedPerson?.length);
            input = {
                ...input, 
                concernedPerson: concernedPerson, 
                status: 'PENDING',
                dureeRdv: Number(appointment?.dureeRdv)-(dureeMotif || 0),
                nombrePersonne: concernedPerson?.length
            }
        }
        res = await handleUpdateAppointment(input);
        if(res?.success){
            const mairie = await handleGetMairieBySlug(appointment.mairie);
            if(mairie && input?.status === 'CANCELED'){
                for(const person of appointment?.concernedPerson){
                    if(person?.ants !== ''){
                        await handleDeleteAppointment({
                            ants: person?.ants,
                            meeting_point: mairie?.name,
                            meeting_point_id: mairie?._id,
                            appointment_date: appointment?.heureRdv?.replace('T', ' ')
                        })
                    }
                }
            }
            const result = await handleAddHistories({
                phoneNumber: appointment.phoneNumber,
                appointmentId: appointment._id,
                status: input?.status,
                mairie: appointment.mairie,
                heureRdv: appointment.heureRdv,
                typeDossier: appointment.typeDossier,
                nombrePersonne: concernedPerson?.length > 0 ? concernedPerson?.length : 1,
                usager: appointment.usager
            })
            
            if(result.success){
                setRows(prevState => prevState?.filter(row => row?.application_id !== data?.application_id))
                getAllAppointment(range.startDate, range.endDate);
                setSuccess(true);
            }
        }
        setLoading(false);
    }

    return (
        <Box>
            <Stack flexDirection={"row"} className="items-center justify-between">
                <p className="title-bold px-5 mb-0">Détail des numéros consommés:</p>
            </Stack>
            <Stack className="px-5 py-2" style={{ maxHeight: '500px' }}>
                {rows.map((data, key: any) => 
                    <Stack key={key} className='py-2' flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack>
                            <p>{capitalize(data?.appointment?.firstName)} {data?.appointment?.lastName}</p>
                            <p>{capitalize(data?.meeting_point)} pris le {dayjs(data?.appointment?.heureRdv).format('DD MMM YYYY à HH:mm')}</p>
                        </Stack>    
                        <LoadingButton
                            loading={loading && indexData === key } 
                            variant="contained" 
                            color='primary' 
                            className="btn-rdv btn-reset btn-icon"
                            startIcon={<CancelIcon />}
                            onClick={() => handleDeletePerson(data, key)}
                        >
                            Annuler le RDV
                        </LoadingButton>
                    </Stack>
                )}
                {rows?.length === 0 && <p>Aucun rendez-vous</p>}
            </Stack>
        </Box>
    )
}
