import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import '../../../Mairie.style.scss';
import dayjs, {Dayjs} from 'dayjs';
import frLocale from 'date-fns/locale/fr';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import { useEffect, useState } from "react";
import { CalendarWeek } from "../../../../../../../../application/components/CalendarWeek/CalendarWeek";
import { initialCalendar } from "../../../../../../../../infrastructure/data/initialCalendar";
import { handleGetAppointmentBy } from "../../../../../../../../helpers/appointment.helper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const StepTwo = ({ register, errors, getValues, handleSubmit, control, setValue, mairies, onSubmitHandler }) => {
    const [holiday, setHoliday] = useState<Dayjs | null>(dayjs());
    const [calendarWeek, setCalendarWeek] = useState(mairies[0]?.calendar?.hours || initialCalendar);
    const [holidays, setHolidays] = useState<any>(mairies[0]?.calendar?.holidays || []);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [holidayAlert, setHolidayAlert] = useState<any>(null);
    const [error, setError] = useState<boolean>(false);

    const handleChangeHour = (data, type, index) => {
        let startOrEnd = type;
        const typeHour = type.includes('2') ? 1 : 0;
        if(typeHour === 1) {
            startOrEnd = type === 'startHour2' ? 'startHour' : 'endHour';
        }
        let prevHour = calendarWeek;
        if(data){
            prevHour[index][typeHour][startOrEnd] = dayjs(data).format('HH:mm');   
        }else{
            prevHour[index][typeHour][startOrEnd] = '-';
        }
        setCalendarWeek(prevHour);
        setValue('hours', prevHour);
    }

    const handleAddHoliday = () => {
        if(holidayAlert){
            const day = dayjs(holidayAlert).format('MM/DD/YYYY');
            const isExist = holidays.find(data => data === day);
            if(isExist){
                setError(true);
            }else{
                setHolidays((current) => [...current, dayjs(holidayAlert).format('MM/DD/YYYY')]);
            }
        }
        setOpenAlert(false);

    }

    const handleCloseModalAlert = () => {
        setOpenAlert(false);
    }

    const addHolidays = async(date) => {
        const day = dayjs(date).format('MM/DD/YYYY');
        const isExist = holidays.find(data => data === day);
        if(isExist){
            setError(true);
        }else{
            setError(false);
            const res = await handleGetAppointmentBy(mairies[0].slug, dayjs(date).format('YYYY-MM-DD'));
            if(res?.length > 0){
                setOpenAlert(true);
                setHolidayAlert(date);
            }else{
                setHolidays((current) => [...current, day]);
            }
        }
    }

    const handleRemoveHoliday = (holiday) => {
        setHolidays(holidays.filter(item => item !== holiday));
    }

    useEffect(() => {
        setValue('holidays', holidays);
    }, [holidays, setValue]);

    return (
        <div className=" mt-5">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Stack gap={2}>
                    <div className="bloc-info-mairie">
                        <p className="name-mairie mb-0">{mairies[0]?.name}</p>
                        <p className="text-light-grey">{mairies[0]?.address}</p>
                    </div>
                    <div className="bloc-hour">
                        <p className="title-bloc mb-5">Heures d'ouverture*</p>
                        <CalendarWeek calendarWeek={calendarWeek} handleChangeHour={handleChangeHour} />
                    </div>
                    <div className="bloc-hour">
                        <p className="mb-2 title-bloc">Jours fériés</p>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                <DesktopDatePicker
                                    value={holiday}
                                    minDate={dayjs('2017-01-01')}
                                    inputFormat={'dd/MM/yyyy'}
                                    onChange={(newValue) => {
                                        setHoliday(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                            </LocalizationProvider>
                            <AddCircleIcon className="cursor-pointer" sx={{ color: '#44c644' }} onClick={() => addHolidays(holiday)} />
                        </Stack>
                        {error && <p className="text-red">Cette date existe déjà sur votre liste de jour férié.</p>}
                        <p className="mt-2 title-bloc">Liste des jours :</p>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'} flexWrap={'wrap'}>
                            {holidays?.length > 0 && holidays.map(holiday => 
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'} className="item-holiday">
                                    <p>{dayjs(holiday).format('DD MMM YYYY')}</p>
                                    <DeleteIcon className="cursor-pointer" sx={{ color: 'red', fontSize: '16px'}} onClick={() => handleRemoveHoliday(holiday)} />
                                </Stack>
                            )}
                        </Stack>
                    </div>
                </Stack>
                <Stack className="div-btn-next">
                    <Button className="btn-next" variant={"contained"} color={"primary"} type="submit">Suivant</Button>
                </Stack>
            </form>
            <Dialog
                open={openAlert}
                onClose={handleCloseModalAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className=' text-center'>
                    <WarningIcon sx={{ fill: 'orange' }} /> Avertissement
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Attention il y des rendez-vous qui sont déjà prises pour ce date.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalAlert} variant="outlined" color="primary">Annuler</Button>
                    <Button onClick={handleAddHoliday} variant="outlined" color="secondary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
