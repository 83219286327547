export const menuMairieAdmin = [
    {
        name: 'INFORMATIONS MAIRIE',
        key: 'info_mairie'
    },
    {
        name: 'LISTE DES MAIRIES',
        key: 'liste_mairie'
    }
]

export const menuMairieSuperAdmin = [
    {
        name: 'AJOUTER MAIRIE',
        key: 'add_mairie'
    },
    {
        name: 'MAIRIE ANNEXE',
        key: 'add_mairie_annexe'
    },
    {
        name: 'LISTE DES MAIRIES',
        key: 'liste_mairie'
    },
    {
        name: 'SMS/EMAIL MOTIFS',
        key: 'sms_email_motif'
    },
    {
        name: 'IMPORT/EXPORT RDV',
        key: 'export_rdv'
    },
    {
        name: 'PARAMETRE',
        key: 'parameter'
    }
]