import dayjs from "dayjs";
import { create } from "zustand";
import NODE_API from "../../helpers/axios.helper";
import { handleGetBusyDate } from "../../helpers/calendar.helper";
import { getListAgendasByMairie } from "../../helpers/mairie.helper";
import { useAuthStore } from "./auth.store";
import { useMairieStore } from "./mairie.store";

interface AgendaStoreType {
    appointments: any;
    agendas: any[];
    defaultResourceBureau: any[];
    loading: boolean;
    busyDate: any;
    slug: string;
}

type Actions = {
    reset: () => void;
    setBusyDate: (data: any) => void;
};

const initialState: AgendaStoreType = {
    appointments: [],
    agendas: [],
    defaultResourceBureau: [],
    loading: false,
    busyDate: [],
    slug: "",
};

export const useAgendaStore = create<AgendaStoreType & Actions>()((set) => ({
    ...initialState,
    reset: () => {
        set(initialState);
    },
    setBusyDate: (value) => set((state) => ({ busyDate: value })),
}));

export const changeSlug = (value) => {
    useAgendaStore.setState(() => ({ appointments: [] }));
    useAgendaStore.setState(() => ({ slug: value }));
};

export const refetchBusyDate = () => {
    handleGetBusyDate(useAgendaStore.getState().setBusyDate);
};

export const getAllAppointment = async (
    startDate: any = null,
    endDate: any = null
) => {
    useAgendaStore.setState(() => ({ loading: true }));
    const slug = useAgendaStore.getState().slug;
    const user = useAuthStore.getState().user;
    let res: any = {};
    if (user?.role === "ADMIN" || user?.role === "AGENT") {
        if (slug) {
            if (startDate && endDate) {
                res = await NODE_API.get(
                    `/appointment/slug/${slug}?start=${dayjs(startDate).format(
                        "YYYY-MM-DD"
                    )}&end=${dayjs(endDate).format("YYYY-MM-DD")}`
                );
            } else {
                res = await NODE_API.get(`/appointment/slug/${slug}`);
            }
        }
    } else {
        if (startDate && endDate) {
            res = await NODE_API.get(
                `/appointment/has-ants-number?start_date=${dayjs(
                    startDate
                ).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format(
                    "YYYY-MM-DD"
                )}`
            );
        } else {
            res = await NODE_API.get(`/appointment/has-ants-number`);
        }
    }

    if (res?.data) {
        let newData: any[] = [];
        (res?.data ?? []).map((appointment) => {
            const endHour = dayjs(appointment.heureRdv).add(
                appointment.dureeRdv,
                "minutes"
            );

            newData.push({
                ...appointment,
                title:
                    appointment.usager?.firstName +
                    " " +
                    appointment.usager?.lastName,
                startHour: dayjs(appointment.heureRdv)
                    .add(45, "second")
                    .toString(),
                endHour: endHour.toString(),
                type: appointment.typeDossier,
                typeRdv: appointment.type,
                location: appointment?.room?._id,
            });
        });
        useAgendaStore.setState(() => ({ appointments: newData }));
    }
    useAgendaStore.setState(() => ({ loading: false }));
};

export const getMairieAgendasBySlug = async ({ slug, user }) => {
    if (slug && user) {
        useAgendaStore.setState(() => ({ loading: false }));
        const agendasMairie = useMairieStore.getState().agendas;
        let res: any = null;
        if (user?.role === "ADMIN") {
            res = await getListAgendasByMairie(slug, null);
        } else {
            res = await getListAgendasByMairie(slug, user?._id);
        }
        if (res?.success) {
            useAgendaStore.setState(() => ({ agendas: res?.data }));
            let resourceBureau = [];
            // const bureau = res.data.filter((room => room.mairie.slug === slug ));
            resourceBureau = res.data.map((agenda, idx) => {
                return {
                    id: agenda._id,
                    text: agenda.name,
                };
            });
            useAgendaStore.setState(() => ({
                defaultResourceBureau: resourceBureau,
            }));
        }
        useAgendaStore.setState(() => ({ loading: false }));
    }
};
