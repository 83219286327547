export const TYPE_STATUS = [
    {name: 'Honoré', key: 'DONE'},
    {name: 'Non honoré', key: 'NOT_HONORED'},
    {name: 'Annulé', key: 'CANCELED'},
    {name: 'Manque de document', key: 'DOCUMENT_MISSED'},
    {name: 'A venir', key: 'PENDING'},
]

export const TYPE_STATUS_MAPPING = {
    'DONE': 'Honoré',
    'NOT_HONORED': 'Non honoré',
    'CANCELED': 'Annulé',
    'DOCUMENT_MISSED': 'Manque de document',
    'PENDING': 'A venir'
}