import jsPDF from 'jspdf/dist/jspdf.es'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)

export const ExportTableToPDF = (
    fileName: string, 
    tables: { title: string, html?: HTMLElement | any, data?: any }[], 
    pdfOptions: any = {}
) => {
    
    const unit = pdfOptions?.unit || "px";
    const size = pdfOptions?.size || "A4";
    const orientation = pdfOptions?.orientation || "portrait";
    const backgroundColorTableHeader = '#c84c51'
    const doc = new jsPDF(orientation, unit, size)
    const biasPositionTitle = 30;
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    let previousPositionTable = doc.lastAutoTable.finalY || 10
    let isFirstTable = true;
    doc.setFontSize(12)

    for (const table of tables) {
        const options = {
            headStyles: { fillColor: backgroundColorTableHeader }
        }
        if (isFirstTable) {
            doc.text(table.title, pageWidth / 2, 20, { align: 'center' })
        } else {
            doc.text(table.title, pageWidth / 2, (previousPositionTable || 0) + biasPositionTitle, { align: 'center' })
            Object.assign(options, { startY:  previousPositionTable + 40 })
        }
        if (table.data) {
            Object.assign(options, table.data)
        } else {
            Object.assign(options, { html: table.html })
        }
        doc.autoTable(options)
        previousPositionTable = doc?.lastAutoTable?.finalY || 10
        isFirstTable = false
    }

    doc.save(fileName);
}