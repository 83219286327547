import NODE_API from "./axios.helper";

export const handlePostAgendaParameter = async(data) => {
    try {
        const input = {
            mairie: data.mairie,
            defaultView: data?.defaultView?.toUpperCase(),
            step: Number(data.step),
            alertNewRdv: data.alertNewRdv,
            reminderHour: data.reminderHour,
            verificationAccount: data.verificationAccount,
            configurationWeek: data.configurationWeek,
            notification: data.notification,
            verificationAdministrated: data.verificationAdministrated,
            takeRdvType: data.takeRdvType,
            before_number: data.before_number,
            before_type: data.before_type,
            until_number: data.until_number,
            until_type: data.until_type,
            agendaHour: data?.agendaHour
        }
        const res = NODE_API.post('/agenda-parameter',input)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handlePutAgendaParameter = async(data) => {
    try {
        const input = {
            mairie: data.mairie,
            defaultView: data?.defaultView?.toUpperCase(),
            step: Number(data.step),
            alertNewRdv: data.alertNewRdv,
            reminderHour: Number(data.reminderHour),
            verificationAccount: data.verificationAccount,
            configurationWeek: data.configurationWeek,
            notification: data.notification,
            verificationAdministrated: data.verificationAdministrated,
            takeRdvType: data.takeRdvType,
            before_number: data.before_number,
            before_type: data.before_type,
            until_number: data.until_number,
            until_type: data.until_type,
            agendaHour: data?.agendaHour
        }
        const res = NODE_API.put('/agenda-parameter/'+data._id, input)

        return res;
    } catch (error) {
        console.log(error);
    }
}