import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";


export const InputPhone = ({ control, getValues, errors, defaultValue }: any) => {
    return (
        <Stack flexDirection={'row'} alignItems={"center"} className="w-full">
            <FormControl sx={{ width: 125 }}>
                <Select value={'33'} className={"bg-grey border-radius-left"}>
                    <MenuItem value={'33'}>
                        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/fr.png`}
                                srcSet={`https://flagcdn.com/w40/fr.png 2x`}
                                alt=""
                            />
                            <span>+33</span>
                        </Stack>
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <Controller
                    control={control}
                    name="phoneNumber"
                    defaultValue={defaultValue}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <Stack flexDirection={"row"} alignItems={"center"}>
                                <NumberFormat
                                    format="## ## ## ## ##"
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    defaultValue={defaultValue}
                                    customInput={TextField}
                                    className={"bg-grey border-radius-right w-full"}
                                />
                                <div className={"absolute"} style={{right: 15}}>
                                    { !!errors?.phoneNumber ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues && getValues('phoneNumber') && <CheckCircle color={"secondary"}/>)
                                    }
                                </div>
                            </Stack>
                        </>
                    )}
                />
            </FormControl>
        </Stack>
    )
}