import { useEffect, useState } from "react";
import { Box, FormControl, Grid, InputAdornment, OutlinedInput, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import '../../../Mairie.style.scss';
import dayjs, { Dayjs } from 'dayjs';
import frLocale from 'date-fns/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SelectCodePostal } from "../../../../../../../../application/components/SelectCodePostal";
import { InputPhone } from "../../../../../../../../application/components/InputPhone/InputPhone";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FormPropsMairie } from "../../../../../../../../application/types/mairie.type";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F2F2F2',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));


export const FormMairie = ({ register, errors, getValues, control, setValue, mairie }: FormPropsMairie) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(mairie?.subscription?.startDate ? dayjs(mairie?.subscription?.startDate) : dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(mairie?.subscription?.endDate ? dayjs(mairie?.subscription?.endDate) : dayjs());
    const params = {
        register, 
        errors,
        control,
        getValues
    }

    useEffect(() => {
        setValue('startDate', startDate);
        setValue('endDate', endDate);
    }, [endDate, setValue, startDate])
  
    return (
        <>
            <Stack gap={2} className="padding-left-right">
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Nom de la Commune*'}
                                defaultValue={mairie?.name ?? ''}
                                {...register("name")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.name ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('name') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Dossier*'}
                                defaultValue={mairie?.slug ?? ''}
                                {...register("slug")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.slug ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('slug') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse*'}
                                defaultValue={mairie?.address ?? ''}
                                {...register("address")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.address ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('address') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse complémentaire*'}
                                defaultValue={mairie?.address2 ?? ''}
                                {...register("address2")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.address2 ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('address2') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <SelectCodePostal disabled={false} register={register} setValue={setValue} data={mairie} />
                </Stack>  
                <Stack>
                    <Paper className="custom-paper">
                        <InputPhone {...params} defaultValue={mairie?.phoneNumber?.replace('+33','0') ?? ''} />
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Votre adresse email*'}
                                type={"email"}
                                defaultValue={mairie?.email ?? ''}
                                {...register("email")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.email ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Identifiant*'}
                                defaultValue={mairie?.username ?? ''}
                                {...register("username")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.username ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('username') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Mot de passe*'}
                                type="password"
                                defaultValue={mairie?.user?.password ?? ''}
                                {...register("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.password ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('password') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Confirmer Mot de passe*'}
                                type="password"
                                defaultValue={mairie?.user?.password ?? ''}
                                {...register("confirmPassword")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.confirmPassword ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('confirmPassword') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
            </Stack> 
            <Stack marginTop={3} className="padding-left-right">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Item className="bloc-number-mairie">
                            <p>NOMBRE D'AGENDA</p>
                                <TextField
                                    sx={{ width: 80}}
                                    type='number'
                                    size="small"
                                    defaultValue={mairie?.agenda ?? 1}
                                    {...register("agenda", { valueAsNumber: true, validate: (value) => value > 0 })}
                                    InputProps={{
                                        inputProps: {
                                            max: 100,
                                            min: 1
                                        }
                                    }}
                                />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item className="bloc-number-mairie">
                            <p>Nombre station d'accueil</p>
                            <TextField
                                sx={{ width: 80}}
                                type='number'
                                size="small"
                                defaultValue={mairie?.room ?? 1}
                                {...register("room", { valueAsNumber: true, validate: (value) => value >= 0 })}
                                InputProps={{
                                    inputProps: {
                                        max: 100,
                                        min: 0
                                    }
                                }}
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item className="bloc-number-mairie">
                            <p>Nombre de sms</p>
                            <TextField
                                sx={{ width: 80}}
                                type='number'
                                size="small"
                                defaultValue={mairie?.sms ?? 100}
                                {...register("sms", { valueAsNumber: true, validate: (value) => value > 0 })}
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    }
                                }}
                            />
                        </Item>
                    </Grid>
                </Grid>
            </Stack>
            <Stack marginTop={4} className="padding-left-right">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, overflow: 'hidden' }} size="small" aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell className="bg-min-grey" colSpan={2} align="center">ABONNEMENT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" component="th" scope="row" className="uppercase bg-min-grey">
                                    début
                                </TableCell>
                                <TableCell align="left">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                    <DesktopDatePicker
                                        views={['year', 'month']}
                                        value={startDate}
                                        minDate={dayjs()}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {InputProps?.endAdornment}
                                                <input className="mx-2" ref={inputRef} {...inputProps} />
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" component="th" scope="row" className="uppercase bg-min-grey">
                                    fin
                                </TableCell>
                                <TableCell align="left">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                    <DesktopDatePicker
                                        views={['year', 'month']}
                                        value={endDate}
                                        minDate={dayjs()}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {InputProps?.endAdornment}
                                                <input className="mx-2" ref={inputRef} {...inputProps} />
                                            </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                        </TableBody>    
                    </Table>
                </TableContainer>
            </Stack>
        </>
    )
}
