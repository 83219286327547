import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Grid } from '@mui/material';
import { SousMenuUsager } from "../../../../application/components/SousMenu/SousMenuUsager";
import { CustomDialog } from '../../../../application/components/CustomDialog';
import { useEffect, useState } from 'react';
import { DetailsUsager } from '../components/DetailsUsager';
import { getComparator, stableSort } from '../../../../infrastructure/utils';
import TableSorting from '../../../../application/components/table-sorting';
import { Order } from '../../../../application/types/type';
import { useAuthStore } from '../../../../application/store/auth.store';

import { refetchListUsagers, useMairieStore } from '../../../../application/store/mairie.store';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const headCells: readonly any[] = [
    {
        id: ['firstName', 'lastName'],
        numeric: false,
        disablePadding: true,
        filter: true,
        label: 'Nom',
    },
    {
        id: 'phoneNumber',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Téléphone',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Adresse email',
    },
    {
        id: ['zip', 'city'],
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Ville',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        filter: false,
        label: 'Action',
    },
];

  
export const ListeUsager = () => {
    const  user  = useAuthStore((state) => state.user);
    const  usagers  = useMairieStore((state) => state.usagers);
    const [open, setOpen] = useState(false);
    const [usager, setUsager] = useState<any>(null);
    const [rows, setRows] = useState<any>(usagers);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage};
    const handleCloseModal = () => {
        setOpen(false)
    }

    useEffect(() => {
        setRows(usagers);
    }, [usagers])

    return (
        <Box paddingTop={8}>
            {['ADMIN', 'AGENT'].includes(user.role) && <SousMenuUsager />}
            <Box className="box-mairie">
                <Grid container className="grid-mairie">
                    <TableContainer component={Paper}>
                        <TableSorting rows={rows} headCells={headCells} paramsSorting={paramsSorting} setRows={setRows} originalRows={usagers}>
                            <>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.firstName} {row.lastName} 
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                                <StyledTableCell align="left">{row.zip}, {row.city}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <TuneIcon 
                                                        className="cursor-pointer" 
                                                        onClick={() => {setUsager(row); setOpen(true)}}
                                                    />
                                                    {/* <DeleteForeverIcon 
                                                        className="btn-delete"
                                                        onClick={() => null}
                                                    /> */}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </>
                        </TableSorting>
                    </TableContainer>
                </Grid>
                <CustomDialog
                    setOpen={setOpen}
                    open={open}
                    onClose={handleCloseModal}
                    component={<DetailsUsager usager={usager} callback={refetchListUsagers} />}
                />
            </Box>
        </Box>
    )
}