import axios from "axios";
import jwtDecode from "jwt-decode";
import { uniqBy } from "lodash";
import { create } from "zustand";
import { handleLogin } from "../../helpers/login.helper";
import { getListAgendasByMairie } from "../../helpers/mairie.helper";
import { TYPE_DOSSIERS } from "../../infrastructure/data/type-dossier.data";
import { useAgendaStore } from "./agenda.store";
import { useMairieStore } from "./mairie.store";

interface AuthStoreType {
    user: any;
    motifs: any;
    logged: boolean;
    loading: boolean;
}

type Actions = {
    setUser: (value: any) => void;
    setLogged: (value: any) => void;
};

export const useAuthStore = create<AuthStoreType & Actions>()((set) => ({
    user: localStorage.getItem("info")
        ? JSON.parse(localStorage.getItem("info") as string)
        : null,
    motifs: TYPE_DOSSIERS,
    logged: localStorage.getItem("access_token") ? true : false,
    loading: false,
    setUser: (value) => set((state) => ({ user: value })),
    setLogged: (value) => set((state) => ({ logged: value })),
}));

export const loginHandler = async (data: {
    username: string;
    password: string;
}) => {
    const res = await handleLogin({ ...data });
    if (res?.token) {
        const decoded: any = jwtDecode(res.token) || {};
        useAuthStore.setState(() => ({ user: { ...res, ...decoded } }));
        axios.defaults.headers.common["Authorization"] = `Bearer ${res?.token}`;

        localStorage.setItem("access_token", res.token);
        localStorage.setItem("info", JSON.stringify(res));
        useAuthStore.setState(() => ({ logged: true }));
    }
    return res;
};

export const logoutHandler = (): any => {
    useAuthStore.setState(() => ({ user: {} }));
    useAuthStore.setState(() => ({ logged: false }));
    useMairieStore.getState().reset();
    useAgendaStore.getState().reset();
    localStorage.clear();
    delete axios.defaults.headers.common["Authorization"];
};

export const getMotifs = async (slug, isIframe) => {
    const res = await getListAgendasByMairie(slug);

    if (res?.data && isIframe) {
        let agendasMotifs: any = res.data.reduce(
            (acc, currentState: any) => acc.concat(currentState?.motifs),
            []
        );
        agendasMotifs = uniqBy(agendasMotifs, "_id");
        agendasMotifs = agendasMotifs?.filter((data) => !data.hidden);

        let results = agendasMotifs.map((data) => {
            return {
                label: data.name,
                key: data.name.replaceAll(" ", "_"),
            };
        });
        useAuthStore.setState(() => ({ motifs: results }));
    } else {
        useAuthStore.setState(() => ({ motifs: TYPE_DOSSIERS }));
    }
};
