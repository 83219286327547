import { uniqBy } from "lodash";
import { create } from "zustand";
import {
    handleGetAntsStatus,
    handleGetCanceledAppointment,
    handleGetDuplicatedAppointment,
} from "../../helpers/appointment.helper";
import NODE_API from "../../helpers/axios.helper";
import { handleGetDefaultMotif } from "../../helpers/defaultMotif.helper";
import {
    getListAgendasByMairie,
    getListAgentByMairie,
    getListUsagers,
    handleFilteredMairies,
    handleGetListMairies,
    handleGetMairies,
} from "../../helpers/mairie.helper";
import { getMairieAgendasBySlug } from "./agenda.store";

interface MairieStoreType {
    agents: any;
    usagers: any;
    agendas: any;
    mairies: any;
    defaultMotif: any;
    TYPE_DOSSIERS: any;
    motifs: any;
    loading: boolean;
    listMairies: any;
    filteredMairies: any;
    canceledDate: any;
    duplicatedDate: any;
    rdvConsumed: any;
    rdvExpired: any;
    rdvDuplicated: any;
}

type Actions = {
    reset: () => void;
    setConsumed: (value: any) => void;
    setExpired: (value: any) => void;
    setDuplicated: (value: any) => void;
};

const initialState: MairieStoreType = {
    agents: [],
    usagers: [],
    agendas: [],
    mairies: [],
    defaultMotif: [],
    TYPE_DOSSIERS: [],
    motifs: [],
    loading: false,
    listMairies: [],
    filteredMairies: [],
    canceledDate: [],
    duplicatedDate: [],
    rdvConsumed: [],
    rdvExpired: [],
    rdvDuplicated: [],
};

export const useMairieStore = create<MairieStoreType & Actions>()((set) => ({
    ...initialState,
    setConsumed: (value) => set((state) => ({ rdvConsumed: value })),
    setExpired: (value) => set((state) => ({ rdvExpired: value })),
    setDuplicated: (value) => set((state) => ({ rdvDuplicated: value })),
    reset: () => {
        set(initialState);
    },
}));

export const getTypeDossiers = (agendas) => {
    let agendasMotifs: any = agendas.reduce(
        (acc, currentState: any) => acc.concat(currentState?.motifs),
        []
    );
    agendasMotifs = uniqBy(agendasMotifs, "_id");
    const data = agendasMotifs.map((motif: any) => {
        return {
            label: motif.name,
            key: motif.name.replaceAll(" ", "_"),
        };
    });
    useMairieStore.setState(() => ({ TYPE_DOSSIERS: data }));
};

export const getDefaultMotifs = async () => {
    const result = await handleGetDefaultMotif();
    if (result?.length > 0) {
        useMairieStore.setState(() => ({ defaultMotif: result }));
    }
};

export const getListMotifs = async (mairies) => {
    if (mairies.length > 0) {
        useMairieStore.setState(() => ({ loading: true }));
        const res = await NODE_API.get(`/motifs/slug/${mairies[0]?.slug}`);
        if (res?.data) {
            useMairieStore.setState(() => ({ motifs: res.data }));
        }
        useMairieStore.setState(() => ({ loading: false }));
    }
};

export const getMotifsBySlug = async (slug) => {
    const res = await NODE_API.get(`/motifs/slug/${slug}`);
    if (res?.data) {
        return res?.data;
    }
};

export const refetchListMairies = async () => {
    if (localStorage.getItem("access_token")) {
        useMairieStore.setState(() => ({ loading: true }));

        const res = await handleGetMairies();
        if (res?.success) {
            useMairieStore.setState(() => ({ mairies: res.data }));
        }
        useMairieStore.setState(() => ({ loading: false }));
    }
};

export const getListMairies = async () => {
    const res = await handleGetListMairies();
    if (res?.success) {
        useMairieStore.setState(() => ({ listMairies: res.data }));
    }
};

export const getCanceledDate = async (mairies) => {
    if (mairies && mairies[0]) {
        const res: any = await handleGetCanceledAppointment(mairies[0]?.slug);
        if (res?.success) {
            useMairieStore.setState(() => ({ canceledDate: res.data }));
        }
    }
};

export const getDuplicatedDate = async (mairies) => {
    if (mairies && mairies[0]) {
        const res: any = await handleGetDuplicatedAppointment(mairies[0]?.slug);
        if (res?.success) {
            useMairieStore.setState(() => ({ duplicatedDate: res.data }));
        }
    }
};

export const getAntsRdv = async (mairies, type = "consumed") => {
    if (mairies && mairies[0]) {
        const res: any = await handleGetAntsStatus(mairies[0]?.slug, type);
        if (res?.success) {
            if (type === "consumed")
                useMairieStore.setState(() => ({ rdvConsumed: res.data }));
            if (type === "expired")
                useMairieStore.setState(() => ({ rdvExpired: res.data }));
            if (type === "validated")
                useMairieStore.setState(() => ({ rdvDuplicated: res.data }));
        }
    }
};

export const getMairieAgent = async (mairies) => {
    if (mairies.length > 0) {
        const res = await getListAgentByMairie(mairies[0]?._id);
        if (res?.success) useMairieStore.setState(() => ({ agents: res.data }));
    }
};

export const refetchListAgendas = async ({ mairies, user }) => {
    if (mairies.length > 0 && user) {
        useMairieStore.setState(() => ({ loading: true }));

        let res: any = null;
        if (user?.role === "ADMIN") {
            res = await getListAgendasByMairie(mairies[0]?.slug, null);
        } else {
            res = await getListAgendasByMairie(mairies[0]?.slug, user?._id);
        }
        await getMairieAgendasBySlug({
            slug: mairies[0]?.slug,
            user: user?._id,
        });
        if (res?.success) {
            useMairieStore.setState(() => ({ agendas: res.data }));
        }
        useMairieStore.setState(() => ({ loading: false }));
    }
};

export const getUsagers = async (slug) => {
    const res = await getListUsagers(slug);
    if (res?.success) useMairieStore.setState(() => ({ usagers: res.data }));
};

export const getFilterdMairies = async (data) => {
    useMairieStore.setState(() => ({ filteredMairies: [] }));
    useMairieStore.setState(() => ({ loading: true }));

    const res: any = await handleFilteredMairies(data);
    if (res?.length > 0) {
        useMairieStore.setState(() => ({ filteredMairies: res }));
    }
    useMairieStore.setState(() => ({ loading: false }));
};

export const getMoreFilterdMairies = async (data, callback) => {
    useMairieStore.setState(() => ({ loading: true }));

    const res: any = await handleFilteredMairies(data);
    if (res?.length > 0) {
        callback((prevState) => [...prevState, ...res]);
    }
    useMairieStore.setState(() => ({ loading: false }));
};

export const refetchListUsagers = () => {
    const mairies = useMairieStore.getState().mairies;
    if (mairies[0]) getUsagers(mairies[0]?.slug);
};
