import {Checkbox, IconButton, Stack, Typography} from "@mui/material";
import {TaskAlt} from "@mui/icons-material";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSessionStorage} from "usehooks-ts";
import {DEFAULT_RDV_HOUR} from "../common/constant";
import AxiosContext from "../context/axios.context";
import {LoadingButton} from "@mui/lab";
import dayjs from "dayjs";

export const ConfirmRdv = () => {
    const [session]: any = useSessionStorage('session', {});
    const { value: { instance: axios } } = useContext(AxiosContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleConfirm = () => {
        const hasNoDuplication = true;
        if (hasNoDuplication) {
            const appointment = {
                firstName: session.firstName,
                lastName: session.lastName,
                phoneNumber: session.phoneNumber,
                heureRdv: dayjs(session.heureRdv).utc().local().format().split('+')[0],
                dureeRdv: (session.nombrePersonne || 1) * DEFAULT_RDV_HOUR,
                typeDossier: session?.typeDossier,
                concernedPerson: session.concernedPerson,
                email: session.email,
                status: 'PENDING',
                mairie: session.ville.slug,
                nombrePersonne: session.nombrePersonne,
                city: session.city,
                room: session.bureau,
                type: session.type
            }
            setLoading(true);
            axios.post('/appointment', {
                ...appointment,
                heureRdv: appointment.heureRdv && appointment.heureRdv?.slice(0,16) + ":00"
            })
                .finally(() => {
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/confirmation-rdv/confirmed');
                    },500)
                })
        }
    }

    return <>
        <Stack flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={4}>
            <Stack flexDirection={"column"} alignItems={"center"}>
                <IconButton><TaskAlt style={{ height: 58, width: 58}} className={"text-green-700"}/></IconButton>
                <Typography variant={"subtitle1"}><span className={"text-green-700 font-bold uppercase"}>Merci !</span></Typography>
                <Typography variant={"subtitle1"}><span className={"text-green-700 font-bold uppercase"}>Votre compte est vérifié</span></Typography>
                <Typography variant={"subtitle1"} className={"text-center"}><span className={"font-bold uppercase"}>CLIQUEZ SUR LE BOUTON CI-DESSOUS POUR CONFIRMER VOTRE RENDEZ-VOUS</span></Typography>
            </Stack>
            <Stack className={"text-center"} gap={2}>
                {/*<Box><IconButton><WarningSvg/></IconButton></Box>*/}
                <p className={"font-bold"}><Checkbox/> Être notifié si un rendez-vous se libère plus près ou plus tôt</p>
                <LoadingButton loading={loading} variant={"contained"} size={"large"} color={"secondary"} className={"uppercase"} onClick={() => handleConfirm()}>Confirmer le rendez-vous</LoadingButton>
                {/* <Button variant={"text"} onClick={() => setAlertCancelRdvAssociated(true)}>Annuler</Button> */}
            </Stack>
            {/* <CustomDialog
                open={openAlertRdvAssociated || openAlertCancelRdvAssociated}
                setOpen={openAlertRdvAssociated ? setAlertRdvAssociated : setAlertCancelRdvAssociated}
                onClose={handleOnCloseDialog}
                component={
                    openAlertRdvAssociated ?
                        <AlertRdvAssociated isWarning={false}/> :
                        <AlertRdvAssociated isWarning={true} />
                }
            /> */}
        </Stack>
    </>
}
