import React from "react";
import { Link, Box, Stack } from "@mui/material";
import './cgu.style.scss';

export const CGU = () => {
    return (
        <Box className="box-cgu">
                <div className="div-cgu">
                    <h1>CONDITIONS GENERALES D’UTILISATION</h1>
                    <Stack marginTop={2}>
                        <p className="title">Article 1 : DEFINITIONS</p>
                        <p>
                            <span className="bold">CGU</span> : désigne les présentes conditions générales d’utilisation du site rdvenmairie.fr <br></br>
                            <span className="bold">Site</span>: désigne le site internet <Link href='https://www.rdvenmairie.fr' target='_blank'>https://www.rdvenmairie.fr</Link> <br></br>
                            <span className="bold">Editeur</span> : désigne la société INFO LOCALE <br></br>
                            <span className="bold">Utilisateur</span> : désigne tout personne physique accédant au Site <br></br>
                            <span className="bold">Espace utilisateur</span> : désigne l’espace dédié d’un Utilisateur bénéficiant des Services proposés par l’Editeur. Cet espace est accessible après identification à l’aide d’un identifiant correspondant à l’ adresse email personnelle de l’Utilisateur et la saisie du code à 6 chiffres reçu sur à cette adresse. <br></br>
                            <span className="bold">Service</span> : désigne le Service de prise de rendez-vous en ligne proposé par l’Editeur à l’Utilisateur dans le cadre des CGU.<br></br>
                            <span className="bold">Partenaires</span> : désigne les collectivités qui adhèrent à la solution de prise de rendez-vous en ligne de l’Editeur.<br></br>
                            <span className="bold">Espace partenaire</span>: désigne l’espace dédié d’un Partenaire bénéficiant des Services proposés par l’Editeur.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 2 : MENTIONS LEGALES</p>
                        <p>
                            L'édition du site https://www.rdvenmairie.fr est assurée par la Société SARL INFO LOCALE au capital de 15000 euros, immatriculée au RCS de CHARTRES sous le numéro 438 718 637 000 20, dont le siège social est situé au 9 RUE DU PARC <br></br>
                            Numéro de téléphone 0237330325 <br></br>
                            Adresse e-mail : contact@info-locale.fr. <br></br>
                            Numéro de TVA intracommunautaire : FR 74438718637

                        </p>
                        <br></br>
                        <p>
                            L'hébergeur du site https://www.rdvenmairie.fr est la société OVHcloud, dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix – France SAS au capital de 10 174 560 € / RCS Lille Métropole 424 761 419 00045 / Code APE 2620Z / N° TVA : FR 22 424 761 419
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 3 : OBJET DES CGU</p>
                        <p>
                            Les présentes CGU ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par L’Editeur et de définir les conditions d’accès et d’utilisation des services par l'Utilisateur.<br></br>
                            Les présentes CGU sont accessibles sur le Site à la rubrique <span className="bold">«CGU»</span>
                        </p>
                        <br></br>
                        <p>
                            Toute utilisation du Site implique l'acceptation préalable implicite et sans aucune réserve ni restriction des présentes CGU par l’Utilisateur. Lors de la réservation d’un rendez-vous en mairie, chaque Utilisateur accepte explicitement les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ».<br></br>
                            En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le Site.<br></br>
                            L’Editeur se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 4 : CONDITIONS ET MODALITÉS D’ACCÈS AU SITE</p>
                        <p>
                            Le Site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet et à un navigateur. Tous les équipements et services nécessaires à l’accès au Site et à son utilisation restent à la charge exclusive de l’Utilisateur. <br></br>
                            L’Editeur ne peut être tenu responsable de toute difficulté d’accès au réseau Internet et des dysfonctionnements et anomalies liées à l’accès au Site qui ne lui seraient pas imputables.<br></br>
                            L’Editeur ne pourra être tenu responsable de tout dommage indirect résultant de l’accès au Site ou de l’impossibilité d’y accéder.<br></br>
                            L’Editeur se réserve le droit à tout moment, sans préavis et sans droit d’indemnité au profit de l’Utilisateur, d’interrompre définitivement, de suspendre et/ou de modifier l’accès à tout ou partie du Site afin d’en assurer la maintenance et la mise à jour.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 5 : SERVICES PROPOSÉS PAR LE SITE</p>
                        <p>
                        Le Site permet à l'Utilisateur un accès gratuit aux services suivants :<br></br>
                        - Recherche de rendez-vous disponibles auprès des Partenaires du Site selon différents critères (lieu d’habitation, motif du rendez-vous, nombre de personnes concernées) <br></br>
                        - Réservation d’un rendez-vous en ligne auprès du Partenaire sélectionné.<br></br>
                        - Report ou annulation d’un rendez-vous.<br></br>
                        - Envoie d’une alerte de rappel de rendez-vous 48h avant.<br></br>
                        - Inscription sur une liste d’attente pour être informée lorsqu’un rendez-vous se libère.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 6 : ENGAGEMENTS</p>
                        <div>
                            <span className='underline'>L’Editeur s’engage à : </span><br></br>

                            - fournir les Services conformément aux CGU.<br></br>

                            <span className='underline'>L’Utilisateur s’engage à :</span>
                            <p>- Respecter les termes des CGU ;</p>
                            <p>- Être une personne physique en capacité de souscrire à ses obligations au sens du droit Français et/ou disposer de l’autorité parentale, de tuteur ou de curateur sur les personnes pour lesquelles il saisit les informations personnelles.</p>

                            <p>- Fournir les informations demandées sur le site et en garantir l’exactitude.</p>

                            <p>- Ne pas créer de fausse identité ou usurper l’identité d’un tiers.</p>
                            <p>- Informer immédiatement l’Editeur en cas de perte ou de vol de ses éléments d’identification (Téléphone portable ou Email)</p>

                            <p>- Autoriser l’Editeur et/ou ses Partenaires à lui transmettre des informations nécessaires à la bonne réalisation des services par le biais de sms ou emails.</p>

                            <p>- Honorer le rendez-vous réservé sur le Site.</p>

                            <p>- Déplacer ou annuler, en amont, le rendez-vous en cas d’impossibilité de l’honorer.</p>
                            <p>- Ne pas porter atteinte aux droits de l’Editeur ou de ses Partenaires, notamment en utilisant toute machine, robot ou autre moyen, susceptible de modifier, réacheminer, endommager, extraire, indexer toute page du Site;</p>
                            <p>- Ne pas pratiquer de reverse engineering, décompiler, désassembler, déchiffrer ou autrement tenter d’obtenir le code source en relation avec toute propriété intellectuelle sous-jacente utilisée pour fournir les Services ;</p>

                        </div>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 7 : Responsabilité</p>
                        <p>
                            Les sources des informations diffusées sur le Site sont réputées fiables mais le Site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br></br>
                            Les rendez-vous disponibles sur le Site et leurs motifs sont proposés par les Partenaires qui en assurent la saisie depuis leur Espace Partenaire.<br></br>
                            L’Editeur et ses Partenaires déclinent toute responsabilité en cas d’erreur de saisie qui aurait pour conséquence de ne pouvoir assurer la tenue du rendez-vous. <br></br>
                            L’Utilisateur est responsable des coordonnées qu’il saisit sur le Site. L’Editeur et ses Partenaires déclinent toute responsabilité en cas d’impossibilité de prendre les contacts nécessaires à la bonne réalisation du service du fait d’erreur(s) ou d’omission(s) dans la saisie des coordonnées par l’Utilisateur.<br></br>
                            La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.

                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 8 : CAS DE FORCE MAJEURE</p>
                        <p>
                            Aucune des Parties ne pourra être tenue pour responsable du retard de l’inexécution ou tout autre manquement à ses obligations prévues au Contrat, dès lors que cette défaillance résultera d’un cas de force majeure.<br></br>
                            Est considéré comme un cas de force majeure tout évènement ayant les caractères de la force majeure au sens de l’Article 1218 du Code Civil ou habituellement retenus par la jurisprudence.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">Article 9 : Collecte et protection des données à caractère personnel</p>
                        <p>
                            Conformément aux dispositions de la loi dite « informatique et liberté » du 6 janvier 1978, et conformément à la loi de réglementation Européenne entré en vigueur le 25/5/2018, Règlement Général pour la Protection des Données (RGPD) :
                        </p>
                        <br></br>
                        <p>L’Utilisateur est informé de ce que l’Editeur procède à des traitements automatisés de ses données personnelles, notamment lors de la prise d’un rendez-vous en ligne dans un contexte nécessitant la collecte de ces données personnelles.</p>
                        <br></br>
                        <p>Doivent être considérées comme données personnelles toutes les données étant susceptibles d’identifier un utilisateur.</p>
                        <br></br>
                        <p>Les informations nominatives que vous serez amené à fournir sur le Site ne pourront être utilisées que par l’Editeur et ses Partenaires.</p>
                        <p className='mini-title'>9.1. Traitements et finalités</p>
                        <p>L’Editeur collecte et exploite les informations personnelles de l’Usager dans l’optique de simplifier son accès à ses services et à ceux de ses Partenaires. Les données personnelles que l’Utilisateur fournit sur le Site ne sont pas transmises à des tiers externes à l’Editeur à l’exception des destinataires identifiés au 7.3 des présentes CGU.</p><br></br>
                        <p>
                            En prenant un rendez-vous en ligne, l’Utilisateur fournit des données personnelles.<br></br>
                            Ces données sont les suivantes : Nom, prénom, date de naissance, code postal, ville, numéro de pré-demande ANTS, adresse email et numéro de téléphone.<br></br>
                            Ces informations sont collectées pour les finalités suivantes :<br></br>
                            - Conserver les contenus ayant trait à l’existence d’un rendez-vous dans une collectivité et ce pour une durée illimitée.<br></br>
                            - Permettre aux Partenaires de communiquer avec les Utilisateurs<br></br>
                            - Permettre à l’Utilisateur d’accéder à son espace utilisateur en vue de consulter, modifier ou supprimer les rendez-vous pris.<br></br>
                            - Produire des statistiques sur la répartition et le volume des rendez-vous pris sur le Site.
                        </p>
                        <p className='mini-title'>9.2. Limitation et pertinence</p>
                        <p>
                        L’Editeur s’engage à limiter ses collectes aux données strictement nécessaires à la finalité du traitement envisagé, sans lesquelles il ne pourrait assurer le service du Site de manière qualitative.
                        </p>
                        <p className='mini-title'>9.3. Destinataires</p>
                        <p>Afin d’assurer la meilleure qualité de service possible, l’Editeur est conduit à communiquer les données collectées à différents destinataires, dûment habilités à cet effet, à savoir :</p><br></br>
                        <p><span className='underline'>Ses services internes</span> : administrateurs techniques et fonctionnels impliqués dans le développement, l’amélioration et la maintenance du Site.</p>
                        <p><span className='underline'>Ses Partenaires : </span> : <br></br></p>
                        <div>
                            <ul>
                                <li>Les collectivités locales</li>
                                <li>Mailjet : outil d’emailing utilisé pour transmettre à l’Utilisateur les codes uniques visant à s’identifier ou valider un rendez-vous, les informations relatives aux rendez-vous, les confirmations, modifications ou suppressions de rendez-vous. </li>
                                <li>Octopush : Outil d’envoi de SMS automatisés utilisé pour transmettre à l’Utilisateur les codes uniques visant à s’identifier ou valider une prise de rendez-vous, Envoyer 48h avant un rappel de rendez-vous </li>
                            </ul>
                        </div>
                        <p className='mini-title'>9.4. Conservation des données</p>
                        <p>Les données collectées sont stockées de manière sécurisée et seront systématiquement détruites après 3 ans jour pour jour.</p>
                        <p className="mini-title">9.5 Droit de l’Utilisateur sur les données le concernant</p>  
                        <p>
                            Toute modification des données personnelles est du droit de l’Utilisateur.<br></br>
                            L’utilisateur peut accéder et obtenir copie des données le concernant, s’opposer au traitement de ces données, les faire rectifier ou les faire définitivement effacer. L’utilisateur dispose d’un droit à la limitation du traitement de ses données.<br></br>
                            Pour toute demande d’exercice de ses droits sur ce traitement l’Utilisateur peut : <br></br>
                            Contacter le Site par voie électronique : contact@rdvenmairie.fr
                        </p>
                        <p className="mini-title">9.6 Sécurité</p>
                        <p>L’Editeur met en œuvre toutes les mesures techniques et organisationnelles utiles au regard de la nature des données à caractère personnel que l’Utilisateur lui confie, et des risques présentés par leur traitement – afin de préserver la sécurité de ses données et d’empêcher qu’elles soient déformées, détruites, endommagées, ou que des tiers non autorisés y aient accès.</p><br></br>
                        <p>À cette fin, l’Editeur met en place des mesures techniques telles que des pare-feux ; des mesures organisationnelles telles qu’un système d’identifiant et mot de passe unique pour chacun de ses collaborateurs, internes ou externes ; des moyens de protection physiques, etc.</p><br></br>
                        <p>
                            Le Site dispose d’un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.<br></br>
                            Un certificat SSL (« Secure Socket Layer » Certificate) a pour but de sécuriser les données échangées entre l’Utilisateur et le Site.
                        </p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">ARTICLE 10 – LIENS HYPERTEXTES ET COOKIES</p>
                        <p>Le Site contient un certain nombre de liens hypertextes vers d’autres sites (Partenaires, informations …). Cependant, l’Editeur n’a pas la possibilité de vérifier le contenu des sites ainsi visités  et décline donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites.</p><br></br>
                        <p>L’Utilisateur est informé que lors de ses visites sur le SIte, un ou des cookies sont susceptibles de s’installer automatiquement sur son ordinateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’Utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le Site.</p><br></br>
                        <p>
                            L’Editeur informe l’Utilisateur qu’il utilise des cookies de type « identifiants de session », pour la durée d’une session<br></br>
                            Par ailleurs, le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de refuser de la manière décrite à l’adresse suivante : https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur 
                        </p>
                        <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’Utilisateur peut toutefois configurer son ordinateur pour refuser l’installation des cookies. Il peut également les effacer de son navigateur après les avoir acceptés.</p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">ARTICLE 11 : Propriété intellectuelle</p>
                        <p>La structure du Site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de l’Editeur et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle.</p><br></br>
                        <p>Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par le Site, par quelque procédé que ce soit, sans l’autorisation préalable, expresse et écrite de l’Editeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle. Et ce, à l’exception des éléments expressément désignés comme libres de droits sur le site.</p><br></br>
                        <p>L’accès au Site ne vaut pas reconnaissance d’un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l’éditeur.</p><br></br>
                        <p>Il est interdit à l’utilisateur d’introduire des données sur le site qui modifieraient ou qui seraient susceptibles d’en modifier le contenu ou l’apparence ;</p>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">ARTICLE 12 : NON-RENONCIATION ET INDÉPENDANCE</p>
                        <p>Le fait pour l’une des Parties de ne pas faire appliquer l’un de ses droits ou de ne pas exiger l’exécution d’une quelconque des obligations ou responsabilités incombant à l’autre Partie, en vertu des CGU, ne pourra pas être considéré en soi comme renonciation par ladite Partie à ses droits, obligations et responsabilités découlant des présentes CGU.</p><br></br>
                        <p>Si une clause des présentes CGU s’avérait nulle au regard d’une règle de droit entrée en vigueur ou d’une décision judiciaire devenue définitive, elle serait alors réputée non-écrite, sans pour autant entraîner la nullité des présentes, ni altérer la validité des autres stipulations.</p><br></br>
                    </Stack>
                    <Stack marginTop={2}>
                        <p className="title">ARTICLE 13 : DROIT APPLICABLE ET JURIDICTION COMPETENTE </p>
                        <p>
                            La législation française s'applique aux présentes CGU. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.<br></br>
                            Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 2.
                        </p>
                    </Stack>
                </div>
        </Box>
    )
}