import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { Alert, Box, Grid, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import './Mairie.style.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { StepOne } from "./view/admin/step/stepOne";
import { StepThree } from "./view/admin/step/stepThree";
import { StepTwo } from "./view/admin/step/stepTwo";
import { ListeMairie } from "./view/liste/ListeMairie";
import { AddMairie } from "./view/superAdmin/AddMairie";
import { AddMairieAnnexe } from "./view/superAdmin/AddMairieAnnexe";
import { ListeMairieSuperAdmin } from "./view/superAdmin/ListeMairieSuperAdmin";
import { menuMairieSuperAdmin, menuMairieAdmin } from "./data/menu";
import { TabMenu } from "../../../../../application/components/TabMenu/TabMenu";
import { handleUpdateMairie } from "../../../../../helpers/mairie.helper";
import { uploadImage } from "../../../../../helpers/upload.helper";
import { API } from "../../../../../constant";
import { handlePutCalendar } from "../../../../../helpers/calendar.helper";
import { handlePutSocialMedia } from "../../../../../helpers/socialMedia.helper copy";
import { Parameter } from "./view/superAdmin/Parameter";
import { ListeMotif } from "../motifs/view/ListeMotif";
import { useAuthStore } from "../../../../../application/store/auth.store";
import { ExportAppointments } from "../agendas/view/ExportAppointment";
import { useMairieStore, refetchListMairies } from "../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    name: yup.string().min(2).required(),
    address: yup.string().min(2).required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][^6-7][0-9]+$/)
        .required(),
    email: yup.string().email()
});

const sousMenuMairie = [
    {
        name: 'Présentation Mairie',
        key: 'presentation'
    },
    {
        name: 'Horaires',
        key: 'horaire'
    },
    {
        name: 'Internet réseaux sociaux',
        key: 'internet'
    }
]

export const Mairie = () => {
    const  user  = useAuthStore((state) => state.user);
    const  mairies  = useMairieStore((state) => state.mairies);
    const  defaultMotif  = useMairieStore((state) => state.defaultMotif);
    const [profile, setProfile] = useState<any>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [fileProfile, setFileProfile] = useState<any>(null);
    const [activeSousMenu, setActiveSousMenu] = useState<string>(user?.role === 'SUPERADMIN' ? 'add_mairie': 'info_mairie');
    const [activeMenuMairie, setActiveMenuMairie] = useState<string>('presentation');
  
    const menus = useMemo(() => {
        if(user?.role === 'SUPERADMIN')
            return menuMairieSuperAdmin;
        else
            return menuMairieAdmin
    }, [user]);

    useEffect(() => {
        if(mairies[0]?.picture){
            setProfile(API+mairies[0]?.picture);
        }
    }, [mairies]);

    
    
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            name: mairies[0]?.name
        }
    });

    const handleChangeProfile = (e) => {
        setFileProfile(e.target.files[0]);
        setProfile(URL.createObjectURL(e.target.files[0]));
    }

    const onSubmitHandler = async(data) => {
        let input = {...data, city: data?.city.nom, _id: mairies[0]?._id};
        
        if(fileProfile){
            const resUpload = await uploadImage(fileProfile, mairies[0]?.slug, 'photos');
            if(resUpload?.success){
                input = {...input, picture: resUpload.data};
            }
        }
     
        const res = await handleUpdateMairie(input);
        if(res?.success){
            await handlePutCalendar({
                _id: mairies[0]?._id,
                hours: data.hours,
                holidays: data?.holidays
            })
            await handlePutSocialMedia({
                _id: mairies[0]?._id,
                facebook: data.facebook,
                twitter: data.twitter,
                instagram: data.instagram,
                url: data.url,
            })
            refetchListMairies();
            if(activeMenuMairie === 'presentation'){
                setActiveMenuMairie('horaire');
            }else if(activeMenuMairie === 'horaire'){
                setActiveMenuMairie('internet');
            }else{
                setSuccess(true)
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }, [success]);  

    const params = {
        register, 
        handleSubmit, 
        errors, 
        getValues, 
        setValue,
        control,
        mairies,
        onSubmitHandler
    }

    return (
        <>
            <TabMenu activeSousMenu={activeSousMenu} setActiveSousMenu={setActiveSousMenu} menus={menus}/>
            <Grid item xs={12}>
                {success && <Alert severity="success" className="mt-2">Mairie modifié avec succès!</Alert>}
            </Grid>
            {activeSousMenu === 'add_mairie' && <AddMairie setActiveSousMenu={setActiveSousMenu} />}
            {activeSousMenu === 'add_mairie_annexe' && <AddMairieAnnexe setActiveSousMenu={setActiveSousMenu} />}
            {activeSousMenu === 'info_mairie' && mairies?.length > 0 &&
            <Box className="box-mairie">
                <Grid container gap={2} justifyContent={' space-between'}>
                    <Grid item xs={3}>
                        <p className="fw-500 uppercase">Photo</p>
                        <div className="bloc-image">
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" onChange={handleChangeProfile} />
                                {profile ? <img className="profile-mairie" src={profile} width={"100%"} height={"100%"} alt={mairies[0]?.name} /> : <PhotoCamera />}
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div className="tab-mairie">
                            {sousMenuMairie.map((menu, key) => 
                                <div 
                                    key={key}
                                    className={activeMenuMairie === menu.key ? 'step-mairie uppercase active' : 'step-mairie uppercase'}
                                    onClick={() => setActiveMenuMairie(menu.key)}
                                >
                                    {menu.name}
                                </div>
                            )}
                        </div>
                        {activeMenuMairie === 'presentation' && <StepOne {...params} />}
                        {activeMenuMairie === 'horaire' && <StepTwo {...params} />}
                        {activeMenuMairie === 'internet' && <StepThree {...params} />}
                    </Grid>
                </Grid>
            </Box>}
            
            {activeSousMenu === 'liste_mairie' && 
                <>
                    {user?.role === 'SUPERADMIN'? <ListeMairieSuperAdmin /> : <ListeMairie mairies={mairies} />}
                </>
            }
            {activeSousMenu === 'sms_email_motif' && 
               <ListeMotif motifs={defaultMotif} />
            }
            {activeSousMenu === 'export_rdv' && 
               <ExportAppointments />
            }
            {activeSousMenu === 'parameter' && 
                <Parameter />
            }
        </>
    )
}