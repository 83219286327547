import axios from "axios"
import { API } from "../constant"

export const uploadImage = async (file, filename, folder) => {
    const form = new FormData();
    form.append('filename', filename);
    form.append('destination', folder);
    form.append('file', file);

    const response = (await axios({
        method: 'post',
        url: `${API}/upload`,
        data: form,
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    }))?.data;
    return response;

} 