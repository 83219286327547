import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import './Gestion.style.scss';
import { BREAKPOINT_TABLETTE } from "../../../constant";
import { useMemo, useState } from "react";
import { DetailsRdv } from "../DetailsRdv";
import { StepOneDeletion } from "./step/StepOneDeletion";
import { StepTwoDeletion } from "./step/StepTwoDeletion";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { DetailsUsager } from "../../../presentation/admin/usager/components/DetailsUsager";
import { CustomDialog } from "../CustomDialog";
import { useUsagerStore, handleGetAppointments, getUsager } from "../../store/usager.store";

export const BlocListRdv = () => {
    const [searchParams] = useSearchParams();
    const user = useUsagerStore(state => state.user);
    const appointments = useUsagerStore(state => state.appointments);
    const identity = useUsagerStore(state => state.identity);
    const uniqAppointment = useUsagerStore(state => state.uniqAppointment);
    const infoUser = useMemo(() => {
        return user
    }, [user]);
    const [appointment, setAppointment] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);
    const handleFilterChange = (event) => {}
    const [activeStep, setActiveStep] = useState(1);
    const groupedAppointment = useMemo(() => {
        const comingRdv: any = [];
        const oldRdv: any = [];
        if(appointments && appointments?.length > 0){
            appointments.map(appointment => {
                if(dayjs(appointment?.heureRdvDate).isAfter(dayjs())){
                    comingRdv.push(appointment);
                }else{
                    oldRdv.push(appointment);
                }
            })
        }
        return {
            comingRdv : comingRdv,
            oldRdv: oldRdv
        }
    }, [appointments])

    const handleCloseModal = () => {
        setOpen(false)
    }

    const appointmentUniq = useMemo(() => {
        const data = appointments?.find(appointment=> appointment?.id === uniqAppointment);
        return data;
    }, [appointments, uniqAppointment])

    return (
        <>
            {!appointment &&
                <Paper elevation={4} className={tablette ? 'p-2 m-auto' : 'p-8 m-auto'} sx={{
                    maxWidth: 1200,
                    maxHeight: '80vh',
                    overflow: 'auto'
                }}>
                    {user && <Stack className='info-usager'>
                        <p className='name mb-0'>{infoUser?.firstName} <span className='lastname'>{infoUser?.lastName}</span> </p>
                        <Box className='mt-2'>
                            <p className='phone'>Téléphone : 
                                <span className='text-blue'>{infoUser?.phoneNumber}</span>
                            </p>
                            <p className='text-title'>Adresse mail : <span className='text-blue'>{infoUser?.email}</span> </p>
                            <p className="mb-0">Code postal : <span className="bold-title">{infoUser?.city}</span></p>
                        </Box>
                        <div className='edit-usager'>
                            <EditIcon onClick={() => setOpen(true)}/>
                        </div>
                    </Stack>}
                    {!searchParams.get('canceled') ?  <>
                        <Box className={tablette ? "p-4 m-auto" : 'p-8 m-auto'} sx={{
                            maxWidth: 1024,
                        }}>
                            <p className="big-title-red p-2">Liste de vos rendez-vous à venir</p>
                            <Grid item xs={tablette ? 12 : 4} marginTop={tablette ? 2 : 0}>
                                <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                                    <Typography variant={"subtitle1"} marginLeft={2}><span className={'font-bold'}>Filtre</span></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            defaultValue={'farest'}
                                            onChange={handleFilterChange}
                                            size="small"
                                            renderValue={(selected: any) => {
                                                return <span>
                                                    Le plus tôt (Date)
                                                </span>
                                            }}
                                        >
                                            <MenuItem value={'nearest'}>Le plus tôt (Date)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            {groupedAppointment && groupedAppointment.comingRdv.length > 0 ? groupedAppointment.comingRdv.map((appointment: any, index: number) => 
                                <DetailsRdv key={index} appointment={appointment} setAppointment={setAppointment} handleGetAppointments={handleGetAppointments} identity={identity} type="coming"/>
                            ): <p className="text-empty">Vous n'avez aucun rendez-vous</p>}
                        </Box>
                        <Box className={tablette ? "p-4 m-auto" : 'p-8 m-auto'} sx={{
                            maxWidth: 1024,
                        }}>
                            <p className="big-title-red p-2">Liste de vos rendez-vous passés</p>
                            <Grid item xs={tablette ? 12 : 4} marginTop={tablette ? 2 : 0}>
                                <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                                    <Typography variant={"subtitle1"} marginLeft={2}><span className={'font-bold'}>Filtre</span></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            defaultValue={'farest'}
                                            onChange={handleFilterChange}
                                            size="small"
                                            renderValue={(selected: any) => {
                                                return <span>
                                                    Le plus tôt (Date)
                                                </span>
                                            }}
                                        >
                                            <MenuItem value={'nearest'}>Le plus tôt (Date)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            {groupedAppointment && groupedAppointment.oldRdv.length > 0 ? groupedAppointment.oldRdv.map((appointment: any, index: number) => 
                                <DetailsRdv key={index} appointment={appointment} setAppointment={setAppointment} handleGetAppointments={handleGetAppointments} identity={identity}  type="old" />
                            ): <p className="text-empty">Vous n'avez aucun rendez-vous</p>}
                        </Box>
                    </>:
                    <Box className={tablette ? "p-4 m-auto" : 'p-8 m-auto'} sx={{
                        maxWidth: 1024,
                    }}>
                        {appointmentUniq && <DetailsRdv appointment={appointmentUniq} setAppointment={setAppointment} handleGetAppointments={handleGetAppointments} identity={identity} type="coming" />}
                    </Box>}
            </Paper>}
            {appointment && 
                <Paper elevation={4} className={"m-auto"} sx={ {
                    maxWidth: 600,
                    maxHeight: '80vh',
                    overflow: 'auto'
                } }>
                    {activeStep === 1 && <StepOneDeletion appointment={appointment} setAppointment={setAppointment} setActiveStep={setActiveStep} />}
                    {activeStep === 2 && <StepTwoDeletion appointment={appointment} setAppointment={setAppointment} setActiveStep={setActiveStep} />}
                </Paper>
            }
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={<DetailsUsager usager={user} callback={getUsager} gestionAccount={true} />}
            />
        </>
    )
}
