import { Box, Link } from "@mui/material";
import './warning.style.scss';

const WarningPage = () => {
    return (
        <Box className="box-login">
            <Box className="div-warning">
                <p>Désolé le créneau n'est plus disponible.</p>
                <Link href="https://rendezvouspasseport.ants.gouv.fr/" target={'_blank'}>Merci de choisir un autre créneau</Link>
            </Box>    
        </Box>
    )
}

export default WarningPage;