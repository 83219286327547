import {createContext, useEffect, useState} from "react";
import {API} from "../../constant";
import axios, {AxiosInstance} from "axios";

export const AxiosContext = createContext({
    value: {
        token: '',
        authorized: false,
        instance: {} as AxiosInstance,
    },
    setValue: {} as any
});

export const AxiosProvider = ({children}) => {
    const [value, setValue] = useState({
        token: '',
        authorized: false,
        instance: {} as AxiosInstance,
    });

    useEffect(() => {
        setValue((prev: any) => {
            const instance = axios.create({ baseURL: API }) as AxiosInstance;
            Object.assign(instance.defaults, {}, {
                headers: {
                    common: {
                        'Authorization': `bearer ${prev.token}`
                    }
                }
            })
            return {
                ...prev,
                authorized: true,
                instance: { ...instance }
            }
        })

    }, [])

    return <AxiosContext.Provider value={{value, setValue}}>{children}</AxiosContext.Provider>;
}

export default AxiosContext
