import NODE_API from './axios.helper';

export const handleGetAppointmentUsager = async(data) => {
    try {
        const res = await NODE_API.post('/appointment/phone-email', data)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const handleGetAppointmentByUsagerId = async(usagerId: string) => {
    try {
        const res = await NODE_API.get('/appointment/usager/' + usagerId)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const handleGetUsager = async(data) => {
    try {
        const res = await NODE_API.post('/usagers/phone-email', data)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const handleGetUsagerById = async(_id: string) => {
    try {
        const res = await NODE_API.get('/usagers/' + _id)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const handleAddUsager = async(data) => {
    try {
        const res = await NODE_API.post('/usagers', data)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const handleUpdateUsager = async(data) => {
    try {
        const res = await NODE_API.put(`/usagers/${data._id}`, data)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}

export const generateTokenUsager = async(data) => {
    try {
        const res = await NODE_API.get('/usagers/token/'+data._id)
        if(res?.success){
            return res?.data
        }
    } catch (error) {
        
    }
}