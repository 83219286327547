import { useState } from "react";
import { Box } from "@mui/material";
import './Motif.style.scss';
import { TabMenu } from "../../../../../application/components/TabMenu/TabMenu";
import { menuMotifAdmin } from "./data/data";
import { AddMotif } from "./view/AddMotif";
import { ListeMotif } from "./view/ListeMotif";
import { CustomLoading } from "../../../../../application/components/LazyLoading/LazyLoading";
import { useMairieStore } from "../../../../../application/store/mairie.store";

export const Motifs = () => {
    const [activeSousMenu, setActiveSousMenu] = useState<string>('modify');
    const  motifs  = useMairieStore((state) => state.motifs);
    const  loading  = useMairieStore((state) => state.loading);

    return (
        <>
            <TabMenu activeSousMenu={activeSousMenu} setActiveSousMenu={setActiveSousMenu} menus={menuMotifAdmin}/>
            {loading && 
                <Box className="container m-auto flex justify-center mt-4">
                    <CustomLoading />
                </Box>
            }
            {!loading && <Box className="container m-auto">
                {activeSousMenu === 'add' && <AddMotif />}
                {activeSousMenu === 'modify' && <ListeMotif motifs={motifs}/>}
            </Box>}
        </>
    )
}