import { useEffect, useMemo, useState } from "react";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import '../Agent.style.scss';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormAgent } from "../form/FormAgent";
import { handleUpdateUser } from "../../../../../../helpers/user.helper";
import { useMairieStore, getMairieAgent } from "../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][0-9][0-9]+$/),
    email: yup.string().email(),
    password: yup
        .string()
        .required('Veuillez entrer votre mot de passe')
        .min(8, 'Your password is too short.'),
    confirmPassword: yup
        .string()
        .required('Veuillez retaper votre mot de passe')
        .oneOf([yup.ref('password')], 'Les mots de passe ne sont pas identiques')
    });


export const DetailsAgent = ({ agent, handleCloseModal }) => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const [mairieParent, setMairieParent] = useState<any>(null);
    const [success, setSuccess] = useState<Boolean>(false);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const listMairies = useMemo(() => {
        const array = [...mairies];
        if(mairies.length > 0){
            if(mairies[0]?.annexes){
                return array.concat(mairies[0]?.annexes)
            }   
        }
        return array;
    }, [mairies]);
    
    const params = {
        register, 
        errors,
        control,
        getValues,
        setValue,
        agent,
        mairieParent,
        setMairieParent,
        listMairies
    }

    useEffect(() => {
        if(agent){
            const mairie = listMairies.find((item) => item._id === agent.mairie);
            if(mairie){
                setMairieParent(mairie);
            }
        }
    }, [agent, listMairies])


    const onSubmitHandler = async (data) => {
        const input = {
            ...data,
            _id: agent._id,
            mairie: mairieParent._id,
        }
        try {
            const res = await handleUpdateUser(input, 'AGENT');
            if(res?.success){
                setSuccess(true);
                getMairieAgent(mairies);
                setTimeout(() => {
                    handleCloseModal(true)
                },1000)
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <Box>
            <Box className="mt-0 pb-5 px-5">
                <Grid container>
                    <Grid item xs={12} className="mt-2">
                        {success && <Alert severity="success">Agent modifié avec succès!</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <p className="mx-4 uppercase mb-2 underline font-bold">information de l'agent</p>
                    </Grid>
                    <Grid item xs={12} className="mt-5">
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <FormAgent {...params} />
                            <Stack className="div-btn-next padding-left-right">
                                <Button className="btn-next" variant={"contained"} color={"secondary"} type="submit">Modifier</Button>
                            </Stack>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}