import {Grid, Stack} from "@mui/material";
import { useEffect, useState} from "react";

import {CustomCalendar } from "./CustomCalendar";
import {BREAKPOINT_MOBILE} from "../../constant";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import { CustomLoading } from "./LazyLoading/LazyLoading";
import dayjs from "dayjs";
require('dayjs/locale/fr');

export const FullCalendarView = (props) => {
    const { item, onDateSelect } = props;
    const [loading, setLoading] = useState(false);
    const [calendarLists, setCalendarLists] = useState([] as any[]);
    const [today, setToday] = useState(dayjs().locale('fr'));
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);

    useEffect(() => {
        let count = 1;
        const todayDate = dayjs(today).clone().locale('fr');
        const calendar = [todayDate];
        while(count < (mobile ? 3 : 5)) {
            calendar.push(todayDate.add(count,'day'))
            count++;
        }
        setCalendarLists(calendar);
    }, [mobile, today]);

    return <>
        <Stack gap={2} alignItems={"center"} paddingX={mobile ? 0 : 4}>
            {/* <MairieCard 
                selected={selected}
                item={item} 
                detailView={true}
            /> */}
            {loading ? 
                <Grid item xs={12} marginTop={0} className="flex justify-center">
                    <CustomLoading size={100} /> 
                </Grid>:
                <CustomCalendar
                    calendarLists={calendarLists}
                    onDateSelect={onDateSelect}
                    availableHours={item.available_slots}
                    startDate={today}
                    setStartDate={setToday}
                />
            }
        </Stack>
    </>
}
