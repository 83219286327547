import NODE_API from "./axios.helper";

export const upsertSetting = async (data: any) => {
    try {
        let res: any = {};
        const input = {
            key: data?.key,
            limitNumberAnts: data?.limitNumberAnts,
            smsSeuilNumber: data?.smsSeuilNumber,
            smsAlertNumber: data?.smsAlertNumber,
            numberPersonToNotify: data?.numberPersonToNotify
        }

        if(data?._id){
            res = NODE_API.put(`/settings/`+data._id, input);
        }else{
            res = NODE_API.post(`/settings`, input);
        }
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetSettings = (data: any) => {
    try {
        const res = NODE_API.get(`/settings/key/`+data.key);
        return res;
    } catch (error) {
        console.log(error);
    }
}

