import { create } from 'zustand';
import { handleGetAppointmentUsager, handleGetUsager } from '../../helpers/usager.helper';
import { isEmpty } from 'lodash';

interface UsagerStoreType {
    user: any;
    identity: string,
    uniqAppointment: any,
    appointments: any,
    logged: boolean;    
}

type Actions = {
    reset: () => void;
    setUser: (value: any) => void;
    setLogged: (value: any) => void;
};

const initialState: UsagerStoreType = {
    user: localStorage.getItem('infoUser') ? JSON.parse(localStorage.getItem('infoUser') as string) : null,
    identity: localStorage.getItem('identityUsager') ?? '',
    logged: localStorage.getItem('access_token') ? true : false,
    uniqAppointment: null,
    appointments: [],
}

export const useUsagerStore = create<UsagerStoreType & Actions>()((set) =>  ({
    ...initialState,
    reset: () => {
        set(initialState)
    },
    setUser: (value) => set((state) => ({ user: value })),
    setLogged: (value) => set((state) => ({ logged: value}))
}))

export const handleChangeIdentity = (newIdentity) => {
    const user = useUsagerStore.getState().user;
    const identity = useUsagerStore.getState().identity;
    if(user?.email !== identity && user?.phoneNumber !== identity){
        useUsagerStore.setState(() => ({ user: {} }));
        useUsagerStore.setState(() => ({ identity: newIdentity }));
    }
}

export const handleLogout = () => {
    useUsagerStore.getState().reset();
    localStorage.removeItem('identityUsager');
    localStorage.removeItem('access_token');
    localStorage.removeItem('infoUsager');
}

export const handleGetAppointments = async(identity) => {
    let data: any = {};
    if(identity?.includes('@'))
        data = {email: identity}
    else
        data = {phoneNumber: identity}

    if(!isEmpty(data)){
        const result: any = await handleGetAppointmentUsager(data);
        useUsagerStore.setState(() => ({ appointments : result }));
    }
};

export const handleSetUniqAppointment = (id) => {
    useUsagerStore.setState(() => ({ uniqAppointment : id }));
}

export const getUsager = async(dataUser) => {
    const resultUsager = await handleGetUsager({
        phoneNumber: dataUser?.phoneNumber,
        email: dataUser?.email
    });
  
    if(resultUsager){
        useUsagerStore.setState(() => ({ user:resultUsager }));
        localStorage.setItem('infoUsager', resultUsager);
    }
}

