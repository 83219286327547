import { Box, Button, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import frLocale from 'date-fns/locale/fr';
import dayjs from "dayjs";
import { handleGetAnalytics, handleGetAnalyticsMonthly } from "../../../helpers/analytics";
import { isEmpty, round } from "lodash";
import { CustomLoading } from "../../../application/components/LazyLoading/LazyLoading";
import { ViewTableStat } from "./view-table-stat/ViewTableStat";
import './statistique.style.scss';
import { useMairieStore } from "../../../application/store/mairie.store";
import { Print } from "@mui/icons-material";
import { ExportTableToPDF } from "../../../infrastructure/utils/export-pdf-table";


dayjs.locale('fr')

export const Statistique = () => {
  const mairies = useMairieStore((state) => state.mairies);
  const [loading, setLoading] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<any>(null);
  const [analyticsMonthly, setAnalyticsMonthly] = useState<any>(null);
  const graphAnneeRef = useRef<ReactApexChart>(null)
  const [preSelectedFilter, setPreSelectedFilter] = useState('today');
  const [currentDatefilter, setCurrentDateFilter] = useState<any>({
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
  });
  const [filter, setFilter] = useState<any>({
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
    typeDossier: ''
  });

  const getAnalyticsData = useCallback(async () => {
    setLoading(true);
    const res = await handleGetAnalytics(mairies[0]?.slug,{
      startDate: dayjs().startOf('day').format('YYYY-MM-DD'),
      endDate: dayjs().endOf('day').format('YYYY-MM-DD'),
    });
    const resMonthly = await handleGetAnalyticsMonthly(mairies[0]?.slug);
    setLoading(false);
    setAnalyticsMonthly(resMonthly);
    setAnalytics(res);
  }, [mairies])

  useEffect(() => {
    getAnalyticsData();
  }, []);

  const analyticsStatus: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.status) && Object.keys(analytics?.status).map(key => {
      listDataRow.push({
        name: key,
        number: analytics?.status[key],
        percent: round((Number(analytics?.status[key]) * 100) / Number(analytics.total_include_canceled), 2)
      })
    })
    return {
      rows: listDataRow,
      series: [
        analytics?.status['A venir'.toUpperCase()] ?? 0,
        analytics?.status['Honoré'.toUpperCase()] ?? 0,
        analytics?.status['Non honoré'.toUpperCase()] ?? 0,
        analytics?.status['Annulé'.toUpperCase()] ?? 0,
        analytics?.status['Manque de document'.toUpperCase()] ?? 0,
      ],
      options: {
        chart: {
          width: 480,
          type: 'pie',
        },
        labels: ['A venir', 'Honoré', 'Non honoré', 'Annulé', 'Manque de document'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const analyticsTypeRDV: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.type) && Object.keys(analytics?.type).map(key => {
      listDataRow.push({
        name: key === 'online' ? 'En ligne' : 'Mairie',
        number: analytics?.type[key],
        percent: round((Number(analytics?.type[key]) * 100) / Number(analytics.total), 2)
      })
    })
    return {
      rows: listDataRow,
      series: [
        analytics?.type?.online ?? 0,
        analytics?.type?.mairie ?? 0,
      ],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['En ligne', 'En mairie'],
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const analyticsTypeDossier: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.typeDossier) && Object.keys(analytics?.typeDossier).map(key => {
      listDataRow.push({
        name: key,
        number: analytics?.typeDossier[key],
        percent: round((Number(analytics?.typeDossier[key]) * 100) / Number(analytics.total), 2)
      })
    })
    return {
      rows: listDataRow,
      series: !isEmpty(analytics?.typeDossier) ? Object.keys(analytics?.typeDossier).map(key => analytics?.typeDossier[key]) : [0, 0, 0, 0, 0, 0],
      options: {
        chart: {
          width: 480,
          type: 'pie',
        },
        labels: !isEmpty(analytics?.typeDossier) ? Object.keys(analytics?.typeDossier) : ['DEPOT_CNI', 'DEPOT_PASSEPORT', 'DEPOT_CNI_&_PASSEPORT', 'RETRAIT_CNI', 'RETRAIT_PASSEPORT', 'RETRAIT_CNI_&_PASSEPORT'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const analyticsDepartement: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.departement) && Object.keys(analytics?.departement).map(key => {
      listDataRow.push({
        name: key,
        number: analytics?.departement[key],
        percent: round((Number(analytics?.departement[key]) * 100) / Number(analytics.total), 2)
      })
    })
    return {
      rows: listDataRow,
      series: !isEmpty(analytics?.departement) ? Object.keys(analytics?.departement).map(key => analytics?.departement[key]) : [0, 0, 0, 0, 0, 0],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: !isEmpty(analytics?.departement) ? Object.keys(analytics?.departement) : [],
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const analyticsZip: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.zip) && Object.keys(analytics?.zip).map(key => {
      listDataRow.push({
        name: key,
        number: analytics?.zip[key],
        percent: round((Number(analytics?.zip[key]) * 100) / Number(analytics.total), 2)
      })
    })
    return {
      rows: listDataRow,
      series: !isEmpty(analytics?.zip) ? Object.keys(analytics?.zip).map(key => analytics?.zip[key]) : [0, 0, 0, 0, 0, 0],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: !isEmpty(analytics?.zip) ? Object.keys(analytics?.zip) : [],
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const analyticsCity: any = useMemo(() => {
    const listDataRow: any = [];
    !isEmpty(analytics?.city) && Object.keys(analytics?.city).map(key => {
      listDataRow.push({
        name: key,
        number: analytics?.city[key],
        percent: round((Number(analytics?.city[key]) * 100) / Number(analytics.total), 2)
      })
    })
    return {
      rows: listDataRow,
      series: !isEmpty(analytics?.city) ? Object.keys(analytics?.city).map(key => analytics?.city[key]) : [0, 0, 0, 0, 0, 0],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: !isEmpty(analytics?.city) ? Object.keys(analytics?.city) : [],
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  }, [analytics]);

  const dataMonthly: any = useMemo(() => {
    return {
      series: analyticsMonthly?.series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Statistique des RDV par année'
        },
        xaxis: {
          categories: analyticsMonthly?.categories,
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }
  }, [analyticsMonthly?.categories, analyticsMonthly?.series])

  const handleFilter = useCallback(async (newValue = filter) => {
    if (newValue?.startDate && newValue?.endDate) {
      setLoading(true);
      const res = await handleGetAnalytics(mairies[0]?.slug, newValue);
      setCurrentDateFilter({
        startDate: dayjs(newValue?.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(newValue?.endDate).format('YYYY-MM-DD'),
      })
      setLoading(false);
      setAnalytics(res);
    }
  }, [mairies, filter])

  const handleOnSelect = (event) => {
    const value = event.target.value || ''
    let newValue = {}
    switch(value) {
      case 'today':
        newValue = {
          startDate: dayjs().startOf('day').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('day').format('YYYY-MM-DD'),
        }
        break
      case 'current-week':
        newValue = {
          startDate: dayjs().startOf('week').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('week').format('YYYY-MM-DD'),
        }
        break
      case 'last-week':
        newValue = {
          startDate: dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
          endDate: dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
        }
        break
      case 'current-month':
        newValue = {
          startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
        }
        break
      case 'quarter':
        newValue = {
          startDate: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
          endDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        }
        break
      case 'current-year':
        newValue = {
          startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        }
        break
    }
    setFilter(newValue)
    setPreSelectedFilter(value)
    handleFilter(newValue)
  }

  const handlePrintClick = async ({ currentDatefilter }) => {
    const $motifs: any = document.getElementById('statistique-motifs')?.querySelector('table')
    const $departements: any = document.getElementById('statistique-departements')?.querySelector('table')
    const $codePostaux: any = document.getElementById('statistique-codes-postaux')?.querySelector('table')
    const $city: any = document.getElementById('statistique-city')?.querySelector('table')
    const $status: any = document.getElementById('statistique-status')?.querySelector('table')
    const $typeRdv: any = document.getElementById('statistique-type-rdv')?.querySelector('table')
    
    const date = `${dayjs(currentDatefilter.startDate).format('DD MMM')} au ${dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}`
    const tables = [
      { title: `Statistiques sur les types de dossier du ${date}`, html: $motifs },
      { title: `Statistiques sur les départements du ${date}`, html: $departements },
      { title: `Statistiques sur les codes postaux du ${date}`, html: $codePostaux },
      { title: `Statistiques sur les communes du ${date}`, html: $city },
      { title: `Statistiques sur les statuts de RDV du ${date}`, html: $status },
      { title: `Statistiques sur les types de RDV du ${date}`, html: $typeRdv },
    ]
    // const $graphAnnee: any = document.getElementById('statistique-graph-annee')
    // const graphAnnee_canvas = (await html2Canvas($graphAnnee)).toDataURL('image/png')
    let filename = `Rapport-du-${dayjs(currentDatefilter.startDate).format('DD-MMM-YYYY').replace('.', '')}`
    filename += `-au-${dayjs(currentDatefilter.endDate).format('DD-MMM-YYYY').replace('.', '')}`
    filename += `-${dayjs().format('HH-mm')}`
    
    ExportTableToPDF(`${filename}.pdf`, tables)
  }

  return (
    <>
      <Box marginTop={15} paddingLeft={2} paddingRight={2}>
        <Stack>
          <p>FILTRER PAR: </p>
          <Stack direction={'row'} gap={2} marginBottom={1} alignItems={'center'}>
            <Select value={preSelectedFilter} autoWidth size="small" onChange={handleOnSelect}>
              <MenuItem value={'today'}>Aujourd'hui</MenuItem>
              <MenuItem value={'current-week'}>Cette semaine</MenuItem>
              <MenuItem value={'last-week'}>Semaine dernière</MenuItem>
              <MenuItem value={'current-month'}>Mois courant</MenuItem>
              <MenuItem value={'quarter'}>Dernier trimestre</MenuItem>
              <MenuItem value={'current-year'}>Année {dayjs(filter.startDate).format('YYYY')}</MenuItem>
            </Select>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <DesktopDatePicker
                value={filter.startDate}
                onChange={(newValue) => {
                  setFilter(prevState => ({ ...prevState, startDate: newValue }))
                }}
                renderInput={(params) => <TextField {...params} size="small" sx={{ width: 200 }} />}
              />
            </LocalizationProvider>
            à
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <DesktopDatePicker
                value={filter.endDate}
                onChange={(newValue) => {
                  setFilter(prevState => ({ ...prevState, endDate: newValue }))
                }}
                renderInput={(params) => <TextField {...params} size="small" sx={{ width: 200 }} />}
              />
            </LocalizationProvider>
            <Button variant={"contained"} color={"secondary"} onClick={() => handleFilter({
              startDate: dayjs(filter.startDate).format('YYYY-MM-DD'),
              endDate: dayjs(filter.endDate).format('YYYY-MM-DD')
            })}>Filtrer</Button>
          </Stack>
        </Stack>
        {loading ? <Box marginTop={15} display={'flex'} justifyContent={'center'}>
          <CustomLoading />
        </Box> :
          <Grid container>
            <Grid item xs={12} style={{ maxWidth: '1200px', textAlign: 'right', margin: 'auto' }}>
              <Button onClick={() => handlePrintClick({ currentDatefilter })} variant="contained"><Print style={{ marginRight: '.25em' }} /> Imprimer</Button>
            </Grid>
            <Grid item xs={12}>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsTypeDossier.options} series={analyticsTypeDossier.series} type="pie" width={480} />
                  </Grid>
                  <Grid item xs={7} id={'statistique-motifs'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les types de dossier du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsTypeDossier?.rows} total={analytics?.total} />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsDepartement.options} series={analyticsDepartement.series} type="pie" width={380} />
                  </Grid>
                  <Grid item xs={7} id={'statistique-departements'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les départements du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsDepartement?.rows} total={analytics?.total} />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsZip.options} series={analyticsZip.series} type="pie" width={380} />
                  </Grid>
                  <Grid item xs={7} id={'statistique-codes-postaux'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les codes postaux du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsZip?.rows} total={analytics?.total} />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsCity.options} series={analyticsCity.series} type="pie" />
                  </Grid>
                  <Grid item xs={7} id={'statistique-city'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les villes du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsCity?.rows} total={analytics?.total} />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsStatus.options} series={analyticsStatus.series} type="pie" width={480} />
                  </Grid>
                  <Grid item xs={7} id={'statistique-status'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les statuts de RDV du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsStatus?.rows} total={analytics?.total_include_canceled} />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="div-stat">
                <Grid container>
                  <Grid item xs={5}>
                    <ReactApexChart options={analyticsTypeRDV.options} series={analyticsTypeRDV.series} type="pie" width={420} />
                  </Grid>
                  <Grid item xs={7} id={'statistique-type-rdv'}>
                    <Typography marginBottom={2} variant={"subtitle1"} className='text-center'>Statistiques sur les types de RDV du {dayjs(currentDatefilter.startDate).format('DD MMM')} au {dayjs(currentDatefilter.endDate).format('DD MMM YYYY')}</Typography>
                    <ViewTableStat rows={analyticsTypeRDV?.rows} total={analytics?.total} />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} id={'statistique-graph-annee'}>
              <ReactApexChart ref={graphAnneeRef} options={dataMonthly.options} series={dataMonthly.series} type="bar" height={430} />
            </Grid>
          </Grid>}
      </Box>
    </>
  )
}
