import { useMemo, useState } from "react";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import '../Agent.style.scss';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormAgent } from "../form/FormAgent";
import { handleAddUser } from "../../../../../../helpers/user.helper";
import { getMairieAgent, useMairieStore } from "../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    password: yup
        .string()
        .required('Veuillez entrer votre mot de passe')
        .min(8, 'Your password is too short.'),
    confirmPassword: yup
        .string()
        .required('Veuillez retaper votre mot de passe')
        .oneOf([yup.ref('password')], 'Les mots de passe ne sont pas identiques')
    });


export const AddAgent = ({ setActiveSousMenu }) => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const [mairieParent, setMairieParent] = useState<any>(null);
    
    const [error, setError] = useState<String>('');
    const [success, setSuccess] = useState<Boolean>(false);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const listMairies = useMemo(() => {
        const array = [...mairies];
        if(mairies.length > 0){
            if(mairies[0]?.annexes){
                return array.concat(mairies[0]?.annexes)
            }   
        }
        return array;
    }, [mairies]);
    
    const params = {
        register, 
        errors,
        control,
        getValues,
        setValue,
        mairieParent,
        setMairieParent,
        listMairies
    }

    const onSubmitHandler = async (data) => {
        const input = {
            ...data,
            mairie: mairieParent._id,
        }
        try {
            const res = await handleAddUser(input, 'AGENT');
            if(res?.success){
                setSuccess(true);
                getMairieAgent(mairies);
                setTimeout(() => {
                    setActiveSousMenu('modify')
                },1000)
            }else{
                setError(res?.data)
            }
        } catch (error: any) {
            console.log(error);
            setError('Une erreur est survenue lors de l\'ajout');
        }
    };

    return (
        <Box className="box-mairie">
            <Grid container>
                <Grid item xs={12}>
                    {success && <Alert severity="success">Agent ajouté avec succès!</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <p className="mx-4 uppercase mb-2 underline font-bold">information de l'agent</p>
                </Grid>
                <Grid item xs={12} className="mt-5">
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormAgent {...params} />
                        <Stack className="div-btn-next padding-left-right">
                            <Button className="btn-next" variant={"contained"} color={"secondary"} type="submit">Créer</Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}