import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import '../../Mairie.style.scss';
import { useForm } from "react-hook-form";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { getListToken } from "../../../../../../../helpers/token.helper";
import dayjs from "dayjs";
import { upsertSetting, handleGetSettings } from "../../../../../../../helpers/setting.helper";
require('dayjs/locale/fr');

const schema = yup.object().shape({
    limitNumberAnts: yup.number().required(),
    smsSeuilNumber: yup.number().required(),
    smsAlertNumber: yup.number().required(),
    numberPersonToNotify : yup.number().required(),
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#D2C9C2',
      color: '#000000',
      fontWeight: '700'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export const Parameter = () => {
    const [tokens, setTokens] = useState<any>([]);
    const [copySuccess, setCopySuccess] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const [settings, setSettings] = useState<any>(null);

    const getSetting = async() => {
        const res: any = await handleGetSettings({ key: 'SUPERADMIN' });
        if(res?.success){
            setSettings(res?.data);
        }else{
            setSettings({});
        }
    }

    useEffect(() => {
        getSetting();
    }, []);

    const getTokens = async() => {
        const res = await getListToken();
        if(res){
            setTokens(res);
        }
    }

    useEffect(() => {
        getTokens();
    }, []);

    const onSubmitHandler = async (data) => {
        const res = await upsertSetting({
            ...data,
            key: 'SUPERADMIN',
            _id: settings?._id
        })
        if(res?.success){
            setSuccess(true);
        }
    };

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copié!');
        } catch (err) {
            setCopySuccess('Erreur du copie!');
        }
    };

    useEffect(() => {
        setTimeout(() => {
          setCopySuccess('');
        }, 10000);
      }, [success]);
    

    return (
        <Box className="box-mairie">
            {copySuccess !== '' && <Alert severity="success" className="mt-2">{copySuccess}</Alert>}
            {success && <Alert severity="success" className="mt-2">Paramètre modifié avec succès!</Alert>}
            <p className="text-bold title-bold">Réglages et paramètres</p>
            {settings && <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container className="relative mt-4">
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <p className="mb-0">Nombre maximum d'élément à afficher sur l'api ANTS :</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }} >
                                    <OutlinedInput
                                        id="limitNumberAnts"
                                        type="number"
                                        defaultValue={settings?.limitNumberAnts}
                                        {...register("limitNumberAnts", { valueAsNumber: true, validate: (value) => value > 0 })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.limitNumberAnts ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('limitNumberAnts') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} marginTop={2}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <p className="mb-0">Seuil Sms :</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }} >
                                    <OutlinedInput
                                        id="smsSeuilNumber"
                                        type="number"
                                        defaultValue={settings?.smsSeuilNumber}
                                        {...register("smsSeuilNumber", { valueAsNumber: true, validate: (value) => value > 0 })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.smsSeuilNumber ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('smsSeuilNumber') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} marginTop={2}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <p className="mb-0">Alerte Sms :</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }} >
                                    <OutlinedInput
                                        id="smsAlertNumber"
                                        type="number"
                                        defaultValue={settings?.smsAlertNumber}
                                        {...register("smsAlertNumber", { valueAsNumber: true, validate: (value) => value > 0 })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.smsAlertNumber ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('smsAlertNumber') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} marginTop={2}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <p className="mb-0">Nombre de personne à notifier :</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ width: '100%' }} >
                                    <OutlinedInput
                                        id="numberPersonToNotify"
                                        type="number"
                                        defaultValue={settings?.numberPersonToNotify}
                                        {...register("numberPersonToNotify", { valueAsNumber: true, validate: (value) => value > 0 })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.numberPersonToNotify ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('numberPersonToNotify') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="btn-save-reset justify-end">
                        <Button className="btn-next" variant={"contained"} color={"secondary"} startIcon={<SaveIcon />} type="submit">Enregistrer</Button>
                        <Button className="btn-next" variant={"contained"} color={"primary"} onClick={() => null}>Annuler</Button>
                    </div>
                </Grid>
            </form>}
            <hr></hr>
            <p className="text-bold title-bold">Gestion token</p>
            <Stack flexDirection="row" marginTop={2}>
                <TableContainer className='table-container-custom'>
                    <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell width={150}>Token</StyledTableCell>
                                <StyledTableCell align="right">Date d'expiration</StyledTableCell>
                                <StyledTableCell align="right">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tokens.length > 0 && tokens.map((data) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" width={200}>
                                            <p className="mb-0 token-text">{data.token}</p>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {data.expiredAt && dayjs(data.expiredAt).locale('fr').format('DD/MM/YYYY à HH:mm')}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Tooltip title={"Copier le token"}>
                                                <IconButton onClick={() => copyToClipBoard(data.token)}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Modifier le token">
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Supprimer le token">
                                                <IconButton>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
    )
}
