import React, { useMemo } from "react";
import {Accordion, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import {blue, grey} from "@mui/material/colors";
import CalendarWarning from '../../../../application/assets/svg/warning.png';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {CircleRounded, ExpandCircleDown} from "@mui/icons-material";
import { TYPE_STATUS_MAPPING } from '../../../../infrastructure/data/type-status';
import dayjs from "dayjs";
import moment from "moment";
import capitalize from "lodash/capitalize";

const DuplicatedDateCard = ({data}) => <>
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandCircleDown />}
            sx={{ backgroundColor: grey[100] }}
        >
            <Stack>
                <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    <Typography>{data?.firstName} <b>{data?.lastName}</b></Typography>
                    {data?.duplicated.find(item =>  item.status === 'DONE') &&  <img src={CalendarWarning} width="20px" alt={"logo-duplicate"}/>}
                </Stack>
                <Typography sx={{ color: blue['400']}}>{data?.phoneNumber}</Typography>
                <Typography sx={{ color: blue['400']}}>{data?.email}</Typography>
                <Typography sx={{ color: blue['400']}}>{data?.typeDossier?.replace(/_/g,' ')}</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails>
            {/* { uniqBy(data?.duplicated, (v: any) => [v, v.mairie].join())?.map(entry => ( */}
            {data?.duplicated.map(entry => (
                <TimelineCard data={entry}/>
            ))}
        </AccordionDetails>
    </Accordion>
</>

const TimelineCard = ({data}) => <>
    <Timeline>
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: '12px 0' }}
                align="right"
                color="text.secondary"
            >
                <Typography fontSize={'small'}>{dayjs(data?.heureRdv).format('DD/MM/YYYY')}</Typography>
                <Typography fontSize={'small'}>{dayjs(data?.heureRdv).format('HH:mm')}</Typography>
                <Stack flexDirection={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                    {data?.status === 'DONE' &&  <img src={CalendarWarning} width="20px" alt={"logo-duplicate"}/>}
                    <Typography fontSize={'small'} sx={{ color: 'red' }}>
                        RDV {TYPE_STATUS_MAPPING[data?.status] }
                    </Typography>
                </Stack>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineConnector sx={{ backgroundColor: blue[100] }}/>
                <TimelineDot sx={{ backgroundColor: blue[100] }}>
                    <CircleRounded sx={{ color: blue[500] }} />
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: blue[100] }}/>
            </TimelineSeparator >
            <TimelineContent>
                <Typography variant="h6" component="span">
                    {capitalize(data?.mairie_json?.name)}
                </Typography>
                <Typography fontSize={'small'}>{data?.typeDossier?.replace(/_/g, ' ')} ({data?.nombrePersonne})</Typography>
                <Typography fontSize={'small'} color={'text.secondary'}>En ligne</Typography>
            </TimelineContent>
        </TimelineItem>
    </Timeline>
</>

export const DuplicatedDate = ({data}) => {
    const newData = useMemo(() => {
        let result: any = [];
        data.map((user: any) => {
            const duplicate = user?.duplicated;
            const startOfWeek = moment().startOf('week').toDate();
            const endOfWeek   = moment().endOf('week').toDate();
            const res = duplicate.filter(rdv => (dayjs(rdv?.heureRdv).isAfter(startOfWeek) && dayjs(rdv?.heureRdv).isBefore(endOfWeek)))
            if(res?.length > 0){
                result.push(user);
            }
        })
        return result;
    },[data])

    return <>
        <Stack sx={{ backgroundColor: 'white', height: '100%', padding: '1em 3em', borderRadius:'8px'}}>
            <Typography variant={'h5'}>Doublons potentiels (cette semaine)</Typography>
            <Typography variant={'caption'} className={'text-gray-400'}>Usager ayant au moins deux rdv pour les mêmes motifs dans les mairies du réseau</Typography>
            <Stack marginTop={4} sx={{ maxHeight: '400px', overflow: 'auto' }}>
                {newData && newData.map((entry: any, key: number) => (
                    <>
                      {entry?.duplicated.length > 0 && <DuplicatedDateCard key= {key} data={entry} />}
                    </>
                ))}
            </Stack>
        </Stack>
    </>
}
