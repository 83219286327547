import { Select } from '@mui/material';
import { makeStyles } from '@mui/styles';

import styled from 'styled-components';

export const useStyles: any = makeStyles(() => ({
  autoCompleteSiteWeb: {
    '& div': {
      '& button': {
        margin: '0',
      }
    },
    '& fieldset': {
      top: '0',
    }
  },
  multiSelectLabel: {
    '&[data-shrink=true]': {
      fontSize: '16px !important',
      top: '-20px !important',
      left: '0 !important',
    }
  },
  multiSelectContainer: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      background: '#FFFFFF',  
    },
    '& label': {
      fontSize: '13px',
      top: '-7px',
      left: '10px',
      zIndex: '1',
      '@media (max-width: 576px)': {
        top: '-10px',
      }
    },
  },
  multiSelectMenu: {
    top: '0',
  
    '& .MuiSelect-root': {
      fontSize: '13px',
    },
      
    '& .MuiSelect-select': {
      height: '28px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 576px)': {
        height: 'auto',
      }
    },
  
    '& .MuiSelect-icon': {
      right: '10px'
    }
  },
  multiSelectCk: {
    padding: '0 5px 0',
  },
  multiSelectLiItem: {
    '& .MuiTypography-body1': {
      fontSize: '13px',
    }
  },
  textField: {
    '& .MuiInputBase-root': {
      background: 'white !important'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingBottom: '6px',
      paddingTop: '6px'
    },
  },
  
}));

export const SelectCustom  = styled(Select)`
    background: white;
    fieldset::focus{
        border-color: #158a7e;
        border-width: 1px;
    }
`;