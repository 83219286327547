import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = (props: any) => {
    const _token: any = localStorage.getItem('access_token') || '';
    if (_token) {
        const {exp, role }: any = jwtDecode(_token);
        if (new Date().valueOf() >= exp * 1000) {
            localStorage.removeItem('access_token');
        }

        if(['SUPERADMIN', 'AGENT', 'ADMIN'].includes(role)){
            return props.children;
        }
        
    }else{
        if(props?.route === 'BO'){
            return <Navigate to="/login" />;
        }else{
            return <Navigate to="/gestion/login" />;
        }
    }
    
    
    return props.children;
};
