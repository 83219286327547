import {Button, Grid, Paper, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useSessionStorage} from "usehooks-ts";
import dayjs from "dayjs";
import { isEmpty } from 'lodash';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {handleGetMairie, handleGetMairieSlotTime} from "../../../helpers/mairie.helper";
import uuid from 'react-uuid';
import {SelectDemande} from "../../../application/components/SelectDemande";
import {CustomCalendar} from "../../../application/components/CustomCalendar";
import {RdvStepper} from "../../../application/components/RdvStepper";
import {CustomLoading} from "../../../application/components/LazyLoading/LazyLoading";
import {handleCheckBusy, handleUpSertBusy} from "../../../helpers/busy.helper";
import {Popup} from "../../../application/components/Popup/Popup";
import {Confirmed} from "../../pages/confirmation-rdv";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {REACT_APP_URL} from "../../../constant";
import { CalendarFull } from "../../../application/components/CalendarFull";
import { getMotifs } from "../../../application/store/auth.store";
import { GridWithTheme } from "./style";

export const IFrameCniPasseport = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useSessionStorage('session', {} as any);
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const [today, setToday] = useState(dayjs().locale('fr'));
    const [calendarLists, setCalendarLists] = useState([] as any[]);
    const {mairieId} = useParams()
    const [alert, setAlert] = useState(false);
    const [color, setColor] = useState({
        title: '#c84c51',
        button:  '#c84c51',
        background: 'grey',
        validatePuce:  '#c84c51',
        nonValidatePuce:  'grey'
    })
    const isIframe = useMemo(() => {
        return location.pathname.includes('iframe');
    }, [location.pathname]);

    useEffect(() => {
        if(session?.ville){
            getMotifs(session?.ville?.slug, isIframe)
        }
    }, [session.ville, isIframe]);

    const handleFormSubmit = (payload) => {
        setSession(prev => {
            return {...prev, ...payload, ville: {...prev.ville, ...payload.ville}, villeUser: payload.ville}
        })
    }

    const handleOnDateSelect = async (calendar, data, mairie) => {
        const [hours, minutes] = data.hour.split(':');
        const date = dayjs(calendar).hour(hours).minute(minutes);
        setSession(prev => ({
            ...prev,
            ville: mairie,
            type: data.type,
            bureau: data.idAgenda,
            heureRdv: dayjs(date).utc().local().format().split('+')[0],
            duration: data.duration,
            city: `${prev.villeUser?.codesPostaux}, ${prev.villeUser?.nom}`
        }));

        const isDispo = await handleCheckBusy({
            mairie: mairie._id,
            agenda: data.idAgenda,
            dateRdv: dayjs(calendar).format('DD/MM/YYYY'),
            heureRdv: date,
            sessionId: session?.id,
            duration: Number(data.duration)*Number(session.nombrePersonne),
        })

        if(isDispo){
            const res = await handleUpSertBusy({
                mairie: mairie._id,
                agenda: data.idAgenda,
                dateRdv: dayjs(calendar).format('DD/MM/YYYY'),
                heureRdv: date,
                sessionId: session?.id,
                duration: Number(data.duration)*Number(session.nombrePersonne),
            })
            if(res?.success){
                setActiveStep(2)
            }
        }else{
            setAlert(true);
        }
    }
    const handleModalCallback = () => {
        setActiveStep(1)
    }

    useEffect(() => {
        let count = 1;
        const todayDate = dayjs().locale('fr');
        const calendar = [todayDate];
        while(count < 6) {
            calendar.push(todayDate.add(count,'day'))
            count++;
        }
        setCalendarLists(calendar);
    }, [])

    useEffect(() => {
        if (activeStep === 1) {
            setLoading(true);
            const nombrePersonne = session.nombrePersonne;
            const startDate = dayjs().format('YYYY-MM-DD');
            const endDate = dayjs().add(1, 'year').format('YYYY-MM-DD');

            handleGetMairieSlotTime({
                slug: session?.ville?.slug,
                typeDossier: session?.typeDossier,
                isANTS: session?.ants,
                startDate,
                endDate,
                isOnline: true,
                nombrePersonne
            }).then(response => {
                setSession(prev => ({...prev, ville: {
                        ...prev.ville,
                        available_slots: response.available_slots
                    }}))
            }).finally(() => setLoading(false))
        }
    }, [activeStep, session.nombrePersonne, session?.ants, session?.typeDossier, session.ville?.slug, setSession]);

    useEffect(() => {
        setSession(prev => ({
            id: uuid()
        }));
    }, []);

    useEffect(() => {
        if (mairieId) {
            if (activeStep === 0) {
                handleGetMairie(mairieId)
                    .then(({data}) => {
                        if(data?.color)
                            setColor(data?.color);
                        setSession(prev => ({
                            ...prev,
                            ville: {...prev.ville, ...data}
                        }))
                    })
            }
        } else {
            navigate('/home');
        }
    }, [mairieId, activeStep]);

    return <>
        <GridWithTheme color={color} container justifyContent={'center'} paddingTop={4}>
            <Grid item xs={6}>
                <Typography align={'center'} variant={'h5'} style={{color: color.title}}>Prenez votre rendez-vous en mairie.</Typography>
                <Typography align={'center'} variant={'subtitle2'} marginBottom={4} style={{color: color.title}}>{session?.ville?.name}</Typography>
                <Stack  alignItems={'end'} marginBottom={2}>
                    <a href={`${REACT_APP_URL}/gestion/login`} target={'_blank'} rel="noreferrer">
                        <Button variant={"contained"} style={{background: color.button}}>
                            <span className={"font-bold"}><CalendarMonthIcon/> Gérer mes rendez-vous</span>
                        </Button>
                    </a>
                </Stack>
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepLabel>Motif du RDV</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Date</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Informations</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Validation</StepLabel>
                    </Step>
                </Stepper>
                <Stack alignItems={'center'} marginTop={4}>
                    { activeStep === 0 && <SelectDemande onSearch={handleFormSubmit} antsModalCallback={handleModalCallback}/>}
                    { activeStep === 1 && <>
                        <Typography variant={'h6'}><b>Date</b></Typography>
                        <Typography variant={'subtitle1'}>Sélectionnez la date et l'heure de votre demande de rendez-vous</Typography>
                        <Paper sx={{ padding: 2, marginTop: 2 }}>
                            {(!isEmpty(session.ville?.available_slots) && !loading) ? <>
                                <CustomCalendar
                                    calendarLists={calendarLists}
                                    availableHours={session?.ville?.available_slots}
                                    onDateSelect={(calendar, hour) => handleOnDateSelect(calendar, hour, session.ville)}
                                    startDate={today}
                                    setStartDate={setToday}
                                />
                            </>
                            : (isEmpty(session.ville?.available_slots) && !loading) ? <CalendarFull />
                            : <CustomLoading/> }
                        </Paper>
                    </>}
                    { activeStep === 2 && <>
                        <RdvStepper onSubmitSuccess={() => {
                            setActiveStep(3)
                            console.log('SUBMIT STEP 2')
                        }}/>
                    </>}
                    { activeStep === 3 && <>
                        <Confirmed showTopBar={false} isIFrame={true}/>
                    </>}
                </Stack>
            </Grid>
        </GridWithTheme>
        <Popup message="Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur. Veuillez choisir un autre 🙂" type="error" open={alert} handleClose={() => setAlert(false)} />
    </>
}
