import { Box } from "@mui/material";
import ErrorSvg from "../../assets/svg/401-Error.svg";

const ErrorPage = () => {
    return (
        <Box>
            <img src={ErrorSvg} width={"50%"} alt="Error" className="m-auto"/>
        </Box>
    )
}

export default ErrorPage;