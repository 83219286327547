import { useEffect } from 'react';
import '../styles/layout.style.scss';
import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";
import {Topbar} from "./";
import { useSessionStorage } from 'usehooks-ts';
import dayjs from 'dayjs';
import { getFilterdMairies, refetchListMairies } from '../store/mairie.store';

function Layout() {
    const [session, setSession] = useSessionStorage<any>('session', {});

    useEffect(() => {
        refetchListMairies();
      }, []);

    useEffect(() => {
        if(session?.ville && session?.ville?.coordinates && (session?.ants !== undefined)){
            const input = {
                ...session,
                startDate: dayjs().format('YYYY-MM-DD'),
                endDate: dayjs().add(12, 'month').format('YYYY-MM-DD'),
                ville : {
                    coordinates: session?.ville ? session?.ville?.coordinates : [],
                    zip: session.ville.code
                }
            }
            getFilterdMairies(input)
        }
    }, [session.ville, session?.ants])

    return (
        <>
            <Box>
                <Topbar/>
                <Outlet/>
            </Box>
        </>
    );
}

export default Layout;
