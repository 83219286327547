import { useEffect, useMemo, useState } from "react";
import { Alert, Box, Stack, TextField } from "@mui/material";
import '../../../../usager/Usager.style.scss';
import { ExportToExcel } from "../../../../../../application/components/ExportToExcel/ExportToExcel";
import dayjs from "dayjs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import PublishIcon from '@mui/icons-material/Publish';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TYPE_STATUS_MAPPING } from "../../../../../../infrastructure/data/type-status";
import { capitalize, groupBy, isEmpty } from "lodash";
import { REACT_APP_URL } from "../../../../../../constant";
import InputUpload from "../../../../../../infrastructure/components/widgets/InputUpload";
import { LoadingButton } from "@mui/lab";
import { useAuthStore } from "../../../../../../application/store/auth.store";
import { CustomDialog } from "../../../../../../application/components/CustomDialog";
import { DetailsConsumed } from "../../../../../../application/components/DetailsConsumed";
import { handleAddFileAppointment, handleGetStatus } from "../../../../../../helpers/ants.helper";
import { useMairieStore } from "../../../../../../application/store/mairie.store";
import { getAllAppointment, useAgendaStore } from "../../../../../../application/store/agenda.store";

export const ExportAppointments = () => {
    const  user  = useAuthStore((state) => state.user);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loadingAnts, setLoadingAnts] = useState<boolean>(false);
    const  appointments  = useAgendaStore((state) => state.appointments);
    const [range, setRange] = useState<any>({ startDate: dayjs().hour(1).minute(0), endDate: dayjs().hour(23).minute(59) });
    const [files, setFiles] = useState<any>([])
    const isSUPERADMIN = useMemo(() => user?.role === 'SUPERADMIN',[user?.role]);
    const [duplicates, setDuplicates] = useState<any>([]);
    const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);

    useEffect(() => {
        if(range?.endDate){
          getAllAppointment(range.startDate, range.endDate);
        }
    }, [range?.startDate, range?.endDate]);

    const handleImport = async() => {
        if(files?.length > 0){
            setLoading(true);
            await handleAddFileAppointment(files[0]);
            setLoading(false);
            setSuccess(true);
        }
        setTimeout(() => {
            setSuccess(false);
        }, 3000)
    }

    const columns = useMemo(() => {
        if(isSUPERADMIN){
            return ['application_id','management_url','meeting_point','appointment_date','editor_comment']
        }else{
            return ['Nom','Prénom','Téléphone','Email', 'Nombre de personne', 'Ville', 'Type de dossier', 'Statut', 'Durée', 'Heure de rendez-vous', 'Personnes concerncées']
        }
    }, [isSUPERADMIN])

    const dataToCheck = useMemo(() => {
        if(isSUPERADMIN){
            let resultsAntsToCheck: any = [];
            appointments.map((appointment) => {
                if(appointment?.status !== 'CANCELED'){
                    const mairie = mairies?.find(mairie => mairie?.slug === appointment?.mairie);
                    appointment?.concernedPerson.map((person) => {
                        resultsAntsToCheck.push({
                            application_id: person?.ants,
                            management_url: `${REACT_APP_URL}/gestion/login?ants=${person?.ants}&appointment_id=${appointment?._id}&canceled=true`,
                            meeting_point: capitalize(mairie?.name),
                            meeting_point_id: mairie?._id,
                            appointment_date: appointment?.heureRdv?.replace('T', ' '),
                            editor_comment: '',
                            appointment: appointment
                        })
                    })
                }
            })
            return resultsAntsToCheck
        }else{
            return [];
        }
    }, [appointments, isSUPERADMIN, mairies])

    const dataToExport = useMemo(() =>{
        if(isSUPERADMIN){
            let results: any = [];
            appointments.map((appointment) => {
                const mairie = mairies?.find(mairie => mairie?.slug === appointment?.mairie);
                appointment?.concernedPerson.map((person) => {
                     results.push({
                        application_id: person?.ants,
                        management_url: `${REACT_APP_URL}/gestion/login?ants=${person?.ants}&appointment_id=${appointment?._id}&canceled=true`,
                        meeting_point: capitalize(mairie?.name),
                        meeting_point_id: capitalize(mairie?._id),
                        appointment_date: appointment?.heureRdv?.replace('T', ' '),
                        editor_comment: '',
                     })
                })
            })
            return results;
        }else{
            return appointments.map((appointment) => {
                let concernedPersonne = '';
                appointment?.concernedPerson.map((person) => {
                    concernedPersonne += `${person?.lastName} ${person?.firstName} ${person?.ants} \n`; 
                })
                return {
                    lastName: appointment.usager?.lastName,
                    firstName: appointment.usager?.firstName,
                    phoneNumber: appointment.phoneNumber,
                    email: appointment.email,
                    nombrePersonne: appointment?.nombrePersonne,
                    city: appointment?.city,
                    typeDossier: appointment.typeDossier,
                    status: TYPE_STATUS_MAPPING[appointment?.status],
                    duree: `${appointment?.dureeRdv} mn`,
                    heureRdv: dayjs(appointment?.heureRdv).format('DD/MM/YYYY à HH:mm'),
                    concernedPersonne: concernedPersonne
                }
            })
        }
    },[appointments, isSUPERADMIN, mairies]);

    const handleCloseModalDuplicate = () => {
        setOpenDuplicate(false);
    }

    const handleCheckAnts = async() => {
        setLoadingAnts(true);
        const appointmentsMap = groupBy(dataToCheck, "meeting_point_id")
        const resAntsIdsStatus: any =  []
        for (const [meeting_point_id, application_ids] of Object.entries(appointmentsMap)) {
            const response = await handleGetStatus(application_ids, meeting_point_id);
            if (Array.isArray(response) && response?.length > 0) {
                resAntsIdsStatus.push(...response)
            } 
        }
        const duplicatesAnts: any = [];
        if(!isEmpty(resAntsIdsStatus)){
            Object.keys(resAntsIdsStatus).map(async(key) => {
                if(resAntsIdsStatus[key]?.status === 'consumed'){
                    const appointment = dataToCheck?.find(data => data?.application_id === key);
                    duplicatesAnts.push({application_id: key, ...appointment});
                }
            })
        }
        setDuplicates(duplicatesAnts);
        setOpenDuplicate(true);
        setLoadingAnts(false)
    }

    return (
        <Box className="box-mairie flex justify-between gap-2">
            <div className={isSUPERADMIN ? 'bloc-export bloc-export-full' : 'bloc-export'}>
                <p className="title-export">Exporter les rendez vous en format .xlsx</p>
                <span>Du :</span>
                <div className="flex items-center">
                    <div className="flex items-center gap-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                            <DesktopDatePicker
                                value={range.startDate}
                                minDate={dayjs()}
                                onChange={(newValue) => {
                                    setRange(prevState => ({...prevState , startDate:newValue}));
                                }}
                                renderInput={(params) => <TextField {...params} size="small" sx={{ width: 150 }} />}
                            />
                        </LocalizationProvider>
                        au
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                            <DesktopDatePicker
                                value={range.endDate}
                                minDate={dayjs()}
                                onChange={(newValue) => {
                                    setRange(prevState => ({...prevState , endDate:newValue}));
                                }}
                                renderInput={(params) => <TextField {...params} size="small" sx={{ width: 150 }} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className='mt-2'>
                    <p className="mb-0 bold">{dataToExport?.length} rendez-vous à exporter</p>
                </div>
                <div className="details-export">
                    {dataToExport?.length > 0 && <Stack flexDirection={'row'} justifyContent={'flex-end'} gap={1}>
                        <ExportToExcel apiData={dataToExport} fileName={`Liste des rendez-vous du ${dayjs(range.startDate).format('DD/MM/YYYY')} au  ${dayjs(range.endDate).format('DD/MM/YYYY')}`} headCells={columns} />
                        {isSUPERADMIN && <LoadingButton
                            variant={"contained"}
                            loading={loadingAnts} 
                            className="btn-next" 
                            color={"secondary"}
                            onClick={() => handleCheckAnts()}
                            type="submit"
                        >
                            Vérifier les numéros ANTS
                        </LoadingButton>}
                    </Stack>}
                </div>
            </div>
            {isSUPERADMIN && <div className="bloc-export bloc-export-full">
                {success && <Alert severity="success" className="mt-2">Vos rendez-vous ont été importé avec succès.</Alert>}
                <p className="title-export">Importer vers l'ANTS</p>
                <InputUpload files={files} setFiles={setFiles} />
                <div className="btn-save-reset padding-left-right">
                <LoadingButton
                    fullWidth 
                    variant={"contained"}
                    loading={loading} 
                    className="btn-next" 
                    color={"secondary"}
                    startIcon={<PublishIcon />} 
                    onClick={() => handleImport()}
                    type="submit"
                >
                        Importer
                </LoadingButton>
                </div>
            </div>}
            <CustomDialog
                setOpen={setOpenDuplicate}
                open={openDuplicate}
                onClose={handleCloseModalDuplicate}
                component={
                    <DetailsConsumed duplicates={duplicates} range={range} />}
            />
        </Box>
    )
}