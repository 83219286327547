import {Navigate, useRoutes} from "react-router-dom";
import {Layout} from "../layout";
import {LayoutBO} from "../layout";
import {ProtectedRoute} from "./Protected.routes";
import {HomePage} from "../../presentation/pages/home";
import {SearchPage} from "../../presentation/pages/search";
import {ConfirmationRdv, Confirmed, SummaryRdv} from "../../presentation/pages/confirmation-rdv";
import { Agenda } from "../../presentation/admin/agenda/Agenda";
import { AppConfiguration } from "../../presentation/admin/configuration/index";
import { Statistique } from "../../presentation/admin/statistique/Statistique";
import { Mairie } from "../../presentation/admin/configuration/components/mairie/Mairie";
import { Historique } from "../../presentation/admin/historique/Historique";
import { Login } from "../../presentation/pages/login/Login";
import { ListeUsager } from "../../presentation/admin/usager/view/ListeUsager";
import { AddUsager } from "../../presentation/admin/usager/view/AddUsager";
import { SousMenuUsager } from "../components/SousMenu/SousMenuUsager";
import { Agendas } from "../../presentation/admin/configuration/components/agendas/Agendas";
import { Agent } from "../../presentation/admin/configuration/components/agents/Agent";
import { Motifs } from "../../presentation/admin/configuration/components/motifs/Motifs";
import GestionLogin from "../../presentation/pages/gestion/view/GestionLogin";
import GestionRdv from "../../presentation/pages/gestion/view/GestionRdv";
import DashboardRDV from "../../presentation/admin/dashboard-rdv";
import { ImportExport } from "../../presentation/admin/usager/view/ImportExport";
import { SmsEmail } from "../../presentation/admin/configuration/components/sms-email/SmsEmail";
import { ProtectedAdminRoute } from "./ProtectedAdmin.routes";
import {IFrameCniPasseport} from "../../presentation/iframe/cni-passeport";
import { Sms } from "../../presentation/admin/configuration/components/sms-email/components/Sms";
import { Email } from "../../presentation/admin/configuration/components/sms-email/components/Email";
import { CGU } from "../../presentation/pages/cgu/CGU";
import Collectivity from "../../presentation/pages/collectivity/collectivity";

const AppRouter = () => {

    return useRoutes([
        {
            path: '/',
            element: <Layout/>,
            children: [
                {
                    path: 'home',
                    element: <HomePage/>
                },
                {
                    path: 'gestion',
                    children: [
                        {
                            path: 'login',
                            element: <GestionLogin/>
                        },
                        {
                            path: 'rdv',
                            element: <ProtectedRoute><GestionRdv/></ProtectedRoute>
                        },
                    ]
                },
                {
                    path: '/collectivity',
                    element: <Collectivity />,
                },
                {
                    path: 'search',
                    element: <SearchPage/>
                },
                {
                    path: 'confirmation-rdv',
                    element: <ConfirmationRdv/>
                },
                {
                    path: 'confirmation-rdv/summary',
                    element: <SummaryRdv/>
                },
                {
                    path: 'confirmation-rdv/confirmed',
                    element: <Confirmed/>
                },
                { index: true, element: <Navigate to={"/home"}/> }
            ]
        },
        {
            path: '/admin',
            element: <ProtectedRoute route='BO'><LayoutBO /></ProtectedRoute>,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardRDV/>
                },
                {
                    path: 'agenda',
                    element: <Agenda/>
                },
                {
                    path: 'usager',
                    element: <SousMenuUsager />,
                    children: [
                        // {
                        //     path: 'create_usager',
                        //     element: <AddUsager/>
                        // },
                        {
                            path: 'liste_usager',
                            element: <ListeUsager/>
                        },
                        {
                            path: 'import_export',
                            element: <ProtectedAdminRoute menu="export"><ImportExport/></ProtectedAdminRoute>
                        },
                        { index: true, element: <Navigate to={"/admin/usager/liste_usager"}/> }
                        // { index: true, element: <Navigate to={"/admin/usager/create_usager"}/> }
                    ]
                },
                {
                    path: 'configuration',
                    element: <ProtectedAdminRoute menu="space_configuration"><AppConfiguration/></ProtectedAdminRoute>,
                    children: [
                        {
                            path: 'mairie',
                            element: <Mairie/>
                        },
                        {
                            path: 'agendas',
                            element: <Agendas/>
                        },
                        {
                            path: 'agents',
                            element: <Agent/>
                        },
                        {
                            path: 'motifs',
                            element: <Motifs/>
                        },
                        { index: true, element: <Navigate to={"/admin/configuration/mairie"}/> }
                    ]
                    
                },
                {
                    path: 'statistique',
                    element: <ProtectedAdminRoute menu="statistiques"><Statistique/></ProtectedAdminRoute>
                },
                {
                    path: 'smsEmail',
                    element: <ProtectedAdminRoute menu="space_sms"><SmsEmail/></ProtectedAdminRoute>,
                    children: [
                        {
                            path: 'sms',
                            element: <Sms/>
                        },
                        {
                            path: 'email',
                            element: <Email/>
                        },
                        { index: true, element: <Navigate to={"/admin/smsEmail/sms"}/> }
                    ]
                    
                },
                {
                    path: 'historique',
                    element: <Historique/>
                },
                { index: true, element: <Navigate to={"/admin/agenda"}/> }
            ]
        },{
            path: '/login',
            element: <Login />,
        }
        ,{
            path: '/cgu',
            element: <CGU />,
        },
        {
            path: '/iframe/:mairieId',
            element: <IFrameCniPasseport/>
        },
    ])
}
export default AppRouter;
