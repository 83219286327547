import {Topbar} from "../../../application/layout";
import {Box, Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {ReactComponent as CheckBlackSvg} from "../../../application/assets/svg/check-blast.svg";
import {WarningRounded} from "@mui/icons-material";
import WarningIcon from '@mui/icons-material/Warning';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {PreviewConfirmationRdv} from "../../../application/components/PreviewConfirmationRdv";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import {useCallback, useContext, useEffect, useState} from "react";
import {BREAKPOINT_TABLETTE} from "../../../constant";
import keys from "lodash/keys";
import dayjs from "dayjs";
import AxiosContext from "../../../application/context/axios.context";
import {StepTwoDeletion} from "../../../application/components/Gestion/step/StepTwoDeletion";
import {StepOneDeletion} from "../../../application/components/Gestion/step/StepOneDeletion";
import './Confirmed.style.scss';
import {handleSearchApplicationIdANTS} from "../../../helpers/appointment.helper";
import {CustomLoading} from "../../../application/components/LazyLoading/LazyLoading";
import { getMotifsBySlug, useMairieStore } from "../../../application/store/mairie.store";
require('dayjs/locale/fr');

export const Confirmed = ({ showTopBar = true, isIFrame = false }) => {
    const  filteredMairies  = useMairieStore((state) => state.filteredMairies);
    const  TYPE_DOSSIERS  = useMairieStore((state) => state.TYPE_DOSSIERS);
    const [session] = useSessionStorage('session', {} as any);
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [appointment, setAppointment] = useState<any>(null);
    const [textEnd, setTextEnd] = useState<string>('');
    const [activeStep, setActiveStep] = useState(1);
    const [dataAppointment, setDataAppointment] = useState<any>([]);
    const { value: { instance: axios } } = useContext(AxiosContext);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);

    const getMotif = async(slug, typeDossier) => {
        if(slug && typeDossier){
            const res: any = await getMotifsBySlug(slug);
            if(res && res?.length > 0){
                const motif = res?.find(motif => motif.name === typeDossier);
                if(motif){
                    setTextEnd(motif.textEnd);
                }
            }
        }
    }

    useEffect(() => {
        getMotif(session?.ville?.slug, session?.typeDossier?.replaceAll('_',' '));
    }, [session]);

    useEffect(() => {
        const typeDossier = TYPE_DOSSIERS.find(v => v.key === session?.typeDossier);
        setData(prev => ({
            ...prev,
            ...session,
            typeDossier
        }));
    }, [filteredMairies, session]);

    const getDuplicateAppointment = useCallback(async() => {
        setLoading(true);
        const res = (await axios.post('/appointment/associated', {
            phoneNumber: session?.phoneNumber,
            typeDossier: session?.typeDossier
        }))?.data;
        let appointments: any = [];
        if(res && res?.success){
            const data = res?.data;
            if(data?.length > 0){
                appointments = appointments.concat(data);
            }
        }
        const application_ids = session.concernedPerson?.map(v => v.ants).filter(v => !!v);
        const ANTS_RESULTS = await handleSearchApplicationIdANTS(application_ids);
        if (ANTS_RESULTS) {
            keys(ANTS_RESULTS).forEach(ants => {
                const entries: any = ANTS_RESULTS[ants] ? ANTS_RESULTS[ants] : [];
                entries.forEach(entry => {
                    appointments.push({
                        mairie_json: { name: entry?.meeting_point },
                        heureRdv: dayjs(entry?.datetime),
                        management_url: entry?.management_url
                    })
                })
            })
        }
        setLoading(false);
        setDataAppointment(appointments);
    }, [session.concernedPerson, session?.phoneNumber, session?.typeDossier])

    useEffect(() => {
        getDuplicateAppointment();
    }, [getDuplicateAppointment]);

    return <>
        <Box sx={{width: '100%'}}>
            { showTopBar && <Topbar/> }
            <Stack
                className={"bg-white w-100 bloc-confirmed"}
                marginTop={ isIFrame ? 'unset' : '80px' }
                paddingX={tablette || isIFrame ? 3 : 17} paddingY={3}
                justifyContent={"center"} alignItems={"center"} gap={2}
            >
                <CheckBlackSvg/>
                <p className={"font-bold text-center uppercase text-2xl"}>Votre rendez-vous en mairie est confirmé.</p>
                <PreviewConfirmationRdv data={data} design={2} isIFrame={isIFrame} />
                {loading && <Stack flexDirection={'row'} justifyContent={'center'}>
                    <CustomLoading size={100} />
                </Stack>}
                {!loading && !appointment && dataAppointment.length > 1 && <Paper sx={{ width: (tablette || isIFrame) ? '100%': '60%' }}>
                    <div className="header-alert">
                        <p className={"font-bold text-center uppercase text-xl"}>
                            {dataAppointment.length <= 2 ? 'Vous avez un rendez-vous similaire' : 'Vous avez des rendez-vous similaires'}
                        </p>
                    </div>
                    <div className="bloc-text-alert">
                        <p className="text-alert">
                            <WarningIcon sx={{ fill: '#c84c51'}}/>
                            {dataAppointment.length <= 2 ?
                                'Nous vous remercions de vérifier qu\'il ne s\'agit pas d\'un doublon et procéder à son annulation le cas échéant.' :
                                'Nous vous remercions de vérifier qu\'il ne s\'agit pas d\'un doublon et procéder à leur annulation le cas échéant.'}
                        </p>
                        {dataAppointment.map((appointment, index) => {
                            const heureRdv = dayjs(appointment.heureRdv).locale('fr');
                            const isNew = dayjs(appointment.heureRdv).isSame(dayjs(session?.heureRdv));
                            return (
                                <Grid container marginTop={3} key={index}>
                                    <Grid item xs={0.5}>
                                        {isNew && <NewReleasesIcon sx={{ fill: 'orange' }} />}
                                    </Grid>
                                    <Grid item xs={tablette ? 12 : 3}>
                                        <Typography color={"primary"}><span className={"font-bold"}>Type de dossier</span></Typography>
                                        <p>{data?.typeDossier?.label ?? session?.typeDossier?.replaceAll('_',' ')} (x{appointment?.nombrePersonne})</p>
                                    </Grid>
                                    <Grid item xs={tablette ? 12 : 4}>
                                        <Typography color={"primary"}><span className={"font-bold"}>Date du rendez-vous</span></Typography>
                                        <p className={"capitalize"}>{heureRdv.format('dddd DD MMMM YYYY - HH:mm')}</p>
                                    </Grid>
                                    <Grid item xs={tablette ? 12 : 2.5}>
                                        <Typography color={"primary"}><span className={"font-bold"}>Lieu</span></Typography>
                                        <p className={"mb-0"}>{appointment.mairie_json?.name}</p>
                                    </Grid>
                                    <Grid item xs={tablette ? 12 : 2}>
                                        { appointment.management_url ?
                                            <Button
                                                variant="contained"
                                                color='primary'
                                                className="btn-rdv"
                                                target="_blank" href={appointment.management_url}
                                            >
                                                Annuler
                                            </Button> :
                                            <Button
                                                variant="contained"
                                                color='primary'
                                                className="btn-rdv"
                                                onClick={() => {
                                                    setAppointment(appointment)
                                                }}
                                            >
                                                Annuler
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </div>
                </Paper>}
                {appointment &&
                    <Paper elevation={4} className={"m-auto"} sx={ {
                        maxWidth: 600,
                        maxHeight: '80vh',
                        overflow: 'auto'
                    } }>
                        {activeStep === 1 && <StepOneDeletion appointment={appointment} setAppointment={setAppointment} setActiveStep={setActiveStep} />}
                        {activeStep === 2 && <StepTwoDeletion appointment={appointment} setAppointment={setAppointment} setActiveStep={setActiveStep}  refetchData={getDuplicateAppointment} />}
                    </Paper>
                }
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"start"} gap={2}>
                    <WarningRounded color={"error"}/>
                    <p className={"font-bold"}>INFORMATION IMPORTANTE :</p>
                </Stack>
                <Stack width={`${(tablette || isIFrame) ?'100%' : '50%'}`}>
                    <div dangerouslySetInnerHTML={{__html: textEnd}}/>
                </Stack>
            </Stack>
        </Box>
    </>
}
