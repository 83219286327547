import {Stack} from "@mui/material";
import { TimePicker } from 'antd';
import 'antd/dist/antd.min.css';
import dayjs from "dayjs";

interface PropsHour {
    changeHour: (data: any, type: string, index: string) => void;
    defaultValue: any;
    type: string;
    index: string;
    className?: any;
}

export const Hour = ({ changeHour, defaultValue , type, index, className}: PropsHour) => {
    let hour = defaultValue !== '-' ? defaultValue.split(':') : null;

    return (
        <Stack
            spacing={1}
            className={className}
        >
            <TimePicker 
                format={'HH:mm'} 
                className="input-time-picker"
                placeholder="-" 
                defaultValue={hour && dayjs().hour(Number(hour[0])).minute(Number(hour[1]))}
                onChange={(e) => changeHour(e, type, index)}
            />
        </Stack>
    )
}
