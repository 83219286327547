import dayjs from "dayjs";
import { Order } from "../../application/types/type";

export const generateRangeHours = ({ hourly, step, busy = [] as string[], date }) => {
    const hours: string[] = [];
    const day = dayjs(date).format('DD/MM');
    const today =  dayjs().format('DD/MM');
    const localTime = dayjs();
    const timeNow = dayjs().format('HH:mm');
    for (const time of hourly) {
        if (time?.startHour && time?.startHour !== '-' && time?.endHour && time?.endHour !== '-') {
            const startHourArray = time.startHour.split(':');
            const endHourArray = time.endHour.split(':');
            let startHour = localTime.hour(startHourArray[0]).minute(startHourArray[1]);
            const endHour = localTime.hour(endHourArray[0]).minute(endHourArray[1]);
            while(startHour?.isBefore(endHour) || startHour?.isSame(endHour)) {
                const hour = startHour.format('HH:mm');
                if(dayjs(date).isBefore(dayjs(), 'day')){
                    hours.push('-');
                }else {
                    if(day === today){
                        if(parseInt(hour) < parseInt(timeNow)){
                            hours.push('-');
                        }else{
                            if (busy && busy.indexOf(hour) > -1) {
                                hours.push('-')
                            } else {
                                hours.push(hour)
                            }
                        }
                    }else{
                        if (busy && busy.indexOf(hour) > -1) {
                            hours.push('-')
                        } else {
                            hours.push(hour)
                        }
                    }
                }
                startHour = startHour.add(step, 'minutes');
            }
        }
    }
    if (hours.length === 0) {
        let startHour = localTime.hour(8).minute(0);
        const endHour = localTime.hour(12).minute(0);
        while(startHour?.isBefore(endHour) || startHour?.isSame(endHour)) {
            hours.push('-')
            startHour = startHour.add(step, 'minutes');
        }
    }
    return hours;
}

export const checkStatus = (data) => {
    switch (data) {
        case 'PENDING':
            return 'A venir';
        case 'DONE':
            return 'Honoré';
        case 'CANCELED':
            return 'Annulé';
        case 'NOT_HONORED':
            return 'Non honoré';
        case 'DOCUMENT_MISSED':
            return 'Manque de document';
        default:
            return 'A venir';
    }
}

export const convertPhone = (phone) => {
    const phoneNumber = phone.trim('').replace(/\s/g, '').slice(1, 10);
    return `+33${phoneNumber}`;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
  
  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const concatHours = (firstHours, secondHours) =>{
    const newHours = firstHours;
    secondHours.map((hour) =>{
        if(hour !== '-'){
            const isExistInFirst = newHours.find(time =>  time === hour);
            if(!isExistInFirst){
                newHours.push(hour);
            }
        }
    })
    return newHours;
}

export const getMarks = () => {
    let marks: any = [];
    for(let i = 60; i<= 300;){
        marks.push({
            value: i,
            label: `${i}km`
        });
        i += 40;
    }
    return marks;
};
