import { Box, Button, Stack } from "@mui/material";
import IconSendSvgError from '../../../../../../application/assets/svg/icon-send-error.svg';

export const ConfirmSendSmsError = ({ handleClose }) => {
    const handleConfirm = () => {

    }
    return (
        <Box className="p-3">
            <div className="flex justify-center mb-2">
              <img src={IconSendSvgError} width={80} alt="Logo"/>
            </div>
            <p>Malheureusement le nombre de sms à envoyer est supérieur au nombre de sms restant.</p>
            <Stack flexDirection={"row"} justifyContent="center" gap={2} marginTop={2}>
                <Button variant={'contained'} color={'secondary'} onClick={handleConfirm}>
                    Commander SMS
                </Button>
                <Button 
                    variant="contained" 
                    color='primary' 
                    className="btn-rdv btn-reset btn-icon"
                    onClick={handleClose}
                >
                    Modifier la liste
                </Button>
            </Stack>
        </Box>
    )
}