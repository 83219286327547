import NODE_API from './axios.helper';

export const handleLogin = async ({username, password}:any) => {
    try {
        const res = NODE_API.post('/auth/login', {
            username,
            password
        });

        return res;
    } catch (error) {
        console.log(error);
    }
}