import {Topbar} from "../../../application/layout";
import React, {useEffect, useState} from "react";
import {Box, Grid, Stack} from "@mui/material";
import {PreviewConfirmationRdv} from "../../../application/components/PreviewConfirmationRdv";
import {ConfirmRdv} from "../../../application/components/ConfirmRdv";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import {BREAKPOINT_TABLETTE} from "../../../constant";
import isEmpty from "lodash/isEmpty";
import { useMairieStore } from "../../../application/store/mairie.store";

export const SummaryRdv = () => {
    const  filteredMairies  = useMairieStore((state) => state.filteredMairies);
    const [session] = useSessionStorage('session', {} as any);
    const [data, setData] = useState({});
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);

    useEffect(() => {
        const ville = filteredMairies.find(v => v.slug === session?.ville.slug);
        const typeDossier = session.motif;
        setData(prev => ({
            ...prev,
            ...session,
            ville,
            typeDossier
        }));
    }, [filteredMairies, session]);


    return <>
        {!isEmpty(data) && <Box sx={{width: '100%'}}>
            <Topbar variant={"condensed"}/>
            <Stack marginLeft={tablette ? 0 : 10} marginTop={tablette ? 4 : 13}>
                <Grid container>
                    <Grid item xs={tablette ? 12 : 6} marginY={5} padding={5}>
                        <Stack gap={3}>
                            <PreviewConfirmationRdv data={data} design={1}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={tablette ? 12 : 5}>
                        <Stack className={"bg-neutral-100 h-full"} alignItems={"center"} justifyContent={"center"}>
                            <ConfirmRdv/>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>}
    </>
}
