import {
    Alert,
    FormControl,
    InputAdornment,
    OutlinedInput,
    Paper,
    Stack,
} from "@mui/material";
import * as yup from "yup";
import {useMediaQuery} from "usehooks-ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BREAKPOINT_MOBILE } from "../../../constant";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import './Gestion.style.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { UserVerification } from "../UserVerification";
import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { generateTokenUsager } from "../../../helpers/usager.helper";
import { getInfoUserByAnts } from "../../../helpers/user.helper";
import NODE_API from "../../../helpers/axios.helper";
import AxiosContext from "../../context/axios.context";
import { isEmpty } from "lodash";
import { refetchListMairies } from "../../store/mairie.store";
import { LoadingButton } from "@mui/lab";
import debounce from "lodash/debounce";
import { useUsagerStore, handleChangeIdentity, handleGetAppointments, handleSetUniqAppointment } from "../../store/usager.store";

const schema = yup.object().shape({
    ants: yup.boolean(),
    identity: yup.string().when("ants", {
        is: true,
        then: yup.string().email(),
      }),
});

export const BlocLogin = (props) => {
    const location = useLocation();
    const searchParams: any = new URLSearchParams(location.search.replace(/&amp;/g, "&"));
    const ants = searchParams.get("ants");
    const appointment_id = searchParams.get("appointment_id");
    const canceled = searchParams.get("canceled");
    const user = useUsagerStore(state => state.user);
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [ui, setUi] = useState({
        isFormValid: false,
        sendingOTP: false,
        hasError: false
    })
    const [otpRequestToken, setOtpRequestToken] = useState<string>();
    const [dataUser , setDataUser] = useState<any>({
        email: null,
        phoneNumber: null,
        verificationType: 'EMAIL',
        mairie: null
    })
    const { register, watch, handleSubmit, formState: { errors }, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const [loading, setLoading] = useState(false);
    const { value: { instance: axios } } = useContext(AxiosContext);
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const identity = watch('identity');

    const sendBackendRequest = useCallback((value: string) => {
        handleChangeIdentity(value)
        setLoading(false);
    }, []);
    
    const debouncedSendRequest = useMemo(() => {
        return debounce(sendBackendRequest, 1000);
    }, [sendBackendRequest]);
    
    const generateOtp = async(info) => {
        await NODE_API.post('/otp/generate', {
            phoneNumber: info.phoneNumber,
            email: info.email,
            verificationType: info.verificationType,
            mairie: info?.mairie
        }).then((res) => {
            if (res?.success) {
                setOtpRequestToken(res?.data?.otp_request_token)
                setUi(prev => ({...prev, isFormValid: true}));
            }else{
                setUi(prev => ({...prev, sendingOTP: false }));
            }
        }).catch(error => console.log(error))
        .finally(() => {
            setUi(prev => ({...prev, sendingOTP: false }));
        })
    }

    const onSubmitHandler = async(data) => {
        let email = null;
        let phoneNumber = null;
        if(data.identity.includes('@')){
            email = data.identity;
        }else{
            phoneNumber = data.identity;
        }
        setDataUser(prevState => ({
            ...prevState,
            email,
            phoneNumber  
        }))
        generateOtp({
          email: email,
          phoneNumber : phoneNumber,
          verificationType: 'EMAIL',
          mairie: null
        })
        localStorage.setItem('identityUsager', data.identity);
    }

    const handleOtpValid = async() => {
        if(!isEmpty(user)){
            const token = await generateTokenUsager({
                _id: user._id        
            })
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            localStorage.setItem('access_token', token);
            refetchListMairies();
            if(user?.email){
                handleGetAppointments(user?.email);
            }else{
                handleGetAppointments(watch('identity'));
            }

            if(canceled){
                navigate('/gestion/rdv?canceled=true');
            }else{
                navigate('/gestion/rdv');
            }
        }else{
            setUi(prevState => ({...prevState, hasError: true}))
        }
    }

    useEffect(() =>{
        if(appointment_id){
            handleSetUniqAppointment(appointment_id);
        }
    }, [appointment_id]);

    const getInfoByAnts = useCallback(async() => {// Référence à la variable ants
      const user = await getInfoUserByAnts(ants); // Utilisation de la référence
      if(user?.length > 0){
        const infoUser = user[0];
        handleChangeIdentity(infoUser.email);
        setDataUser(prevState => ({
          ...prevState,
          email: infoUser.email,
          phoneNumber : infoUser.phoneNumber ,
          verificationType: infoUser.agendaParameter[0].type,
          mairie: infoUser?.mairieId
        }))
        generateOtp({
          email: infoUser.email,
          phoneNumber : infoUser.phoneNumber ,
          verificationType: infoUser.agendaParameter[0].type,
          mairie: infoUser?.mairieId
        })
        localStorage.setItem('identityUsager', infoUser.email);
      }
    }, [ants])

    useEffect(() => {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      getInfoByAnts();
    }, [getInfoByAnts])

    useEffect(() => {
        if(identity){
            setLoading(true);
            if(identity.includes('@')){
                setDataUser(prevState => ({
                    ...prevState,
                    email: identity
                }))
            }else{
                setDataUser(prevState => ({
                    ...prevState,
                    phoneNumber: identity
                }))
            }
            debouncedSendRequest(identity)
        }
    }, [identity])

    return (
        <Paper elevation={4} className={mobile ? 'p-3' : 'p-12'} sx={ {
            maxWidth: 600,
            width: 'auto'
        } }>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <input hidden {...register(`ants`, { value: Boolean(ants) })}  />
                <p className="big-text">Gérer mes rendez-vous</p>
                {!ants && <Stack className="mt-4">
                    <FormControl className="custom-form-control">
                        <OutlinedInput
                            className={"bg-white"}
                            size="small"
                            type="email"
                            placeholder={'Adresse mail'}
                            {...register("identity")}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors.identity ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues('identity') && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>}
                { ui.hasError && <Alert severity="error" className="mt-2">Merci de prendre un rendez-vous pour accéder au gestionnaire de compte.</Alert> }
                {(ui.isFormValid || ants) && <Stack className="mt-8">
                    <p className="font-bold uppercase m-0">Vérification</p>
                    <UserVerification loading={loading} email={dataUser?.email} phoneNumber={dataUser?.phoneNumber} onValid={handleOtpValid} otpToken={otpRequestToken} verificationType={dataUser?.verificationType}/>
                </Stack>}
                {!ui.isFormValid && <Stack className="mt-5">
                    {!ants && <LoadingButton loading={loading} disabled={loading} fullWidth variant={"contained"} type="submit">Suivant</LoadingButton>}
                </Stack>}
            </form>
        </Paper>
    )
}
