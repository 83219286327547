import React, { useEffect, useMemo } from 'react';
import '../styles/layout.style.scss';
import {AppBar, Box, Button, Grid, Menu, MenuItem} from "@mui/material";
import {ReactComponent as LogoSvg} from "../assets/svg/logo-rdv.svg";
import {ReactComponent as LogoInversedSvg} from "../assets/svg/logo-inversed.svg";
import {HelpOutline} from "@mui/icons-material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {Link, useNavigate} from "react-router-dom";
import {useMediaQuery} from "usehooks-ts";
import {BREAKPOINT_MOBILE} from "../../constant";
import { useUsagerStore, handleLogout, handleGetAppointments, getUsager } from '../store/usager.store';

function Topbar(props) {
    const {variant = 'default'} = props;
    const navigate = useNavigate();
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const user = useUsagerStore(state => state.user);
    const identity = useUsagerStore(state => state.identity);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleGoHome = () => {
        handleLogout();
        navigate('/gestion/login')
    }

    const text_redirect = useMemo(() => {
        let text= 'Gérer mes rendez-vous';
        if(identity && localStorage.getItem('access_token')){
            text = 'Se déconnecter'
        }
        return text;
    }, [identity]);

    const handleGoToRdv = () => {
        navigate('/gestion/rdv');
    }

    const userHeaderMobile = useMemo(() => {
        if(identity && localStorage.getItem('access_token')){
            return <LogoutIcon sx={{ fill: '#c74d51' }} />
        }else{
           return <CalendarMonthIcon sx={{ fill: '#c74d51' }} />
        }
    }, [identity])

    useEffect(() => {
        if(localStorage.getItem('infoUsager')) 
            useUsagerStore.getState().setUser(JSON.stringify(String(localStorage.getItem('infoUsager'))))
    }, [])

    useEffect(() => {
        if(identity && user?.email !== identity && user?.phoneNumber !== identity){
            handleGetAppointments(identity);
            if(identity?.includes('@')){
                getUsager({ email: identity, phoneNumber: '' })
            }else{
                getUsager({ phoneNumber: identity, email: '' })
            }
        }
    },[identity, user?.email, user?.phoneNumber])
    
    return (
        <>
            <AppBar
                position="fixed"
                elevation={3}
            >
                <Grid container display="flex" alignItems={"center"} bgcolor={variant === 'condensed' ? 'primary' : 'white'} height={mobile ? 60 : 85} justifyContent="center">
                    <Grid item xs={mobile ? 6 : 4} display="flex" justifyContent={mobile ? 'center': 'start'} alignItems="center">
                        <Box className={"flex relative cursor-pointer"} onClick={() => navigate('/')}>
                            {variant === 'condensed' ? <LogoInversedSvg />: <LogoSvg/>}
                        </Box>
                    </Grid>
                    
                        <Grid item xs={6} display="flex" justifyContent={"end"} alignItems="center">
                            <Box className={"space-x-5 mx-3"}>
                                {identity && localStorage.getItem('access_token') ?
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant={"contained"} 
                                        color={variant === 'condensed' ? 'inversePrimary' : 'primary'}
                                        onClick={handleClick}
                                    >
                                        Mon compte
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleGoToRdv}>Mes rendez-vous</MenuItem>
                                        <MenuItem onClick={handleGoHome}>Se déconnecter</MenuItem>
                                    </Menu>
                                </>
                                : <Link to='/gestion/login'>
                                    {!mobile ? <Button variant={"contained"} color={variant === 'condensed' ? 'inversePrimary' : 'primary'}>
                                        <span className={"font-bold"}>{text_redirect}</span>
                                    </Button>:
                                        <span className='mx-2'>{userHeaderMobile}</span>
                                    }
                                </Link>}
                                {!mobile && 
                                <>
                                    <Link target={'_blank'} to='/collectivity' style={{ color: '#FFFFFF' }}>
                                        <Button variant={"text"} color={variant === 'condensed' ? 'inherit' : 'primary'}>
                                            <Box className={"flex flex-col"} alignItems={"center"}>
                                                <AccountBalanceIcon/>
                                                <span>Collectivités</span>
                                            </Box>
                                        </Button>
                                    </Link>
                                    
                                    <Button variant={"text"} color={variant === 'condensed' ? 'inherit' : 'primary'}>
                                        <Box className={"flex flex-col"} alignItems={"center"}>
                                            <HelpOutline/>
                                            <span>Besoin d'aide ?</span>
                                        </Box>
                                    </Button>
                                </>
                                }
                            </Box>
                        </Grid>
                    
                </Grid>
            </AppBar>

        </>
    );
}

export default Topbar;
