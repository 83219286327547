import NODE_API from "./axios.helper";
import axios from "axios";
import queryString from 'query-string';
import { API, API_ANTS } from "../constant";

export const handleUpdateAppointment = async(data) => {
    try {
        const res = NODE_API.put('/appointment/'+data._id,{
            ...data,
            heureRdv: data.heureRdv && data.heureRdv?.slice(0,16) + ":00"
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const updateAppointment = async(data:any) => {
    const res = (await axios.put(API + '/appointment/' + data._id, {
        ...data
    }))?.data; 
    if(res?.data){
        const resHistorie = (await axios.post(API + '/histories', {
            phoneNumber: res.data.phoneNumber,
            appointmentId: data._id,
            metadata: {
                status: res.data.status,
                mairie: data.mairie,
                typeDossier: data.typeDossier,
                heureRdv: data.heureRdv && data.heureRdv?.slice(0,16) + ":00",
                nombrePersonne: data.nombrePersonne,
                remarque: data?.comment,
                agent: data?.agent,
                usagerId: data?.usager?._id
            }
        }))?.data;
        if(resHistorie?.data){
            return res?.data
        }
    }
    return null;
};

export const handleGetAppointment = async(data) => {
    try {
        const res = NODE_API.get('/appointment/'+data.id)
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetCanceledAppointment = async(slug: string) => {
    try {
        const res = await NODE_API.get(`/appointment/canceled-date/${slug}`);
        return res
    } catch (error) {
        console.log(error);
    }
}

export const handleGetAppointmentBy = async(slug, heureRdvDate) => {
    try {
        const res = await NODE_API.post('/appointment/searchBy' , {
            mairie : slug,
            heureRdvDate : heureRdvDate
        });
        if(res?.success){
            return res?.data
        }
        return null;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetDuplicatedAppointment = async(slug: string) => {
    try {
        const res = await NODE_API.get(`/appointment/duplicate-date/${slug}`);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetAntsStatus = async(slug: string, type: string) => {
    try {
        const res = (await axios.get(API + `/appointment/ants-date/${slug}?status=${type}`,{
            headers: {
                'Content-Type': 'application/json',
                'x-hub-rdv-auth-token': 'GjuHffCgqNPp8EG8MDwn'
            }
        }))?.data;
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetContactAppointment = async(slug: string, query: any) => {
    try {
        return await NODE_API.get(`/appointment/contact/${slug}`, {
            filter_date: query.filter_date,
            agenda: query.agenda,
            motif: query.motif,
        });
    } catch (error) {
        console.log(error);
    }
}

export const handleSearchApplicationIdANTS = async(application_ids: string[]) => {
    const query = queryString.stringify({application_ids: [...application_ids]});
    const headers = {
        'Content-Type': 'application/json',
        'x-hub-rdv-auth-token': 'GjuHffCgqNPp8EG8MDwn'
    }
    try {
        const res = (await axios.get(API_ANTS+'api/searchApplicationIds?' + query, { headers }))?.data; 
        return res ?? {}; 
    } catch (error) {
        console.log(error)
        return {};
    }
}

