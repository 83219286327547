import styled from 'styled-components';


export const TitleHome = styled.p<any>`
    color: #FFFFFF;
    text-align: center;
    font-size: ${(props:any) => props.type?.mobile ? '1.5rem': '3rem'};
    line-height: 3rem;
`;

export const TitleHomeTop = styled(TitleHome)`
    font-size: ${(props:any) => props.type?.mobile ? '3rem': '4rem'};
    font-weight: 600;
`;
