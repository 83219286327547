import '../../../../application/styles/home.style.scss';
import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import {ReactComponent as LogoSvg} from "../../../../application/assets/svg/logo-inversed.svg";
import {useMediaQuery} from "usehooks-ts";
import {BREAKPOINT_MOBILE, BREAKPOINT_TABLETTE} from "../../../../constant";
import { BlocLogin } from '../../../../application/components/Gestion/BlocLogin';

const GestionLogin = () => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE)
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE)

    return (
        <Box sx={{width: '100%'}}>
            <div className={"header-1-container px-4 w-full"}>
                <Grid container>
                    <Grid item xs={12} md={7} sx={{ margin: 'auto !important' }}>
                        <Stack className='box-logo'>
                            <LogoSvg />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box zIndex={3} marginTop={mobile ? 0: 5}>
                            <BlocLogin />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    )
}

export default GestionLogin;
