import jwtDecode from "jwt-decode";
import ErrorPage from "../components/ErrorPage";

export const ProtectedAdminRoute = (props: any) => {
    const _token: any = localStorage.getItem('access_token') || '';
    if (_token) {
        const {exp }: any = jwtDecode(_token);
        if (new Date().valueOf() >= exp * 1000) {
            localStorage.removeItem('access_token');
        }
    }
    const { access, role }: any = jwtDecode(_token);
    if (role === 'AGENT' && !access[props.menu]) {
        return <ErrorPage />;
    }

    return props.children;
};
