import {Box, Button, Stack} from "@mui/material";
import {useCallback, useState} from "react";
import IconSendSvg from '../../../../../../application/assets/svg/icon-send.svg';
import {CustomDialog} from "../../../../../../application/components/CustomDialog";
import {ConfirmSendSmsError} from "./ConfirmSendSmsError";
import {LoadingButton} from "@mui/lab";
import {NotificationSendSMS, NotificationSendSmsMultiple} from "../../../../../../helpers/notification.helper";
import {ConfirmSendSmsSuccess} from "./ConfirmSendSmsSuccess";

export const ConfirmSendSms = ({ handleClose, messages, recipients, mairieId }) => {
    const [modalSmsError, setModalSmsError] = useState(false);
    const [modalSmsSuccess, setModalSmsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        setModalSmsError(false)
        setModalSmsSuccess(false)
    }

    const handleConfirm = useCallback(() => {
            setLoading(true)
            let request = NotificationSendSMS;
            if (recipients.length > 1) {
                request = NotificationSendSmsMultiple
            }
            request({
                to: recipients,
                messages,
                mairieId,
                metadata: { type: 'exception_sms' }
            }).then(() => {
                setModalSmsError(false)
                setModalSmsSuccess(true)
                setTimeout(() => {
                    handleClose();
                }, 3000)
            }).catch(() => {
                setModalSmsError(true)
                setModalSmsSuccess(false)

            }).finally(() => {
                setLoading(true)
            })
        },
        [recipients],
    );

    return (
        <Box className="p-3">
            <div className="flex justify-center mb-2">
                <img src={IconSendSvg} width={80} alt="Logo"/>
            </div>
            <p>Vous allez envoyer <b>{recipients.length}</b> sms, confirmez-vous l’envoi ?</p>
            <Stack flexDirection={"row"} justifyContent="center" gap={2} marginTop={2}>
                <LoadingButton loading={loading} variant={'contained'} color={'secondary'} onClick={() => handleConfirm()} disabled={recipients.length < 0}>
                    Confirmer
                </LoadingButton>
                <Button
                    variant="contained"
                    color='primary'
                    className="btn-rdv btn-reset btn-icon"
                    onClick={handleClose}
                    disabled={loading}
                >
                    Annuler
                </Button>
            </Stack>
            <CustomDialog
                setOpen={setModalSmsError}
                open={modalSmsError}
                onClose={handleCloseModal}
                component={<ConfirmSendSmsError handleClose={handleCloseModal} />}
            />
            <CustomDialog
                setOpen={setModalSmsSuccess}
                open={modalSmsSuccess}
                onClose={handleCloseModal}
                component={<ConfirmSendSmsSuccess />}
            />
        </Box>
    )
}
