import { Box } from "@mui/material";
import { useState } from "react";
import { TabMenu } from "../../../../../application/components/TabMenu/TabMenu";
import { menuAgentAdmin } from './data/menu';
import './Agent.style.scss';
import { AddAgent } from "./view/AddAgent";
import { ListeAgent } from "./view/ListeAgent";

export const Agent = () => {
    const [activeSousMenu, setActiveSousMenu] = useState<string>('add');

    return (
        <>
            <TabMenu activeSousMenu={activeSousMenu} setActiveSousMenu={setActiveSousMenu} menus={menuAgentAdmin}/>
            <Box className="container m-auto">
                {activeSousMenu === 'add' && <AddAgent setActiveSousMenu={setActiveSousMenu}/>}
                {activeSousMenu === 'modify' && <ListeAgent />}
            </Box>
        </>
    )
}