import {Box, Grid, Stack} from "@mui/material";
import capitalize from "lodash/capitalize";
import {useMemo} from "react";
import {ReactComponent as TownHallSvg} from "../../assets/svg/town-hall.svg";
import './details.style.scss';
import {API, BREAKPOINT_MOBILE, GMAP_KEY} from "../../../constant";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useMediaQuery } from "usehooks-ts";

const DetailsInfoMairie = ({ mairie }) => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const hoursDays = useMemo(() => {
        if(mairie?.calendar?.hours)
            return mairie?.calendar?.hours;
        return null;
    }, [mairie])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GMAP_KEY
    })

    const center = useMemo(() => {
        return {
            lng: mairie.longitude,
            lat: mairie.latitude
        }
    }, [mairie]);

    const mairieInfo = useMemo(() => {
        return [
            {name: 'Tél :', value: mairie.phoneNumber},
            {name: 'Courriel :', value: mairie.email},
            {name: 'Site web :', value: `<a href="${mairie.website}" target="_blank">${mairie?.website ?? mairie?.socialMedia?.url}</a>`},
            {name: 'Adresse :', value: mairie?.public_entry_address ?? mairie?.address},
        ]
    }, [mairie]);

    const socialMedias = useMemo(() => {
        return [
            {name: 'Facebook', value: mairie?.socialMedia?.facebook},
            {name: 'Instagram', value: mairie?.socialMedia?.instagram},
            {name: 'Twitter', value: mairie?.socialMedia?.twitter},
        ]
    }, [mairie]);

    return (
        <Box className="box-info-mairie">
            <Grid container spacing={2}>
                <Grid item xs={mobile ? 12: 4}>
                    <p className="title-mairie">{mairie.name}</p>
                    <Stack>
                        {(mairie?.city_logo || mairie?.picture) ? <img className="profile-mairie-preview" src={mairie?.city_logo ?? API+mairie?.picture} width={"100%"} height={"100%"} alt={mairie.name} /> : <TownHallSvg/>}
                    </Stack>
                    <Stack marginTop={5}>
                        <p className="text-hour">Horaires d'ouverture</p>
                        {hoursDays && Object.keys(hoursDays).map(day =>
                            <>
                                {(hoursDays[day][1]['startHour'] !== '-' || hoursDays[day][0]['startHour'] !== '-') &&
                                    <Stack>
                                        <p className="font-bold">Le {capitalize(day)}: </p>
                                        <p>{hoursDays[day][0]['startHour'] !== '-' && `${hoursDays[day][0]['startHour']}h à ${hoursDays[day][0]['endHour']}h`}  {hoursDays[day][1]['startHour'] !== '-' && hoursDays[day][0]['startHour'] !== '-' && '-'}  {hoursDays[day][1]['startHour'] !== '-' && `${hoursDays[day][1]['startHour']}h à ${hoursDays[day][1]['endHour']}h`}</p>
                                    </Stack>
                                }
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={mobile ? 12: 8}>
                    <Stack marginTop={5}>
                        <p>
                            { mairie.description }
                        </p>
                    </Stack>
                    <Stack className="box-info-adress">
                        <Grid container>
                            <Grid item xs={mobile? 12 :10}>
                                {mairieInfo.map((info) =>
                                    <Grid container>
                                        <Grid item xs={mobile ? 4 : 3}>
                                            <p className="title-adress">{info.name}</p>
                                        </Grid>
                                        <Grid item xs={mobile ? 8: 6}>
                                            <div dangerouslySetInnerHTML={{__html: info.value}}/>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={mobile? 12 : 2}>
                                { socialMedias.map(social => {
                                    if (social.value) {
                                        switch (social.name) {
                                            case 'Facebook':
                                                return <a href={social.value} target={"_blank"}><FacebookIcon style={{color: '#1877F2'}}/></a>
                                            case 'Instagram':
                                                return <a href={social.value} target={"_blank"}><InstagramIcon style={{color: '#E4405F'}}/></a>
                                            case 'Twitter':
                                                return <a href={social.value} target={"_blank"}><TwitterIcon style={{color: '#1DA1F2'}}/></a>
                                        }
                                    }
                                    return <></>
                                })}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack>
                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={{height:'30vh', width:'100wh'}}
                                center={center}
                                zoom={18}
                            >
                                { /* Child components, such as markers, info windows, etc. */}
                                <Marker position={center} />
                            </GoogleMap>
                        ) : <></>}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DetailsInfoMairie;
