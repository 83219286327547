import React from 'react'
import * as FileSaver from "file-saver";
import { utils, write } from "xlsx";
import { Button } from '@mui/material';

export const ExportToExcel = ({ apiData, fileName, headCells }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = utils.json_to_sheet(apiData);
    utils.sheet_add_aoa(ws, [headCells], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button 
        variant="contained" 
        color='secondary'
        onClick={(e) => exportToCSV(apiData, fileName)}
    >
        Exporter
    </Button>
  );
};