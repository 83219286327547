import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomDialog } from "../../../../../../application/components/CustomDialog";
import { AddAgenda } from "./AddAgenda";
import TableSorting from "../../../../../../application/components/table-sorting";
import {
    getComparator,
    stableSort,
} from "../../../../../../infrastructure/utils";
import { Order } from "../../../../../../application/types/type";
import { CustomLoading } from "../../../../../../application/components/LazyLoading/LazyLoading";
import {
    refetchListAgendas,
    useMairieStore,
} from "../../../../../../application/store/mairie.store";
import { handleDeleteAgenda } from "../../../../../../helpers/agenda";
import { useAuthStore } from "../../../../../../application/store/auth.store";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3D3D3D",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#D2C9C2",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const headCells: readonly any[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        filter: true,
        label: "Nom",
    },
    {
        id: "motifs",
        numeric: true,
        disablePadding: false,
        filter: false,
        label: "Motifs",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        filter: false,
        label: "Action",
    },
];

export const ListeAgenda = () => {
    const agendas = useMairieStore((state) => state.agendas);
    const user = useAuthStore((state) => state.user);
    const mairies = useMairieStore((state) => state.mairies);
    const loading = useMairieStore((state) => state.loading);
    const [open, setOpen] = useState(false);
    const [agenda, setAgenda] = useState<any>(null);
    const [rows, setRows] = useState<any>(agendas);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<any>("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {
        order,
        setOrder,
        orderBy,
        setOrderBy,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        setRows(agendas);
    }, [agendas]);

    const handleDelete = async (row) => {
        Swal.fire({
            title: "Attention",
            text: "Voulez-vous vraiment supprimer cette agenda?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Annuler",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await handleDeleteAgenda(row);
                if (res?.success) {
                    refetchListAgendas({ mairies, user });
                    Swal.fire(
                        "Supprimé!",
                        "L'agenda a été supprimé avec succès.",
                        "success"
                    );
                } else {
                    Swal.fire("Erreur!", res?.error, "error");
                }
            }
        });
    };

    return (
        <Box className="box-mairie">
            <Grid container className="grid-mairie">
                {loading && <CustomLoading />}
                {!loading && (
                    <TableContainer component={Paper}>
                        <TableSorting
                            rows={rows}
                            headCells={headCells}
                            paramsSorting={paramsSorting}
                            setRows={setRows}
                            originalRows={agendas}
                        >
                            <>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row: any, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {row?.motifs.map(
                                                        (motif) => (
                                                            <p>{motif.name}</p>
                                                        )
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <TuneIcon
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            setAgenda(row);
                                                            setOpen(true);
                                                        }}
                                                    />
                                                    <DeleteForeverIcon
                                                        className="btn-delete"
                                                        onClick={() =>
                                                            handleDelete(row)
                                                        }
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </>
                        </TableSorting>
                    </TableContainer>
                )}
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={
                    <AddAgenda
                        agenda={agenda}
                        handleCloseModal={handleCloseModal}
                    />
                }
            />
        </Box>
    );
};
