import NODE_API from "./axios.helper";

export const handlePostDefaultMotif = async(data) => {
    try {
        const res = NODE_API.post('/default-motif',{
            name: data.name,
            isDefault: true,
            confirm : data.confirm,
            cancel: data.cancel,
            update: data.update,
            reminder: data.reminder,
            release: data.release
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handlePutDefaultMotif = async(data) => {
    try {
        const res = NODE_API.put('/default-motif/'+data._id,{
            name: data.name,
            isDefault: true,
            confirm : data.confirm,
            cancel: data.cancel,
            update: data.update,
            reminder: data.reminder,
            release: data.release
        })
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleGetDefaultMotif = async() => {
    try{
        const res = await NODE_API.get('/default-motif')
        if(res?.success){
            return res?.data
        }
    }catch (error) {
        console.log(error);
    }
}