import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSessionStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import './Modal.style.scss';

export const ModalAnts = ({ open, handleClose, nombrePersonne, callback }) => {
  const handleChoiceAnts = () => {
    callback({ ants: true })
  }

  const goToAntsPage = () => {
    window.open('https://passeport.ants.gouv.fr/', '_blank');
  }

  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box-ants">
          <Typography id="modal-modal-title" className="bold" variant="h6">
            Pré-demande ANTS
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La réalisation d'une pré-demande sur le site de l'ANTS est désormais une étape préalable à la prise de rendez-vous de demande de titre en ligne.
          </Typography>
          <br></br>
          <Typography id="modal-modal-title" className="bold" variant="subtitle1">
            IMPORTANT: Sans numéro de pré-demande. vous ne pourrez pas prendre de rendez-vous en ligne
          </Typography>
          <Box className="box-btn">
            <Button className="w-full btn-next" variant={"contained"} color={"primary"} onClick={handleChoiceAnts}>
              {nombrePersonne > 1 ? 'J\’ai déjà effectué mes pré-demandes' : 'J\'ai déjà effectué ma pré-demande'}
            </Button>
            <Button className="w-full btn-next" variant={"contained"} color={"primary"} onClick={goToAntsPage}>
              {nombrePersonne > 1 ? 'Je réalise mes pré-demandes maintenant' : 'Je réalise ma pré-demande maintenant'}
            </Button>
          </Box>
          <p>* Une pré-demande par dossier (personne)</p>
        </Box>
      </Modal>
  );
}
