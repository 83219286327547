import {Box, Chip, MenuItem, Select, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";

const TOOLTIP_MOTIF = {
    'DEPOT_CNI': "Dépôt d'un dossier de demande ou de renouvellement d'une carte nationale d'identité.",
    'DEPOT_PASSEPORT' : "Dépôt d'un dossier de demande ou de renouvellement d'un passeport.",
    'DEPOT_CNI_&_PASSEPORT' : "Dépôt d'un dossier de demande ou de renouvellement d'une carte nationale d'identité et d'un passeport.",
    'RETRAIT_CNI' : "Retrait d'une carte nationale d'identité.",
    'RETRAIT_PASSEPORT' : "Retrait  d'un passeport.",
    'RETRAIT_CNI_&_PASSEPORT' : "Retrait  d'une carte nationale d'identité et d'un passeport."
}

export const SelectTypeDossier = (props) => {
    const { selected, onSelect } = props;
    const [value, setValue ] = useState({} as any);

    useEffect(() => {
        const dossier = props.motifs.find(dossier => dossier.key === selected);
        setValue({...dossier});
    }, [props.motifs, selected]);

    return <>
        <Select
            className="w-full"
            displayEmpty
            value={selected}
            renderValue={() => {
                if (value.key) {
                    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={value.label} label={value.label} />
                    </Box>
                }
                return <Box>
                    <span className={'text-neutral-400'}>Ex: Passeport, CNI, etc...</span>
                </Box>
            }}
        >
            {props.motifs?.length === 0 && <p className="w-full ml-2">Aucun motif disponible</p>}
            {props.motifs.map((item, key) => (
                <MenuItem key={item.key} value={item.key} onClick={() => onSelect(item.key)} autoFocus={false}>
                    <Tooltip title={ TOOLTIP_MOTIF[item.key] || '' } arrow key={key} placement={'top'} followCursor>
                        <p className="w-full">{item.label}</p>
                    </Tooltip>
                </MenuItem>
            ))}
        </Select>
    </>
}
