import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, Box, Button, Grid } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";
import { FormMairie } from "./form/FormMairie";
import { FormMairieAnnexe } from "./form/FormMairieAnnexe";
import { handleUpdateMairie } from "../../../../../../../helpers/mairie.helper";
import { useState } from "react";
import { convertPhone } from "../../../../../../../infrastructure/utils";
import { refetchListMairies } from "../../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    name: yup.string().min(2).required(),
    slug: yup.string().required(),
    address: yup.string().required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][^6-7][0-9]+$/)
        .required(),
    email: yup.string().email(),
    zip: yup.string().required(),
    city: yup.object().required(),
});

export const DetailsMairie = ({ mairie, handleCloseModal }: any) => {
    const [error, setError] = useState<String>('');
    const [success, setSuccess] = useState<Boolean>(false);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const params = {
        register, 
        errors,
        control,
        getValues,
        mairie,
        setValue
    }
    
    const onSubmitHandler = async(data) => {
        let input = {...data, _id: mairie._id};
        if(data.confirmPassword === ""){
            delete input.confirmPassword;
            delete input.password;
            delete input.username;
        }
        
        const res = await handleUpdateMairie({
            ...input,
            city: input?.city?.nom,
            latitude: input?.city?.mairie?.coordinates[1],
            longitude: input?.city?.mairie?.coordinates[0],
            phoneNumber: convertPhone(input?.phoneNumber)
        });
        if(res?.success){
            setSuccess(true);
            refetchListMairies();
            setTimeout(() => {
                handleCloseModal();
            },1000)
            
        }else{
            setError(res?.data);
        }
    }

    return (
        <Box>
            <Grid container className='grid-mairie details-mairie p-0' >
                <Grid item xs={12}>
                    {success && <Alert severity="success">Mairie modifié avec succès!</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                </Grid>
                {!mairie.parent && <p className="mx-4 uppercase mb-2 underline font-bold">présentation mairie</p>}
                <Grid item xs={12} >
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        {!mairie.parent ? <FormMairie {...params} /> : 
                        <>
                            <div>
                                <p className="mini-title">Mairie annexe</p>
                                <p className="m-4 uppercase mb-2 underline font-bold">informations mairie annexe</p>
                            </div>
                            <FormMairieAnnexe {...params} />
                        </>}
                        <div className="div-btn-save padding-left-right">
                            <Button className="btn-next" variant={"contained"} color={"secondary"} startIcon={<SaveIcon />} type="submit">Enregistrer</Button>
                            <Button className="btn-next" variant={"contained"} color={"primary"} onClick={handleCloseModal}>Annuler</Button>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}
