export const initialCalendar = 
    {
        lundi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ],
        mardi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ],
        mercredi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ],
        jeudi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ],
        vendredi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ],
        samedi: [
            {startHour: '08:00', endHour: '12:00'},
            {startHour: '14:00', endHour: '17:00'}
        ]
    }
;

export const initialCalendarCustom = 
    {
        lundi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ],
        mardi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ],
        mercredi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ],
        jeudi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ],
        vendredi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ],
        samedi: [
            {startHour: '-', endHour: '-'},
            {startHour: '-', endHour: '-'}
        ]
    }
;