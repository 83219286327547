import { useEffect, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Stack, TextareaAutosize, TextField } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from "react-hook-form";
import { SwatchesPicker } from 'react-color';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { yupResolver } from "@hookform/resolvers/yup";
import { listCheckMotif, listInfoContent, initialMotif } from "../data/data";
import { handlePostMotif, handlePutMotif } from "../../../../../../helpers/motif.helper";
import { getListMotifs, useMairieStore } from "../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    name: yup.string().required(),
    hidden: yup.boolean(),
    duration: yup.number().required(),
});
interface PropsMotif {
    dataMotif?: any;
    handleCloseModal?: any;
}

export const AddMotif = ({dataMotif, handleCloseModal}: PropsMotif) => {
    const alertSection: any = useRef(null);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [color, setColor] = useState(dataMotif?.color ?? '#fff');
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const [textEndRdv, setTextEndRdv] = useState(dataMotif?.textEnd);
    const { register, handleSubmit, formState: { errors }, getValues, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    
    const textSuccess = useMemo(() => {
        if(dataMotif)
            return 'Le motif a été modifié avec succès!'
        else
            return 'Le motif a été ajouté avec succès!'
    }, [dataMotif]);

    const defaultSmsEmail = useMemo(() => {
        if(dataMotif){
            const value = {
                confirm : dataMotif?.confirm?.length > 0 ? dataMotif?.confirm : initialMotif.confirm,
                cancel: dataMotif?.cancel?.length > 0 ? dataMotif?.cancel : initialMotif.cancel,
                update: dataMotif?.update?.length > 0 ? dataMotif?.update : initialMotif.update,
                reminder: dataMotif?.reminder?.length > 0 ? dataMotif?.reminder : initialMotif.reminder,
                release: dataMotif?.release?.length > 0 ? dataMotif?.release : initialMotif.release,
            }
            return value;
        }else{
            return null
        }
    }, [dataMotif]);

    useEffect(() => {
        if(dataMotif){
            setValue('name', dataMotif.name);
            setValue('duration', dataMotif.duration);
        }
    }, [dataMotif, setValue])

    const [smsEmail, setSmsEmail] = useState(defaultSmsEmail ?? initialMotif)

    useEffect(() => {
        setError('');
        Object.keys(smsEmail).map((data) => {
            const isEmptyData = smsEmail[data].find((item) => item.checked === true && item.object === '');
            if(isEmptyData){
                setError('L\'objet et le contenu ne doivent pas etre vide');
            } 
        })
    }, [smsEmail]);

    

    const handleChangeComplete = (color) => {
        setColor(color.hex);
    };

    const handleChangeCheck = (checkMotif, type) => {
        const element = smsEmail[checkMotif.name].find((item) => item.type === type);
        if(element){
            setSmsEmail(currentState =>  ({...currentState, [checkMotif.name]: currentState[checkMotif.name].map((data) => {
                if(data.type === type){
                    return {...data, checked: !data.checked}
                }
                return data;
            })}))    
        }
    }

    const handleChangeData = (value, key, field, type) => {
        setSmsEmail(currentState =>  ({...currentState, [key]: currentState[key].map((data) => {
            if(data.type === type){
                return {...data, [field]: value}
            }
            return data;
        })}))   
    }

    const onSubmitHandler = async (data) => {
        let res:any = {};
        const antsDuration = handleMultiple5('antsDuration', data.antsDuration)
        const duration = handleMultiple5('duration', data.duration)
        
        const input = {
            name: data.name.toUpperCase(),
            duration,
            antsDuration,
            color: color,
            hidden: data.hidden,
            textEnd: textEndRdv,
            confirm : smsEmail.confirm,
            cancel: smsEmail.cancel,
            update: smsEmail.update,
            reminder: smsEmail.reminder,
            release: smsEmail.release,
            mairie: mairies[0]?.slug
        }
        if(dataMotif){
            res = await handlePutMotif({
                ...input, _id: dataMotif._id
            });
            
            if(res?.success){
                setSuccess(true);
            }else
                setError(res?.errorMessage);
        }else{
            res = await handlePostMotif({
                ...input
            });
            if(res?.success)
                setSuccess(true);
            else
                setError(res?.errorMessage);
        }
        getListMotifs(mairies);   
        alertSection?.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    }

    const handleMultiple5 = (key: string, value?) => {
        value ??= getValues()[key]
        if (value !== "") {
            const roundedValue = Math.round(parseInt(value) / 5) * 5;
            setValue(key, roundedValue.toString());
            return roundedValue.toString()
        }
        return value
    }

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid item xs={12} ref={alertSection}>
                {success &&  <Alert severity="success" className="mt-2">{textSuccess}</Alert>}
                {error && <Alert severity="error" className="mt-2">{error}</Alert>}
            </Grid>
            <Box className="box-admin" sx={{ marginTop: dataMotif && '0px !important' }}>
                <Stack>
                    <FormControl className="custom-form-control">
                        <OutlinedInput
                            className={"w-full bg-grey"}
                            placeholder={'Nom du motif*'}
                            defaultValue={dataMotif?.name}
                            size="small"
                            disabled={dataMotif?.isDefault}
                            {...register("name")}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors.name ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues('name') && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                <Grid container marginTop={3}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    className="blue-label"
                                    control={<Checkbox 
                                        defaultChecked={dataMotif?.hidden}
                                        {...register('hidden')}
                                    />}
                                    label="Ne pas afficher ce motif sur l'iframe"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <p className="bold-title">Durée du Rdv :</p>
                        <Box className="flex items-center mt-2">
                            <FormControl>
                                <TextField
                                    sx={{ width: 80}}
                                    type='number'
                                    size="small"
                                    defaultValue={dataMotif?.duration}
                                    {...register("duration")}
                                    onBlur={() => handleMultiple5('duration')}
                                    InputProps={{
                                        inputProps: {
                                            max: 300,
                                            min: 5,
                                            step: 5
                                        }
                                    }}
                                />
                            </FormControl>
                            <p className="mb-0 ml-2">(300 minutes max.)</p>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <p className="bold-title">Durée du Rdv Avec n° de pré-demande :</p>
                        <Box className="flex items-center mt-2">
                            <FormControl>
                                <TextField
                                    sx={{ width: 80}}
                                    type='number'
                                    size="small"
                                    defaultValue={dataMotif?.antsDuration}
                                    {...register("antsDuration")}
                                    onBlur={() => handleMultiple5('antsDuration')}
                                    InputProps={{
                                        inputProps: {
                                            max: 300,
                                            min: 5,
                                            step: 5
                                        }
                                    }}
                                />
                            </FormControl>
                            <p className="mb-0 ml-2">(300 minutes max.)</p>
                        </Box>
                    </Grid>
                </Grid>
                <Stack className="mt-5">
                    <p className="bold-title">Couleurs :</p>
                    <SwatchesPicker
                        color={color}
                        height={150}
                        width={"100%"}
                        onChangeComplete={handleChangeComplete}
                    />
                </Stack>
                <Stack marginTop={2}>
                    <p><span className="bold-title">Texte</span> (Affiché à l 'usager à la fin de la procédure de prise de RVD en ligne) :</p>
                    {/* @ts-ignore */}
                    <ReactQuill theme="snow" value={textEndRdv} onChange={setTextEndRdv} />
                </Stack>
                <Stack marginTop={2}>
                    <p className="bold-title">SMS/Mail :</p>
                    <div className="info mt-2">
                       <InfoOutlinedIcon sx={{ fontSize: '15px' }}/> Cochez le type de notification que vous souhaitez envoyer à vos usagers (peut être vide)
                    </div>
                    <Grid container marginTop={1}>
                        {listCheckMotif.map((checkMotif, key) => {
                            const checkedEmail = smsEmail[checkMotif.name]?.find((data) => data.type === 'EMAIL')?.checked;
                            const checkedSms = smsEmail[checkMotif.name]?.find((data) => data.type === 'SMS')?.checked;
                            return (
                            <Grid item xs={6} key={key} className="pt-0">
                                <div className="div-checkBox">
                                    <FormLabel component="legend" className="m-0">{checkMotif.text}</FormLabel>
                                    <FormControl component="fieldset" className="flex items-center flex-row">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                control={<Checkbox 
                                                    onChange={() => handleChangeCheck(checkMotif, 'EMAIL')}
                                                    defaultChecked={checkedEmail}
                                                />}
                                                label="Mail"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox 
                                                    onChange={() => handleChangeCheck(checkMotif, 'SMS')}
                                                    defaultChecked={checkedSms}
                                                />}
                                                label="SMS"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            </Grid>)
                        }
                        )}
                    </Grid>
                </Stack>
            </Box>
            
            {Object.keys(smsEmail).map((data: string) => 
            <>
                {smsEmail[data]?.map((item, index) => {
                    const text = listCheckMotif.find((checkMotif) => checkMotif.name === data)?.text;
                    return (
                    <>
                        {item?.type === 'SMS' && item.checked &&
                            <Box key={index} className="mt-5 p-0 bg-min-grey box-grey radius-top">
                                <p className="bold-title radius-top bg-white mb-0 px-3 py-1">rédaction sms de "{text}"</p>
                                <Box className="p-5">
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">Objet* :</p>
                                        <FormControl className="custom-form-control">
                                            <OutlinedInput
                                                className={"w-full bg-grey"}
                                                placeholder={'Ex : Ville de Paris'}
                                                defaultValue={item.subject}
                                                size="small"
                                                onChange={(e) => handleChangeData(e.target.value, data, 'subject', 'SMS')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        { !!errors.objectEmail ?
                                                            <CancelRounded color={"error"}/> :
                                                            (!!getValues('objectEmail') && <CheckCircle color={"secondary"}/>)}
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">Action* :</p>
                                        <FormControl sx={{ minWidth: 80 }}>
                                            <Select
                                                labelId="action"
                                                id="action"
                                                size="small"
                                                defaultValue={text}
                                                autoWidth
                                            >
                                                <MenuItem value={text}>{text}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">texte SMS* :</p>
                                        <TextareaAutosize
                                            className="bg-grey textarea-mairie"
                                            value={smsEmail[data][0]['content']}
                                            onChange={(e) => handleChangeData(e.target.value, data, 'content', 'SMS')}
                                            aria-label="minimum height"
                                            minRows={6}
                                        />
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <Grid container className="gap-2">
                                            {listInfoContent.map((valueContent) => 
                                                <Grid item xs={5.5}>
                                                    <div className="flex items-center gap-5">
                                                        <p className="mb-0 w-40 font-semibold">{valueContent.id}</p>
                                                        <div className="info-content">
                                                        {valueContent.text}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Stack>
                                    <Stack marginTop={3}>
                                        <p>Attention à la longueur de vos SMS, lorsqu'une variable est remplacée, le contenu peut alors dépasser
                                        les 160 caractères. Deux SMS seraient alors facturés.</p>
                                    </Stack>
                                </Box>
                            </Box>
                        }
                        {item?.type === 'EMAIL' && item.checked &&
                            <Box className="mt-5 p-0 bg-min-grey box-grey radius-top">
                                <p className="bold-title radius-top bg-white mb-0 px-3 py-1">rédaction de l'email de "{text}"</p>
                                <Box className="p-5">
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">Objet* :</p>
                                        <FormControl className="custom-form-control">
                                            <OutlinedInput
                                                className={"w-full bg-grey"}
                                                placeholder={'Ex : Ville de Paris'}
                                                defaultValue={item.subject}
                                                size="small"
                                                onChange={(e) => handleChangeData(e.target.value, data, 'subject', 'EMAIL')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        { !!errors.objectEmail ?
                                                            <CancelRounded color={"error"}/> :
                                                            (!!getValues('objectEmail') && <CheckCircle color={"secondary"}/>)}
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">Action* :</p>
                                        <FormControl sx={{ minWidth: 80 }}>
                                            <Select
                                                labelId="action"
                                                id="action"
                                                size="small"
                                                defaultValue={text}
                                                autoWidth
                                            >
                                                <MenuItem value={text}>{text}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <p className="bold-title mb-0">texte de l'email* :</p>
                                        {/* @ts-ignore */}
                                        <ReactQuill 
                                            theme="snow" 
                                            value={smsEmail[data][1]['content']} 
                                            onChange={(value) => handleChangeData(value, data, 'content', 'EMAIL')}
                                        />
                                    </Stack>
                                    <Stack marginTop={2}>
                                        <Grid container className="gap-2">
                                            {listInfoContent.map((valueContent) => 
                                                <Grid item xs={5.5}>
                                                    <div className="flex items-center gap-5">
                                                        <p className="mb-0 w-40 font-semibold">{valueContent.id}</p>
                                                        <div className="info-content">
                                                        {valueContent.text}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Stack>
                                </Box>
                            </Box>
                        }
                    </>
                )})}
            </>
            )}
            
            <Box className="box-desk flex justify-end gap-2 mb-3 m-auto px-4">
                <Button className="btn-next" variant={"contained"} color={"secondary"} startIcon={<SaveIcon />} type="submit">{dataMotif? 'Enregistrer': 'Créer'}</Button>
                <Button className="btn-next" variant={"contained"} color={"primary"} onClick={() => {
                    reset();
                    handleCloseModal();
                }}>Annuler</Button>
            </Box>
        </form>
    )
}
