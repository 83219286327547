import { Grid } from "@mui/material";
import styled from "styled-components";

export const GridWithTheme = styled(Grid)<{ color?: any; }>`
    height: 100%;
    min-height: 100vh;
    background: ${props => props.color.background || '#fff'};
    .MuiStepIcon-root {
        color: ${props => props.color.nonValidatePuce || 'rgba(0, 0, 0, 0.38)'}; 
        &.Mui-active {
            color: ${props => props.color.validatePuce || '#fff'}; 
        }
        &.Mui-completed  {
            color: ${props => props.color.validatePuce || 'rgba(0, 0, 0, 0.38)'}; 
        }
    }

    

    .MuiButton-root{
        background-color: ${props => props.color.button || '#C74C51'}; 
        &:hover{
            background-color: ${props => props.color.button || '#C74C51'}; 
        }
    }

    .MuiCheckbox-root.Mui-checked{
        color: ${props => props.color.button || '#C74C51'}; 
    }

    .title-calendar {
        background: ${props => props.color.button || '#C74C51'}; 
    }

    .MuiTypography-root {
        color: ${props => props.color.title || '#C74C51'}; 
    }
`;