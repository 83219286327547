import { Box, Stack, TextField } from "@mui/material";
import '../Usager.style.scss';
import { SousMenuUsager } from "../../../../application/components/SousMenu/SousMenuUsager";
import { ExportToExcel } from "../../../../application/components/ExportToExcel/ExportToExcel";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useAuthStore } from "../../../../application/store/auth.store";
import { useMairieStore } from "../../../../application/store/mairie.store";

export const ImportExport = () => {
    const  user  = useAuthStore((state) => state.user);
    const  usagers  = useMairieStore((state) => state.usagers);
    const [dataToExport, setDataToExport] = useState([]);

    const [range, setRange] = useState<any>({ startDate: null, endDate: null });

    useEffect(() => {
        if(range){
            let listData: any = usagers.filter((usager: any) => (dayjs(usager.createdAt).isSame(range.startDate, 'day') || dayjs(usager.createdAt).isAfter(range.startDate, 'day')) && (dayjs(usager.createdAt).isSame(range.endDate, 'day') || dayjs(usager.createdAt).isBefore(range.endDate, 'day')));
            listData = listData.map((usager) => {
                return {
                    lastName: usager.lastName,
                    firstName: usager.firstName,
                    phoneNumber: usager.phoneNumber,
                    email: usager.email,
                    zip: usager.zip,
                    city: usager.city,
                }
            })
            setDataToExport(listData)
        }
    }, [range]);

    useEffect(() => {
        const listUsagers: any = usagers.map((usager) => {
            return {
                lastName: usager.lastName,
                firstName: usager.firstName,
                phoneNumber: usager.phoneNumber,
                email: usager.email,
                zip: usager.zip,
                city: usager.city,
            }
        })
        setDataToExport(listUsagers)
    }, [usagers]);

    return (
        <Box marginTop={8}>
            {['ADMIN', 'AGENT'].includes(user.role) && <SousMenuUsager />}
            <Box className="box-mairie flex">
                <div className="bloc-export">
                    <p className="title-export">Exporter fichier</p>
                    <span>Exporter la liste des usagers.</span>
                    <div className="details-export">
                        <p className="title-export">Exporter les rendez vous en format .xlsx</p>
                        <span>Du :</span>
                        <div className="flex items-center">
                            <div className="flex items-center gap-3">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                    <DesktopDatePicker
                                        value={range.startDate}
                                        onChange={(newValue) => {
                                            setRange(prevState => ({...prevState , startDate:newValue}));
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" sx={{ width: 150 }} />}
                                    />
                                </LocalizationProvider>
                                au
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                    <DesktopDatePicker
                                        value={range.endDate}
                                        onChange={(newValue) => {
                                            setRange(prevState => ({...prevState , endDate:newValue}));
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" sx={{ width: 150 }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className="mb-0 bold">{dataToExport?.length} usager{dataToExport?.length > 1 && 's'} à exporter</p>
                        </div>
                        <p>La liste sera exportée au format .xlsx</p>
                        <p>Attention à vous conformer aux obligations RGPD la concernant.</p>
                        <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                            <ExportToExcel apiData={dataToExport} fileName={'usagers'} headCells={['Nom','Prénom','Téléphone','Email','Code Postale', 'Ville']} />
                        </Stack>
                    </div>
                </div>
            </Box>
        </Box>
    )
}