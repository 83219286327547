import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export const  CustomLoading = (props: CircularProgressProps) => {
  return (
    <Box className='m-auto' sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#c84c51"
        }}
        size={props.size}
        thickness={2}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#FFFFFF",
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={props.size}
        thickness={2}
        {...props}
      />
    </Box>
  );
}