import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TableSorting from "../../../application/components/table-sorting";
import { useEffect, useMemo, useState } from "react";
import { getComparator, stableSort } from "../../../infrastructure/utils";
import { Order } from "../../../application/types/type";
import { CanceledDate } from "./components/canceled-date";
import { AntsComponentDate } from "./components/ants-date";
import { DuplicatedDate } from "./components/duplicated-date";
import { CustomLoading } from "../../../application/components/LazyLoading/LazyLoading";

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { DatePicker } from "antd";

import "moment/locale/fr";
import locale from "antd/es/date-picker/locale/fr_FR";
import { useMairieStore } from "../../../application/store/mairie.store";
import {
    getAllAppointment,
    useAgendaStore,
} from "../../../application/store/agenda.store";
import { Print } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { ExportTableToPDF } from "../../../infrastructure/utils/export-pdf-table";
import { groupBy } from "lodash";

dayjs.extend(weekday);
dayjs.extend(localeData);
require("dayjs/locale/fr");

const headCells: readonly any[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        filter: true,
        label: "NOM",
    },
    {
        id: "heureRdv",
        numeric: false,
        isDate: true,
        disablePadding: false,
        filter: false,
        label: "DATE DE RDV",
    },
    {
        id: "duration",
        numeric: true,
        disablePadding: false,
        filter: true,
        label: "Durée",
    },
    {
        id: "city",
        numeric: false,
        disablePadding: false,
        filter: true,
        label: "Ville",
    },
    {
        id: "typeRdv",
        numeric: false,
        disablePadding: false,
        filter: true,
        label: "Prise de RDV",
    },
    {
        id: "motif",
        numeric: false,
        disablePadding: false,
        filter: true,
        label: "motif",
    },
];

const EnhancedTableToolbar = ({ date, setDate }) => {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ marginRight: "1em" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Liste des RDV du jour
                {/* Liste des RDV du jour ({capitalize(date.locale('fr-FR').format('dddd, DD MMMM YYYY'))}) */}
            </Typography>
            <DatePicker
                locale={locale}
                allowClear={false}
                value={date}
                format={"DD/MM/YYYY"}
                onChange={(value) => setDate(value)}
            />
            {/* <Tooltip title="Filter list">
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip> */}
        </Toolbar>
    );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
    padding: "1rem",
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3D3D3D",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function DashboardRDV() {
    const slug = useAgendaStore((state) => state.slug);
    const appointments = useAgendaStore((state) => state.appointments);
    const loading = useAgendaStore((state) => state.loading);
    const canceledDate = useMairieStore((state) => state.canceledDate);
    const duplicatedDate = useMairieStore((state) => state.duplicatedDate);
    const rdvConsumed = useMairieStore((state) => state.rdvConsumed);
    const rdvDuplicated = useMairieStore((state) => state.rdvDuplicated);
    const rdvExpired = useMairieStore((state) => state.rdvExpired);
    const motifs = useMairieStore((state) => state.motifs);
    const TYPE_DOSSIERS = useMairieStore((state) => state.TYPE_DOSSIERS);

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<any>("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {
        order,
        setOrder,
        orderBy,
        setOrderBy,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
    };
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const rowData = useMemo(() => {
        const sortAppointments = appointments.sort((a, b) =>
            dayjs(a.heureRdv).isAfter(dayjs(b.heureRdv)) ? 1 : -1
        );
        return sortAppointments.filter(
            (appointment) =>
                dayjs(appointment?.heureRdv)
                    .locale("fr-FR")
                    .format("DD/MM/YYYY") ===
                    selectedDate.format("DD/MM/YYYY") &&
                appointment.status !== "CANCELED"
        );
    }, [appointments, selectedDate]);
    const [rows, setRows] = useState<any[]>(rowData);

    const handlePrintClick = (props?: any) => {
        const buildDataForPrint = (data: any, title) => {
            return {
                title,
                // html: document.getElementById('today-appointments-table')?.querySelector('table')
                data: {
                    head: [
                        [
                            "NOM",
                            "Num pré-demande",
                            "DATE DE RDV",
                            "DURÉE",
                            "VILLE",
                            "PRISE DE RDV",
                            "MOTIF",
                        ],
                    ],
                    body: data.map((row) => {
                        const typeDossier = TYPE_DOSSIERS.find(
                            (v) => v.key === row.typeDossier
                        );
                        const numbersAnts = row.concernedPerson.reduce(
                            (prev, curr) => prev + `${curr.ants} \n`,
                            ""
                        );
                        return [
                            `${row.usager?.firstName} ${row.usager?.lastName}\n${row.phoneNumber}\n${row.email}`,
                            `${numbersAnts}`,
                            `${dayjs(row.heureRdv)
                                .locale("fr-FR")
                                .format("dddd DD MMMM à HH:mm")}`,
                            `${row.dureeRdv}mn`,
                            `${row.city}`,
                            `${
                                row.typeRdv?.isOnline ? "En ligne" : "En mairie"
                            }`,
                            `${typeDossier?.label}(${row.nombrePersonne})`,
                        ];
                    }),
                },
            };
        };
        const tables: any = [];
        if (props?.printPerAgenda) {
            const groupes = groupBy(rowData, "room.name");
            for (const [agendaName, data] of Object.entries(groupes)) {
                const title = `Liste des RDV du jour ${dayjs(
                    selectedDate
                ).format("DD/MM/YYYY")} à ${dayjs().format(
                    "HH:mm"
                )} : Agenda ${agendaName}`;
                tables.push(buildDataForPrint(data, title));
            }
        } else {
            const title = `Liste des RDV du jour ${dayjs(selectedDate).format(
                "DD/MM/YYYY"
            )} à ${dayjs().format("HH:mm")}`;
            tables.push(buildDataForPrint(rowData, title));
        }
        const filename = `Liste-rdv-${dayjs(selectedDate).format(
            "DD-MM-YYYY-à-HH-mm"
        )}.pdf`;
        ExportTableToPDF(filename, tables, { orientation: "landscape" });
    };

    useEffect(() => {
        setRows(rowData);
    }, [rowData]);

    useEffect(() => {
        if (slug)
            getAllAppointment(
                selectedDate.hour(1).minute(0),
                selectedDate.hour(23).minute(59)
            );
    }, [selectedDate, slug]);

    return (
        <Box paddingTop={15}>
            <Box className="box-mairie">
                <EnhancedTableToolbar
                    date={selectedDate}
                    setDate={setSelectedDate}
                />
                <Grid container className="grid-mairie">
                    {loading || !slug || slug === undefined ? (
                        <Grid
                            item
                            xs={12}
                            marginTop={5}
                            className="flex justify-center"
                        >
                            <CustomLoading size={100} />
                        </Grid>
                    ) : (
                        <Stack
                            direction="column"
                            sx={{ gap: "1em", position: "relative" }}
                        >
                            <Stack
                                direction={"row"}
                                gap="8px"
                                marginLeft={"auto"}
                            >
                                <Button
                                    onClick={() => handlePrintClick()}
                                    variant="contained"
                                >
                                    <Print style={{ marginRight: ".25em" }} />{" "}
                                    Imprimer
                                </Button>
                                <Button
                                    onClick={() =>
                                        handlePrintClick({
                                            printPerAgenda: true,
                                        })
                                    }
                                    variant="contained"
                                >
                                    <Print style={{ marginRight: ".25em" }} />{" "}
                                    Imprimer par Agenda
                                </Button>
                            </Stack>
                            <TableSorting
                                id="today-appointments-table"
                                rows={rows}
                                headCells={headCells}
                                paramsSorting={paramsSorting}
                                setRows={setRows}
                                originalRows={rowData}
                            >
                                <>
                                    {stableSort(
                                        rows,
                                        getComparator(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            const typeDossier =
                                                TYPE_DOSSIERS.find(
                                                    (v) =>
                                                        v.key ===
                                                        row.typeDossier
                                                );
                                            const motif = motifs.find(
                                                (motif) =>
                                                    motif.name ===
                                                    row.typeDossier?.replaceAll(
                                                        "_",
                                                        " "
                                                    )
                                            );
                                            return (
                                                <>
                                                    <StyledTableRow
                                                        hover
                                                        sx={{
                                                            backgroundColor:
                                                                motif?.color,
                                                        }}
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                    >
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            <p className="mb-0">
                                                                {
                                                                    row.usager
                                                                        ?.firstName
                                                                }{" "}
                                                                {
                                                                    row.usager
                                                                        ?.lastName
                                                                }
                                                            </p>
                                                            <p className="mb-0">
                                                                {
                                                                    row.phoneNumber
                                                                }
                                                            </p>
                                                            <p className="mb-0">
                                                                {row.email}
                                                            </p>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {dayjs(row.heureRdv)
                                                                .locale("fr-FR")
                                                                .format(
                                                                    "dddd DD MMMM à HH:mm"
                                                                )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.dureeRdv}mn
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.city}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.typeRdv
                                                                ?.isOnline
                                                                ? "En ligne"
                                                                : "En mairie"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {typeDossier?.label}
                                                            (
                                                            {row.nombrePersonne}
                                                            )
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                </>
                                            );
                                        })}
                                </>
                            </TableSorting>
                        </Stack>
                    )}
                </Grid>
            </Box>
            <Box
                className="box-rdv-dashboard"
                sx={{ minHeight: "200px !important" }}
            >
                <Grid container spacing={2} justifyContent={"space-around"}>
                    <Grid
                        item
                        xs={7}
                        direction={"column"}
                        className="flex gap-5"
                    >
                        <AntsComponentDate data={rdvConsumed} type="consumed" />
                        <AntsComponentDate data={rdvExpired} type="expired" />
                    </Grid>
                    <Grid item xs={5}>
                        <DuplicatedDate data={duplicatedDate} />
                    </Grid>
                    <Grid item xs={12}>
                        <AntsComponentDate
                            data={rdvDuplicated}
                            type="duplicated"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CanceledDate data={canceledDate} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
