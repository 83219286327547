import NODE_API from './axios.helper';

export const handleAddUser = async (data:any, role: string) => {
    try {
        let input: any = {
            email: data?.email,
            mairie: data.mairie,
            phoneNumber: data?.phoneNumber,
            username: data.username,
            password: data.password,
            role: role
        }
        if(data?.access){
            input = {
                ...input,
                lastName: data.lastName,
                firstName: data.firstName,
                access: data.access
            }
        }
        const res = await NODE_API.post('/users', {
            ...input
        });
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const handleUpdateUser = async (data:any, role: string) => {
    try {
        let input: any = {
            email: data?.email,
            mairie: data.mairie,
            phoneNumber: data?.phoneNumber,
            username: data.username,
            password: data.password,
            role: role
        }
        if(data?.access){
            input = {
                ...input,
                lastName: data.lastName,
                firstName: data.firstName,
                access: data.access
            }
        }
        const res = await NODE_API.put('/users/'+data._id, {
            ...input
        });
        return res;
    } catch (error) {
        console.log(error)
    }
}

export const getInfoUserByAnts = async(ants) => {
    try {
        const res = await NODE_API.get('/appointment/ants?num='+ants+'&fields=concernedPerson,email,phoneNumber')
        if(res?.success){
            return res?.data
        }else{
            return [];
        }
    } catch (error) {
        throw (error);
    }
}

export const handleDeleteUser = async(user) => {
    try {
        const res = await NODE_API.delete('/users/'+user._id);
        if(res?.success){
            return res?.data
        }else{
            return [];
        }
    } catch (error) {
        throw (error);
    }
}