import dayjs from 'dayjs'
import isEmpty from "lodash/isEmpty";

const compareWithException = (exceptionsDate) => {
    let isExclude = false;
    const today = dayjs();
    exceptionsDate.map((date) => {
        if (today >  dayjs(date.startDate) && today < dayjs(date.endDate)) {
           isExclude = true
        }
    })
    return isExclude;
}

export const treatAgendaWithBusy = (item, agendas) => {
    const result: any = [];

    agendas.map((agenda) => {
        let listHours = {};
        if(agenda?.defaultHours){
            const todayIsExcludeDate = compareWithException(agenda.excludeDefaultHours);
            if(!todayIsExcludeDate){
                listHours = agenda.mairieHours;
                if(agenda.exceptionHours){
                    const exceptionHours = agenda.exceptionHours;
                    Object.keys(exceptionHours).map((key) => {
                        exceptionHours[key].map((hour, idxHour) => {
                            if(hour.startHour !== '-'){
                                listHours[key][idxHour] = hour;
                            }
                        })
                    })  
                }  
            }
        }else{
            const todayIsExcludeDate = compareWithException(agenda.excludeCustomHours);
            if(!todayIsExcludeDate){
                listHours = agenda.customHours;
                if(agenda.exceptionHours){
                    const exceptionHours = agenda.exceptionHours;
                    Object.keys(exceptionHours).map((key) => {
                        exceptionHours[key].map((hour, idxHour) => {
                            if(hour.startHour !== '-'){
                                listHours[key][idxHour] = hour;
                            }
                        })
                    })  
                }
            }  
        }
        
        Object.keys(listHours).map((day) => {
            listHours = {...listHours, [day]:listHours[day].map((hours) => {
                return {...hours, id: agenda._id};
            })}
        })

        if(!isEmpty(listHours))
            result.push(listHours)
    })
    return result;
}

