import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import styled from "styled-components";

const PREFIX = 'BO';

const classes = {
    appointment: `${PREFIX}-appointment`,
    passTypeAppointment: `${PREFIX}-passTypeAppointment`,
    canceledAppointment: `${PREFIX}-canceledAppointment`,
    notCanceledAppointment: `${PREFIX}-notCanceledAppointment`,
    cniPassTypeAppointment: `${PREFIX}-cniPassTypeAppointment`,
    cniTypeAppointment: `${PREFIX}-cniTypeAppointment`,
    weekEndCell: `${PREFIX}-weekEndCell`,
    weekEndDayScaleCell: `${PREFIX}-weekEndDayScaleCell`,
    text: `${PREFIX}-text`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    addButton: `${PREFIX}-addButton`,
    overlay: `${PREFIX}-overlay`,
};

export const StyledAppointmentsAppointment = styled(Appointments.Appointment)((props) => ({
    [`&.${classes.appointment}`]: {
      borderRadius: 0,
      borderBottom: 0,
    },
    backgroundColor: `${props.color} !important`,
    [`&.${classes.canceledAppointment}`]: {
      backgroundColor: '#FFFFFF !important',
      border: '1px solid red !important'
    }
}));