import '../../../../application/styles/home.style.scss';
import {Box, Grid} from "@mui/material";
import {useMediaQuery} from "usehooks-ts";
import {BREAKPOINT_MOBILE} from "../../../../constant";
import { BlocListRdv } from '../../../../application/components/Gestion/BlocListRdv';

const GestionRdv = () => {
    const mobile = useMediaQuery(BREAKPOINT_MOBILE)

    return (
        <Box sx={{width: '100%'}}>
            <div className={"header-1-container pxx-3 w-full"}>
                <Grid container>
                    <Grid item xs={12} className="m-auto">
                        <Box zIndex={3} marginTop={mobile ? 0: 5}>
                            <BlocListRdv />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    )
}

export default GestionRdv;
