import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { CustomDialog } from '../../../../../../../application/components/CustomDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { DetailsMairie } from './DetailsMairie';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D3D3D',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));
  
export const ListeMairie = ({ mairies }) => {
    const [open, setOpen] = useState(false);
    const [mairie, setMairie] = useState<any>(null);
    const [openAnnexe, setOpenAnnexe] = useState(false);

    const handleCloseModal = () => {
        setOpen(false)
    }

    return (
        <Box className="box-mairie">
            <Grid container className="grid-mairie">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                <StyledTableCell>Mairie</StyledTableCell>
                                <StyledTableCell align="left">Adresse</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {mairies?.length > 0 && mairies.map((row, key) => (
                            <>
                                <StyledTableRow key={key}>
                                    <StyledTableCell>
                                        {row.annexes && row.annexes.length > 0 && 
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenAnnexe(!openAnnexe)}
                                        >
                                            {openAnnexe ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>}
                                    </StyledTableCell> 
                                    <StyledTableCell component="th" scope="row">
                                        {row.name} <br></br> <span className="text-light-grey">{row.phoneNumber
                                        }</span> 
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.address} <br></br>{row.codePostal}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <TuneIcon 
                                            className="cursor-pointer" 
                                            onClick={() => {setMairie(row); setOpen(true)}}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                    <Collapse in={openAnnexe} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 1 }}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Mairie annexe{row.annexes && row.annexes.length > 1 && 's'}
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell>Nom</StyledTableCell>
                                                        <StyledTableCell>Adresse</StyledTableCell>
                                                        <StyledTableCell align="center">Action</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.annexes && row.annexes.map((annexe, index) => (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {annexe.name} <br></br> <span className="text-light-grey">{annexe.phoneNumber}</span> 
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{annexe.address} <br></br>{annexe.zip}</StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <TuneIcon 
                                                                    className="cursor-pointer" 
                                                                    onClick={() => {setMairie(annexe); setOpen(true)}}
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={<DetailsMairie mairie={mairie} handleCloseModal={handleCloseModal} />}
            />
        </Box>
    )
}