import { useEffect } from 'react';
import './application/styles/app.scss';
import {BrowserRouter, useLocation} from "react-router-dom";
import AppRouter from "./application/routes/App.routes";
import {BASE_URL} from "./constant";
import {AxiosProvider} from "./application/context/axios.context";
import {ThemeProvider} from "@mui/material/styles";
import {CustomTheme} from "./Theme";
import { AgendaContextProvider } from './application/context/agenda.context';
import { MairiesContextProvider } from './application/context/mairies.context';
import { UsagerContextProvider } from './application/context/usager.context';
import { SnackbarProvider } from 'notistack';

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Rendez-vous en Mairie :)';
    }, [pathname]);
    return null;
}

function App() {
    return (
        <BrowserRouter basename={BASE_URL}>
            <AxiosProvider>
              <SnackbarProvider 
                maxSnack={3} 
                autoHideDuration={5000}
                anchorOrigin={{ horizontal: 'right', vertical: 'top'}}
              >
                <ScrollToTop/>
                <ThemeProvider theme={CustomTheme}>
                    <AppRouter/>
                </ThemeProvider>         
              </SnackbarProvider>
            </AxiosProvider>
        </BrowserRouter>
    );
}

export default App;
