import { useCallback, useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import dayjs from "dayjs";
import { GetSmsEmailHistories } from "../../../../../../../helpers/histories.helper";
import { debounce, isDate } from "lodash";
import { useMairieStore } from "../../../../../../../application/store/mairie.store";

const StyledTableCell = styled(TableCell)(({ theme, color }) => ({
    padding: "1rem",
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3D3D3D",
        color: theme.palette.common.white,
        flexDirection: "column",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTabelShortLabel = styled(TableSortLabel)(({ theme, color }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
}));

const TextFieldCustom = styled(TextField)(({ theme, color }) => ({
    color: "#FFFFFF",
    "& .MuiInputBase-root": {
        color: "#FFFFFF",
    },
    "& .MuiButtonBase-root": {
        color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FFFFFF",
        color: "#FFFFFF",
    },
}));

export const HeaderTableHisotries = ({
    headCell,
    handleChangeRows,
    page,
    setPage,
    setFilter,
    filter,
    limit,
}) => {
    const [searched, setSearched] = useState<any>(null);
    const mairies = useMairieStore((state) => state.mairies);

    const delayedSearch = useCallback(
        debounce(async (searchTerm, page, limit) => {
            let valueOfSearch = searchTerm?.value;
            let query = `page=${page}&limit=${limit}`;
            Object.keys(filter).map((key) => {
                if (
                    filter[key] &&
                    filter[key] !== "" &&
                    key !== searchTerm?.key
                ) {
                    let value = filter[key];
                    if (key === "sendDate") {
                        value = dayjs(filter[key]).format("DD-MM-YYYY");
                    }
                    query += `&${key}=${value}`;
                }
            });
            if (searchTerm?.key === "sendDate") {
                if (isDate(searchTerm.value))
                    valueOfSearch = dayjs(searchTerm.value).format(
                        "DD-MM-YYYY"
                    );
            }

            if (searchTerm?.key && valueOfSearch)
                query += `&${searchTerm.key}=${valueOfSearch}`;

            const res = await GetSmsEmailHistories(mairies[0]?._id, query);
            if (res?.data) {
                handleChangeRows(res);
            }
            setSearched(null);
        }, 1000),
        []
    );

    useEffect(() => {
        delayedSearch(null, page, limit);
    }, [page, limit]);

    const requestSearch = (searchedVal: any, key: any, type) => {
        setSearched({ key: key, value: searchedVal });
        setFilter((prevState) => ({ ...prevState, [key]: searchedVal }));
        delayedSearch({ key: key, value: searchedVal }, 1, limit);
    };

    return (
        <StyledTableCell
            key={headCell.id}
            align="left"
            className="uppercase"
            padding={headCell.disablePadding ? "none" : "normal"}
        >
            <StyledTabelShortLabel>{headCell.label}</StyledTabelShortLabel>
            {headCell.filter && (
                <>
                    {!headCell?.isDate ? (
                        <TextFieldCustom
                            sx={{ width: "100%" }}
                            value={searched?.value}
                            size="small"
                            onChange={(e) =>
                                requestSearch(
                                    e.target.value,
                                    headCell.id,
                                    headCell.numeric ? "numeric" : "text"
                                )
                            }
                        />
                    ) : (
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={frLocale}
                        >
                            <DesktopDatePicker
                                value={searched?.value}
                                onChange={(newValue) => {
                                    requestSearch(
                                        newValue,
                                        headCell.id,
                                        "date"
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextFieldCustom
                                        {...params}
                                        size="small"
                                        sx={{ width: 200 }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                </>
            )}
        </StyledTableCell>
    );
};
