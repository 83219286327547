import { Box } from "@mui/material";
import { useState } from "react";
import { TabMenu } from "../../../../../application/components/TabMenu/TabMenu";
import { menuAgendaAdmin } from './data/data';
import { Parameter } from "./view/Parameter";
import { AddAgenda } from "./view/AddAgenda";
import { ListeAgenda } from "./view/ListAgenda";
import './Agenda.style.scss';
import { ExportAppointments } from "./view/ExportAppointment";

export const Agendas = () => {
    const [activeSousMenu, setActiveSousMenu] = useState<string>('parameter');

    return (
        <>
            <TabMenu activeSousMenu={activeSousMenu} setActiveSousMenu={setActiveSousMenu} menus={menuAgendaAdmin}/>
            <Box className="container m-auto">
                {activeSousMenu === 'parameter' && <Parameter />}
                {activeSousMenu === 'add' && <AddAgenda />}
                {activeSousMenu === 'modify' && <ListeAgenda />}
                {activeSousMenu === 'export' && <ExportAppointments />}
            </Box>
        </>
    )
}