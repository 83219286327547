import { Box } from "@mui/material";
import './TabMenu.style.scss';

export const TabMenu = ({ activeSousMenu, setActiveSousMenu, menus }) => {

    return (
        <Box marginTop={3}>
            <div className="tab-menu">
                {menus.map((menu, key) => 
                <div 
                    key={key}
                    className={activeSousMenu === menu.key ? 'item-sous-menu active' : 'item-sous-menu'}
                    onClick={() => setActiveSousMenu(menu.key)}
                >
                    {menu.name}
                </div>
                )}
            </div>
        </Box>
    )
}