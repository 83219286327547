import {Box, FormControl, InputAdornment, OutlinedInput, Stack, TextareaAutosize, Typography} from "@mui/material";
import {CancelRounded, CheckCircle} from "@mui/icons-material";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {LoadingButton} from "@mui/lab";
import { InputPhone } from "./InputPhone/InputPhone";
import range from "lodash/range";
import { useEffect } from "react";
import { FormPersonConcerned } from './FormPersonConcerned/FormPersonConcerned';
import { useSessionStorage } from "usehooks-ts";
import { SelectCodePostal } from "./SelectCodePostal";
import { useSearchParams } from "react-router-dom";

const formSchema = {
    isNotOtherType: yup.boolean(),
    showAnts: yup.boolean(),
    
    firstName: yup.string().when("isNotOtherType", {
        is: true,
        then: yup.string()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/).min(2).required()
    }),
    lastName: yup.string().when("isNotOtherType", {
        is: true,
        then: yup.string()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/).min(2).required()
    }),
    ants:  yup.string().when("showAnts", {
        is: true,
        then: yup.string().min(10).max(10).required()
      }),
  };

const schema = yup.object().shape({
    isNotOtherType: yup.boolean(),
    isAntsWebsite: yup.boolean(),
    firstName: yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/).min(2).required(),
    lastName: yup.string().matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/).min(2).required(),
    city: yup.object().when("isAntsWebsite", {
        is: true,
        then: yup.object().required()
    }),
    zip: yup.string().when("isAntsWebsite", {
        is: true,
        then: yup.string().required()
    }),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][6-7][0-9]+$/)
        .required(),
    email: yup.string().email(),
    comment: yup.string(),
    concernedPerson: yup.array().when("isNotOtherType", {
            is: true,
            then: yup.array()
                .of(yup.object().shape(formSchema))
                .required("Must have fields")
                .min(1, "Minimum of 1 field")
        }),
});


export const IdentificationForm = ({onConfirm, loading, nombrePersonne, ants, ...props}) => {
    const [session, setSession] = useSessionStorage('session', {} as any);
    const [searchParams] = useSearchParams();
    const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const { fields } = useFieldArray({
        control,
        name: "concernedPerson"
    });

    const onSubmitHandler = (data) => {
        const phoneNumber = data.phoneNumber.trim('').replace(/\s/g, '').slice(1, 10);
        onConfirm({
            ...data,
            phoneNumber: `+33${phoneNumber}`
        });
    };

    useEffect(() => {
        let list: any = [];
        if(nombrePersonne > 0){
            range(0, nombrePersonne).map(data => {
                list.push({firstName: '', lastName: '', birthday: null, ants: '', showAnts: ants});
            })
            setValue('concernedPerson',list);
        }
    }, [nombrePersonne])

    useEffect(() => {
        setValue('isNotOtherType', (session?.typeDossier.includes('CNI') || session?.typeDossier.includes('PASSEPORT')))
    }, [session?.typeDossier])

    useEffect(() => {
        if(!session?.typeDossier.includes('CNI') && !session?.typeDossier.includes('PASSEPORT')){
            setValue(`concernedPerson.${0}.firstName`, getValues('firstName'));
            setValue(`concernedPerson.${0}.lastName`, getValues('lastName'));
        }
    }, [session?.typeDossier, setValue, getValues('firstName'), getValues('lastName')])

    return <>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <input hidden {...register(`isAntsWebsite`, { value: Boolean(searchParams.get('duration')) })}  />
            <input hidden {...register(`isNotOtherType`, { value: (session?.typeDossier.includes('CNI') || session?.typeDossier.includes('PASSEPORT')) })}  />
            <Stack gap={2}>
                {(session?.typeDossier.includes('CNI') || session?.typeDossier.includes('PASSEPORT')) && 
                    <>
                        {fields.map((field, index) => {
                            return (
                                <FormPersonConcerned key={index} field={field} index={index} register={register} getValues={getValues} setValue={setValue} ants={ants} errors={errors} typeDossier={session?.typeDossier} hidden={true}/>
                            )
                        })}
                        <Box>
                            <Typography variant={"h6"}><span className={"font-bold"}>Vos informations de contact</span></Typography>
                        </Box>
                    </>
                }
                <Stack>
                    <p className="mb-0">Nom</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'Votre Nom'}
                            {...register("lastName")}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors.lastName ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues('lastName') && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                <Stack>
                    <p className="mb-0">Prénom</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'Votre Prénom'}
                            {...register("firstName")}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors.firstName ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues('firstName') && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                {searchParams.get('duration') && <Stack>
                    <SelectCodePostal disabled={false} register={register} setValue={setValue} />  
                    {(errors?.city || errors?.zip) && <p className="mb-0 text-red-500">Les valeurs ne devraient pas être vide</p>}
                </Stack>}
                <Stack>
                    <p className="mb-0">Numéro de téléphone mobile</p>
                    <p className={"text-sm"}>(06 ou 07)</p>
                    <Stack flexDirection={'row'} alignItems={"center"} className="w-full">
                        <InputPhone control={control} getValues={getValues} errors={errors} defaultValue={''} />
                    </Stack>
                </Stack>
                <Stack>
                    <p className="mb-0">Mail</p>
                    <FormControl>
                        <OutlinedInput
                            className={"bg-white"}
                            placeholder={'Votre adresse email'}
                            type={"email"}
                            {...register("email")}
                            endAdornment={
                                <InputAdornment position="end">
                                    { !!errors.email ?
                                        <CancelRounded color={"error"}/> :
                                        (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                {(!session?.typeDossier.includes('CNI') && !session?.typeDossier.includes('PASSEPORT')) && <Stack>
                    <p className="mb-0">Qu'attendez vous du rendez-vous?</p>
                    <TextareaAutosize
                        {...register('comment')}
                        aria-label="minimum height"
                        minRows={6}
                    />
                </Stack>}
                <Stack>
                    <LoadingButton loading={loading} disabled={loading} variant={"contained"} color={"secondary"} type={"submit"}>Enregistrer vos informations</LoadingButton>
                </Stack>
            </Stack>
        </form>
    </>
}
