import { Stack, Grid, Button, Box, Alert } from "@mui/material";
import '../Usager.style.scss';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import TableHistorique from "../../../../application/components/TableHistorique/TableHistorique";
import { useEffect, useState } from "react";
import { EditUsager } from "./form/EditUsager";
import { handleUpdateUsager } from "../../../../helpers/usager.helper";
import { convertPhone } from "../../../../infrastructure/utils";
import { API } from "../../../../constant";
import axios from 'axios';
import SmsSending from "../../../../application/components/SmsSending";
import { CustomDialog } from "../../../../application/components/CustomDialog";
import EmailSending from "../../../../application/components/EmailSending";
import { PersonOff } from "@mui/icons-material";
import dayjs from "dayjs";
import { useMairieStore } from "../../../../application/store/mairie.store";
import { getAllAppointment, useAgendaStore } from "../../../../application/store/agenda.store";

const schema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][6-7][0-9]+$/)
        .required(),
    email: yup.string().email(),
    zip: yup.string().required(),
    city: yup.object().required(),
});

export const DetailsUsager = ({ usager, callback, gestionAccount = false }) => {
    const [error, setError] = useState<String>('');
    const [openContact, setOpenContact] = useState<boolean>(false);
    const [success, setSuccess] = useState<Boolean>(false);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [histories, setHistories] = useState<any[]>([]);
    const [edit, setEdit] = useState<boolean>(false);
    const [componentContact, setComponentContact] = useState<any>(<SmsSending usager={usager} mairie={mairies[0]}/>);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const [canAnonymUser, setCanAnonymUser] = useState(false)

    const getAllHistories = async(usager) => {
        let input = {
            'metadata.usagerId': usager._id,
        }

        if(!gestionAccount){
            Object.assign(input, {'metadata.mairie': mairies[0]?.slug});
        }
        
        const res = (await axios.post(API + '/histories/searchBy', input))?.data;
        if(res?.data){
            setHistories(res?.data);
        }
    };

    useEffect(() => {
        if (histories) {
            const pendingAppointment = histories.filter(
                data => data.metadata?.status === 'PENDING' && 
                dayjs(data.metadata?.heureRdv).isAfter(dayjs())
                )
            setCanAnonymUser(pendingAppointment?.length === 0 && (usager.email || usager.phoneNumber))
        }

    }, [histories])

    useEffect(() => {
        getAllHistories(usager);
      },[usager]);

    const params = {
        register, 
        errors,
        control,
        getValues,
        setValue
    }

    const onSubmitHandler = async (data) => {
        const input = {
            ...data,
            city: data.city.nom,
            _id: usager._id,
            phoneNumber: convertPhone(data.phoneNumber)
        }
        const res = await handleUpdateUsager(input);
        if(res){
            setSuccess(true);
            if(gestionAccount){
                callback({
                    phoneNumber: convertPhone(data.phoneNumber),
                    email: data?.email
                })
            }else{
                callback()
            }
            getAllAppointment();
        }else{
            setError('Une erreur est survenue lors de la création de l\'usager.');
        }
    };

    const handleCloseModalContact = () => {
        setOpenContact(false);
    }

    const handleOpenModalContact = (type) => {
        if(type === 'sms')
            setComponentContact(<SmsSending usager={usager} mairie={mairies[0]}/>);
        if(type === 'email')
            setComponentContact(<EmailSending usager={usager}/>);
    
        setOpenContact(true);
      }

    const handleAnonymous = async () => {
        const input = {
            _id: usager._id,
            phoneNumber: null,
            email: null,
        }
        const res = await handleUpdateUsager(input);
        if(res){
            setSuccess(true);
            if(gestionAccount){
                callback()
            }else{
                callback({
                    phoneNumber: convertPhone(usager?.phoneNumber),
                    email: usager?.email
                })
            }
            getAllAppointment();
        }else{
            setError('Une erreur est survenue lors de la création de l\'usager.');
        }
    }

    return (
        <>
            <Stack gap={2}>
                <Grid item xs={12}>
                    {success && <Alert severity="success">Usager modifié avec succès!</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                </Grid>
                {!edit &&
                <Grid container spacing={1} className="padding-left-right">
                    <Grid item xs={6}>
                        <p className="mb-0 text-firstname">{usager?.firstName} <span className="text-lastname">{usager?.lastName} </span></p>
                        <Box>
                            <p className='phone'>Téléphone : <span className='text-blue' onClick={() => handleOpenModalContact('sms')}>{usager.phoneNumber}</span></p>
                            <p className='text-title'>Adresse mail : <span className='text-blue' onClick={() => handleOpenModalContact('email')}>{usager.email}</span> </p>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box>
                            <p className="mb-0">Code postal : <span className="bold-title">{usager.zip}</span></p>
                            <p className="mb-0">Ville : <span className="bold-title">{usager.city}</span></p>
                        </Box>
                    </Grid>
                </Grid>}
                {edit && 
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <EditUsager {...params} usager={usager}/>
                    <Box className="flex justify-end gap-2 m-auto px-5 mt-4">
                        <Button className="btn-next" variant={"contained"} color={"secondary"} type="submit" startIcon={<SaveIcon />}>Enregistrer</Button>
                        <Button className="btn-next" variant={"contained"} color={"primary"} onClick={() => setEdit(false)}>Annuler</Button>
                    </Box>
                </form>}
                {!edit && 
                <Box className="flex justify-end gap-2 m-auto px-7">
                    <Button className="btn-next" variant={"contained"} color={"secondary"} onClick={() => setEdit(true)} startIcon={<EditIcon />}>Modifier</Button>
                    { canAnonymUser && 
                        <Button className="btn-next" variant={"contained"} color={"primary"} onClick={() => handleAnonymous()} startIcon={<PersonOff />}>Anonymiser</Button>
                    }
                </Box>}
                <hr className="custom-hr"></hr>
                <Stack className="padding-left-right">
                    <p className="text-historique p-3">HISTORIQUE USAGER</p>
                    <TableHistorique histories={histories ?? []} gestionAccount={gestionAccount} />
                </Stack>
            </Stack> 
            <CustomDialog
                setOpen={setOpenContact}
                open={openContact}
                onClose={handleCloseModalContact}
                component={componentContact}
            />
        </>
    )
}
