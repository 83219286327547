import { useEffect, useState } from "react";
import { Autocomplete, FormControl, InputAdornment, OutlinedInput, Paper, Stack, TextField } from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import '../../../Mairie.style.scss';
import { SelectCodePostal } from "../../../../../../../../application/components/SelectCodePostal";
import { InputPhone } from "../../../../../../../../application/components/InputPhone/InputPhone";
import { FormPropsMairie } from "../../../../../../../../application/types/mairie.type";

import { useMairieStore } from "../../../../../../../../application/store/mairie.store";
export const FormMairieAnnexe = ({ register, errors, control, getValues, setValue, mairie }: FormPropsMairie) => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const [mairieParent, setMairieParent] = useState<any>(null);

    useEffect(() => {
        const parent = mairies.find((data) => data._id === mairie?.parent)
        if(parent){
            setMairieParent(parent)
        }else{
            if(mairies[0]?.annexes){
                const children = mairies[0]?.annexes.find((data) => data._id === mairie?.parent)
                if(children){
                    setMairieParent(children)
                }
            }
        }
    }, [mairie, mairies])

    const params = {
        register, 
        errors,
        control,
        getValues
    }

    useEffect(() => {
        if(mairieParent)
            setValue('parent', mairieParent?._id);
    }, [mairieParent, setValue]);

    return (
        <>
            <Stack gap={2} className="padding-left-right">
                <Stack>
                    <Paper className="custom-paper">
                        <Autocomplete
                            disableClearable
                            options={mairies}
                            getOptionLabel={(option) => {
                                if (option['name'])
                                     return option['name'] 
                                if (typeof option === 'string')
                                    return option
                                return ''
                            }}
                            value={mairieParent}
                            onChange={(event: any, newValue: any) => {
                                setMairieParent(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "10px!important",
                                        },
                                    }}
                                    placeholder={"Mairie Principale*"}
                                />
                            )}
                        />
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Dossier*'}
                                defaultValue={mairie?.slug}
                                {...register("slug")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.slug ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('slug') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Nom de la Mairie annexe*'}
                                defaultValue={mairie?.name}
                                {...register("name")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.name ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('name') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse*'}
                                defaultValue={mairie?.address}
                                {...register("address")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.address ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('address') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Adresse complémentaire*'}
                                defaultValue={mairie?.address2}
                                {...register("address2")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.address2 ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('address2') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>            
                </Stack>
                <Stack>
                    <SelectCodePostal disabled={false} register={register} setValue={setValue} data={mairie} />
                </Stack>  
                <Stack>
                    <Paper className="custom-paper">
                        <InputPhone {...params} defaultValue={mairie?.phoneNumber?.replace('+33','0') ?? ''} />
                    </Paper>
                </Stack>
                <Stack>
                    <Paper className="custom-paper">
                        <FormControl className="custom-form-control">
                            <OutlinedInput
                                className={"bg-grey"}
                                placeholder={'Votre adresse email*'}
                                type={"email"}
                                defaultValue={mairie?.email ?? ''}
                                {...register("email")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        { !!errors.email ?
                                            <CancelRounded color={"error"}/> :
                                            (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Stack>
            </Stack>
        </>
    )
}
