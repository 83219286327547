import NODE_API from "./axios.helper";

export const postalCodeSearchByCity = async (index: string) => {
    try {
        const res = NODE_API.get(`/mairies/search?city=${index}&fields=nom,codesPostaux,code,mairie,coordinates`);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const citySearchByZip = async (index: string) => {
    try {
        const res = NODE_API.get(`/mairies/search?zip=${index}&fields=nom,codesPostaux,code,mairie,coordinates`);
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getListCityAndPostalCode = async () => {
    try {
        const res = NODE_API.get('/mairies/search?zip=&fields=nom,codesPostaux,code,mairie,coordinates');
        return res;
    } catch (error) {
        console.log(error);
    }
}