
import { FormControlLabel, FormGroup, Grid, TableCell, TableRow, styled, Stack } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import  capitalize  from "lodash/capitalize";
import { SwitchButton } from "../../../../../../application/components/SwitchButton";

require('dayjs/locale/fr');

const BorderedTD = styled(TableCell)`
    &.MuiTableCell-root {
    border-left: 1px solid rgba(224, 224, 224, 1);
    font-weight: bold;
    }
`;

export const TableHourTypeCustom = ({ index, day, hours, callback, type='busy'}) => {
    const handleChangeTypeHour = (callback, day, index, typeRDV) => {
        callback(prevState => {
            const newState = prevState[day].map((obj, id) => {
              if(id === index){
                let type = {isMairie: false, isOnline: false}
                if(obj?.type){
                    type = obj.type 
                }
                type = {...type, [typeRDV]: !type[typeRDV]}
                return {...obj, type: type}
              }else{
                return obj
              }
            });
            return {...prevState, [day]: newState};
        });
    }
     const handleDelete = (callback, day, index) => {
        callback(prevState => {
            delete prevState[day];
            return {...prevState};
        });
     }

    return (
        <TableRow  key={index}>
            <BorderedTD sx={{ width: '5%' }}>{capitalize(day)}</BorderedTD>
            <BorderedTD sx={{ width: '15%' }} align="right">{hours.startHour}h à {hours.endHour}h</BorderedTD>
            {type !== 'busy' && <BorderedTD align="right" sx={{ width: '25%' }}>
                <Stack flexDirection={'row'} gap={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                            <SwitchButton 
                                sx={{ m: 1 }} 
                                defaultChecked={hours['type']?.isOnline}
                                onChange={() => handleChangeTypeHour(callback, day,  index, 'isOnline' )}
                            />}
                            label="En ligne"
                            className="switch-label-motif"
                            labelPlacement="start"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                            <SwitchButton 
                                sx={{ m: 1 }} 
                                defaultChecked={hours['type']?.isMairie}
                                onChange={() => handleChangeTypeHour(callback, day,  index, 'isMairie' )}
                            />}
                            label="Mairie"
                            className="switch-label-motif"
                            labelPlacement="start"
                        />
                    </FormGroup>
                </Stack>
            </BorderedTD>}
            <BorderedTD sx={{ width: '5%' }} align="right">
                <DeleteForeverIcon 
                    className="btn-delete"
                    onClick={() => handleDelete(callback, day, index)}
                />
            </BorderedTD>
        </TableRow>
    )
}