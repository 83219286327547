export const TYPE_DOSSIERS = [
    {
        key: 'DEPOT_CNI',
        label: 'Dépôt CNI'
    },
    {
        key: 'DEPOT_PASSEPORT',
        label: 'Dépôt Passeport'
    },
    {
        key: 'DEPOT_CNI_&_PASSEPORT',
        label: 'Dépôt CNI & Passeport'
    },
    {
        key: 'RETRAIT_CNI',
        label: 'Retrait CNI'
    },
    {
        key: 'RETRAIT_PASSEPORT',
        label: 'Retrait Passeport'
    },
    {
        key: 'RETRAIT_CNI_&_PASSEPORT',
        label: 'Retrait CNI & Passeport'
    }
]
