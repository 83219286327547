import {IconButton, Stack, Typography} from "@mui/material";

import {ArrowBackIos, ArrowForwardIos} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useMediaQuery} from "usehooks-ts";
import {BREAKPOINT_MOBILE} from "../../constant";
import dayjs from "dayjs";

export const CustomCalendar = ({ calendarLists, availableHours , onDateSelect, startDate, setStartDate }) => {
    const [currentDate, setCurrentDate] = useState(startDate);
    const [calendars, setCalendars] = useState(calendarLists);
    const [firstView, setFirstView] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState({
        hours: null,
        day: null, 
        month: null
    });

    const mobile = useMediaQuery(BREAKPOINT_MOBILE);

    useEffect(() => {
        if (mobile && calendarLists.length > 2) {
            setCalendars(calendarLists.slice(0, 2))
        } else {
            setCalendars(calendarLists)
        }
    }, [calendarLists, mobile]);

    useEffect(() => {
        setCurrentDate(dayjs(calendars[0]));
    }, [calendars]);

    const handleSelectHours = (calendar, data) => {
       if(data.hour !== '-'){
            setSelectedDate(currentDate => ({...currentDate, 
                hours: data.hour,
                day: calendar.date(),
                month: calendar.month(),
                years: calendar.year()
            }));
            onDateSelect(calendar, data)
       }
    }

    const handleWeekChange = (type = 'next') => {
        const add =  mobile ? 2 : 5;
        setFirstView(false);
        if(type === 'next') {
            setCalendars(prev => prev.map(calendar => (
                dayjs(calendar).add(add, 'days')
            )))
        } else if (type === 'back'){
            setCalendars(prev => prev.map(calendar => (
                dayjs(calendar).subtract(add, 'days')
            )))
        }
    }

    useEffect(() => {
        let existHours = false;
        const add =  mobile ? 2 : 5;
        if(calendars.length > 0){
            calendars.map((calendar) => {
                ((availableHours && availableHours[calendar.format('YYYY-MM-DD')]) || []).map((data, key) => {
                    if(data){
                        existHours = true;
                    }
                })
            })
            if(!existHours && firstView && availableHours){
                setCalendars(prev => prev.map(calendar => (
                    dayjs(calendar).add(add, 'days')
                )))
            }
        }
    },[availableHours, calendars, firstView, mobile])

    return <>
        <Stack alignItems={"center"} position={"relative"} className="calendar-custom">
            {/* <div style={spiralStyle}/> */}
            <Stack flexDirection={'row'} alignItems={"center"} justifyContent={"center"} gap={5} className={"w-full my-3 title-calendar"}>
                {/*<Box><IconButton onClick={() => handleMonthChange('back')}><ArrowBackIos/></IconButton></Box>*/}
                <p className={'capitalize font-bold'}>{dayjs(currentDate).format('MMMM YYYY')}</p>
                {/*<Box><IconButton onClick={() => handleMonthChange('next')}><ArrowForwardIos/></IconButton></Box>*/}
            </Stack>
            <Stack className={"navigation-button-calendar-weeks"} flexDirection={"row"}>
                <IconButton onClick={() => handleWeekChange('back')}><ArrowBackIos/></IconButton>
                <IconButton onClick={() => handleWeekChange('next')}><ArrowForwardIos/></IconButton>
            </Stack>
            <Stack flexDirection={"row"} gap={1} height={315} className={"relative overflow-y-scroll"}>
                {calendars.length > 0 && calendars.map((calendar, key) => {
                    return (
                        <Stack className="column-hour" flexDirection={'column'} gap={3} key={key}>
                            <Stack alignItems={"center"} className={"sticky-element top-0 bg-white p-2"}>
                                <Stack>
                                    <Typography color={"primary"}><span className={'font-bold capitalize'}>{calendar.format('ddd')}</span></Typography>
                                    <span className={'text-gray-500 text-sm'}>{calendar.format('DD/MM')}</span>
                                </Stack>
                            </Stack>
                            <Stack gap={1}>
                                {((availableHours && availableHours[calendar.format('YYYY-MM-DD')]) || []).map((data, key) => {
                                    const isChecked = selectedDate.month === calendar.month() && selectedDate.day === calendar.date() && selectedDate.hours === data.hour;
                                    const bg = isChecked ? 'bg-blue-400 hover:bg-blue-300': 'bg-neutral-200 hover:bg-neutral-300';
                                    return (
                                        <Stack
                                            key={key}
                                            className={`${bg}  text-center p-4 rounded-lg cursor-pointer  item-calendar`}
                                            width={85}
                                            onClick={()=>handleSelectHours(calendar, data)}
                                        >
                                            { data.hour }
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    </>
}


