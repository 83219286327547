import {Grid, Stack, Typography} from "@mui/material";
import '../SmsEmail.style.scss';
import {useEffect, useState} from "react";
import {GetSmsCountByMairie} from "../../../../../../helpers/histories.helper";
import {CustomLoading} from "../../../../../../application/components/LazyLoading/LazyLoading";
import { useMairieStore } from "../../../../../../application/store/mairie.store";

const actions = [
    // { label: 'Confirmation création de compte', field: 'confirm_account_creation' },
    { label: 'Confirmation du rendez-vous', field: 'confirm_rdv' },
    { label: 'Modification du rendez-vous', field: 'update_rdv' },
    { label: 'Annulation du rendez-vous', field: 'cancel_rdv' },
    { label: 'Rappel rendez-vous', field: 'reminder_rdv' },
    { label: 'Envoi exceptionel', field: 'exception_sms' },
    { label: 'Vérification de compte (code sms)', field: 'otp_verification' },
]

export const VolumeSms = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const  mairies  = useMairieStore((state) => state.mairies);

    useEffect(() => {
        if (mairies[0]) {
            setLoading(true);
            GetSmsCountByMairie(mairies[0]._id)
                .then(result => {
                    setData({...result.data})
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [mairies])
    return <div className={'volume-sms-container'}>
        <Stack maxWidth={'1200px'} margin={'auto'}>
            { loading ? <Stack marginTop={3}><CustomLoading/></Stack> :
                <>
                    <table className={'table'} width={'60%'}>
                        <thead>
                        <tr>
                            <th><Typography textTransform={'uppercase'} fontWeight={'bolder'}>Action</Typography></th>
                            <th><Typography textTransform={'uppercase'} fontWeight={'bolder'}>Nombre de sms consommés</Typography></th>
                        </tr>
                        </thead>
                        <tbody>
                        { actions.map(action => (
                            <tr>
                                <td><Typography>{action.label}</Typography></td>
                                <td><Typography textAlign={'center'}>{(data && data[action.field]) || 0}</Typography></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Stack maxWidth={'1200px'} margin={'auto'}>
                        <Grid container width={'100%'}>
                            <Grid item bgcolor={'#a1d0f6'} padding={1}><Typography fontWeight={'bolder'}>QUOTA: {mairies[0]?.quotaSms || 0} SMS</Typography></Grid>
                            <Grid item bgcolor={'#f69e9e'} padding={1}><Typography fontWeight={'bolder'}>RESTE: {mairies[0]?.sms || 0} SMS</Typography></Grid>
                        </Grid>
                    </Stack>
                </>
            }

        </Stack>
    </div>
}
