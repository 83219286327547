import {Box, Dialog, IconButton, Stack} from "@mui/material";
import {Close} from "@mui/icons-material";

export interface SimpleDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedValue?: string;
    onClose: (value?: string) => void;
    component: any
}

export const CustomDialog = (props: SimpleDialogProps) => {
    const { onClose, open, setOpen, component } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog className="dialog-info" onClose={handleClose} open={open} maxWidth={"lg"}>
            <Box className="p-0">
                <Stack alignItems={"end"}>
                    <Box>
                        <IconButton onClick={() => setOpen(false)}>
                            <Close/>
                        </IconButton>
                    </Box>
                </Stack>
                { component }
            </Box>
        </Dialog>
    );
}
