import {Alert, Box, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import './SmsSending.style.scss';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {NotificationSendEmail} from "../../../helpers/notification.helper";
import {LoadingButton} from "@mui/lab";
import { useMairieStore } from "../../store/mairie.store";

const schema = yup.object().shape({
    to: yup.string().required(),
    subject: yup.string().required(),
});

const EmailSending = ({ usager }) => {
    const mairies = useMairieStore((state) => state.mairies)
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const { register, handleSubmit, formState: {  isValid }, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const onEditorStateChange = (editorState) => {
        setValue("HTMLPart", editorState);
    };

    useEffect(() => {
        register('HTMLPart', { required: true })
    }, [register]);


    useEffect(() => {
        setValue('to', usager.email);
    }, [usager])

    const onSubmitHandler = (data) => {
        setIsLoading(true);
        setIsSent(false);
        NotificationSendEmail({
            to: [{ Email: data.to }],
            subject: data.subject,
            HTMLPart: data.HTMLPart,
            mairie: mairies?.[0]?._id
        }).then(() => {
            setIsSent(true)
        }).finally(() => {
            setIsLoading(false);
        })
        setIsLoading(false)
    }
    return (
        <>
            <Box paddingLeft={2} paddingRight={2}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Stack width={500} maxWidth={500} minWidth={200}>
                        <Stack>
                            <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Destinataire :</Typography>
                            <TextField
                                disabled={true}
                                size={'small'}
                                {...register('to')}
                            />
                        </Stack>
                        <Stack marginTop={2}>
                            <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Sujet :</Typography>
                            <TextField
                                size={'small'}
                                {...register('subject')}
                            />
                        </Stack>
                        <Stack marginTop={2}>
                            <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>texte de l'email* :</Typography>
                            {/* @ts-ignore */}
                            <ReactQuill
                                theme="snow"
                                style={{ marginTop: '.5em' }}
                                value={watch('HTMLPart')}
                                onChange={onEditorStateChange}
                            />
                        </Stack>
                        { isSent ? <Stack marginY={2}>
                            <Alert severity={'success'}>Email envoyé !</Alert>
                        </Stack> : <>
                            <Stack flexDirection={"row"} gap={2} className="py-4 w-full justify-end">
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    color='secondary'
                                    className="btn-rdv"
                                    type="submit"
                                    disabled={!(isValid && watch('HTMLPart')?.length > 0) || isLoading}
                                >
                                    Envoyer
                                </LoadingButton>
                            </Stack>
                        </>
                        }
                    </Stack>
                </form>
            </Box>
        </>
    )
}

export default EmailSending;
