import { Box } from "@mui/material"

export const Historique = () => {
   
    return (
        <>
            <Box marginTop={15}>
                
            </Box>
        </>
    )
}