import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomDialog } from '../../../../../../application/components/CustomDialog';
import { DetailsAgent } from './DetailsAgent';
import WarningIcon from '@mui/icons-material/Warning';
import { handleDeleteUser } from '../../../../../../helpers/user.helper';
import TableSorting from '../../../../../../application/components/table-sorting';
import { getComparator, stableSort } from '../../../../../../infrastructure/utils';
import { Order } from '../../../../../../application/types/type';
import { useMairieStore, getMairieAgent } from '../../../../../../application/store/mairie.store';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D3D3D',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const headCells: readonly any[] = [
    {
        id: ['firstName', 'lastName'],
        numeric: false,
        disablePadding: true,
        filter: true,
        label: 'Nom',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Adresse email',
    },
    {
        id: 'passkey',
        numeric: false,
        disablePadding: false,
        filter: true,
        label: 'Mot de passe',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        filter: false,
        label: 'Action',
    },
];
  
export const ListeAgent= () => {
    const  agents  = useMairieStore((state) => state.agents);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [agent, setAgent] = useState<any>(null);
    const [rows, setRows] = useState<any>(agents);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage};

    useEffect(() => {
        setRows(agents);
    }, [agents])

    const handleCloseModal = () => {
        setOpen(false)
    }

    const handleCloseModalAlert = () => {
        setOpenAlert(false)
    }

    const handleRemoveAgent = (data) => {
        setAgent(data);
        setOpenAlert(true);
    }

    const handleDeleteAgent = async() => {
        const res = await handleDeleteUser(agent);
        if(res){
            setOpenAlert(false);
            getMairieAgent(mairies);
        }
    }
    
    return (
        <Box className="box-mairie">
            <Grid container className="grid-mairie">
                <TableContainer component={Paper}>
                    <TableSorting rows={rows} headCells={headCells} paramsSorting={paramsSorting} setRows={setRows} originalRows={agents}>
                        <>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.lastName} {row.firstName} 
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                                            <StyledTableCell align="left">{row?.passkey}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <TuneIcon 
                                                    className="cursor-pointer" 
                                                    onClick={() => {setAgent(row); setOpen(true)}}
                                                />
                                                <DeleteForeverIcon 
                                                    className="btn-delete"
                                                    onClick={() => handleRemoveAgent(row)}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </>
                    </TableSorting>
                </TableContainer>
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={<DetailsAgent agent={agent} handleCloseModal={handleCloseModal}/>}
            />
            <Dialog
                open={openAlert}
                onClose={handleCloseModalAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className=' text-center'>
                    <WarningIcon sx={{ fill: 'orange' }} /> Suppression d'un agent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez vous vraiment supprimer l'agent {agent?.lastName} {agent?.firstName} ? 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalAlert} variant="outlined" color="primary">Annuler</Button>
                    <Button onClick={handleDeleteAgent} variant="outlined" color="secondary">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}