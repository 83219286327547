import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import '../../Mairie.style.scss';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMairie } from "./form/FormMairie";
import { handleAddMairie } from "../../../../../../../helpers/mairie.helper";
import { useState } from "react";
import { convertPhone } from "../../../../../../../infrastructure/utils";
import { refetchListMairies } from "../../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    name: yup.string().min(2).required(),
    slug: yup.string().required(),
    address: yup.string().required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][^6-7][0-9]+$/)
        .required(),
    email: yup.string().email(),
    username: yup.string().required(),
    zip: yup.string().required(),
    city: yup.object().required(),
    password: yup
        .string()
        .required('Veuillez entrer votre mot de passe')
        .min(8, 'Your password is too short.'),
    confirmPassword: yup
        .string()
        .required('Veuillez retaper votre mot de passe')
        .oneOf([yup.ref('password')], 'Les mots de passe ne sont pas identiques')
});


export const AddMairie = ({setActiveSousMenu}:any) => {
    const [error, setError] = useState<String>('');
    const [success, setSuccess] = useState<Boolean>(false);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    
    const params = {
        register, 
        errors,
        control,
        getValues,
        setValue
    }

    const onSubmitHandler = async (data) => {
        const res = await handleAddMairie({
            ...data,
            city: data?.city?.nom,
            latitude: data?.city?.mairie?.coordinates[1],
            longitude: data?.city?.mairie?.coordinates[0],
            phoneNumber: convertPhone(data?.phoneNumber)
        });
        if(!res?.success){
            if(res?.errorMessage){
                setError(res?.errorMessage);
            }else{
                setError(res?.data);
            }
        }else{
            setSuccess(true);
            refetchListMairies();
            reset();
            setTimeout(() => {
                setActiveSousMenu("liste_mairie");
            },1000)
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Box className="box-mairie">
            <Grid container>
                <Grid item xs={12}>
                    {success && <Alert severity="success">Mairie ajouté avec succès!</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                </Grid>
                <Grid item xs={12} marginTop={2}>
                    <p className="mx-4 uppercase mb-2 underline font-bold">présentation mairie</p>
                </Grid>
                <Grid item xs={12} className="mt-5">
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormMairie {...params} />
                        <Stack className="div-btn-next padding-left-right">
                            <Button className="btn-next" variant={"contained"} color={"secondary"} type="submit">Créer</Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </Box>
    )
}
