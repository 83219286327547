import "../styles/layout.style.scss";
import { AppBar, Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HelpIcon from "@mui/icons-material/Help";
import { ReactComponent as LogoSvg } from "../assets/svg/logo-rdv.svg";
import { ReactComponent as LogoInversedSvg } from "../assets/svg/logo-inversed.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery, useSessionStorage } from "usehooks-ts";
import { BREAKPOINT_MOBILE } from "../../constant";
import { BlocMenuBO, BlocNavIcon } from "../styled/bloc";
import { testAccess } from "../../infrastructure/utils/acces";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { handleGetSettings } from "../../helpers/setting.helper";
import { logoutHandler, useAuthStore } from "../store/auth.store";
import { useMairieStore } from "../store/mairie.store";

const TopbarBO = (props) => {
    const location = useLocation();
    const user = useAuthStore((state) => state.user);
    const mairies = useMairieStore((state) => state.mairies);
    const { enqueueSnackbar } = useSnackbar();
    const [session, setSession] = useSessionStorage("session", {} as any);
    const { variant = "default" } = props;
    const navigate = useNavigate();
    const mobile = useMediaQuery(BREAKPOINT_MOBILE);
    const [settings, setSettings] = useState<any>(null);
    const [notifications, setNotification] = useState<any>({
        seuilNotification: false,
        alertNotification: false,
    });

    const name = useMemo(() => {
        if (["ADMIN", "SUPERADMIN"].includes(user?.role)) {
            return user?.role.replace("_", " ");
        } else {
            return `${user.firstName} ${user?.lastName}`;
        }
    }, [user]);

    const smsMairie = useMemo(() => {
        if (mairies.length > 0) {
            return mairies[0].sms;
        }
        return 0;
    }, [mairies]);

    const getSetting = async () => {
        const res: any = await handleGetSettings({ key: "SUPERADMIN" });
        if (res?.success) {
            setSettings(res?.data);
        } else {
            setSettings({});
        }
    };

    useEffect(() => {
        getSetting();
    }, []);

    useEffect(() => {
        if (settings?.smsSeuilNumber) {
            setNotification((previous) => ({
                ...previous,
                seuilNotification:
                    Number(smsMairie) < Number(settings?.smsSeuilNumber)
                        ? true
                        : false,
            }));
        }

        if (settings?.smsAlertNumber) {
            setNotification((previous) => ({
                ...previous,
                alertNotification:
                    Number(smsMairie) < Number(settings?.smsAlertNumber)
                        ? true
                        : false,
            }));
        }
    }, [settings, smsMairie]);

    const handleLogout = () => {
        if (session) setSession({});
        logoutHandler();
        navigate("/login");
        navigate(0);
    };

    const handleClick = () => {
        if (notifications?.seuilNotification) {
            enqueueSnackbar(
                `Votre solde de SMS est actuellement de ${smsMairie}.  Pensez à recharger votre compte en utilisant le bon de commande qui se trouve dans la rubrique : configurations > Les emails et SMS > Commander SMS`,
                { variant: "warning" }
            );
        }
        if (notifications?.alertNotification) {
            enqueueSnackbar(
                `Important et Urgent : Votre solde de SMS est bientôt épuisé ( ${smsMairie} SMS restant). Pour continuer à profiter de toutes les fonctionnalités de notification du site, nous vous invitons à nous retourner le bon de commande qui se trouve dans la rubrique : configurations > Les emails et SMS > Commander SMS`,
                { variant: "error" }
            );
        }
    };

    return (
        <>
            <AppBar position="fixed" elevation={3}>
                <Grid
                    container
                    display="flex"
                    alignItems={"center"}
                    bgcolor={variant === "condensed" ? "primary" : "white"}
                    height={85}
                    justifyContent="center"
                >
                    <BlocNavIcon
                        item
                        xs={mobile ? 12 : 2}
                        display="flex"
                        justifyContent={mobile ? "center" : "start"}
                        alignItems="center"
                    >
                        <Box
                            className={"flex relative cursor-pointer"}
                            id={"logo-rem"}
                            onClick={() => navigate("/admin/dashboard")}
                        >
                            {variant === "condensed" ? (
                                <LogoInversedSvg />
                            ) : (
                                <LogoSvg />
                            )}
                        </Box>
                    </BlocNavIcon>
                    {!mobile && (
                        <>
                            <BlocMenuBO item xs={9}>
                                {["ADMIN", "AGENT"].includes(user.role) && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/dashboard"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/dashboard"
                                    >
                                        Tableau de bord
                                    </Link>
                                )}
                                {["ADMIN", "AGENT"].includes(user.role) && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/agenda"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/agenda"
                                    >
                                        Agendas
                                    </Link>
                                )}
                                {["ADMIN", "AGENT"].includes(user.role) && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/usager"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/usager"
                                    >
                                        Usagers
                                    </Link>
                                )}
                                {testAccess(user, "space_configuration") && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/configuration"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/configuration"
                                    >
                                        Configurations
                                    </Link>
                                )}
                                {testAccess(user, "space_sms") && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/smsEmail"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/smsEmail"
                                    >
                                        EMAIL/SMS
                                    </Link>
                                )}
                                {testAccess(user, "statistiques") && (
                                    <Link
                                        className={
                                            location.pathname.includes(
                                                "/admin/statistique"
                                            )
                                                ? "link active"
                                                : "link"
                                        }
                                        to="/admin/statistique"
                                    >
                                        Statistiques
                                    </Link>
                                )}
                                {/* <Link className={location.pathname.includes('/admin/historique') ? 'link active': 'link'} to="/admin/historique">Historique</Link> */}
                            </BlocMenuBO>
                        </>
                    )}
                    <Grid
                        item
                        xs={1}
                        className="div-icon-logout items-center"
                        gap={2}
                    >
                        {["ADMIN", "AGENT"].includes(user.role) && (
                            <>
                                <Tooltip title="Support d'aide">
                                    <IconButton>
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Notifications">
                                    <IconButton onClick={handleClick}>
                                        <NotificationsActiveIcon
                                            sx={{ fill: "orange" }}
                                        />
                                        {(notifications?.seuilNotification ||
                                            notifications?.alertNotification) && (
                                            <NewReleasesIcon
                                                sx={{
                                                    fill: "red",
                                                    width: "16px",
                                                    position: "absolute",
                                                    left: "-8px",
                                                    top: "0px",
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        <Stack>
                            <p className="name-user">{name}</p>
                        </Stack>
                        <Tooltip title="Se déconnecter">
                            <IconButton onClick={() => handleLogout()}>
                                <LogoutIcon className="icon-logout" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    );
};

export default TopbarBO;
