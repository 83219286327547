import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export const ViewTableStat = ({rows, total}) => {

    const exportRows = React.useMemo<any[]>(() => {
        const data: any = [['Intitulés', 'Nombre', '%']];
        for (const row of rows) {
            data.push([row.name, row.number, `${row.percent}`])
        }
        data.push(['Total', total, '100' ])
        return data
    }, [rows, total])

    const handleExport = () => {
        const worksheet = XLSX.utils.aoa_to_sheet(exportRows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const csvData = XLSX.utils.sheet_to_csv(worksheet);
        const BOM = "\uFEFF";
        const blob = new Blob([BOM + csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'statistiques.csv'); // Downloads the CSV file
    }

    return (
        <TableContainer component={Paper}>
            <Button onClick={handleExport} variant='contained' color="secondary" style={{float: 'right'}}>.csv</Button>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Intitulés</TableCell>
                    <TableCell align="right">Nombre</TableCell>
                    <TableCell align="right">%</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right">{row.number}</TableCell>
                        <TableCell align="right">{row.percent}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">{total}</TableCell>
                    <TableCell align="right">100 %</TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
