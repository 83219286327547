import { IconButton, Paper, TablePagination, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import { useState } from 'react';
import { checkStatus } from '../../../infrastructure/utils';
import './TableHistorique.style.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#D2C9C2',
    color: '#000000',
    fontWeight: '700'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TableHistorique({ histories, gestionAccount = false }:any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className="mb-4" sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className='table-container-custom'>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>DATE RDV</StyledTableCell>
              <StyledTableCell align="right">Motif</StyledTableCell>
              <StyledTableCell align="right">Réservé</StyledTableCell>
              <StyledTableCell align="right">Statut</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {histories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((historie, id) => {
              const remarque = historie?.metadata?.remarque || null;
              const nombrePersonne = historie?.appointmentId?.nombrePersonne || 1;
              const heureRdv = historie.metadata?.heureRdv;
              const isBeforeToday = dayjs().isBefore(dayjs(heureRdv));
              const agent = historie.metadata?.agent || null;
          
              const status = historie?.metadata?.status === 'PENDING' && !isBeforeToday ? 'NOT_HONORED' : historie?.metadata?.status;
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell component="th" scope="row">
                    {dayjs(heureRdv).format('DD MMM YYYY - HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="right">{nombrePersonne} x {historie?.metadata?.typeDossier?.replaceAll('_', ' ')}</StyledTableCell>
                  <StyledTableCell align="right">
                    {agent ? agent?.firstName ? agent?.firstName+' '+agent?.lastName : 'ADMIN' : 'En ligne'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {checkStatus(status)}
                    {remarque && !gestionAccount &&
                      <Tooltip title={remarque}>
                        <IconButton>
                              <InfoIcon />
                          </IconButton>
                      </Tooltip>}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={histories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
