import { useState } from "react";
import {
    Button,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Paper,
    Stack,
    TextareaAutosize,
    TextField
} from "@mui/material";
import {CancelRounded, CheckCircle} from "@mui/icons-material";
import '../../../Mairie.style.scss';
import {SelectCodePostal} from "../../../../../../../../application/components/SelectCodePostal";
import {InputPhone} from "../../../../../../../../application/components/InputPhone/InputPhone";
import {REACT_APP_URL} from "../../../../../../../../constant";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MuiColorInput } from 'mui-color-input'

export const StepOne = (props) => {
    const { register, errors, getValues, handleSubmit, setValue, mairies, onSubmitHandler } = props;
    const [color, setColor] = useState({
        title: mairies[0]?.color?.title ?? '#c84c51',
        button:  mairies[0]?.color?.button ?? '#c84c51',
        background:  mairies[0]?.color?.background ?? 'white',
        validatePuce:  mairies[0]?.color?.validatePuce ?? '#c84c51',
        nonValidatePuce:  mairies[0]?.color?.nonValidatePuce ?? 'grey'
    })

    const handleChangeColor = (value, type) => {
        setColor(prevState => ({...prevState, [type]: value}));
        setValue('color', {...color, [type]: value})
    }

    return (
        <div className=" mt-5">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Stack gap={2}>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Nom de la Commune*'}
                                    key={mairies[0]?.name}
                                    defaultValue={mairies[0]?.name}
                                    {...register('name')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.name ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('name') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Adresse*'}
                                    defaultValue={mairies[0]?.address}
                                    {...register("address")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.adress ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('adress') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack>
                        <SelectCodePostal disabled={false} register={register} setValue={setValue} data={mairies[0]} />
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <InputPhone {...props} defaultValue={mairies[0]?.phoneNumber?.replace('+33','0')} />
                        </Paper>
                    </Stack>
                    <Stack>
                        <Paper className="custom-paper">
                            <FormControl className="custom-form-control">
                                <OutlinedInput
                                    className={"bg-grey"}
                                    placeholder={'Votre adresse email*'}
                                    type={"email"}
                                    defaultValue={mairies[0]?.email}
                                    {...register("email")}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            { !!errors.email ?
                                                <CancelRounded color={"error"}/> :
                                                (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Paper>
                    </Stack>
                    <Stack>
                        <TextareaAutosize
                            className="bg-grey textarea-mairie"
                            defaultValue={mairies[0]?.description}
                            {...register("description")}
                            aria-label="minimum height"
                            placeholder="Description mairie"
                            minRows={6}
                        />
                    </Stack>
                    <Stack>
                        <TextField
                            id="input-with-icon-textfield"
                            label="IFRAME"
                            disabled={true}
                            defaultValue={`${REACT_APP_URL}/iframe/${mairies[0]?._id}`}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => navigator.clipboard.writeText(`${REACT_APP_URL}/iframe/${mairies[0]?._id}`) } >
                                        <ContentCopyIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }}
                            variant="standard"
                        />
                    </Stack>
                    {mairies[0]?.annexes?.map((annexe: any, index: number) => 
                        <Stack key={index}>
                            <TextField
                                id="input-with-icon-textfield"
                                label={'IFRAME '+annexe.name}
                                disabled={true}
                                defaultValue={`${REACT_APP_URL}/iframe/${annexe?._id}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => navigator.clipboard.writeText(`${REACT_APP_URL}/iframe/${annexe?._id}`) } >
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                variant="standard"
                            />
                        </Stack>
                    )}
                    <p>THEME IFRAME</p>
                    <Grid container gap={2}>
                        <Grid item xs={3}>
                            <p>Couleur du titre :</p>
                            <MuiColorInput value={color.title} onChange={(value) => handleChangeColor(value, 'title')} />
                        </Grid>
                        <Grid>
                            <p>Couleur du bouton :</p>
                            <MuiColorInput value={color.button} onChange={(value) => handleChangeColor(value, 'button')} />
                        </Grid>
                        <Grid>
                            <p>Couleur du background :</p>
                            <MuiColorInput value={color.background} onChange={(value) => handleChangeColor(value, 'background')} />
                        </Grid>
                        <Grid>
                            <p>Couleur des puces validées :</p>
                            <MuiColorInput value={color.validatePuce} onChange={(value) => handleChangeColor(value, 'validatePuce')} />
                        </Grid>
                        <Grid>
                            <p>Couleur des puces non validées :</p>
                            <MuiColorInput value={color.nonValidatePuce} onChange={(value) => handleChangeColor(value, 'nonValidatePuce')} />
                        </Grid>
                    </Grid>
                                
                </Stack>
                <Stack className="div-btn-next">
                    <Button className="btn-next" variant={"contained"} color={"primary"} type="submit">Suivant</Button>
                </Stack>
            </form>
        </div>
    )
}
