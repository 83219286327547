import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Grid } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMemo, useState, useEffect } from 'react';
import { CustomDialog } from '../../../../../../application/components/CustomDialog';
import { AddMotif } from './AddMotif';
import { DetailMotif } from './DetailMotif';
import { getComparator, stableSort } from '../../../../../../infrastructure/utils';
import TableSorting from '../../../../../../application/components/table-sorting';
import { Order } from '../../../../../../application/types/type';
import { useAuthStore } from '../../../../../../application/store/auth.store';
import { removeMotif } from '../../../../../../helpers/motif.helper';
import Swal from 'sweetalert2';
import { getListMotifs, useMairieStore } from '../../../../../../application/store/mairie.store';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D3D3D',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#D2C9C2',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


const headCells: readonly any[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        filter: true,
        label: 'Motifs',
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        filter: true,
        label: 'Durée sans pré-demande',
    },
    {
        id: 'antsDuration',
        numeric: true,
        disablePadding: false,
        filter: true,
        label: 'Durée avec pré-demande',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        filter: false,
        label: 'Action',
    },
];

const headCellsSuperAdmin: readonly any[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        filter: false,
        label: 'Motifs',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        filter: false,
        label: 'Action',
    },
];
  
export const ListeMotif = ({ motifs }) => {
    const  user  = useAuthStore((state) => state.user);
    const  mairies  = useMairieStore((state) => state.mairies);
    const [open, setOpen] = useState(false);
    const [motif, setMotif] = useState<any>();
    const [rows, setRows] = useState<any>(motifs);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const paramsSorting = {order, setOrder, orderBy, setOrderBy, page, setPage, rowsPerPage, setRowsPerPage};

    const handleCloseModal = () => {
        setOpen(false)
    }

    useEffect(() => {
        setRows(motifs);
    }, [motifs]);

    const detailsMotif = useMemo(() => {
        if(user?.role === 'SUPERADMIN'){
            return <DetailMotif dataMotif={motif} handleCloseModal={handleCloseModal} />
        }else{
            return <AddMotif dataMotif={motif} handleCloseModal={handleCloseModal}/>
        }
    }, [user, motif])

    const handleRemoveMotif = async(data) => {
        const res: any = await removeMotif(data);
        if(res?.success){
            Swal.fire(
                'Supprimé!',
                'Le motif a été supprimé avec succès.',
                'success'
            )
            getListMotifs(mairies);   
        }else{
            Swal.fire('Erreur!', res?.errorMessage, 'error')
        }
    }

    return (
        <Box className="box-mairie">
            <Grid container className="grid-mairie">
                <TableContainer component={Paper}>
                    <TableSorting rows={rows} headCells={ user?.role === 'SUPERADMIN' ? headCellsSuperAdmin : headCells  } paramsSorting={paramsSorting} setRows={setRows} originalRows={motifs}>
                        <>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index) => {
                                    return (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name} 
                                            </StyledTableCell>
                                            {user?.role !== 'SUPERADMIN' && <StyledTableCell align="left">{row.duration} mn</StyledTableCell>}
                                            {user?.role !== 'SUPERADMIN' && <StyledTableCell align="left">{row.antsDuration ? `${row.antsDuration}  mn` : ''}</StyledTableCell>}
                                            <StyledTableCell align="center">
                                                <TuneIcon 
                                                    className="cursor-pointer" 
                                                    onClick={() => {setMotif(row); setOpen(true)}}
                                                />
                                                {!row.isDefault && <DeleteForeverIcon 
                                                    className="btn-delete"
                                                    onClick={() => handleRemoveMotif(row)}
                                                />}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            }
                        </>
                    </TableSorting>
                </TableContainer>
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={detailsMotif}
            />
        </Box>
  
    )
}