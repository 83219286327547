import axios from "axios";
import {API} from "../constant";

let authCookie: string = '';

export default class NODE_API {
  static async login() {
    if(localStorage.getItem('access_token')){
        authCookie = `Bearer ${localStorage.getItem('access_token')}`
        axios.defaults.headers.common['Authorization'] = authCookie;
    }
    return authCookie;
  }
  
  static async get(endpoint: string, params = {}, headers = {}) {
    try {
      await NODE_API.login();

      return (await axios.get(API + endpoint, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          ...headers
        }, params
      }))?.data;

    } catch (e: any) {
      return null;
    }
  }

  static async post(endpoint: string, data: any = {}, headers = {}) {
    try {
      await NODE_API.login();
      
      const res = (await axios.post(API + endpoint, data, { headers }))?.data;
      
      return res;
    } catch (e: any) {
      return e.response.data;
    }
  }

  static async put(endpoint: string, data: any = {}, headers = {}) {
    try {
      await NODE_API.login();
      
      const res = (await axios.put(API + endpoint, data, { headers }))?.data;
      
      return res;
    } catch (e: any) {
      return e.response.data;
    }
  }

  static async delete(endpoint: string, headers = {}) {
    try {
      await NODE_API.login();
      
      const res = (await axios.delete(API + endpoint, { headers }))?.data;
      
      return res;
    } catch (e: any) {
      return e.response.data;
    }
  }
}
