import {Alert, Box, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import './SmsSending.style.scss';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {NotificationSendSMS} from "../../../helpers/notification.helper";
import {LoadingButton} from "@mui/lab";


const schema = yup.object().shape({
    phoneNumber: yup.string().required(),
    message: yup.string().required()
});

const SmsSending = ({ usager, mairie }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const { register, handleSubmit, formState: {  isValid }, setValue, watch } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    useEffect(() => {
        setValue('phoneNumber', usager.phoneNumber)
    }, [usager])

    const onSubmitHandler = (data) => {
        setIsLoading(true);
        setIsSent(false);
        NotificationSendSMS({
            to: [{ phone_number: data.phoneNumber }],
            messages: data.message,
            mairieId: mairie.slug,
            metadata: { type: 'exception_sms' }
        }).then(() => {
            setIsSent(true)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <>
            <Box paddingLeft={2} paddingRight={2}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Stack width={500} maxWidth={500} minWidth={200}>
                        <Stack>
                            <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Destinataire :</Typography>
                            <TextField
                                disabled={true}
                                size={'small'}
                                {...register('phoneNumber')}
                            />
                        </Stack>
                        <Stack marginTop={2}>
                            <Typography sx={{ textTransform: 'uppercase' }} variant={'caption'} fontWeight={'bold'}>Message* :</Typography>
                            <TextField
                                label=""
                                multiline
                                variant={'outlined'}
                                rows={4}
                                helperText={`${(watch('message')?.length || 0) % 160}/160 | ${Math.ceil(watch('message')?.length / 160) || 0 } sms`}
                                {...register('message')}
                            />
                        </Stack>
                        { isSent ? <Stack marginY={2}>
                            <Alert severity={'success'}>Message envoyé !</Alert>
                        </Stack> : <>
                            <Stack flexDirection={"row"} gap={2} className="py-4 w-full justify-end">
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    color='secondary'
                                    className="btn-rdv"
                                    type="submit"
                                    disabled={!isValid || isLoading}
                                >
                                    Envoyer
                                </LoadingButton>
                            </Stack>
                        </>
                        }
                    </Stack>
                </form>
            </Box>
        </>
    )
}

export default SmsSending;
