import { Box, Stack } from "@mui/material"
import { Link } from "react-router-dom"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

export const ViewAlert = () => {
    return (
        <Box className="px-3 pb-3">
            <Stack flexDirection={'row'} justifyContent={'center'}>
                <CheckCircleOutlineIcon sx={{ fill: 'green', fontSize: '50px'}}/>
            </Stack>
            <p>Votre rendez-vous viens d'etre modifié.</p>
            <p>Vous pouvez accéder à voter compte pour gérer vos rendez-vous.</p>
            <Link
                to={`/gestion/login/`}
            >
                Gérer mes rendez-vous
            </Link>
        </Box>
    )
}