import {Topbar} from "../../../application/layout";
import React, {useCallback, useEffect, useState} from "react";
import {Box, Grid, Stack} from "@mui/material";
import {RdvStepper} from "../../../application/components/RdvStepper";
import {PreviewMairie} from "../../../application/components/PreviewMairie";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BREAKPOINT_TABLETTE} from "../../../constant";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import uuid from 'react-uuid';
import { handleGetMairieBySlug } from "../../../helpers/mairie.helper";
import { handleCheckBusy, handleUpSertBusy } from "../../../helpers/busy.helper";
import WarningPage from "../../../application/components/WarningPage";
import { CustomLoading } from "../../../application/components/LazyLoading/LazyLoading";
import { useMairieStore } from "../../../application/store/mairie.store";
dayjs.extend(utc);
dayjs.extend(timezone); 

export const ConfirmationRdv = () => {
    const  filteredMairies  = useMairieStore((state) => state.filteredMairies);
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [session, setSession] = useSessionStorage('session', {} as any);
    const navigate = useNavigate();
    const [ville, setVille] = useState({} as any);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);

    const getMairieBySlug = useCallback(async() => {
        if(searchParams.get('mairie')){
            const res = await handleGetMairieBySlug(searchParams.get('mairie'));
            
            handleCheckBusyViaAntsUrl({
                _id: res?._id,
                agenda: searchParams.get('agenda'),
                dateRdv: dayjs(searchParams.get('date')).format('DD/MM/YYYY'),
                heureRdv: (searchParams.get('date') ?? '').replace('Z',''),
                sessionId: session?.id,
                person: searchParams.get('person'),
                duration: searchParams.get('duration')
            })

            setSession(prev => ({...prev, ville: res }));
            setVille(res);
        }
    }, [searchParams, session?.id]);

    useEffect(() => {
        getMairieBySlug();
    }, [getMairieBySlug]);

    const handleCheckBusyViaAntsUrl = async(data) => {
      setLoading(true)
      const isDispo = await handleCheckBusy({
        mairie: data._id,
        agenda: data.agenda,
        dateRdv: data.dateRdv,
        heureRdv: data.heureRdv,
        sessionId: data.sessionId,
        duration: Number(data.duration)*Number(data?.person),
      })
      if(isDispo){
        await handleUpSertBusy({
          mairie: data._id,
          agenda: data.agenda,
          dateRdv: data.dateRdv,
          heureRdv: data.heureRdv,
          duration: Number(data.duration)*Number(data?.person),
          sessionId: data.sessionId 
        })
      }else{
        setError(true);
      }
      setLoading(false)
    }
    useEffect(() => {
        let data = {};
        if(searchParams.get('mairie')){
            const villeUser = {
                nom : capitalize(searchParams.get('mairie')?.replaceAll('_', ' ')),
                codesPostaux: searchParams.get('code'),
            }
            let sessionId = uuid();

            if(session?.id){
                sessionId = session?.id;
            }
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            data = {
                ...data, 
                id: sessionId,
                motif: {key: searchParams.get('reason'), label : searchParams.get('reason')?.replaceAll('_', ' ')}, 
                code: searchParams.get('code'),
                villeUser, 
                bureau: searchParams.get('agenda'),
                typeDossier: searchParams.get('reason'),
                heureRdv: dayjs.tz(searchParams.get('date')?.replace('Z', ''), timezone),
                nombrePersonne: searchParams.get('person'),
                duration: searchParams.get('duration'),
                type: { isMairie: false, isOnline: true }
            }
        } 
        setSession(prev => ({...prev, ...data}));
    }, [searchParams])

    useEffect(() => {
        if(!searchParams.get('reason')){
            if (!session.heureRdv) {
                navigate('/search')
            } else {
                const selectedVille = filteredMairies.find(v => v.slug === session?.ville?.slug);
                setVille(selectedVille);
            }
        }
    }, [filteredMairies, searchParams, session.heureRdv, session?.ville?.slug]);

    

    return <>
      {loading && 
        <Box className="box-loading">
            <CustomLoading size={100} /> 
        </Box>
      }
      {!loading && <>
        {!error ? 
          <Box sx={{width: '100%'}}>
              <Topbar variant={"condensed"}/>
              {!isEmpty(ville) && <Stack marginLeft={tablette ? 0: 10} marginTop={13}>
                  <Grid container>
                      <Grid item xs={tablette ? 12 : 6} marginY={tablette ? 0 : 5} padding={5}>
                          <Stack gap={3}>
                              <PreviewMairie item={ville} />
                          </Stack>
                      </Grid>
                      <Grid item xs={tablette ? 12 : 5}>
                          <Stack className={"bg-neutral-100 h-full"} paddingY={8} paddingX={4}>
                              <RdvStepper onSubmitSuccess={() => {
                                  navigate('/confirmation-rdv/confirmed');}
                              }/>
                          </Stack>
                      </Grid>
                  </Grid>
              </Stack>}
          </Box>
        : 
        <WarningPage />}
      </>}
    </>
}
