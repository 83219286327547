import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Stack, TextareaAutosize, TextField , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material"
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { CancelRounded, CheckCircle, PhotoCamera } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';
import { SelectCodePostal } from "../../../../../../../application/components/SelectCodePostal";
import { InputPhone } from "../../../../../../../application/components/InputPhone/InputPhone";
import dayjs, { Dayjs } from 'dayjs';
import frLocale from 'date-fns/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { CalendarWeek } from "../../../../../../../application/components/CalendarWeek/CalendarWeek";
import { initialCalendar } from "../../../../../../../infrastructure/data/initialCalendar";
import { handlePutCalendar } from "../../../../../../../helpers/calendar.helper";
import { handleUpdateMairie } from "../../../../../../../helpers/mairie.helper";
import { uploadImage } from "../../../../../../../helpers/upload.helper";
import { API } from "../../../../../../../constant";
import { convertPhone } from "../../../../../../../infrastructure/utils";
import { handleGetAppointmentBy } from "../../../../../../../helpers/appointment.helper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { refetchListMairies } from "../../../../../../../application/store/mairie.store";

const schema = yup.object().shape({
    name: yup.string().min(2).required(),
    address: yup.string().min(2).required(),
    phoneNumber: yup.string()
        .transform((_value, originalValue) => {
            return originalValue.replace(/[^0-9]/g, '');
        })
        .min(10).max(10)
        .matches(/^[0][^6-7][0-9]+$/)
        .required(),
    email: yup.string().email()
});

export const DetailsMairie = ({ mairie, handleCloseModal }: any) => {
    const alertSection: any = useRef(null);
    const [profile, setProfile] = useState<any>(null);
    const [holiday, setHoliday] = useState<Dayjs | null>(dayjs());
    const [success, setSuccess] = useState<boolean>(false);
    const [fileProfile, setFileProfile] = useState<any>(null);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [holidayAlert, setHolidayAlert] = useState<any>(null);
    const [error, setError] = useState<boolean>(false);
    const [holidays, setHolidays] = useState<any>(mairie.calendar?.holidays || []);
    const [calendarWeek, setCalendarWeek] = useState(mairie.calendar?.hours || initialCalendar);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    
    useEffect(() => {
        if(mairie?.picture){
            setProfile(API+mairie?.picture);
        }
    }, [mairie])

    const handleChangeHour = (data, type, index) => {
        let startOrEnd = type;
        const typeHour = type.includes('2') ? 1 : 0;
        if(typeHour === 1) {
            startOrEnd = type === 'startHour2' ? 'startHour' : 'endHour';
        }
        let prevHour = calendarWeek;
        if(data){
            prevHour[index][typeHour][startOrEnd] = dayjs(data).format('HH:mm');   
        }else{
            prevHour[index][typeHour][startOrEnd] = '-';
        }
        setCalendarWeek(prevHour);
        setValue('hours', prevHour);
    }
    const props = { register, handleSubmit, errors , getValues, control };

    const handleChangeProfile = (e) => {
        setFileProfile(e.target.files[0]);
        setProfile(URL.createObjectURL(e.target.files[0]));
    }

    const onSubmitHandler = async(data) => {
        let input = {...data, _id: mairie._id, city: data?.city.nom, phoneNumber: convertPhone(data?.phoneNumber)};
        
        if(fileProfile){
            const resUpload = await uploadImage(fileProfile, mairie.slug, 'photos');
            if(resUpload?.success){
                input = {...input, picture: resUpload.data};
            }
        }

        const res = await handleUpdateMairie(input);
        if(res?.success){
            await handlePutCalendar({
                _id: mairie._id,
                hours: data.hours,
                holidays: holidays
            })
            setSuccess(true);
            refetchListMairies();
            alertSection?.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }
    };

    const handleAddHoliday = () => {
        if(holidayAlert){
            const day = dayjs(holidayAlert).format('MM/DD/YYYY');
            const isExist = holidays.find(data => data === day);
            if(isExist){
                setError(true);
            }else{
                setHolidays((current) => [...current, dayjs(holidayAlert).format('MM/DD/YYYY')]);
            }
        }
        setOpenAlert(false);

    }

    const handleCloseModalAlert = () => {
        setOpenAlert(false);
    }

    const addHolidays = async(date) => {
        const day = dayjs(date).format('MM/DD/YYYY');
        const isExist = holidays.find(data => data === day);
        if(isExist){
            setError(true);
        }else{
            setError(false);
            const res = await handleGetAppointmentBy(mairie.slug, dayjs(date).format('YYYY-MM-DD'));
            if(res?.length > 0){
                setOpenAlert(true);
                setHolidayAlert(date);
            }else{
                setHolidays((current) => [...current, day]);
            }
        }
    }

    const handleRemoveHoliday = (holiday) => {
        setHolidays(holidays.filter(item => item !== holiday));
    }

    return (
        <Box>
            <Grid container spacing={2} className='grid-mairie details-mairie px-5' >
                <Grid item xs={12} ref={alertSection}>
                    {success && <Alert severity="success" className="mt-2">Mairie modifié avec succès!</Alert>}
                </Grid>
                <Grid item xs={4}>
                    <p className="font-bold uppercase">Photo</p>
                    <Grid item xs={4}>
                        <div className="bloc-image">
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/*" type="file" onChange={handleChangeProfile} />
                                {profile ? <img className="profile-mairie" src={profile} width={"100%"} height={"100%"} alt={mairie.name} /> : <PhotoCamera />}
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Stack gap={2}>
                        <p className="font-bold uppercase">présentation mairie</p>
                        <Stack>
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        placeholder={'Nom de la Commune*'}
                                        defaultValue={mairie.name}
                                        {...register("name")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.name ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('name') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Paper>
                        </Stack>
                        <Stack>
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        placeholder={'Adresse*'}
                                        defaultValue={mairie.address}
                                        {...register("address")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.adress ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('lastName') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Paper>            
                        </Stack>
                        <Stack>
                            <SelectCodePostal disabled={false} register={register} setValue={setValue} data={mairie} />
                        </Stack>  
                        <Stack>
                            <Paper className="custom-paper">
                                <InputPhone {...props} defaultValue={mairie.phoneNumber.replace('+33','0')} />
                            </Paper>
                        </Stack>
                        <Stack>
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        placeholder={'Votre adresse email*'}
                                        type={"email"}
                                        defaultValue={mairie.email}
                                        {...register("email")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                { !!errors.email ?
                                                    <CancelRounded color={"error"}/> :
                                                    (!!getValues('email') && <CheckCircle color={"secondary"}/>)}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Paper>
                        </Stack>
                        <Stack>
                            <TextareaAutosize
                                className="bg-grey textarea-mairie"
                                defaultValue={mairie.description}
                                {...register("description")}
                                aria-label="minimum height"
                                minRows={6}
                            />
                        </Stack>
                    </Stack>
                    <Stack className="mt-4">
                        <p className="font-bold uppercase">Horaires</p>
                        <div className="bloc-hour mt-4">
                            <p className="title-bloc">Heures d'ouverture*</p>
                            <CalendarWeek calendarWeek={calendarWeek} handleChangeHour={handleChangeHour} isDisabled={false} />
                        </div>
                        <div className="bloc-hour">
                            <p className="mb-2 title-bloc">Jours fériés</p>
                            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                    <DesktopDatePicker
                                        value={holiday}
                                        minDate={dayjs('2017-01-01')}
                                        inputFormat={'dd/MM/yyyy'}
                                        onChange={(newValue) => {
                                            setHoliday(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                    />
                                </LocalizationProvider>
                                <AddCircleIcon className="cursor-pointer" sx={{ color: '#44c644' }} onClick={() => addHolidays(holiday)} />
                            </Stack>
                            {error && <p className="text-red">Cette date existe déjà sur votre liste de jour férié.</p>}
                            <p className="mt-2 title-bloc">Liste des jours :</p>
                            <Stack flexDirection={'row'} flexWrap={'wrap'} gap={1} alignItems={'center'}>
                                {holidays?.length > 0 && holidays.map(holiday => 
                                    <Stack flexDirection={'row'}  gap={1} alignItems={'center'} className="item-holiday">
                                        <p>{dayjs(holiday).format('DD MMM YYYY')}</p>
                                        <DeleteIcon className="cursor-pointer" sx={{ color: 'red', fontSize: '16px'}} onClick={() => handleRemoveHoliday(holiday)} />
                                    </Stack>
                                )}
                            </Stack>
                        </div>
                    </Stack>
                    <div className="div-btn-save">
                        <Button className="btn-next" variant={"contained"} color={"secondary"} startIcon={<SaveIcon />} type="submit">Enregistrer</Button>
                        <Button className="btn-next" variant={"contained"} color={"primary"} onClick={handleCloseModal}>Annuler</Button>
                    </div>
                </form>
                </Grid>
            </Grid>
            <Dialog
                open={openAlert}
                onClose={handleCloseModalAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className=' text-center'>
                    <WarningIcon sx={{ fill: 'orange' }} /> Avertissement
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Attention il y des rendez-vous qui sont déjà prises pour ce date.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalAlert} variant="outlined" color="primary">Annuler</Button>
                    <Button onClick={handleAddHoliday} variant="outlined" color="secondary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
