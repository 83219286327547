import { Box } from "@mui/material";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/auth.store";
import { LIST_SOUS_MENU_SUPER_ADMIN, LIST_SOUS_MENU } from "./data";
import './SousMenu.style.scss';

export const SousMenuConfig = () => {
    const  user  = useAuthStore((state) => state.user);

    const menus = useMemo(() => {
        if(user?.role === 'SUPERADMIN')
            return LIST_SOUS_MENU_SUPER_ADMIN;
        else
            return LIST_SOUS_MENU;
    }, [user]);

    const location = useLocation();

    return (
        <Box>
            <div className="div-sous-menu">
                {menus.map((menu, key) => 
                    <Link
                        key={key}
                        className={location.pathname.includes(`/admin/configuration/${menu.key}`) ? 'item-sous-menu active' : 'item-sous-menu'}
                        to={`/admin/configuration/${menu.key}`}
                    >
                        {menu.name}
                    </Link>
                )}
            </div>
        </Box>
    )
}