import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Alert, Box, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { handlePostAgendaParameter, handlePutAgendaParameter } from "../../../../../../helpers/agendaParameter.helper";
import { refetchListMairies, useMairieStore } from "../../../../../../application/store/mairie.store";
import { TimePicker } from 'antd';
import 'antd/dist/antd.min.css';
import dayjs from 'dayjs';

export const Parameter = () => {
    const  mairies  = useMairieStore((state) => state.mairies);
    const [error, setError] = useState<String>('');
    const [success, setSuccess] = useState<Boolean>(false);
    const [agendaHour, setAgendaHour] = useState<any>({
        startHour: '07:00',
        endHour: '18:00'
    })
    const { register, handleSubmit } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        if(mairies[0]?.agendaParameter?.agendaHour){
            setAgendaHour(mairies[0]?.agendaParameter?.agendaHour)
        }
    }, [mairies]);

    const handleChangeHour = (data, type) => {
        setAgendaHour(prevState => ({...prevState, [type]: dayjs(data).format('HH:mm')}))
    }

    const onSubmitHandler = async(data) => {
        let alertRdv: any = [];
        let verificationAccount: any = [];
        let verificationAdministrated: any = [];
        let notification: any = [];
        let res:any = {};
        Object.keys(data).map((key) => {
            if(key.includes("alerte")){
                const newKey = key.split('_')[1];
                alertRdv.push({ type: newKey.toUpperCase(), checked: data[key] });
            }
            if(key.startsWith("verificationAccount")){
                verificationAccount.push({
                    type: data[key].toUpperCase(),
                    checked: true
                });
            }
            if(key.startsWith("verificationAdministrated")){
                verificationAdministrated.push({
                    type: data[key].toUpperCase(),
                    checked: true
                });
            }
            if(key.startsWith("notification")){
                notification.push({
                    type: data[key].toUpperCase(),
                    checked: true
                });
            }
        })
        let input: any = {
            mairie: mairies[0]?._id,
            defaultView: data.defaultView,
            step: data.step,
            alertNewRdv: alertRdv,
            reminderHour: data?.reminderHour || 48,
            takeRdvType: data.takeRdvType,
            until_number: data.until_number,
            until_type: data.until_type,
            configurationWeek: data.configurationWeek,
            agendaHour: agendaHour,
            notification,
            verificationAdministrated,
            verificationAccount
        }
        
        if(input?.takeRdvType === 'before_date'){
            input = {
                ...input,
                before_number: data.before_number,
                before_type: data.before_type,
            }
        }

        if(mairies[0]?.agendaParameter){
            res = await handlePutAgendaParameter({
                ...input, _id: mairies[0]?.agendaParameter?._id
            });
            if(res?.success)
                setSuccess(true);
            else
                setError(res?.errorMessage);
        }else{
            res = await handlePostAgendaParameter({
                ...input
            });
            if(res?.success)
                setSuccess(true);
            else
                setError(res?.errorMessage);
        }
        refetchListMairies();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <>
            {mairies[0] && 
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Grid item xs={12} marginTop={2}>
                        {success && <Alert severity="success" className="mt-2">Mairie modifié avec succès!</Alert>}
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                    <Box className="box-admin">
                        <p>S' applique à tous les agendas</p>
                        <div className="form-parameter">
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Vue par défaut :</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="defaultView"
                                    defaultValue={(mairies[0]?.agendaParameter?.defaultView)?.toLowerCase() ?? 'day'}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="day" control={<Radio {...register('defaultView')} />} label="Jour" />
                                    <FormControlLabel value="week" control={<Radio {...register('defaultView')} />} label="Semaine" />
                                    <FormControlLabel value="month" control={<Radio {...register('defaultView')} />} label="Mois" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Pas de l'agenda :</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="step"
                                    defaultValue={mairies[0]?.agendaParameter?.step ?? '30'}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="5" control={<Radio {...register('step')} />} label="5mn" />
                                    <FormControlLabel value="10" control={<Radio {...register('step')} />} label="10mn" />
                                    <FormControlLabel value="15" control={<Radio {...register('step')} />} label="15mn" />
                                    <FormControlLabel value="20" control={<Radio {...register('step')} />} label="20mn" />
                                    <FormControlLabel value="30" control={<Radio {...register('step')} />} label="30mn" />
                                    <FormControlLabel value="60" control={<Radio {...register('step')} />} label="60mn" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Présentation de l'agenda :</FormLabel>
                                <Stack flexDirection={'row'} gap={1} alignItems={'end'}>
                                    <p className="mb-0">matin: </p>
                                    <Stack
                                        spacing={1}
                                    >
                                        <TimePicker 
                                            format={'HH:mm'} 
                                            className="input-time-picker"
                                            placeholder="-" 
                                            value={dayjs().hour(Number(agendaHour?.startHour.split(':')[0])).minute(Number(agendaHour?.startHour.split(':')[1])) as any}
                                            onChange={(value) => handleChangeHour(value, 'startHour')}
                                        />
                                    </Stack>
                                    <p className="mb-0">soir: </p>
                                    <Stack
                                        spacing={1}
                                    >
                                        <TimePicker 
                                            format={'HH:mm'} 
                                            className="input-time-picker"
                                            placeholder="-" 
                                            value={dayjs().hour(Number((agendaHour?.endHour.split(':'))[0])).minute(Number((agendaHour?.endHour.split(':'))[1])) as any}
                                            onChange={(value) => handleChangeHour(value, 'endHour')}
                                        />
                                    </Stack>
                                </Stack>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Les RDV en ligne peuvent être pris :</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="position"
                                    defaultValue={mairies[0]?.agendaParameter?.takeRdvType ?? 'last_min'}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="last_min" control={<Radio  {...register('takeRdvType')} />} label="Jusqu'à la dernière minute" />
                                    <FormControlLabel value="before_date" control={<Radio {...register('takeRdvType')} />} label="au plus tard" />
                                    <div className="flex justify-center items-center">
                                        <TextField 
                                            id="number-day" 
                                            variant="outlined" 
                                            type={'number'} 
                                            defaultValue={mairies[0]?.agendaParameter?.before_number ?? 1} 
                                            {...register('before_number', { valueAsNumber: true, validate: (value) => value > 0 })}
                                            size="small"   
                                            style = {{width: 65}}
                                        />
                                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                {...register('before_type')}
                                                size="small"
                                                defaultValue={mairies[0]?.agendaParameter?.before_type ?? 'day'} 
                                                autoWidth
                                            >
                                                <MenuItem value='hour'>Heure</MenuItem>
                                                <MenuItem value='day'>Jour</MenuItem>
                                                <MenuItem value='week'>Semaine</MenuItem>
                                                <MenuItem value='month'>Mois</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p className="mb-0">avant</p>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="form-radio items-center">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio" sx={{ marginTop: '0px !important' }}>Les RDV en ligne peuvent être pris :</FormLabel>
                                <div className="flex justify-center items-center">
                                    <p className="mb-0">Jusqu'à </p>
                                    <TextField 
                                        id="number-day" 
                                        variant="outlined" 
                                        type={'number'} 
                                        defaultValue={mairies[0]?.agendaParameter?.until_number ?? 1}  
                                        {...register('until_number', { valueAsNumber: true, validate: (value) => value > 0 })}
                                        size="small"   
                                        sx={{ mx: 1, width: 65}}
                                    />
                                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            {...register('until_type')}
                                            size="small"
                                            defaultValue={mairies[0]?.agendaParameter?.until_type ?? 'month'}  
                                            autoWidth
                                        >
                                            <MenuItem value='day'>Jour</MenuItem>
                                            <MenuItem value='week'>Semaine</MenuItem>
                                            <MenuItem value='month'>Mois</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <p className="mb-0">à l'avance</p>
                                </div>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Gérer la récurrence des créneaux :</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="step"
                                    defaultValue={mairies[0]?.agendaParameter?.configurationWeek}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="true" control={<Radio {...register('configurationWeek')} />} label="Oui" />
                                    <FormControlLabel value="false" control={<Radio {...register('configurationWeek')} />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                            {/* <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Alertes nouveaux RDV :</FormLabel>
                                <FormControlLabel
                                    {...register('alerte_dashboard')}
                                    control={
                                        <Checkbox 
                                            defaultChecked={listAlertRdv.find((data) => data.type === 'DASHBOARD')?.checked}
                                        />
                                    }
                                    label="Tableau de bord"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    {...register('alerte_sms')}
                                    control={
                                        <Checkbox 
                                            defaultChecked={listAlertRdv.find((data) => data.type === 'SMS')?.checked}
                                        />
                                    }
                                    label="SMS"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    {...register('alerte_email')}
                                    control={
                                        <Checkbox 
                                            defaultChecked={listAlertRdv.find((data) => data.type === 'EMAIL')?.checked}
                                        />
                                    }
                                    label="Mail"
                                    labelPlacement="end"
                                />
                            </FormControl> */}
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Vérification compte (Prise de rendez-vous)</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="step"
                                    defaultValue={mairies[0]?.agendaParameter?.verificationAccount ? mairies[0]?.agendaParameter?.verificationAccount[0]?.type : 'SMS'}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="SMS" control={<Radio {...register('verificationAccount')} />} label="SMS" />
                                    <FormControlLabel value="EMAIL" control={<Radio {...register('verificationAccount')} />} label="Email" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Vérification compte administré (modification / annulation rendez-vous)</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="step"
                                    defaultValue={mairies[0]?.agendaParameter?.verificationAdministrated ? mairies[0]?.agendaParameter?.verificationAdministrated[0]?.type : 'SMS'}
                                    className="group-radio"
                                >
                                    <FormControlLabel value="SMS" control={<Radio {...register('verificationAdministrated')} />} label="SMS" />
                                    <FormControlLabel value="EMAIL" control={<Radio {...register('verificationAdministrated')} />} label="Email" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl className="form-radio">
                                <FormLabel id="demo-form-control-label-placement" className="text-black title-radio">Notification avant le rendez-vous :</FormLabel>
                                <Stack>
                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                        <p className="mb-0">Avant</p>
                                        <RadioGroup
                                            row
                                            aria-labelledby="step"
                                            defaultValue={mairies[0]?.agendaParameter?.reminderHour ? mairies[0]?.agendaParameter?.reminderHour : 48}
                                            className="group-radio"
                                        >
                                            <FormControlLabel value={48} control={<Radio {...register('reminderHour')} />} label="48h" />
                                            <FormControlLabel value={24} control={<Radio {...register('reminderHour')} />} label="24h" />
                                        </RadioGroup>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                        <p className="mb-0">par</p>
                                        <RadioGroup
                                            row
                                            aria-labelledby="step"
                                            defaultValue={mairies[0]?.agendaParameter?.notification ? mairies[0]?.agendaParameter?.notification[0]?.type : 'SMS'}
                                            className="group-radio"
                                        >
                                            <FormControlLabel value="SMS" control={<Radio {...register('notification')} />} label="SMS" />
                                            <FormControlLabel value="EMAIL" control={<Radio {...register('notification')} />} label="Email" />
                                        </RadioGroup>
                                    </Stack>
                                </Stack>
                            </FormControl>
                        </div>    
                    </Box>
                    <div className="btn-save-reset padding-left-right">
                        <Button className="btn-next" variant={"contained"} color={"secondary"} startIcon={<SaveIcon />} type="submit">Enregistrer</Button>
                        <Button className="btn-next" variant={"contained"} color={"primary"} onClick={() => null}>Annuler</Button>
                    </div>
                </form>
            }
        </>
    )
}
