import NODE_API from "./axios.helper";
import { v4 as uuid } from 'uuid';

export const NotificationSendSMS = async (payload: { messages: string, to: any[], mairieId: any, metadata: any }) => {
    try {
        return NODE_API.post(`/notification/send-sms`, payload);
    } catch (error) {
        console.log(error);
    }
}

export const NotificationSendSmsMultiple = async (payload: { messages: string, to: any[], mairieId: any, metadata: any }) => {
    try {
        return NODE_API.post(`/notification/send-sms/multiple`, payload);
    } catch (error) {
        console.log(error);
    }
}

export const NotificationSendEmail = async (payload: { HTMLPart: string, to: any[], subject: string, mairie: string }) => {
    const CustomID = uuid()
    try {
        return NODE_API.post(`/notification/send-email`, { ...payload, CustomID  });
    } catch (error) {
        console.log(error);
    }
}
