import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Popover,
    Stack,
    useMediaQuery,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import CancelIcon from "@mui/icons-material/Cancel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API, BREAKPOINT_TABLETTE } from "../../constant";
import { ReactComponent as TownHallSvg } from "../assets/svg/town-hall.svg";
import NODE_API from "../../helpers/axios.helper";
import { FullCalendarView } from "./FullCalendarView";
import {
    handleGetMairieBySlug,
    handleGetMairieSlotTime,
} from "../../helpers/mairie.helper";
import { getMotifsBySlug } from "../../helpers/motif.helper";
import { CustomLoading } from "./LazyLoading/LazyLoading";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    handleDeleteAppointment,
    handleGetStatus,
    handlePostAppointment,
} from "../../helpers/ants.helper";
import { testStatusAntsNumber } from "../../infrastructure/utils/ants";
import { isEmpty } from "lodash";
import { CustomDialog } from "./CustomDialog";
import { DetailsDuplicate } from "./DetailsDuplicate";
import { Popup } from "./Popup/Popup";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { useMairieStore } from "../store/mairie.store";
import { refetchBusyDate } from "../store/agenda.store";
import { handleUpdateAppointment } from "../../helpers/appointment.helper";
import { handleAddHistories } from "../../helpers/histories.helper";
require("dayjs/locale/fr");

const formSchema = {
    showAnts: yup.boolean(),
    firstName: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
        .min(2)
        .required(),
    lastName: yup
        .string()
        .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-_]+$/)
        .min(2)
        .required(),
    ants: yup.string().when("showAnts", {
        is: true,
        then: yup.string().min(10).max(10),
    }),
};

const schema = yup.object().shape({
    concernedPerson: yup
        .array()
        .of(yup.object().shape(formSchema))
        .required("Must have fields")
        .min(1, "Minimum of 1 field"),
});

export const DetailsRdv = ({
    appointment,
    setAppointment,
    handleGetAppointments,
    identity,
    type,
}) => {
    const mairies = useMairieStore((state) => state.mairies);
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [loadingReq, setLoadingReq] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [mairieSlotTime, setMairieSlotTime] = useState<any>({});
    const [showANTS, setShowANTS] = useState<boolean>(false);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);
    const mairie = mairies.find((mairie) => mairie.slug === appointment.mairie);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const openPopper = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [alert, setAlert] = useState<any>({
        open: false,
        message:
            "Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur.",
    });
    const [duplicates, setDuplicates] = useState<any>({});
    const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            concernedPerson: appointment.concernedPerson,
        },
    });

    const textUpdate = useMemo(() => {
        if (showANTS) {
            return "Annuler";
        } else {
            if (appointment.concernedPerson?.length > 1) {
                return "Mettre à jour mes numeros de prédemande";
            } else {
                return "Mettre à jour mon numero de prédemande";
            }
        }
    }, [appointment, showANTS]);

    const profile = useMemo(() => {
        if (mairie?.picture) return API + mairie?.picture;

        return null;
    }, [mairie?.picture]);

    useEffect(() => {
        if (!isEmpty(duplicates)) {
            setOpenDuplicate(true);
        }
    }, [duplicates]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseModalDuplicate = () => {
        setOpenDuplicate(false);
    };

    const handleCloseAlert = () => {
        setAlert((prevState) => ({ ...prevState, open: false }));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (data) => {
        setAppointment(data);
    };

    const getMotif = useCallback(async () => {
        setLoading(true);
        if (type !== "old") {
            const res: any = await getMotifsBySlug(appointment?.mairie);
            let mairieSlotTime: any = {};
            if (res) {
                const selectedMotif = appointment.typeDossier.replaceAll(
                    "_",
                    " "
                );
                if (res?.length > 0) {
                    const motif = res.find(
                        (data) => data.name === selectedMotif
                    );
                    if (motif && appointment.dureeRdv === motif.antsDuration) {
                        mairieSlotTime = await handleGetMairieSlotTime({
                            startDate: dayjs().format("YYYY-MM-DD"),
                            endDate: dayjs()
                                .add(12, "month")
                                .format("YYYY-MM-DD"),
                            slug: appointment.mairie,
                            typeDossier: appointment.typeDossier,
                            isOnline: true,
                            nombrePersonne: appointment.nombrePersonne,
                            isANTS: true,
                        });
                    } else {
                        mairieSlotTime = await handleGetMairieSlotTime({
                            startDate: dayjs().format("YYYY-MM-DD"),
                            endDate: dayjs()
                                .add(12, "month")
                                .format("YYYY-MM-DD"),
                            slug: appointment.mairie,
                            typeDossier: appointment.typeDossier,
                            isOnline: true,
                            nombrePersonne: appointment.nombrePersonne,
                            isANTS: false,
                        });
                    }
                }
            }
            setLoading(false);

            if (mairieSlotTime) {
                setMairieSlotTime(mairieSlotTime);
            }
        }
    }, [appointment]);

    useEffect(() => {
        getMotif();
    }, [getMotif]);

    useEffect(() => {
        setTimeout(() => {
            setSuccess(false);
        }, 10000);
    }, [success]);

    const handleOnDateSelect = async (calendar, data, mairie) => {
        setLoadingReq(true);
        const [hours, minutes] = data.hour.split(":");
        const newHeureRdv = dayjs(dayjs(calendar).hour(hours).minute(minutes))
            .second(0)
            .format()
            .split("+")[0];
        const input = {
            heureRdv: newHeureRdv,
        };
        for (const person of appointment?.concernedPerson) {
            await handleDeleteAppointment({
                ants: person?.ants,
                meeting_point: mairie?.name,
                meeting_point_id: mairie?._id,
                appointment_date: appointment?.heureRdv?.replace("T", " "),
            });
        }
        const res = await handleUpdateAppointment({
            ...input,
            _id: appointment._id,
        });

        if (res?.success) {
            setLoadingReq(false);
            await handleAddHistories({
                phoneNumber: appointment.phoneNumber,
                appointmentId: appointment._id,
                status: appointment?.status,
                mairie: appointment.mairie,
                heureRdv: newHeureRdv,
                typeDossier: appointment.typeDossier,
                nombrePersonne: appointment?.nombrePersonne,
                usager: appointment.usager,
            });

            await handlePostAppointment({
                ...res?.data,
                meeting_point: mairie.name,
                meeting_point_id: mairie._id,
            });
            refetchBusyDate();
            handleClose();
            setOpen(false);
            handleGetAppointments(identity);
            setSuccess(true);
        }
        setLoadingReq(false);
    };

    const handleUpdateANTS = () => {
        setShowANTS((prevState) => !prevState);
    };

    const onSubmitHandler = async (data) => {
        let success = true;
        setLoadingReq(true);
        const mairie = await handleGetMairieBySlug(appointment.mairie);
        for (const person of appointment?.concernedPerson) {
            await handleDeleteAppointment({
                ants: person?.ants,
                meeting_point: mairie?.name,
                meeting_point_id: mairie?._id,
                appointment_date: appointment?.heureRdv?.replace("T", " "),
            });
        }
        if (data?.concernedPerson[0]?.ants !== "") {
            const appointmentsId = data?.concernedPerson.reduce(
                (prev, curr) => [...prev, curr.ants],
                []
            );
            const resAntsIdsStatus = await handleGetStatus(appointmentsId, mairie?._id);
            if (!isEmpty(resAntsIdsStatus)) {
                Object.keys(resAntsIdsStatus).map(async (key) => {
                    const resAnts = testStatusAntsNumber(
                        resAntsIdsStatus[key]?.status
                    );
                    if (resAnts?.success) {
                        if (resAnts?.error) {
                            const isSame = resAntsIdsStatus[
                                key
                            ]?.appointments?.find(
                                (data) =>
                                    data?.meeting_point === mairie?.name &&
                                    data?.appointment_date ===
                                        appointment?.heureRdv
                            );
                            if (
                                !isSame &&
                                resAntsIdsStatus[key]?.appointments?.length > 0
                            ) {
                                setDuplicates(resAntsIdsStatus);
                                success = false;
                            }
                        }
                    } else {
                        setAlert({
                            message: resAnts?.error?.replace(
                                "$num",
                                `<br> <p>- ${key}</p>`
                            ),
                            open: true,
                        });
                        success = false;
                        return "break";
                    }
                });
            }
        }

        if (success) {
            const res = await NODE_API.put("/appointment/" + appointment._id, {
                concernedPerson: data.concernedPerson,
            });
            if (res?.success) {
                await handlePostAppointment({
                    ...res?.data,
                    meeting_point: mairie.name,
                    meeting_point_id: mairie._id,
                });
                handleGetAppointments(identity);
                setSuccess(true);
                setShowANTS(false);
            }
        }
        setLoadingReq(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container className="bloc-list-rdv">
                <Grid item xs={tablette ? 6 : 6}>
                    {success && (
                        <Alert severity="success" className="mt-2">
                            Rendez-vous modifié avec succès!
                        </Alert>
                    )}
                    <Grid container className="flex justify-start items-center">
                        {!tablette && (
                            <Grid item xs={4}>
                                {profile ? (
                                    <img
                                        className="logo-mairie"
                                        src={profile}
                                        width={"100%"}
                                        height={"100%"}
                                        alt={mairie?.name}
                                    />
                                ) : (
                                    <TownHallSvg className="logo-mairie" />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={tablette ? 12 : 8}>
                            <p className="capitalize">
                                {dayjs(appointment.heureRdv)
                                    .locale("fr")
                                    .format("dddd DD MMMM YYYY - HH:mm")}
                            </p>
                            <p className="name-mairie">{mairie?.name}</p>
                            <p className="type-motif">
                                {appointment.typeDossier.replaceAll("_", " ")}
                            </p>
                            <span className="duration">
                                Durée de rendez-vous : {appointment.dureeRdv}{" "}
                                min
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={tablette ? 6 : 6}>
                    <Grid container>
                        <Grid item xs={tablette ? 6 : 6} />
                        <Grid
                            item
                            xs={tablette ? 6 : 6}
                            className="flex justify-end"
                        >
                            {appointment?.status === "CANCELED" && (
                                <p className="status-cancel">Annulé</p>
                            )}
                            {appointment?.status !== "CANCELED" &&
                                type !== "old" && (
                                    <p className="status-actif">Actif</p>
                                )}
                            {appointment?.status === "PENDING" &&
                                type === "old" && (
                                    <p className="status-cancel">Non honoré</p>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                {appointment.status === "PENDING" &&
                    type === "coming" &&
                    !searchParams.get("canceled") && (
                        <Grid
                            item
                            xs={tablette ? 12 : 6}
                            display={"flex"}
                            marginTop={2}
                            gap={2}
                        >
                            <Button
                                variant="contained"
                                color={!showANTS ? "secondary" : "primary"}
                                className="btn-rdv btn-icon"
                                onClick={handleUpdateANTS}
                            >
                                {textUpdate}
                            </Button>
                            {showANTS && (
                                <LoadingButton
                                    loading={loadingReq}
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    className="btn-rdv btn-icon"
                                >
                                    Enregistrer
                                </LoadingButton>
                            )}
                        </Grid>
                    )}
                {searchParams.get("canceled") && (
                    <Grid
                        item
                        xs={tablette ? 12 : 6}
                        display={"flex"}
                        marginTop={2}
                        gap={2}
                    ></Grid>
                )}
                <>
                    {appointment.status === "PENDING" && type === "coming" && (
                        <>
                            {loading ? (
                                <Grid
                                    item
                                    xs={tablette ? 12 : 6}
                                    marginTop={tablette ? 5 : 0}
                                    className="flex justify-center"
                                >
                                    <CustomLoading size={30} />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={tablette ? 12 : 6}
                                    className={
                                        tablette
                                            ? "flex justify-end items-end gap-3 flex-wrap"
                                            : "flex justify-end items-end gap-3"
                                    }
                                    marginTop={tablette ? 3 : 0}
                                >
                                    {!searchParams.get("canceled") && (
                                        <LoadingButton
                                            loading={loadingReq}
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<EventIcon />}
                                            className="btn-rdv btn-icon"
                                            onClick={(e) => handleClick(e)}
                                        >
                                            Reprogrammer le RDV
                                        </LoadingButton>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-rdv btn-reset btn-icon"
                                        startIcon={<CancelIcon />}
                                        onClick={() =>
                                            handleDelete(appointment)
                                        }
                                    >
                                        Annuler le RDV
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </>
                {showANTS && (
                    <Grid item xs={tablette ? 12 : 6}>
                        {appointment.concernedPerson.map((person, index) => (
                            <Stack
                                marginTop={2}
                                gap={3}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <p className="mt-5">
                                    {person.firstName} {person.lastName}
                                </p>
                                <Stack>
                                    <p className="mb-0">Numéro ANTS</p>
                                    <FormControl>
                                        <OutlinedInput
                                            className={"bg-white"}
                                            placeholder={"10 caractères"}
                                            size="small"
                                            {...register(
                                                `concernedPerson.${index}.ants`,
                                                { required: true }
                                            )}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                            onInput={(e: any) =>
                                                (e.target.value = (
                                                    "" + e.target.value
                                                ).toUpperCase())
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {!!errors[
                                                        `concernedPerson.${index}..ants`
                                                    ] ? (
                                                        <CancelRounded
                                                            color={"error"}
                                                        />
                                                    ) : (
                                                        !!getValues(
                                                            `concernedPerson.${index}.ants`
                                                        ) && (
                                                            <CheckCircle
                                                                color={
                                                                    "secondary"
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                        ))}
                    </Grid>
                )}
                <Popover
                    id={id}
                    open={openPopper}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Box sx={{ maxWidth: "650px", padding: "10px" }}>
                        <FullCalendarView
                            onDateSelect={(calendar, hour) =>
                                handleOnDateSelect(calendar, hour, mairie)
                            }
                            item={mairieSlotTime}
                        />
                    </Box>
                </Popover>
                <CustomDialog
                    setOpen={setOpenDuplicate}
                    open={openDuplicate}
                    onClose={handleCloseModalDuplicate}
                    component={<DetailsDuplicate duplicates={duplicates} />}
                />
                <Popup
                    message={alert?.message}
                    type="error"
                    open={alert?.open}
                    handleClose={handleCloseAlert}
                />
            </Grid>
        </form>
    );
};
