import { useEffect, useMemo } from 'react';
import '../styles/layout.style.scss';
import {Outlet, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import {TopbarBO } from ".";
import { useSessionStorage } from 'usehooks-ts';
import { getMotifs, useAuthStore } from '../store/auth.store';
import { useMairieStore, refetchListMairies, getDefaultMotifs, getListMairies, refetchListAgendas, getDuplicatedDate, getCanceledDate, getListMotifs, getTypeDossiers, refetchListUsagers, getMairieAgent, getAntsRdv } from '../store/mairie.store';
import { changeSlug, getMairieAgendasBySlug } from '../store/agenda.store';

function Layout() {
    const location = useLocation();
    const  user  = useAuthStore((state) => state.user);
    const  logged  = useAuthStore((state) => state.logged);
    const  mairies  = useMairieStore((state) => state.mairies);
    const  agendas  = useMairieStore((state) => state.agendas);
    const [session, setSession] = useSessionStorage<any>('session', {});
    const isIframe = useMemo(() => {
      return location.pathname.includes('iframe');
    }, [location.pathname]);

    useEffect(() => {
      if(session?.ville){
        getMotifs(session?.ville?.slug, isIframe)
      }
    }, [session?.ville, isIframe]);

    useEffect(() => {
      getDefaultMotifs();
      refetchListMairies();
      getListMairies();
    }, []);

    useEffect(() => {
      if(mairies[0] && user){
        refetchListAgendas({ mairies: mairies, user: user})
        getMairieAgendasBySlug({ slug: mairies[0]?.slug, user: user })
      }
    }, [mairies, user]);
     
    useEffect(() => {
      getDuplicatedDate(mairies);
      getAntsRdv(mairies, 'consumed');
      getAntsRdv(mairies, 'expired');
      getAntsRdv(mairies, 'validated');
      getCanceledDate(mairies);
      getListMotifs(mairies);
      getMairieAgent(mairies);
      refetchListUsagers();
      changeSlug(mairies[0]?.slug);
    }, [mairies]);

    useEffect(() => {
      getTypeDossiers(agendas)
    }, [agendas])

    useEffect(() => {
      if(localStorage.getItem('info') && !user)
        useAuthStore.getState().setUser(JSON.parse(localStorage.getItem('info') as string));
      if(localStorage.getItem('access_token') && !logged)
        useAuthStore.getState().setLogged(JSON.parse(localStorage.getItem('access_token') as string));
    }, [user, logged])

    return (
        <>
            <Box>
                <TopbarBO />
                <Outlet/>
            </Box>
        </>
    );
}

export default Layout;
